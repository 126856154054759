import DetailLabel from 'components/DetailLabel/detailLabel';
import { HEADING_4 } from 'components/text';
import DefaultAvatar from 'assets/hub-default-user-profile.svg';
import MoreIconFilled from 'assets/icons/more-icon-filled.svg';
import MoreIcon from 'assets/icons/more-icon.svg';
import profileStyles from 'hub/memberProfilePage/memberProfile.module.scss';
import Dropdown from 'components/dropdown';
import { MenuItem, MenuList } from '@material-ui/core';
import { GroupUserAccess } from 'types/cohort';
import { DashboardRole } from 'types/dashboardUser';
import { useAccounts } from 'context/account-context';
import { Icon } from '@whoop/web-components';
import styles from './profile.module.scss';

export type ProfileData = {
  heading: string;
  value: string;
};

export type UserInfo = {
  name: string;
  avatar_url: string;
  subheading: string;
  user_id: number;
  role: DashboardRole;
};

type ProfileProps = {
  leftColumn: ProfileData[];
  rightColumn: ProfileData[];
  topHeader: string;
  bottomHeader: string;
  user: UserInfo;
  groups: GroupUserAccess[];
  handleRemoveMemberFromGroup: (group: GroupUserAccess) => void;
  profileOptionDropdown: boolean;
  handleRemoveMemberFromAccount: () => void;
};

function Profile({
  leftColumn,
  rightColumn,
  topHeader,
  user,
  bottomHeader,
  groups,
  handleRemoveMemberFromGroup,
  profileOptionDropdown,
  handleRemoveMemberFromAccount,
}: ProfileProps) {
  const { checkAccess } = useAccounts();
  return (
    <>
      <div className={`${profileStyles.detailHeader} ${styles.detailHeaderContainer}`}>
        <div className={styles.flex}>
          <img src={user?.avatar_url ? user.avatar_url : DefaultAvatar} alt="my_account" className={styles.avatar} />
          <div>
            <div className={profileStyles.idText}>
              {user?.name}
            </div>
            <div className={styles.subheadingText}>
              {user?.subheading}
            </div>
          </div>
        </div>
        {profileOptionDropdown && (
          <div>
            <Dropdown
              button={<img src={MoreIcon} alt="more" />}
              buttonProps={{ variant: 'link', 'aria-label': 'more_options_btn' }}
              dropdownStyles={{ borderTop: '3px solid black' }}
              closeOnClick
            >
              <MenuList disablePadding>
                <MenuItem onClick={() => handleRemoveMemberFromAccount()}>
                  <span className={styles.removeMember}>Remove from Account</span>
                </MenuItem>
              </MenuList>
            </Dropdown>
          </div>
        )}
      </div>
      <div className={profileStyles.section}>
        <HEADING_4>{topHeader}</HEADING_4>
        <div className={profileStyles.sectionContainer}>
          {leftColumn.map(
            (column) => (
              <DetailLabel
                key={column.value}
                title={column.heading}
                data={column.value}
              />
            ),
          )}
        </div>
        <div className={profileStyles.sectionContainer}>
          {rightColumn.map(
            (column) => (
              <DetailLabel
                key={column.value}
                title={column.heading}
                data={column.value}
              />
            ),
          )}
        </div>
      </div>
      <div className={profileStyles.section}>
        <HEADING_4>{bottomHeader}</HEADING_4>
        {groups.length > 0
          && (
            <div className={styles.groupContainer}>
              {groups.map((group) => (
                <div key={group.id} className={styles.groupBox}>
                  <div className={styles.groupTitle}>
                    {group.name}
                  </div>
                  {user?.role === DashboardRole.GROUP_MANAGER
                    && checkAccess(DashboardRole.ACCOUNT_MANAGER) && (
                      <div className={styles.moreButton}>
                        <Dropdown
                          button={<img src={MoreIconFilled} alt="more" />}
                          buttonProps={{ variant: 'link', 'aria-label': 'more_options_group_btn' }}
                          dropdownStyles={{ borderTop: '3px solid black' }}
                          closeOnClick
                        >
                          <MenuList disablePadding>
                            <MenuItem onClick={() => handleRemoveMemberFromGroup(group)}>
                              <span className={styles.removeMember}>Remove from Group</span>
                            </MenuItem>
                          </MenuList>
                        </Dropdown>
                      </div>
                  )}
                </div>
              ))}
            </div>
          )}
        {groups.length === 0 && (
          <div className={styles.emptyGroupContainer}>
            <Icon name="community" />
            <p>No Groups Managed Yet</p>
          </div>
        )}
      </div>
    </>
  );
}

export default Profile;
