import { AddHubMemberRequest, HubUserProfile } from 'types/hub';
import { ActivateSensorReducerState } from '../../../wizards/hub/activateSensorWizard/utils';

export enum EditMemberProfileSteps {
  Input = 'input',
  Loading = 'loading',
  Error = 'error',
  Edit = 'edit',
  Done = 'done',
}

export type HubUserProfileReducerState = {
  step: EditMemberProfileSteps;
  error: Error;
  hubs: string[];
  sensorIds: string[];
  firstName: string;
  lastName: string;
  username: string;
  sensorId: string;
  dateOfBirth: string;
  gender: string;
  height: string;
  weight: string;
  hub: string;
  email: string;
  patientIdNumber: string;
};

export type HubUserProfileReducerAction =
| { type: 'next' }
| { type: 'loading'; value: boolean }
| { type: 'setHubs'; hubs: string[] }
| { type: 'setSensorIds'; sensorIds: string[] }
| { type: 'handleInputText'; field: string; value: string }
| { type: 'error'; payload: Error }
| { type: 'done' };

export const editMemberProfileReducer = (
  state: HubUserProfileReducerState,
  action: HubUserProfileReducerAction,
): HubUserProfileReducerState => {
  switch (action.type) {
    case 'next':
      if (state.step === EditMemberProfileSteps.Input) {
        return {
          ...state,
          step: EditMemberProfileSteps.Edit,
        };
      } if (state.step === EditMemberProfileSteps.Loading) {
        return {
          ...state,
          step: EditMemberProfileSteps.Input,
        };
      }
      return {
        ...state,
      };
    case 'setHubs':
      return {
        ...state,
        hubs: action.hubs,
      };
    case 'setSensorIds':
      return {
        ...state,
        sensorIds: action.sensorIds,
      };
    case 'handleInputText':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'error':
      return {
        ...state,
        step: EditMemberProfileSteps.Error,
      };
    case 'loading':
      if (action.value) {
        return {
          ...state,
          step: EditMemberProfileSteps.Loading,
        };
      }
      return {
        ...state,
        step: EditMemberProfileSteps.Input,
      };
    case 'done':
      return {
        ...state,
        step: EditMemberProfileSteps.Done,
      };
    default:
      return state;
  }
};

const getHeightLabel = (meterHeight: number) => {
  const totalInches: number = Math.round(meterHeight * 39.3701);
  const feet: number = Math.floor(totalInches / 12);
  const inches: string = (totalInches % 12).toFixed(0);

  if (Number(inches) > 0) {
    return `${feet} ft ${inches} in`;
  }
  return `${feet} ft`;
};

const getWeightLabel = (weight: number) => `${Math.round(weight * 2.20462)} lbs`;

export const parseHubData = (data: HubUserProfile): HubUserProfileReducerState => ({
  firstName: data?.first_name,
  lastName: data?.last_name,
  username: data?.username,
  sensorId: data?.strap_serial,
  dateOfBirth: data?.birthday,
  gender: data?.gender,
  height: getHeightLabel(data?.height),
  weight: getWeightLabel(data?.weight),
  hub: data?.hub_names.join(', '),
  email: data?.email,
  patientIdNumber: data?.member_identifier,
  step: EditMemberProfileSteps.Input,
  error: null,
  hubs: [],
  sensorIds: [],
});

const getMeterHeight = (height: string) => {
  const ftInch = height.split(' ');
  const ft = Number(ftInch[0]);
  let inches = 0;
  if (ftInch.length > 2) {
    inches = Number(ftInch[2]);
  }
  return ft * 0.3048 + inches * 0.0254;
};

const getKgWeight = (weight: string) => {
  const lbs: number = Number(weight.split(' ')[0]);
  return lbs * 0.453592;
};

export const getProfileFromState = (state: HubUserProfileReducerState | ActivateSensorReducerState)
: HubUserProfile => ({
  member_identifier: state.patientIdNumber,
  first_name: state.firstName,
  last_name: state.lastName,
  username: state.username,
  email: state.email,
  strap_serial: state.sensorId,
  birthday: state.dateOfBirth,
  gender: state.gender,
  height: getMeterHeight(state.height),
  weight: getKgWeight(state.weight),
  hub_names: state.hub.split(', '),
});

export const getAddHubMemberRequestFromState = (state: ActivateSensorReducerState)
: AddHubMemberRequest => ({
  member_identifier: state.patientIdNumber,
  password: state.newPassword,
  strap_serial: state.sensorId,
  birthday: state.dateOfBirth,
  gender: state.gender,
  height: getMeterHeight(state.height),
  weight: getKgWeight(state.weight),
  hub_names: state.hub.split(', '),
});
