import PropTypes from 'prop-types';
import { Button } from '@whoop/web-components';
import { HEADING_2, P1 } from 'components/text';
import styles from './tableHeader.module.scss';

function TableHeader(props) {
  const {
    heading, total, selected, unit, pageSize, toggleAllRowsSelected, customSubheading,
  } = props;

  const unitPlural = total > 1 ? `${unit}s` : unit;

  const showSelectAllOption = selected > 0 && total > pageSize;

  let subheading;
  if (customSubheading) {
    subheading = customSubheading;
  } else {
    subheading = selected > 0
      ? `${selected} selected out of ${total} ${unitPlural}`
      : `${total} ${unitPlural} total`;
  }

  return (
    <div className={styles.tableHeader}>
      <HEADING_2 className={styles.heading}>{heading}</HEADING_2>
      <P1 className={styles.subtext}>
        {subheading}
        {showSelectAllOption && (
          <Button
            label={`Select all ${total} across pages?`}
            variant="link"
            className={styles.selectAll}
            onClick={toggleAllRowsSelected}
            data-testid="show-all-btn"
          />
        )}
      </P1>
    </div>
  );
}

TableHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  selected: PropTypes.number,
  pageSize: PropTypes.number,
  toggleAllRowsSelected: PropTypes.func,
  customSubheading: PropTypes.string,
};

TableHeader.defaultProps = {
  selected: 0,
  pageSize: 100,
  toggleAllRowsSelected: () => {},
  customSubheading: '',
};

export default TableHeader;
