import { ResponsiveBar, BarDatum, BarTooltipProps } from '@nivo/bar';
import { Colors } from '@whoop/web-components';
import GraphTooltip from 'progress/summary/GraphTooltip/graphTooltip';
import { ActivationType } from 'progress/summary/RecoverySection/MemberActivationSection/MemberActivationsLabel/memberActivationsLabel';

type MemberActivationsChartProps = {
  data: BarDatum[];
};

const margins = {
  top: 0, right: 0, bottom: 20, left: 0,
};

function MemberActivationsChart({ data }: MemberActivationsChartProps) {
  const getTooltip = (node: BarTooltipProps<BarDatum>) => <GraphTooltip activationType={node.id} description={`${node.id === 'active' ? 'activated' : node.id} ${node.id === ActivationType.INVITED ? 'invitations' : 'memberships'}`} value={node.value} />;

  return (
    <ResponsiveBar
      data={data}
      keys={['active', 'pending', 'invited', 'inactive']}
      padding={0.1}
      margin={margins}
      indexBy="y"
      layout="horizontal"
      enableLabel={false}
      colors={[Colors.teal, Colors.sleepBlue, Colors.yellow, Colors.white]}
      borderRadius={2}
      enableGridX={false}
      enableGridY={false}
      axisLeft={null}
      tooltip={(node: BarTooltipProps<BarDatum>) => (getTooltip(node))}
      axisBottom={null}
      defs={[{
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: '#E6E6E6',
        rotation: -45,
        lineWidth: 2,
        spacing: 4,
      }]}
      fill={[{
        match: {
          id: 'inactive',
        },
        id: 'lines',
      }]}
    />
  );
}

export default MemberActivationsChart;
