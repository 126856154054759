import { useNavigate } from 'react-router-dom';
import { Button } from '@whoop/web-components';
import SuccessIcon from 'assets/icons/checkGreen.svg';
import styles from '../forgot.module.scss';

type CheckEmailProps = {
  email: string;
};

function CheckEmail({ email }: CheckEmailProps) {
  const navigate = useNavigate();

  return (
    <div className={styles.checkEmail}>
      <div className={styles.checkEmailInfoText}>
        <img className={styles.logo} src={SuccessIcon} alt="Success" />

        <h1>Reset Link Sent</h1>
        <p>{`A link to reset your password has been sent to ${email}.`}</p>
      </div>
      <Button
        label="Return to Sign In"
        variant="normal"
        data-testid="backToLoginBtn"
        className={styles.checkEmailReturnToSignIn}
        onClick={() => navigate('/login')}
      />
    </div>
  );
}

export default CheckEmail;
