import dayjs from 'dayjs';
import { ActivityDetails } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import { Metrics } from 'types/analytics';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { deserializeRange } from '@whoop/web-commons';
import { convertStrainToString } from 'progress/utils/numberFormatter';
import { SectionData } from './activitySection';

dayjs.extend(advancedFormat);

export type strainLevelDataType = {
  level: string;
  cycle_id: number;
  name: string;
  strain: string;
  activityId: string;
  fromDate: Date;
};

export const getStrainLevelData = (
  activityData: ActivityDetails[],
  uniqueStrainLevels: Set<string>,
) => {
  const strainLevelOrder = ['All-Out', 'Strenuous', 'Moderate', 'Light'];
  const uniqueStrainLevelsList = [...uniqueStrainLevels];

  const strainLevelData: strainLevelDataType[] = activityData?.map((activity) => {
    let level = 'Light';
    if (activity.scaled_intensity_score > 10 && activity.scaled_intensity_score <= 14) {
      level = 'Moderate';
    } else if (activity.scaled_intensity_score > 14 && activity.scaled_intensity_score <= 18) {
      level = 'Strenuous';
    } else if (activity.scaled_intensity_score > 18 && activity.scaled_intensity_score > 18) {
      level = 'All-Out';
    }
    return {
      level,
      cycle_id: activity.cycle_id,
      name: activity.type,
      strain: convertStrainToString(activity.scaled_intensity_score),
      activityId: activity.id,
      // @ts-ignore - dayjs type error with cycle_day_start being an array of numbers
      fromDate: dayjs(activity.cycle_day_start).toDate(),
    };
  });

  const finalStrainData = uniqueStrainLevelsList.map((level) => {
    const levelList: SectionData[] = [];
    strainLevelData.forEach((strainData: strainLevelDataType) => {
      if (strainData.level === level) {
        levelList.push({
          rowTitle: strainData.name,
          rowDetail: strainData.strain,
          fromDate: strainData.fromDate,
          activityId: strainData.activityId,
        });
      }
    });
    return { level, count: levelList.length, level_list: levelList };
  });
  return finalStrainData.sort((a, b) => strainLevelOrder.indexOf(a.level)
      - strainLevelOrder.indexOf(b.level));
};

export const getActivityData = (activityData: ActivityDetails[], uniqueActivities: Set<string>) => {
  const uniqueActivityDataList = [...uniqueActivities];
  return uniqueActivityDataList.map((activityName) => {
    const dateList: SectionData[] = [];
    activityData.forEach((activity) => {
      if (activity.type === activityName) {
        const lowerBound = deserializeRange(activity.during).lower.date;
        dateList.push({
          rowTitle: `${dayjs(lowerBound).format('ddd, MMM D')}\xa0\xa0${dayjs(lowerBound).format('H:mm')}`,
          rowDetail: convertStrainToString(activity.scaled_intensity_score),
          // @ts-ignore - dayjs type error with cycle_day_start being an array of numbers
          fromDate: dayjs(activity.cycle_day_start).startOf('day').toDate(),
          activityId: activity.id,
        });
      }
    });
    return { name: activityName, count: dateList.length, date_list: dateList };
  });
};

export const getDayData = (
  activityData: ActivityDetails[],
  dayStrain: Metrics[],
  uniqueDays: Set<string>,
) => {
  const uniqueDayList = [...uniqueDays];
  return uniqueDayList.map((day) => {
    const activityList: SectionData[] = [];
    let cycle_day_start = '';

    activityData.forEach((activity) => {
      const stringDate = activity.cycle_day_start.join('-');
      const date = dayjs(stringDate, 'YYYY-MM-DD').format('ddd, MMM Do');
      if (day === date) {
        activityList.push({
          rowTitle: activity.type,
          rowDetail: convertStrainToString(activity.scaled_intensity_score),
          fromDate: dayjs(stringDate, 'YYYY-MM-DD').startOf('day').toDate(),
          activityId: activity.id,
        });
        cycle_day_start = stringDate;
      }
    });
    const avgStrain = dayStrain?.find((a) => a.cycle_start_day.join('-') === cycle_day_start);
    return {
      date: day,
      day_strain: convertStrainToString(avgStrain?.metric_value),
      activities: activityList,
    };
  });
};
