import {
  baseGroups,
} from '../store/groups';
import { getAccount, getCollaborators, getDashboardUsers } from './accountActions';
import { getSeats } from './seatActions';

export const getGroups = (includePrimaryGroup) => {
  const groups = baseGroups.map((group) => {
    const { seatIds, ...rest } = group;
    return { ...rest };
  });
  if (!includePrimaryGroup) {
    return groups.filter((g) => !g.is_primary);
  }
  return groups;
};

export const getGroup = (groupId) => {
  const { seatIds, ...rest } = baseGroups.find((g) => g.id === groupId);
  return { ...rest };
};

export const createGroup = (group) => {
  const newGroup = {
    name: group.name,
    member_count: 1,
    avatar_url: null,
    collaborators: [],
    about: group.about,
    id: baseGroups.length + 1,
    community_id: baseGroups.length + 1,
    salesforce_account_id: getAccount().id,
    legacy_team_id: null,
    seatIds: [],
    concealed: group.concealed,
  };
  baseGroups.push(newGroup);
  return newGroup;
};

export const deleteGroup = (groupId) => {
  const currentGroup = baseGroups.find((group) => group.id === groupId);
  const index = baseGroups.indexOf(currentGroup);
  baseGroups.splice(index, 1);
};

export const getGroupMembers = (groupId) => {
  const group = baseGroups.find((g) => g.id === groupId);
  const groupSeats = getSeats().filter((seat) => group.seatIds.includes(seat.id));
  const groupMembers = groupSeats.map((seat) => ({
    ...seat,
    seat_id: seat.id,
    cohort_id: groupId,
  }));
  return {
    total_count: group.seatIds.length,
    offset: 0,
    records: groupMembers,
  };
};

export const addGroupMember = (seatId, groupId) => {
  const group = baseGroups.find((g) => g.id === groupId);
  if (!group.seatIds.includes(seatId)) {
    group.seatIds.push(seatId);
  }
};

export const removeGroupMember = (seatId, groupId) => {
  const group = baseGroups.find((g) => g.id === groupId);
  group.seatIds = group.seatIds.filter((id) => id !== seatId);
};

export const getAvailableGroupMembers = (groupId) => {
  const group = baseGroups.find((g) => g.id === groupId);
  const availableSeatIds = getSeats()
    .filter((id) => !group.seatIds.includes(id));
  return availableSeatIds.map((seat) => ({
    ...seat,
    seat_id: seat.id,
    cohort_id: groupId,
  }));
};

export const getAvailableCollaborators = (groupId) => {
  const group = baseGroups.find((g) => g.id === groupId);
  const availableCollaborators = getDashboardUsers()
    .filter((id) => !group.collaborators.includes(id));
  return availableCollaborators.map((collab) => {
    const nameComponents = collab.name.split(' ');
    return {
      ...collab,
      first_name: nameComponents[0],
      last_name: nameComponents[1],
    };
  });
};

export const addCollaborator = (userId, groupId) => {
  const group = baseGroups.find((g) => g.id === groupId);
  const collaborator = getCollaborators().find((collab) => collab.user_id === userId);
  if (!group.collaborators.includes(collaborator)) {
    group.collaborators.push(collaborator);
  }
};

export const updateGroup = (groupId, group) => {
  const newGroup = baseGroups.find((g) => g.id === groupId);
  newGroup.about = group.about;
  newGroup.concealed = group.concealed;
  return newGroup;
};

export const removeCollaboratorFromGroup = (userId, groupId) => {
  const group = baseGroups.find((g) => g.id === groupId);
  const groupCollaborator = group.collaborators.find((gc) => gc.user_id === userId);
  if (groupCollaborator) {
    const indexOfCollaborator = group.collaborators.indexOf(groupCollaborator);
    group.collaborators.splice(indexOfCollaborator, 1);
  }
};
