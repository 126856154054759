import { ShippingMethodType } from './types';

export const typeEmails = { value: 'type', label: 'Type in Emails' };
export const uploadEmails = { value: 'upload', label: 'Upload CSV' };
export const emailInputOptions = [typeEmails, uploadEmails];
export const inviteSteps = {
  input: 'input',
  shippingMethod: 'shippingMethod',
  details: 'details',
  send: 'send',
  loading: 'loading',
};

export const EMAIL_INPUT_STEP_NUMBER = 1;
export const SHIPPING_METHOD_STEP_NUMBER = 2;
export const TOTAL_STEPS = 2;

export const bulkShippingOption = {
  value: ShippingMethodType.BULK,
  displayValue: 'DISTRIBUTE DEVICES ON SITE',
  description: 'My organization will distribute our unactivated devices to members.',
};

export const directShippingOption = {
  value: ShippingMethodType.DIRECT,
  displayValue: 'SHIP TO MEMBERS DIRECTLY',
  description: 'Devices will be mailed and distributed individually to new members.',
};

export const old_bulkShippingOption = {
  value: ShippingMethodType.BULK,
  displayValue: 'BULK SHIPPING',
  description: "WHOOP devices will be shipped in bulk to your organization's address.",
};

export const old_directShippingOption = {
  value: ShippingMethodType.DIRECT,
  displayValue: 'DIRECT SHIPPING',
  description: 'WHOOP devices will be shipped individually to new members.',
};
