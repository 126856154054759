import styles from './pillarBreakdownBar.module.scss';
import { PillarBarDetails } from './pillarBreakdownBarConsts';

type PillarBreakdownBarProps = {
  count: number;
  displayVals: PillarBarDetails;
};

function PillarBreakdownBarLabel({ count, displayVals }: PillarBreakdownBarProps) {
  if (!count) {
    // no data state
    return <div />;
  }
  return (
    <div data-testid="breakdown-label" className={styles.label}>
      <div className={styles.labelSquare} style={{ backgroundColor: displayVals.color }} />
      <div className={styles.labelCount}>{`${count}x`}</div>
      <div className={styles.labelName}>{displayVals.name}</div>
      <div className={styles.labelDescription}>{`(${displayVals.description})`}</div>
    </div>
  );
}

export default PillarBreakdownBarLabel;
