import { useEffect, useState } from 'react';
import { PulsingLogo } from '@whoop/web-components';
import { longDateFormat } from 'helpers/dateUtils';
import GlassTile from 'accountDetails/glassTile/glassTile';
import WhoopCircleBlack from 'assets/whoop-circle-black.svg';
import { useAccounts } from 'context/account-context';
import { getAllCollaborators } from 'api/dashboardApi';
import { HEADING_2 } from 'components/text';
import { DashboardRole } from 'types/dashboardUser';
import accountDetailStyles from 'accountDetails/accountDetails.module.scss';
import { trackEvent } from 'helpers/analyticsTracking';
import { Collaborator, InviteStatus } from 'types/collaborator';
import styles from './summary.module.scss';

function Summary() {
  const { loading, account } = useAccounts();
  const [accountAdmin, setAccountAdmin] = useState<Collaborator>();

  useEffect(() => {
    const fetchData = async () => {
      const roles: Collaborator[] = await getAllCollaborators();
      const activeCollaborators = roles.filter((collab) => collab.status === InviteStatus.accepted);
      setAccountAdmin(activeCollaborators.find(
        (collab) => collab.role === DashboardRole.ACCOUNT_ADMIN,
      ));
    };
    fetchData();
  }, []);

  if (loading) {
    return <PulsingLogo />;
  }

  const handleEmailCustomSuccessClick = () => {
    trackEvent('Email Customer Success - Button Click', {
      accountId: account.id,
    });
    window.open(
      'https://support.whoop.com/Submit/a/Request-WHOOP-Unite',
      '_blank',
    );
  };

  return (
    <div className={accountDetailStyles.componentContainer}>
      <div className={styles.content}>
        <HEADING_2>Plan Summary</HEADING_2>
        <p className={styles.headingText}>
          Confirm the terms of your WHOOP Unite plan.
        </p>
        <div className={styles.wrapper}>
          <div className={styles.sectionContainer}>
            <div className={styles.infoItem}>
              <p className={styles.itemTitle}>ACCOUNT NAME</p>
              <p data-testid="account-name">{account.account_name}</p>
            </div>
            <div className={styles.infoItem}>
              <p className={styles.itemTitle}>CONTRACT START DATE</p>
              <p>{longDateFormat(new Date(account.start_date))}</p>
            </div>
            <div className={styles.infoItem}>
              <p className={styles.itemTitle}>TOTAL LICENSES PURCHASED</p>
              <p>{account.total_licenses}</p>
            </div>
          </div>
          <div className={styles.sectionContainer}>
            <div className={styles.infoItem}>
              <p className={styles.itemTitle}>ACCOUNT ADMIN</p>
              <p className={styles.primaryOwner}>
                {!accountAdmin || !accountAdmin?.name ? (
                  'No set Primary Owner'
                ) : (
                  <>
                    {accountAdmin.name}
                    {' '}
                    <span className={styles.primaryOwnerEmail}>
                      {accountAdmin.email}
                    </span>
                  </>
                )}
              </p>
            </div>
            <div className={styles.infoItem}>
              <p className={styles.itemTitle}>CONTRACT EXPIRES ON</p>
              <p>{longDateFormat(new Date(account.end_date))}</p>
            </div>
            <div className={styles.infoItem}>
              <p className={styles.itemTitle}>TOTAL ACTIVE LICENSES</p>
              <p>{account.active_seats}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <GlassTile
          title="Have a question?"
          text="Our dedicated support team is here to help with any questions that you may have."
          img={WhoopCircleBlack}
          buttonText="Contact Support"
          buttonAction={handleEmailCustomSuccessClick}
        />
      </div>
    </div>
  );
}

export default Summary;
