import GraphLabel from 'progress/summary/GraphLabel/graphLabel';
import styles from './memberActivationsLabel.module.scss';

type MemberActivationsLabelProps = {
  activationType: ActivationType;
  rightMargin?: boolean;
  value: number | string;
  totalCount: number;
};

export enum ActivationType {
  ACTIVATED = 'activated',
  PENDING = 'pending',
  INVITED = 'invited',
  INACTIVE = 'inactive',
}

function MemberActivationsLabel({
  activationType, value, totalCount, rightMargin = false,
}: MemberActivationsLabelProps) {
  return (
    <div className={`${styles.labelGroup} ${rightMargin ? styles.rightMargin : ''}`}>
      <GraphLabel color={activationType} showSquare>
        {`${activationType}  ${activationType === ActivationType.INVITED ? 'invitations' : 'memberships'}`}
      </GraphLabel>
      <div className={styles.membershipNumbers}>
        <div className={styles.membershipNumber}>
          {value}
        </div>
        <div className={styles.totalMemberships}>
          /
          {totalCount}
        </div>
      </div>
    </div>
  );
}

export default MemberActivationsLabel;
