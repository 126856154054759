import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import { AxisTickProps } from '@nivo/axes';
import { getGridVals } from 'dataVisualizations/utils/utils';
import { DayMonthXAxisTick } from 'dataVisualizations/axis/axis';
import { commonProps } from 'dataVisualizations/heartRateLineGraph/heartRateLineGraphConsts';
import { DayLineTooltip } from 'dataVisualizations/tooltips/tooltips';
import { CustomHoverPoint } from 'dataVisualizations/points/points';
import { LineData } from 'dataVisualizations/types/types';
import { timeAxisValues } from 'dataVisualizations/axis/utils';

type DayLineChartProps = {
  data: LineData[];
  maxVal: number;
  color: string;
  metricName: string;
  customLabelFormat?: (val: number) => string;
};

const margins = {
  top: 50, right: 5, bottom: 50, left: 12,
};

function DayLineChart({
  data,
  maxVal,
  color,
  metricName,
  customLabelFormat,
}: DayLineChartProps) {
  const dateAxisValues = timeAxisValues(
    data[0].x,
    data[data.length - 1].x,
    7,
  );

  return (
    <ResponsiveLine
      {...commonProps}
      data={[{ id: metricName, data }]}
      margin={margins}
      curve="linear"
      yScale={{
        type: 'linear',
        min: 0,
        max: maxVal,
      }}
      gridYValues={getGridVals(maxVal, 4)}
      axisBottom={{
        tickValues: dateAxisValues,
        tickSize: 0,
        renderTick: (tick: AxisTickProps<string>) => DayMonthXAxisTick(tick, false),
      }}
      axisLeft={null}
      colors={color}
      tooltip={(tooltip: PointTooltipProps) => DayLineTooltip(
        tooltip,
        metricName,
        data.length,
        customLabelFormat,
      )}
      layers={['areas', 'grid', 'axes', 'crosshair', 'lines', CustomHoverPoint, 'slices', 'mesh', 'legends']}
    />
  );
}

export default DayLineChart;
