import { useState } from 'react';
import { Button } from '@whoop/web-components';
import Loading from 'loading';
import { getNumFulfilled } from 'helpers/bulkActionUtils';
import ActionModal from 'components/actionModal/actionModal';
import { MEMBERS_COLUMN, SELECTION_COLUMN } from 'tableUtils/tableColumns';
import { SeatDetail } from 'types/seat';
import createHeading from 'helpers/modalUtils';
import { useAccounts } from 'context/account-context';
import { cancelInvite } from '../api/seatApi';
import { PAGE_STATE } from '../constants/pageState';

type WithdrawInviteModalProps = {
  selectedRows: any[];
  setSelectedRows: (selectedRows: any[]) => void;
  refetchData: () => void;
  setPageState: (pageState: string) => void;
  triggerToast: (message: string) => void;
};

function WithdrawInviteModal({
  selectedRows,
  setSelectedRows,
  refetchData,
  setPageState,
  triggerToast,
}: WithdrawInviteModalProps) {
  const [loading, setLoading] = useState(false);
  const { updateAccount } = useAccounts();

  const toastMessage = (fulfilled: number, failed: number) => {
    let msg = '';
    if (fulfilled) {
      msg += `${fulfilled} invite${
        fulfilled === 1 ? ' has' : 's have'
      } been withdrawn and you now have more available seats to invite members.`;
    }
    if (failed) {
      msg += ` ${failed} member failed to be withdrawn.`;
    }
    return msg;
  };

  const handleRevokeInvites = async () => {
    const promises = selectedRows.map((row) => cancelInvite(row.original.id));
    setLoading(true);
    const results = await Promise.allSettled(promises);
    const numFulfilled = getNumFulfilled(results);
    const numFailed = selectedRows.length - numFulfilled;
    setLoading(false);
    setSelectedRows([]);
    setPageState(PAGE_STATE.DASHBOARD);
    await refetchData(); // Fetch Seat Data
    updateAccount(); // Update License Count
    triggerToast(toastMessage(numFulfilled, numFailed));
  };

  const ActionButton = (
    <Button
      label={`Withdraw ${selectedRows.length} ${
        selectedRows.length === 1 ? 'invitation' : 'invitations'
      }`}
      onClick={handleRevokeInvites}
      disabled={selectedRows.length < 1}
      variant="primary"
    />
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <ActionModal
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setPageState={setPageState}
      ActionButton={ActionButton}
      title="Withdraw invitation?"
      body="Withdrawing the invitation will also remove the invitee's information from this account.
    They will not be able to join this enterprise plan with the original invite link."
      heading={createHeading(selectedRows, 'member', 'members')}
      columnList={[SELECTION_COLUMN, MEMBERS_COLUMN]}
      rowAccessor={(row: SeatDetail) => row.id}
    />
  );
}

export default WithdrawInviteModal;
