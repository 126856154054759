import { Button } from '@whoop/web-components';
import { deployApp, getApps } from 'api/hubApi';
import { AcHubApp, HubListPayload } from 'types/hub';
import { useToast } from 'context/toast-context';
import ActionModal from 'components/actionModal/actionModal';
import { HUBS_COLUMN, SELECTION_COLUMN } from 'tableUtils/tableColumns';
import createHeading from 'helpers/modalUtils';
import styles from './activate.module.scss';

type ActivateHubsModalProps = {
  selectedRows: any[];
  setSelectedRows: (selectedRows: any[]) => void;
  setShowModal: (basePage: boolean) => void;
};

function ActivateHubsModal(props: ActivateHubsModalProps) {
  const {
    selectedRows,
    setSelectedRows,
    setShowModal,
  } = props;

  const { openToast } = useToast();
  const label = selectedRows.length === 1 ? 'hub' : 'hubs';

  const handleDeployApp = async () => {
    try {
      const availableApps: AcHubApp[] = await getApps('master'); // these are in order by version descending
      if (availableApps.length) {
        await deployApp(
          availableApps[0],
          selectedRows.map((row) => row.original.mac_address),
        );
      }
      openToast(`Deploying app to ${selectedRows.length} ${label}. Please wait up to 20 minutes for app install`);
    } catch (error) {
      openToast('Error deploying latest update');
    } finally {
      setSelectedRows([]);
      setShowModal(false);
    }
  };

  const ActionButton = (
    <Button
      label={`Activate ${selectedRows.length} ${label}`}
      onClick={handleDeployApp}
      disabled={!selectedRows.length}
      variant="primary"
      className={styles.activateHub}
    />
  );

  return (
    <ActionModal
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setPageState={setShowModal}
      ActionButton={ActionButton}
      title="Are you sure you want to activate these hubs?"
      body={`Activation could take up to 20 minutes while the app is installed on the ${label}`}
      heading={createHeading(selectedRows, 'hub', 'hubs')}
      columnList={[SELECTION_COLUMN, HUBS_COLUMN]}
      rowAccessor={(row: HubListPayload) => row.name}
    />
  );
}

export default ActivateHubsModal;
