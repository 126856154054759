import { ResponsiveBar, BarTooltipProps, ComputedDatum } from '@nivo/bar';
import GraphTooltip from 'progress/summary/GraphTooltip/graphTooltip';
import { AxisTickProps } from '@nivo/axes';
import { BarData } from 'types/analytics';
import { DayMonthXAxisTick, getColorFromVal, PillarYAxisTick } from 'progress/progressPageUtils';

type BarChartProps = {
  data: BarData[];
  tooltipDescription: string;
  colorToUse?: string;
  useStrainAxis?: boolean;
};

const margins = {
  top: 20, right: 0, bottom: 60, left: 28,
};

const gridValues = [0, 20, 40, 60, 80, 100];
const strainGridValues = ['0.0', '3.5', '7.0', '10.5', '14.0', '17.5', '21.0'];

const getColor = (bar: ComputedDatum<BarData>): string => getColorFromVal(bar.value);

function BarChart({
  data, colorToUse, tooltipDescription, useStrainAxis = false,
}: BarChartProps) {
  const roundValue = (value: number) => {
    if (useStrainAxis) {
      return value.toFixed(1);
    }
    return Math.round(value);
  };

  const getTooltip = (node: BarTooltipProps<BarData>) => <GraphTooltip activationType={node.data.date} description={tooltipDescription} value={`${roundValue(node.data.average)}${useStrainAxis ? '' : '%'}`} />;

  return (
    <ResponsiveBar
      data={data}
      minValue={0}
      maxValue={useStrainAxis ? 21 : 100}
      keys={['average']}
      padding={0.1}
      margin={margins}
      indexBy="date"
      layout="vertical"
      enableLabel={false}
      borderRadius={2}
      enableGridX={false}
      enableGridY
      gridYValues={useStrainAxis ? strainGridValues : gridValues}
      axisLeft={{
        tickSize: 0,
        tickValues: useStrainAxis ? strainGridValues : gridValues,
        renderTick: (tick: AxisTickProps<string>) => PillarYAxisTick(tick, false, useStrainAxis),
      }}
      axisBottom={{
        tickSize: 0,
        renderTick: (tick: AxisTickProps<string>) => {
          if (tick.tickIndex % 2 === 0) {
            return DayMonthXAxisTick(tick, true);
          }
          return <g />;
        },
      }}
      tooltip={(node: BarTooltipProps<BarData>) => (getTooltip(node))}
      colors={colorToUse ? [colorToUse] : getColor}
    />
  );
}

export default BarChart;
