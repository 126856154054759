import { client } from 'api/apiClient';
import { HealthMonitorResponse } from 'types/healthMonitor';

export const getHealthMonitorForCohortMember = async (
  cohortId: string | number,
  cohortMemberUserId: number,
  localDate: string,
) => {
  const params = { cohortId, cohortMemberUserId, localDate };
  const response = await client.get<HealthMonitorResponse>(
    '/enterprise-service/v1/health-monitor',
    { params },
  );
  return response.data;
};

export default getHealthMonitorForCohortMember;
