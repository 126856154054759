import { DashboardRole, PrivacyLevel } from './dashboardUser';

export type Collaborator = {
  name?: string;
  email: string;
  avatar_url?: string;
  user_id?: number;
  display_role?: string;
  privacy_level: PrivacyLevel;
  role: DashboardRole;
  status: InviteStatus;
};

export enum RemoveCollaboratorType {
  GROUP = 'group',
  ACCOUNT = 'account',
}

export const RemoveCollaboratorTypeMapToDisplayString:
Map<RemoveCollaboratorType, string> = new Map([
  [RemoveCollaboratorType.ACCOUNT, 'your account'],
  [RemoveCollaboratorType.GROUP, 'this group'],
]);

export const DashboardRoleHierarchy:
Map<DashboardRole, number> = new Map([
  [DashboardRole.WHOOP_CSM, 4],
  [DashboardRole.ACCOUNT_ADMIN, 3],
  [DashboardRole.ACCOUNT_MANAGER, 2],
  [DashboardRole.GROUP_MANAGER, 1]]);

export enum InviteStatus {
  accepted = 'accepted',
  invited = 'invited',
  bounced = 'bounced',
}

export type AddCollaboratorsReducerState = {
  inviteStep: string;
  emails: string[];

  invitedCollaborators: Collaborator[];
  sentData: boolean;
  error: boolean;
};

export type AddCollaboratorsReducerAction =
    | { type: 'next'; error?: boolean; uploadErrors?: string[] }
    | { type: 'back' }
    | {
      type: 'updateInvitedCollaborator';
      invites: Collaborator[];
    }
    | { type: 'sentData' }
    | { type: 'updatedEmails'; emails: string[] }
    | { type: 'error' };

export type WhoopButtonProps = {
  variant: string;
  label: JSX.Element | string;
  disabled?: boolean;
  theme?: string;
};

export type CollaboratorInviteLink = {
  collaborator_invite_link: string;
};

export const isCollaborator = (obj: any): obj is Collaborator => (obj as Collaborator)
  .status !== undefined;

export type CSMUser = {
  user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  avatar_url?: string;
};
