import { useEffect, useState } from 'react';
import GraphLabel from 'progress/summary/GraphLabel/graphLabel';
import SectionHeader from 'progress/summary/SectionHeader/sectionHeader';
import { N9 } from 'components/text';
import styles from 'progress/progressPage.module.scss';
import BarChart from 'progress/summary/BarChart/barChart';
import { Colors } from '@whoop/web-components';
import {
  GroupAverage, GroupAverages, Pillars, StrainMetric,
} from 'types/analytics';
import { analyticsClient } from 'api';
import { createEmptyAverageData, getAvgTrendValue, getAvgValue } from 'progress/progressPageUtils';
import componentStyles from './strainSection.module.scss';

type StrainSectionProps = {
  groupId: number | string;
};

function StrainSection({ groupId }: StrainSectionProps) {
  const [strainData, setStrainData] = useState<GroupAverage[]>();

  useEffect(() => {
    const getSleepData = async () => {
      const data: GroupAverages = await analyticsClient.getAveragesForGroup(
        groupId,
        StrainMetric.DAY_STRAIN,
        Pillars.STRAIN,
      );
      const averages: GroupAverage[] = data.cohort_averages;
      const getAveragesWithEmpty = createEmptyAverageData(averages, StrainMetric.RAW_DAY_STRAIN);
      setStrainData(getAveragesWithEmpty);
    };
    getSleepData();
  }, [groupId]);

  if (!strainData) {
    return null;
  }

  return (
    <>
      <SectionHeader
        title="day strain 30-day average"
        details="Strain is a measure of your cardiovascular exertion throughout the day (Day Strain)."
        avgTitle="today's avg. day strain"
        avgValue={getAvgValue(strainData, true)}
        avgTrend={getAvgTrendValue(strainData, true)}
        showPercentageOnTrend={false}
        showAvg
      />
      <div className={styles.chart}>
        <BarChart
          data={strainData}
          tooltipDescription="Daily Avg. Strain"
          colorToUse={Colors.strainBlue}
          useStrainAxis
        />
      </div>
      <div className={styles.chartLabel}>
        <GraphLabel showSquare={false}>
          resting
          <span className={componentStyles.strainLabelNums}>
            <N9>0-6</N9>
          </span>
        </GraphLabel>
        <GraphLabel showSquare={false}>
          light
          <span className={componentStyles.strainLabelNums}>
            <N9>6-10</N9>
          </span>
        </GraphLabel>
        <GraphLabel showSquare={false}>
          moderate
          <span className={componentStyles.strainLabelNums}>
            <N9>10-14</N9>
          </span>
        </GraphLabel>
        <GraphLabel showSquare={false}>
          strenuous
          <span className={componentStyles.strainLabelNums}>
            <N9>14-18</N9>
          </span>
        </GraphLabel>
        <GraphLabel showSquare={false}>
          all out
          <span className={componentStyles.strainLabelNums}>
            <N9>18-21</N9>
          </span>
        </GraphLabel>
      </div>
    </>
  );
}

export default StrainSection;
