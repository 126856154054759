import { RadioToggle } from '@whoop/web-components';
import { makeStyles } from '@material-ui/core/styles';
import { TITLE_3 } from 'components/text';
import StepSubheader from 'wizards/components/stepSubheader/stepSubheader';
import { emailInputOptions, EMAIL_INPUT_STEP_NUMBER, TOTAL_STEPS } from '../../constants';

const useStyles = makeStyles({
  toggleBtn: {
    margin: '40px auto',
    width: '432px',
  },
});

type EmailsInputHeaderProps = {
  inputType: string;
  setInputType: (inputType: string) => void;
};

function EmailsInputHeader({ inputType, setInputType }: EmailsInputHeaderProps) {
  const classes = useStyles();
  return (
    <div>
      <TITLE_3>Add Team Members</TITLE_3>
      <StepSubheader
        stepNumber={EMAIL_INPUT_STEP_NUMBER}
        totalSteps={TOTAL_STEPS}
      >
        Add members
      </StepSubheader>
      <p>
        Enter email addresses below to invite your team members to take part in
        this program. Invitees will receive a custom email with instructions for
        signing up.
      </p>
      <div className={classes.toggleBtn}>
        <RadioToggle
          value={inputType}
          options={emailInputOptions}
          onChange={setInputType}
        />
      </div>
    </div>
  );
}

export default EmailsInputHeader;
