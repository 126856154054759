import { Icon, classes } from '@whoop/web-components';
import styles from './comparisonArrow.module.scss';

type ComparisonArrowProps = {
  compare?: Comparison;
};

export enum ArrowDirection {
  UP = 'up',
  DOWN = 'down',
  EQUAL = 'equal',
}
export enum ComparisonType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export type Comparison = {
  arrowDirection: ArrowDirection;
  comparisonType?: ComparisonType;
};

function ComparisonArrow({ compare }: ComparisonArrowProps) {
  const neutralIcon = () => <div data-testid="neutralIcon" className={styles.rectangle} />;
  if (!compare) {
    return neutralIcon();
  }

  const arrowClassName = classes(
    styles.arrow,
    compare.comparisonType === ComparisonType.POSITIVE ? styles.green : styles.red,
  );

  if (compare.arrowDirection === ArrowDirection.UP) {
    return <Icon data-testid="arrowUp" name="triangle_up" className={arrowClassName} />;
  } if (compare.arrowDirection === ArrowDirection.DOWN) {
    return <Icon data-testid="arrowDown" name="triangle_down" className={arrowClassName} />;
  }
  return neutralIcon();
}

export default ComparisonArrow;
