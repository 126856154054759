import React, { ReactChild, Ref } from 'react';
import { MenuItem, MenuList } from '@material-ui/core';
import { HubMemberPageOption } from 'hub/hubUtils';
import styles from './hubPageDropdown.module.scss';

type HubPageDropdownProps = {
  items: HubMemberPageOption[];
  activeIcon: ReactChild;
  onItemClick: (row: HubMemberPageOption) => void;
};

// There was an issue with the jest tests that said:
// 'Function components cannot be given refs. Attempts to access this ref will fail.'
// Using React.forwardRef here solves this.
const HubPageDropdown = React.forwardRef((
  { items, activeIcon, onItemClick }: HubPageDropdownProps,
  ref: Ref<HTMLUListElement>,
) => (
  <MenuList disablePadding ref={ref}>
    {items.map((item) => {
      let displayVal = item.name;
      if (item.name === 'active') {
        displayVal = 'activated';
      }
      return (
        <MenuItem
          data-testid={`menu-item-${item.name}`}
          key={`menu-item-${item.name}`}
          className={
            item.active
              ? styles.activeItem
              : styles.inactiveItem
          }
          onClick={() => onItemClick(item)}
        >
          <div className={styles.checkContainer}>
            {item.active ? activeIcon : ''}
          </div>
          <p className={`${styles.itemLabel}`}>{item.display_name ? item.display_name : displayVal}</p>
        </MenuItem>
      );
    })}
  </MenuList>
));

export default HubPageDropdown;
