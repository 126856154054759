import { Datum } from '@nivo/line';
import { Colors } from '@whoop/web-components';
import { getDaysInRange } from 'dataVisualizations/strainRecoveryTrendGraph/strainRecoveryTrendGraphUtils';
import dayjs from 'dayjs';
import { formatDate } from 'progress/profile/profileUtils';

// Todo: add type for data
// This function returns the values for the X-axis
// The values are either in relation to the time the activity was done,
// or the duration the activity took place. The timeInterval and durationInterval
// let us know where the axis marks should be. Each data point has an interval of 6 seconds
export const getXAxisTickMark = (
  axisData: { data: Datum[] },
  timeInterval: number,
  durationInterval: number,
) => {
  const timeTickMark: number[] = [];
  const durationTickMark: number[] = [];
  let lastTimeTick = 0;

  axisData.data.forEach((d: Datum, index: number) => {
    if (index % (durationInterval * 9) === 0) {
      durationTickMark.push(Number(d.x));
    }

    const second = dayjs(d.x).second();
    const minute = dayjs(d.x).minute();
    // The minute should be a multiple of the timeInterval, but
    // since the data is every 6 seconds there could be 10 points within that minute range we want.
    // We will take the first data point in that minute
    if (minute % timeInterval === 0 && second < 10) {
      if (dayjs(d.x).diff(dayjs(lastTimeTick), 'second') > 6) {
        timeTickMark.push(Number(d.x));
      }
      lastTimeTick = Number(d.x);
    }
  });
  return [timeTickMark, durationTickMark];
};

export const getXAxisTickFromMin = (axisData: { data: Datum[] }, hourInterval: number) => {
  const timeTickMark: number[] = [];
  // This is the first hour we want to plot
  let hourToPlot = dayjs(axisData.data[0].x).hour() + 1;

  axisData.data.forEach((d: any) => {
    const hour = dayjs(d.x).hour();
    const minute = dayjs(d.x).minute();

    // Need modulus because hours are 0-23
    // Also Only want to plot this first tick in that hour
    if ((hourToPlot) % 24 === hour && minute % 60 === 0) {
      timeTickMark.push(d.x);
      hourToPlot = hour + hourInterval;
    }
  });
  return timeTickMark;
};

export const getRecoveryColorForVal = (value: number, hidden: boolean = false): string => {
  const RECOVERY_RED_MAX = 33;
  const RECOVERY_YELLOW_MAX = 66;
  if (value <= RECOVERY_RED_MAX) {
    return hidden ? Colors.redAlpha200 : Colors.red;
  } if (value <= RECOVERY_YELLOW_MAX) {
    return hidden ? Colors.yellowAlpha200 : Colors.yellow;
  }
  return hidden ? Colors.greenAlpha200 : Colors.green;
};

export const getAxisTickColor = (strain: boolean, percentage: number) => (
  strain
    ? Colors.strainBlue
    : getRecoveryColorForVal(percentage)
);

export const timeAxisValues = (startDate: string, endDate: string, interval: number = 0) => {
  const keys: string[] = getDaysInRange(dayjs(startDate).toDate(), dayjs(endDate).toDate());
  const parsedDates = keys.map(((date) => formatDate(date)));
  if (interval === 0) {
    return parsedDates;
  }
  return parsedDates.filter((_, index) => index % interval === 0);
};
