import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

type AvatarPropTypes = {
  src?: string;
};

export default function Avatar({ src }: AvatarPropTypes) {
  return src ? (
    <img src={src} style={{ height: '40px', width: '40px', borderRadius: '50%' }} alt="user avatar" />
  ) : (
    <AccountCircleOutlinedIcon />
  );
}
