export enum AccountDetailsTab {
  SUMMARY = 'summary',
  SETTINGS = 'settings',
  PERMISSIONS = 'permissions',
}

export type AccountDetailsParams = {
  accountId: string;
  tabTitle: AccountDetailsTab;
};

export enum AccountConfigurationSettingType {
  Toggle = 'toggle',
  Button = 'button',
}
