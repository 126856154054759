import { HubSeatStatus, HubStatus } from 'types/hub';
import { ActivatedSeatStatus, SeatStatus } from 'types/seat';
import { UseStatePropType } from 'types/useStatePropType';
import FilterDropdown from 'components/filterDropdown/filterDropdown';

type AnyStatus = | SeatStatus | HubStatus | HubSeatStatus | ActivatedSeatStatus;

type StatusDropdownProps = {
  statusValues: AnyStatus[];
  currentStatus: AnyStatus;
  setCurrentStatus: UseStatePropType<AnyStatus>;
  rowsByStatus: Map<AnyStatus, any>;
};

function StatusDropdown({
  statusValues, currentStatus, setCurrentStatus, rowsByStatus,
}: StatusDropdownProps) {
  let currentStatusToDisplay: AnyStatus = currentStatus;
  if (currentStatus === SeatStatus.Active) {
    currentStatusToDisplay = ActivatedSeatStatus.Activated;
  }

  const statusOptions = statusValues.map((status: SeatStatus | HubStatus) => {
    let name: AnyStatus = status;
    if (status === SeatStatus.Active) {
      name = ActivatedSeatStatus.Activated;
    }
    const count = rowsByStatus.has(status) ? rowsByStatus.get(status).length : 0;
    return {
      name: `${name} (${count})`,
      active: status === currentStatus,
      onClick: () => {
        setCurrentStatus(status);
      },
    };
  });

  return (
    <FilterDropdown
      heading={`status: ${currentStatusToDisplay}`}
      options={statusOptions}
      ariaLabel="Status filter button"
    />
  );
}

export default StatusDropdown;
