import LandingPageWrapper from 'landingPageWrapper/landingPageWrapper';
import ErrorIcon from 'assets/icons/xRed.svg';
import { Button } from '@whoop/web-components';
import { useNavigate } from 'react-router-dom';
import styles from './noAccessPage.module.scss';

function NoAccessPage() {
  const navigate = useNavigate();
  return (
    <LandingPageWrapper includeFooter>
      <div className={styles.error}>
        <div>
          <img className={styles.errorIcon} src={ErrorIcon} alt="Error" />
          <h1>Hmm, we couldn’t show this page</h1>
          <p>
            This page may require permissions.
            Try reaching out to your admin if you think there is an error.
          </p>
          <Button
            label="Home Page"
            variant="secondary"
            theme="dark"
            onClick={() => { navigate('/accounts'); }}
          />
        </div>
      </div>
    </LandingPageWrapper>
  );
}

export default NoAccessPage;
