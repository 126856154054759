import HeartRateLineGraph from 'dataVisualizations/heartRateLineGraph/heartRateLineGraph';
import ZonesOfMaxBarGraph from 'dataVisualizations/zonesOfMaxBarGraph/zonesOfMaxBarGraph';
import { Col, Row } from 'layout/layout';
import Card from 'progress/components/Card/card';
import { MoreInfoText } from 'progress/components/IconTooltip/iconTooltipTypes';
import { useEffect, useState } from 'react';
import {
  getFormattedRangeDay,
} from 'progress/profile/profileUtils';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import ActivityStatTile from 'progress/components/ActivityStatTile/activityStatTile';
import { useParams, useSearchParams } from 'react-router-dom';
import { UrlParams } from 'types/profile';
import dayjs from 'dayjs';
import DayStrainPieCard from 'progress/components/dayPillarPieCards/dayStrainPieCard';
import { getActivityDTO, getStrainActivities } from 'api/analyticsApi';
import { ActivityDetails, ActivityDTO, ZoneDuration } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import { getDuration } from 'dataVisualizations/utils/utils';
import { Pillars, StrainMetricType } from 'types/analytics';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import CardPlaceholder from 'progress/components/cardPlaceholder/cardPlaceholder';
import useMetric from 'api/hooks/useMetric';
import StatisticsCard from 'progress/components/statisticsCard/statisticsCard';
import { convertStrainToString } from 'progress/utils/numberFormatter';
import NoDataBanner from 'progress/components/noDataBanner/noDataBanner';
import styles from './views.module.scss';

const hrZonesMoreInfo: MoreInfoText = {
  header: 'What are heart rate zones?',
  details: 'Heart rate zones are a percentage of your maximum heart rate (heartbeats per minute).\n\nExercise too close to your maximum HR (Mhr) and your heart and body will struggle to keep up with the demands.',
};

function DailyView() {
  const { userId } = useParams<UrlParams>();
  const { dateRange } = useDateRange();
  const [activitySelected, setActivitySelected] = useState<ActivityDetails>(null);
  const [activityHr, setActivityHr] = useState<ActivityDTO>();
  const [activities, setActivities] = useState<ActivityDetails[]>([]);
  const [hrDataLoading, setHrDataLoading] = useState<boolean>(false);
  const [isLoadingActivities, setIsLoadingActivities] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [emptyStrainState, setEmptyStrainState] = useState<boolean>(false);

  const {
    data: strain,
  } = useMetric(StrainMetricType.STRAIN, dateRange);

  const getActivities = async () => {
    setIsLoadingActivities(true);
    setActivityHr(null);
    const memberId = parseInt(userId, 10);
    const startTime = dayjs(dateRange.from).startOf('day').format();
    const endTime = dayjs(dateRange.from).endOf('day').format();
    const dayActivities = await getStrainActivities(memberId, startTime, endTime);
    setActivities(dayActivities);
    const urlActivityId = (searchParams.get('activityId'));
    if (urlActivityId) {
      const foundActivity = dayActivities.find((activity) => activity.id === urlActivityId);
      // If the activity selected is the same as the one in the url,
      // Then we don't want to update the activitySelected which will rerender the activity graphs
      if (foundActivity.id !== activitySelected?.id) {
        setActivitySelected(foundActivity);
      }
    } else {
      setActivitySelected(dayActivities[0]);
    }
    setIsLoadingActivities(false);
  };

  const getActivityHR = async (activity: ActivityDetails) => {
    setHrDataLoading(true);
    const memberId = parseInt(userId, 10);
    const activityDTO = await getActivityDTO(memberId, activity.cycle_id, activity.id);
    setActivityHr(activityDTO);
    setHrDataLoading(false);
  };

  useEffect(() => {
    getActivities();
  }, [dateRange]);

  useEffect(() => {
    if (activitySelected) {
      searchParams.set('activityId', activitySelected.id);
      setSearchParams(searchParams, { replace: true });
      getActivityHR(activitySelected);
    }
  }, [activitySelected]);

  useEffect(() => {
    if (strain) {
      setEmptyStrainState(!strain.average_value);
    }
  }, [strain]);

  const getActivityStatsDetail = () => {
    const numActivities = activities.length;
    const formattedDate = getFormattedRangeDay(dateRange);
    if (numActivities > 0) {
      return `${numActivities} activit${numActivities === 1 ? 'y' : 'ies'} on ${formattedDate}`;
    }
    return formattedDate;
  };

  const convertZoneDurations = (zoneDurations: ZoneDuration) => [
    zoneDurations.zone0_to50_duration,
    zoneDurations.zone50_to60_duration,
    zoneDurations.zone60_to70_duration,
    zoneDurations.zone70_to80_duration,
    zoneDurations.zone80_to90_duration,
    zoneDurations.zone90_to100_duration,
  ];

  return (
    <>
      <NoDataBanner pillar={Pillars.STRAIN} />
      <Row center="xs" className={styles.pillarCardsContainer}>
        <Col xs={4}>
          <DayStrainPieCard dateRange={dateRange} />
        </Col>
        <Col xs={8}>
          <StatisticsCard
            pillar={Pillars.STRAIN}
          />
        </Col>
      </Row>
      <Row center="xs" className={styles.activityStatsContainer}>
        <Col xs={12}>
          {(emptyStrainState || (activitySelected && activityHr)) && (
          <Card
            id="Activity Statistics"
            loading={hrDataLoading || isLoadingActivities}
          >
            <CardHeader
              title="Activity Statistics"
              details={getActivityStatsDetail()}
              activities={activities}
              activitySelected={activitySelected}
              setActivitySelected={setActivitySelected}
            />
            <div className={styles.cardContentContainer}>

              <Card
                id="Heart Rate Zones"
                showBackground={false}
                loading={hrDataLoading}
                className={styles.zonesOfMaxBox}
              >
                <CardHeader
                  title="Heart Rate Zones"
                  moreInfoText={hrZonesMoreInfo}
                />
                {!emptyStrainState && (
                <div className={styles.hrZonesChart}>
                  <ZonesOfMaxBarGraph
                    zoneDurations={convertZoneDurations(activitySelected.zone_durations)}
                  />
                </div>
                )}
              </Card>

              <Card
                id="Activity HR"
                showBackground={false}
                loading={hrDataLoading}
                className={styles.activityHrCard}
              >
                <CardHeader
                  title="Activity HR"
                />
                {!emptyStrainState && (
                <div className={styles.activityHRGraph}>
                  <HeartRateLineGraph hrData={activityHr} />
                </div>
                )}
              </Card>

              <div className={styles.tilesColumn}>
                <ActivityStatTile icon="strain" stat="Activity Strain" value={emptyStrainState ? null : convertStrainToString(activitySelected.scaled_intensity_score)} />
                <ActivityStatTile icon="calories" stat="Calories" value={emptyStrainState ? null : Math.round(activitySelected.kilojoules * 0.239006).toLocaleString()} />
                <ActivityStatTile icon="max_heart_rate" stat="Max HR" value={emptyStrainState ? null : activitySelected.max_heart_rate} />
                <ActivityStatTile icon="heart_rate" stat="Avg. HR" value={emptyStrainState ? null : activitySelected.average_heart_rate} />
                <ActivityStatTile icon="duration" stat="Duration" value={emptyStrainState ? null : getDuration(activitySelected.during)} fill />
              </div>
            </div>
          </Card>
          )}
          {!emptyStrainState && !isLoadingActivities && activities?.length === 0 && (
            <CardPlaceholder
              icon="nap"
              cardTitle="Activity Statistics"
              cardDetails={getActivityStatsDetail()}
              text="No Strain Activity detected on this day"
              subtext={['If this appears to be an error, make sure this member wears their WHOOP strap properly.']}
              showBackground
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default DailyView;
