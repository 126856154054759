export const utcTableFormat = (date: Date): string => date.toLocaleDateString(undefined, { timeZone: 'UTC' });

export const longDateFormat = (date: Date): string => date.toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  timeZone: 'UTC',
});

export const shortDateWithTimeFormat = (date: Date): string => date.toLocaleDateString('en-US', {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour12: true,
  hour: 'numeric',
  minute: '2-digit',
});

const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;
const MILLIS_IN_SECOND = 1000;
const SECONDS_IN_HOUR = 3600;

export const dayInMilliSec = HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLIS_IN_SECOND;

export const warnForLastActivity = (date: string): boolean => {
  const daysUntilWarning = 2;
  const warningCutoff = Date.now() - (daysUntilWarning * dayInMilliSec);
  return new Date(date).getTime() < warningCutoff;
};

export const secondsToHoursMinutes = (seconds: number): string => {
  const h = Math.floor(seconds / SECONDS_IN_HOUR);
  const m = Math.floor((seconds % SECONDS_IN_HOUR) / MINUTES_IN_HOUR);

  return `${h}:${m.toString().length === 1 ? `0${m}` : m}`;
};
