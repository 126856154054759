import styles from 'progress/progressPage.module.scss';
import { N4, N5, N9 } from 'components/text';
import { Icon } from '@whoop/web-components';
import componentStyles from './sectionHeader.module.scss';

type CommonProps = {
  title: string;
  details: string;
};

type ConditionalProps =
  | {
    showAvg: true;
    avgTitle: string;
    avgValue: number;
    avgTrend: number;
    showPercentageOnTrend?: boolean;
  }
  | {
    showAvg: false;
    avgTitle?: never;
    avgValue?: never;
    avgTrend?: never;
    showPercentageOnTrend?: never;
  };

type SectionHeaderProps = CommonProps & ConditionalProps;

function SectionHeader({
  title, details, avgTitle, avgValue, avgTrend, showAvg, showPercentageOnTrend = true,
} : SectionHeaderProps) {
  return (
    <>
      <div className={styles.sectionTitle}>
        {title}
      </div>
      <div className={componentStyles.sectionInfo}>
        <div className={componentStyles.details}>
          {details}
        </div>
        { showAvg && (
          <div className={componentStyles.avgSection}>
            <div className={componentStyles.avgDetails}>
              {avgTitle}
            </div>
            {avgValue && (
            <div className={componentStyles.avgValues}>
              <N4>{avgValue}</N4>
              {showPercentageOnTrend && <N5 className={componentStyles.trendPercentSign}>%</N5>}
              <div className={componentStyles.trend}>
                <Icon name={avgTrend > 0 ? 'triangle_up' : 'triangle_down'} className={componentStyles.trendIcon} />
                <N9>
                  {Math.abs(avgTrend)}
                  {showPercentageOnTrend && '%'}
                </N9>
              </div>
            </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default SectionHeader;
