import Card from 'progress/components/Card/card';
import { DateRange } from 'react-day-picker';
import { getFormattedRangeDay, strainMoreInfo } from 'progress/profile/profileUtils';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import PillarPieChart from 'dataVisualizations/pillarPieChart/pillarPieChart';
import { Pillars, StrainMetricType } from 'types/analytics';
import { Colors } from '@whoop/web-components';
import useMetric from 'api/hooks/useMetric';
import styles from './dayPillarPieCards.module.scss';

type DailyViewProps = {
  dateRange: DateRange;
};

function DayStrainPieCard({ dateRange }: DailyViewProps) {
  const detailDate = getFormattedRangeDay(dateRange);
  const {
    data: strain,
    isLoading: isLoadingStrain,
  } = useMetric(StrainMetricType.STRAIN, dateRange);

  return (
    <Card
      loading={isLoadingStrain}
      id="Day Strain"
    >
      <CardHeader
        title="Day Strain"
        details={detailDate}
        moreInfoText={strainMoreInfo}
      />
      <div className={styles.pieChart}>
        <PillarPieChart
          pillarType={Pillars.STRAIN}
          pieValue={strain?.metrics[0]?.metric_value ?? null}
          color={Colors.strainBlue}
        />
      </div>
    </Card>
  );
}

export default DayStrainPieCard;
