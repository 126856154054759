import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './table.module.scss';

const getStyles = (props, column) => {
  const { align, color, width } = column;
  return [
    props,
    {
      style: {
        textAlign: align === 'left' ? 'left' : 'center',
        color: color || 'black',
        width,
        height: '48px',
      },
    },
  ];
};

const getRowStyles = (column) => {
  const { align, width } = column;
  return {
    style: {
      textAlign: align === 'left' ? 'left' : 'center',
      width: width && width,
    },
  };
};

export const headerProps = (props, { column }) => getStyles(props, column);
export const cellProps = (cell) => getRowStyles(cell);

export const rowStyles = (row) => ({
  style: {
    height: '80px',
    background: row.isSelected
      ? styles.selectedColor
      : styles.unselectedColor,
  },
});

export const sortBySvg = (column) => {
  if (column.isSortedDesc) {
    return <ExpandMoreIcon fontSize="small" />;
  }
  return <ExpandLessIcon fontSize="small" />;
};
