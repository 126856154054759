import { classes, Button } from '@whoop/web-components';
import { MoreInfoText } from 'progress/components/IconTooltip/iconTooltipTypes';
import IconTooltip from 'progress/components/IconTooltip/iconTooltip';
import { Row } from 'layout/layout';
import { ActivityDetails } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import styles from '../card.module.scss';

type CommonProps = {
  title: string;
  details?: string;
  moreInfoText?: MoreInfoText;
  sticky?: boolean;
};

type ActivityProps =
  | {
    activities: ActivityDetails[];
    activitySelected: ActivityDetails;
    setActivitySelected: (activity: ActivityDetails) => void;
  }
  | {
    activities?: null;
    activitySelected?: never;
    setActivitySelected?: never;
  };

type CardHeaderProps = CommonProps & ActivityProps;

function CardHeader({
  title, details, moreInfoText, activities, activitySelected, setActivitySelected,
  sticky = false,
}: CardHeaderProps) {
  return (
    <div className={classes(styles.cardHeaderContainer, sticky && styles.stickyHeader)}>
      <div>
        <div className={styles.cardHeader}>
          {title}
        </div>
        <div className={styles.cardDetails}>
          {details}
        </div>
      </div>
      {activities?.length > 0
        && (
          <div className={styles.chipContainer}>
            <Row className={styles.activities}>
              {activities.map((activity: ActivityDetails) => (
                <Button
                  key={activity.id}
                  className={classes(
                    styles.activityChip,
                    activitySelected?.id === activity.id && styles.selectedChip,
                  )}
                  label={activity.translated_type}
                  theme="enterprise"
                  variant="normal"
                  onClick={() => setActivitySelected(activity)}
                />
              ))}
            </Row>
          </div>
        )}
      {moreInfoText && (
        <IconTooltip name={title} moreInfoText={moreInfoText} />
      )}
    </div>
  );
}

export default CardHeader;
