export type FormData = {
  newPassword: string;
  confirmPassword: string;
};

export type ResetParams = {
  token: string;
};

export enum ResetPageState {
  Form,
  Reset,
}
