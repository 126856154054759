import PulsingLogo from 'components/spinner';
import {
  N6, SUBTITLE_1, TITLE_3, BODY_SHORT_1,
} from 'components/text';
import React, { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { addHubMember, editHubUserAccountDetails, resetHubAccountPassword } from 'api/hubApi';
import ResetHubMemberPassword from 'hub/memberProfilePage/resetHubMemberPassword/resetHubMemberPassword';
import ViewMemberProfile from 'hub/memberProfilePage/viewMemberProfile/viewMemberProfile';
import EditMemberProfile from 'hub/memberProfilePage/editMemberProfile/editMemberProfile';
import StickyFooter from 'components/stickyFooter';
import { Button, Icon } from '@whoop/web-components';
import { AddHubMemberRequest, HubUserProfile } from 'types/hub';
import { getAddHubMemberRequestFromState, getProfileFromState } from 'hub/memberProfilePage/editMemberProfile/utils';
import { useToast } from 'context/toast-context';
import styles from './activateSensorWizard.module.scss';

import {
  activateSensorReducer, ActivateSensorReducerState, ActivateSensorSteps, StepLabels,
} from './utils';

type ActivateSensorWizardProps = {
  sensorId?: string;
  accountId: string;
  seatId?: string;
  addMember: boolean;
};

function ActivateSensorWizard({
  sensorId, accountId, seatId, addMember,
} : ActivateSensorWizardProps) {
  const initalState: ActivateSensorReducerState = {
    step: addMember ? ActivateSensorSteps.SET_PASSWORD : ActivateSensorSteps.RESET_PASSWORD,
    error: null,
    hubs: [],
    sensorIds: [],
    firstName: '',
    lastName: '',
    username: '',
    sensorId,
    dateOfBirth: '',
    gender: '',
    height: '',
    weight: '',
    hub: '',
    email: '',
    patientIdNumber: '',
    newPassword: '',
    confirmedPassword: '',
    loading: false,
    passwordErrorMessage: null,
  };
  const [state, dispatch] = useReducer(activateSensorReducer, initalState);
  const {
    step, newPassword, confirmedPassword, loading, passwordErrorMessage,
  } = state;
  const navigate = useNavigate();
  const { openToast } = useToast();

  const delay = (ms: number) => new Promise((res) => {
    setTimeout(res, ms);
  });

  const save = async () => {
    try {
      dispatch({ type: 'loading', value: true });
      if (addMember) {
        const createRequest: AddHubMemberRequest = getAddHubMemberRequestFromState(state);
        await addHubMember(createRequest);
        await delay(3000);
        openToast(`Hub member ${state.patientIdNumber} assigned to strap ${state.sensorId}`);
      } else {
        const profileData: HubUserProfile = getProfileFromState(state);
        await editHubUserAccountDetails(seatId, profileData);
        await resetHubAccountPassword(seatId, confirmedPassword);
      }
      navigate(`/accounts/${accountId}/people`);
    } catch (e) {
      dispatch({ type: 'error', payload: e.message });
    } finally {
      dispatch({ type: 'loading', value: false });
    }
  };

  useEffect(() => {
    if (state.error) {
      openToast('Request failed, please review data to make sure information is correct.');
      dispatch({ type: 'error', payload: null });
    }
  }, [state]);

  const title = addMember ? 'Add Hub Member' : 'Activate Sensor';
  const passwordStep = step === ActivateSensorSteps.RESET_PASSWORD
  || step === ActivateSensorSteps.SET_PASSWORD;
  const finalStep = step === ActivateSensorSteps.REVIEW
  || step === ActivateSensorSteps.ERROR;

  return (
    <>
      <div>
        {loading && <PulsingLogo overlay />}
        <div className={styles.profileContent}>
          <div className={styles.detailHeader}>
            <div>
              <TITLE_3>{title}</TITLE_3>
              <div className={styles.headerDescription}>
                <div className={styles.detailHeader}>
                  <N6>{StepLabels[step].number}</N6>
                  <div className={styles.greyNumbers}>
                    <N6>/3</N6>
                  </div>
                  <div className={styles.titleText}>
                    <SUBTITLE_1 caps>{StepLabels[step].title}</SUBTITLE_1>
                  </div>
                </div>
                <div className={styles.headerDescription}>
                  <BODY_SHORT_1>{StepLabels[step].description}</BODY_SHORT_1>
                </div>
              </div>
            </div>
          </div>
          {(passwordStep)
            && (
            <ResetHubMemberPassword
              sensorId={sensorId}
              dispatch={dispatch}
              newPassword={newPassword}
              confirmedPassword={confirmedPassword}
              addMember={addMember}
              passwordErrorMessage={passwordErrorMessage}
            />
            )}
          {step === ActivateSensorSteps.CREATE_PROFILE
            && (
            <EditMemberProfile
              inWizard
              profile={state}
              setEditProfile={() => {}}
              setProfileData={() => {}}
              seatId={seatId}
              addMember={addMember}
              customDispatch={dispatch}
            />
            )}
          {finalStep
            && (
            <div className={styles.reviewProfile}>
              <ViewMemberProfile
                setEditProfile={() => {}}
                profile={state}
                showEditProfileButton={false}
                addMember={addMember}
              />
            </div>
            )}
        </div>
      </div>
      <StickyFooter>
        <div className={styles.activateSensorFooter}>
          <div className={styles.previousButton}>
            {!passwordStep && (
              <Button variant="link" onClick={() => { dispatch({ type: 'previous' }); }}>
                <>
                  <Icon name="arrow_pillerbar_left" />
                  <span className={styles.arrowMargin}>Previous</span>
                </>
              </Button>
            )}
            {passwordStep && (
            <Button variant="link" onClick={() => navigate(`/accounts/${accountId}/people`)}>
              <span className={styles.arrowMargin}>Cancel</span>
            </Button>
            )}
          </div>
          <div>
            <span className={`${styles.dot} ${passwordStep ? styles.blackDot : ''}`} />
            <span className={`${styles.dot} ${step === ActivateSensorSteps.CREATE_PROFILE ? styles.blackDot : ''}`} />
            <span className={`${styles.dot} ${step === ActivateSensorSteps.REVIEW ? styles.blackDot : ''}`} />
          </div>
          <div>
            {!finalStep ? (
              <Button
                variant="link"
                onClick={() => {
                  dispatch({ type: 'next' });
                }}
              >
                <>
                  <span className={styles.arrowMargin}>Next</span>
                  <Icon name="arrow_pillerbar_right" />
                </>
              </Button>
            )
              : (
                <>
                  <Button onClick={save} theme="enterprise" variant="primary" label="Save" disabled={state.step === ActivateSensorSteps.ERROR} />
                  <Button onClick={() => navigate(`/accounts/${accountId}/people`)} variant="link" label="Cancel" />
                </>
              )}
          </div>
        </div>
      </StickyFooter>
    </>
  );
}

export default ActivateSensorWizard;
