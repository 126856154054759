import env from 'constants/env';

// Use BASE_URI when running in dev and prod envs
// Use localhost when running locally and in circleci
export const mswBaseUri = (path: string) => {
  const basePath = env?.BASE_URI ?? 'http://localhost';
  return `${basePath}/enterprise-service/${path}`;
};

// If running in test env, set delay to 0
export const getDelayVal = (delay: number) => {
  // If JEST_WORKER_ID exists, that means we are running a jest test so we don't want any delay
  if (process.env.JEST_WORKER_ID) {
    return 0;
  }
  return delay;
};

export default mswBaseUri;
