import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button,
} from '@material-ui/core';
import styles from '../register.module.scss';

function ProfileImageUploadModal({ src, handleClose, handleSave }) {
  return (
    <Dialog
      open={src !== null}
      onClose={handleClose}
      className={styles.profileImageModalDialog}
    >
      <DialogTitle>
        <span>Add a profile picture</span>
      </DialogTitle>
      <Divider />
      <DialogContent className={styles.center}>
        <img
          src={src}
          className={styles.profileImageModalContent}
          alt="Profile Icon"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProfileImageUploadModal;
