import styles from './spinner.module.scss';

function PulsingLogo({ overlay = false }) {
  return (
    <div
      data-testid="loader-component"
      className={`${styles.pulsingLogo} ${overlay ? styles.overlayLogo : null}`}
    />
  );
}

export default PulsingLogo;
