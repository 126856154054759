import styled from 'styled-components';

// Web Styles

// Headings
export const TITLE_1 = styled.h1`
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  font-size: 54px;
  line-height: 59px;
  margin: 0px;
`;

export const TITLE_2 = styled.h2`
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 46px;
  margin: 0px;
`;

export const TITLE_3 = styled.h3`
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 35px;
  margin: 0px;
`;

export const TITLE_4 = styled.h4`
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 31px;
  margin: 0px;
`;

export const SUBTITLE_1 = styled.p`
  font-family: ${(props) => (props.caps ? 'ProximaNova-Bold' : 'ProximaNova')};
  font-style: normal;
  line-height: 22px;
  font-weight: ${(props) => (props.caps ? 'bold' : 'normal')};
  font-size: ${(props) => (props.caps ? '18px' : '20px')};

  letter-spacing: ${(props) => props.caps && '0.1em'};
  text-transform: ${(props) => props.caps && 'uppercase'};
  margin: 0px;
`;

export const SUBTITLE_2 = styled.p`
  font-family: ${(props) => (props.caps ? 'ProximaNova-Bold' : 'ProximaNova-Semibold')};
  font-style: normal;
  font-weight: ${(props) => (props.caps ? 'bold' : '600')};
  font-size: ${(props) => (props.caps ? '15px' : '16px')};
  line-height: ${(props) => (props.caps ? '17px' : '20px')};

  letter-spacing: ${(props) => props.caps && '0.1em'};
  text-transform: ${(props) => props.caps && 'uppercase'};
  margin: 0px;
`;

export const SUBTITLE_3 = styled.p`
  font-family: ${(props) => (props.caps ? 'ProximaNova-Bold' : 'ProximaNova-Semibold')};
  font-style: normal;
  font-weight: ${(props) => (props.caps ? 'bold' : '600')};
  font-size: ${(props) => (props.caps ? '13px' : '14px')};
  line-height: ${(props) => (props.caps ? '14px' : '18px')};

  letter-spacing: ${(props) => props.caps && '0.1em'};
  text-transform: ${(props) => props.caps && 'uppercase'};
  margin: 0px;
`;

export const BODY_SHORT_1 = styled.p`
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
`;

export const BODY_SHORT_2 = styled.p`
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
`;

export const BODY_LONG_1 = styled.p`
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
`;

export const BODY_LONG_2 = styled.p`
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
`;

export const LABEL = styled.p`
  font-family: ProximaNova-Semibold;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
`;

export const CAPTION_1 = styled.p`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin: 0px;
`;

export const CAPTION_2 = styled.p`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  margin: 0px;
`;

export const OVERLINE_1 = styled.p`
  font-family: ProximaNova-Semibold;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  margin: 0px;
`;

export const OVERLINE_2 = styled.p`
  font-family: ProximaNova-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0px;
`;

// Mobile Styles

export const HEADING_1 = styled.h1`
  font-family: ProximaNova-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0px;
`;

export const HEADING_2 = styled.h2`
  font-family: ProximaNova-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0px;
`;

export const HEADING_3 = styled.h3`
  font-family: ProximaNova-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0px;
`;

export const HEADING_4 = styled.h4`
  font-family: ProximaNova-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0px;
`;

export const HEADING_5 = styled.h5`
  font-family: ProximaNova-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0px;
`;

export const HEADING_6 = styled.h6`
  font-family: ProximaNova-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0px;
`;

export const P1 = styled.p`
  font-family: ProximaNova-Semibold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
`;

export const P2 = styled.p`
  font-family: ProximaNova-Semibold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
`;

export const P3 = styled.p`
  font-family: ProximaNova-Semibold;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
`;

export const P4 = styled.p`
  font-family: ProximaNova-Bold;
  font-style: normal;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.1em;
  margin: 0px;
`;

// Numbers

export const N1 = styled.p`
  font-family: DinPro;
  font-style: normal;
  font-weight: 700;
  font-size: 90px;
  line-height: 116px;
  margin: 0px;
`;

export const N2 = styled.p`
  font-family: DinPro;
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 90px;
  margin: 0px;
`;

export const N3 = styled.p`
  font-family: DinPro;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 58px;
  margin: 0px;
`;

export const N4 = styled.p`
  font-family: DinPro;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  margin: 0px;
`;

export const N5 = styled.p`
  font-family: DinPro;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  margin: 0px;
`;

export const N6 = styled.p`
  font-family: DinPro;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  margin: 0px;
`;

export const N7 = styled.p`
  font-family: DinPro;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  margin: 0px;
`;

export const N8 = styled.p`
  font-family: DinPro;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  margin: 0px;
`;

export const N9 = styled.p`
  font-family: DinPro;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  margin: 0px;
`;
