import { useState } from 'react';
import LandingPageWrapper from 'landingPageWrapper/landingPageWrapper';
import { UseStatePropType } from 'types/useStatePropType';
import athleticsImage from 'assets/athletics.jpeg';
import Step1 from 'assets/staffPreRegistration/Step1.jpg';
import Step2 from 'assets/staffPreRegistration/Step2.jpg';
import WelcomeStep from './components/welcomeStep';
import SignInStep from './components/signInStep';
import ChangeEmailStep from './components/changeEmailStep';
import { STEPS } from './preRegistration.util';

type PreRegistrationStepsProps = {
  email: string;
  token: string;
  setStep: UseStatePropType<string>;
};

function PreRegistration({ email, token, setStep }: PreRegistrationStepsProps) {
  const [preRegistrationStep, setPreRegistrationStep] = useState(STEPS.WELCOME);

  return (
    <LandingPageWrapper showLogo={false}>
      {preRegistrationStep === STEPS.WELCOME && (
        <WelcomeStep
          image={athleticsImage}
          email={email}
          nextStep={() => setPreRegistrationStep(STEPS.SIGN_IN)}
        />
      )}
      {preRegistrationStep === STEPS.SIGN_IN && (
        <SignInStep
          image={Step1}
          email={email}
          nextStep={() => setPreRegistrationStep(STEPS.CHANGE_EMAIL)}
          backStep={() => setPreRegistrationStep(STEPS.WELCOME)}
        />
      )}
      {preRegistrationStep === STEPS.CHANGE_EMAIL && (
        <ChangeEmailStep
          image={Step2}
          email={email}
          token={token}
          backStep={() => setPreRegistrationStep(STEPS.SIGN_IN)}
          setStep={setStep}
        />
      )}
    </LandingPageWrapper>
  );
}

export default PreRegistration;
