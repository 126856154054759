import React, { useMemo } from 'react';
import {
  Column, CellProps, Row, TableCommonProps,
} from 'react-table';
import { normalCell } from 'tableUtils/tableCells';
import Table from 'components/table';
import { UseStatePropType } from 'types/useStatePropType';
import { GroupMember } from 'types/cohort';
import { SELECTION_COLUMN } from 'tableUtils/tableColumns';
import { capitalizeWords } from 'groups/utils';

type AvailableMembersTableProps = {
  availableMembers: GroupMember[];
  loading: boolean;
  setSelectedRows: UseStatePropType<Row<GroupMember>[]>;
};

function AvailableMembersTable({
  availableMembers,
  loading,
  setSelectedRows,
}: AvailableMembersTableProps) {
  const columns: Column<GroupMember>[] = useMemo(
    () => [
      SELECTION_COLUMN,
      {
        Header: 'Member Name',
        id: 'name',
        accessor: (member: GroupMember) => capitalizeWords(`${member.first_name} ${member.last_name}`),
        width: '30%',
        align: 'left',
        Cell: (cellProps: CellProps<GroupMember>) => normalCell(cellProps),
      },
      {
        Header: 'Email',
        id: 'email',
        width: '40%',
        align: 'left',
        accessor: (row) => (row.email ? row.email : null),
      },
      {
        Header: 'Sensor ID',
        id: 'strap_serial',
        accessor: 'strap_serial',
        width: '33%',
        align: 'left',
        Cell: normalCell,
      },
    ],
    [],
  );

  const getRowStyles = (): TableCommonProps => ({
    style: {
      height: '80px',
    },
  });

  return (
    <Table
      columns={columns}
      data={availableMembers}
      loading={loading}
      tableName="availableGroupMembers"
      unit="available member"
      noDataFoundMessage="Selected members will appear here"
      tableLength={50}
      getAdditionalRowProps={getRowStyles}
      displayHeader={false}
      search={false}
      setSelectedRows={setSelectedRows}
    />
  );
}

export default AvailableMembersTable;
