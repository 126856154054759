import { getFeatureFlagForUser } from 'api/featureFlagApi';
import { useAuth } from 'context/auth-context';
import React, { useEffect, useState } from 'react';

export const FeatureFlagContext = React.createContext({
  getFeatureFlag: null,
});

export const useFeatureFlags = () => React.useContext(FeatureFlagContext);

export function FeatureFlagContextProvider(props) {
  const auth = useAuth();
  const [flags$, setFlags$] = useState(null);

  useEffect(() => {
    setFlags$(new Map());
  }, [auth]);

  const getFeatureFlag = (flag) => {
    if (!auth.user) {
      return Promise.resolve(false);
    }

    const flagAsync = flags$.get(flag) ?? getFeatureFlagForUser(flag);
    flags$.set(flag, flagAsync);
    return flagAsync;
  };

  return flags$ ? (
    <FeatureFlagContext.Provider value={{ getFeatureFlag }} {...props} />
  ) : null;
}
