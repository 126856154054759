import { Cohort } from 'types/cohort';

export enum AddMembersToGroupSteps {
  Selection = 'selection',
  Loading = 'loading',
  Error = 'error',
}

export type AddMembersToGroupReducerState = {
  step: AddMembersToGroupSteps;
  error: Error;
  selectedGroup: Cohort;
};

export type AddMembersToGroupReducerAction =
  | { type: 'next' }
  | { type: 'selectGroup'; selectedGroup: Cohort }
  | { type: 'error'; payload: Error };

export const addMembersToGroupReducer = (
  state: AddMembersToGroupReducerState,
  action: AddMembersToGroupReducerAction,
): AddMembersToGroupReducerState => {
  switch (action.type) {
    case 'next':
      if (state.step === AddMembersToGroupSteps.Selection) {
        return {
          ...state,
          step: AddMembersToGroupSteps.Loading,
        };
      } if (state.step === AddMembersToGroupSteps.Loading) {
        return {
          ...state,
          step: AddMembersToGroupSteps.Selection,
        };
      }
      return {
        ...state,
      };
    case 'selectGroup':
      return {
        ...state,
        selectedGroup: action.selectedGroup,
      };
    case 'error':
      return {
        ...state,
        step: AddMembersToGroupSteps.Error,
      };
    default:
      return state;
  }
};
