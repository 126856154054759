import { useState } from 'react';
import { MenuItem, MenuList } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'components/dropdown';
import RowMessage from 'components/rowMessage';
import { Row } from 'react-table';
import { Icon } from '@whoop/web-components';
import { useAccounts } from 'context/account-context';
import { useAuth } from 'context/auth-context';
import { getCollaboratorInviteLink } from 'api/adminApi';
import { UseStatePropType } from 'types/useStatePropType';
import { Collaborator, InviteStatus, RemoveCollaboratorType } from 'types/collaborator';
import { DashboardRole } from 'types/dashboardUser';
import { removalAccess } from 'accountDetails/collaborator/collaborator';
import CopyIcon from 'assets/icons/copy.svg';
import styles from './moreActionsCollaborator.module.scss';

type MoreActionsCollaboratorsProps = {
  row: Row<Collaborator>;
  setSelectedRows: UseStatePropType<Row<Collaborator>[]>;
  setRemovalModal?: (
    type: RemoveCollaboratorType,
    removalModal: boolean
  ) => void;
  setResendInviteModal: UseStatePropType<boolean>;
};

function MoreActionsCollaborators({
  row, setSelectedRows, setRemovalModal, setResendInviteModal,
}: MoreActionsCollaboratorsProps) {
  const { account, checkAccess } = useAccounts();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [loadingLink, setLoadingLink] = useState(false);
  const [displayCheck, setDisplayCheck] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [rowMessage, setRowMessage] = useState('');

  const handleDeleteInvite = async () => {
    setSelectedRows([row]);
    setRemovalModal(RemoveCollaboratorType.ACCOUNT, true);
  };

  const handleResendInvite = () => {
    setSelectedRows([row]);
    setResendInviteModal(true);
  };

  const handleRowMessageClick = () => {
    setIsAlertVisible(true);
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 3000);
  };

  const fetchCollaboratorInviteLink = async () => {
    if (!loadingLink) {
      setLoadingLink(true);
      setRowMessage('Fetching invite link');
      handleRowMessageClick();
      try {
        const { collaborator_invite_link } = await
        getCollaboratorInviteLink(row.original.email, account.id);

        navigator.clipboard.writeText(collaborator_invite_link); // Copy to clipboard
        setLoadingLink(false);
        setDisplayCheck(true);
        setRowMessage('Link copied to clipboard');
      } catch (err) {
        setDisplayCheck(false);
        setRowMessage('Error fetching invite link');
      }
      handleRowMessageClick();
      setLoadingLink(false);
    }
  };

  const collaborator = row.original;
  return (
    <div className={styles.moreActionsContainer}>
      <Dropdown
        button={<MoreHorizIcon />}
        buttonProps={{ variant: 'link', 'data-testid': 'more-actions-btn', 'aria-label': 'more_actions_btn' }}
        dropdownStyles={{ borderRadius: '8px' }}
        isDisabled={false}
        closeOnClick
      >

        <MenuList className={styles.menuList} disablePadding>
          {collaborator.status === InviteStatus.accepted && (
            <MenuItem
              onClick={() => { navigate(`/accounts/${account.id}/plan/permissions/${row.original.user_id}`); }}
            >
              <Icon name="my_account" className={styles.icon} />
              View Profile
            </MenuItem>
          )}
          {collaborator.status === InviteStatus.invited && (
            <MenuItem
              onClick={() => handleResendInvite()}
            >
              <Icon name="send" className={styles.icon} />
              Resend Invite
            </MenuItem>
          )}
          {collaborator.status !== InviteStatus.accepted && (
            <MenuItem
              onClick={() => fetchCollaboratorInviteLink()}
            >
              <img className={styles.icon} src={CopyIcon} alt="Copy" />
              Copy Invite Link
            </MenuItem>
          )}
          {removalAccess(checkAccess, row.original, user)
              && row.original.role !== DashboardRole.WHOOP_CSM && (
              <MenuItem
                className={styles.removeMember}
                onClick={() => handleDeleteInvite()}
              >
                <Icon name="delete" className={styles.icon} />
                Delete Collaborator
              </MenuItem>
          )}
        </MenuList>
      </Dropdown>
      <RowMessage
        message={rowMessage}
        visible={isAlertVisible}
        check={displayCheck}
      />
    </div>
  );
}

export default MoreActionsCollaborators;
