import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Cohort, GroupMember } from 'types/cohort';
import { GroupPagesParamsType } from 'types/utils';
import { cohortClient } from 'api';
import ActionContent from 'components/actionContent/actionContent';
import { SUBTITLE_3, HEADING_2 } from 'components/text';
import Loading from 'loading';
import ActionFooter from 'components/actionFooter/actionFooter';
import { useToast } from 'context/toast-context';
import {
  addMembersToGroup,
  createToastMessage,
  getActionButtonProps,
  getSecondaryButtonProps,
  ToastType,
} from './utils';
import styles from './addPage.module.scss';
import AddGroupMembersSearch from './addGroupMembersSearch/addGroupMembersSearch';

function AddGroupMembersPage() {
  const { accountId, groupId } = useParams<GroupPagesParamsType>();
  const navigate = useNavigate();
  const { openToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [group, setGroup] = useState<Cohort>();
  const [availableMembers, setAvailableMembers] = useState<GroupMember[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<GroupMember[]>([]);

  const fetchGroup = useCallback(async () => {
    const getGroup = await cohortClient.getGroupById(Number(groupId));
    setGroup(getGroup);
  }, [groupId]);

  const fetchGroupMembers = useCallback(async () => {
    const members = await cohortClient.getAvailableCohortMembers(Number(groupId));

    setAvailableMembers(members);
  }, [groupId, accountId]);

  useEffect(() => {
    setLoading(true);
    const init = async () => {
      await fetchGroup();
      await fetchGroupMembers();
      setLoading(false);
    };
    init();
    return () => {
      setGroup(null);
      setAvailableMembers([]);
      setSelectedMembers([]);
      setLoading(false);
    };
  }, [fetchGroup, fetchGroupMembers]);

  const handleAddMembers = async () => {
    const memberSeatIds = selectedMembers.map((mem) => mem.seat_id);
    const response = await addMembersToGroup(group.id, memberSeatIds);
    openToast(createToastMessage(
      response.fulfilled,
      response.failed,
      group.name,
      ToastType.ADD_MEMBER,
    ));
    navigate(`/accounts/${accountId}/people/groups/${groupId}`);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.container}>
        <ActionContent>
          <HEADING_2 className={styles.heading}>
            Add Members
          </HEADING_2>
          <SUBTITLE_3 className={styles.subtitle}>
            Members can be added to more than one group.
            Members are not notified of how they’re organized in the account.
          </SUBTITLE_3>
          <AddGroupMembersSearch
            availableMembers={availableMembers}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            loading={loading}
          />
        </ActionContent>
      </div>

      <ActionFooter
        getPrimaryButtonProps={() => getActionButtonProps(selectedMembers, handleAddMembers)}
        getSecondaryButtonProps={() => getSecondaryButtonProps(() => navigate(`/accounts/${accountId}/people/groups/${groupId}`))}
      />
    </>
  );
}

export default AddGroupMembersPage;
