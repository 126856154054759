import { dashboardRoleDisplay } from 'accountDetails/collaboratorsPage/roleDropdown/util';
import { N7 } from 'components/text';
import { ReactElement } from 'react';
import { CellProps } from 'react-table';
import { DescriptionDropdownOption } from 'tableUtils/descriptionDropdown/descriptionDropdown';
import { DropdownSelectCell, EditCell, normalCell } from 'tableUtils/tableCells';
import { Profile } from 'types/auth';
import { Collaborator } from 'types/collaborator';
import { DashboardRole } from 'types/dashboardUser';
import styles from './reviewCollaboratorDetails.module.scss';
import { defaultRoleOptions, privacyLevelOptions } from './reviewCollaboratorDetailsUtil';

export const COLLABORATOR_EMAIL_COLUMN = {
  Header: 'COLLABORATOR EMAIL',
  accessor: 'email',
  width: '34%',
  sortType: 'alphanumeric',
  Cell: normalCell,
};

export const JOB_TITLE_COLUMN = {
  Header: 'JOB TITLE',
  accessor: 'display_role',
  width: '28%',
  sortType: 'alphanumeric',
  Cell: normalCell,
};

export const getJobTitleColumn = (
  update: (collaborator: Collaborator) => void,
) => ({
  Header: 'JOB TITLE',
  accessor: 'display_role',
  width: '28%',
  sortType: 'alphanumeric',
  Cell: (cellProps: CellProps<Collaborator>): ReactElement => (
    <EditCell update={update} updateField="display_role" row={cellProps} />
  ),
});

const getRoleOptions = (hasAdminAccess: boolean) => {
  let currentRoleOptions = defaultRoleOptions;
  if (hasAdminAccess) {
    currentRoleOptions = [{
      name: DashboardRole.ACCOUNT_ADMIN,
      active: false,
      description: 'The Account Admin oversees the account and can view account details, purchase add-ons, create and access all groups, and invite/edit Account Managers and Group Managers.',
      value: DashboardRole.ACCOUNT_ADMIN,
    }, {
      name: DashboardRole.ACCOUNT_MANAGER,
      active: true,
      description: 'Account Managers can create and access all groups in the account and invite/edit Group Managers.',
      value: DashboardRole.ACCOUNT_MANAGER,
    }, ...currentRoleOptions];
  }
  return currentRoleOptions;
};

export const ROLE_TEXT_COLUMN = {
  Header: 'ROLE',
  accessor: 'role',
  width: '21%',
  sortType: 'alphanumeric',
  Cell: ({ cell: { value } }: CellProps<any>): ReactElement => {
    const roleOptions = getRoleOptions(true);
    const roleValue = roleOptions.find((o) => o.value === value)?.name;
    return (
      <N7>
        {dashboardRoleDisplay[roleValue as DashboardRole]}
      </N7>
    );
  },
};

// Used in two places. Collaborators table and in the 'add collaborators' flow.
// Collaborators table passes in the optional user variable and the add collaborators
// use case does not. So going to conditionally return DropdownSelectCell.
export const getRoleColumn = (
  field: keyof Collaborator,
  disableEdit: boolean,
  checkAccess: (role: DashboardRole, greaterThanAccessOnly?: boolean) => boolean,
  user?: Profile,
) => ({
  Header: () => <span className={styles.columnHeader}>ROLE</span>,
  accessor: 'role',
  width: '18%',
  sortType: 'alphanumeric',
  align: 'left',
  Cell: (cellProps: CellProps<Collaborator>): ReactElement => {
    const roleOptions = getRoleOptions(checkAccess(DashboardRole.ACCOUNT_ADMIN));
    const { row } = cellProps;
    const isUser = user && user.id === row?.original?.user_id;
    return DropdownSelectCell(cellProps, field, roleOptions, 'role', disableEdit || !checkAccess(DashboardRole.ACCOUNT_MANAGER, true) || row.original.role === DashboardRole.WHOOP_CSM, isUser);
  },
});

export const PRIVACY_LEVEL_TEXT_COLUMN = {
  Header: 'PRIVACY LEVEL',
  accessor: 'privacy_level',
  width: '21%',
  sortType: 'alphanumeric',
  Cell: ({ cell: { value } }: CellProps<any>): ReactElement => (
    <N7>
      {privacyLevelOptions.find((o) => o.value === value)?.name}
    </N7>
  ),
};

export const getPrivacyLevelColumn = (
  field: keyof Collaborator,
  privacyLevelDropdownOptions: DescriptionDropdownOption[],
  disableEdit: boolean,
  checkAccess: (role: DashboardRole, greaterThanAccessOnly: boolean) => boolean,
) => ({
  Header: () => <span className={styles.columnHeader}>PRIVACY LEVEL</span>,
  accessor: 'privacy_level',
  width: '18%',
  // @ts-ignore
  align: 'left',
  sortType: 'alphanumeric',
  Cell: (cellProps: CellProps<Collaborator>): ReactElement => {
    const { row } = cellProps;
    return DropdownSelectCell(cellProps, field, privacyLevelDropdownOptions, 'privacy_level', disableEdit || !checkAccess(row.original.role, true));
  },
});
