import ActionContent from 'components/actionContent/actionContent';
import { useMemo, useState } from 'react';
import Loading from 'loading';
import { BODY_SHORT_1, TITLE_3 } from 'components/text';
import StickyFooter from 'components/stickyFooter';
import { Button } from '@whoop/web-components';
import { UseStatePropType } from 'types/useStatePropType';
import { CellProps, Column, Row } from 'react-table';
import { nameSubtitleWithPictureCell } from 'tableUtils/tableCells';
import Table from 'components/table';
import { removeCollaboratorFromAccount } from 'api/dashboardApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from 'context/toast-context';
import { HandleSendInvitesPayload } from 'accountDetails/addCollaborators/util';
import { removeCohortAccess } from 'api/cohortApi';
import { GroupUserAccess } from 'types/cohort';
import { SELECTION_COLUMN } from 'tableUtils/tableColumns';
import {
  Collaborator,
  InviteStatus,
  RemoveCollaboratorType,
  RemoveCollaboratorTypeMapToDisplayString,
} from 'types/collaborator';
import { deleteCollaboratorInvite } from 'api/adminApi';
import styles from './removeCollaborator.module.scss';

type RemoveCollaboratorProps = {
  type: RemoveCollaboratorType;
  setModal: UseStatePropType<boolean>;
  collaborators: (Collaborator)[];
  group?: GroupUserAccess;
};

export const handleRemove = async (
  collaborators: Collaborator[],
  removeFunction: (user: Collaborator) => Promise<void>,
): Promise<HandleSendInvitesPayload> => {
  const promises: Promise<void>[] = collaborators.map(
    (invited: Collaborator) => removeFunction(invited),
  );
  const results = await Promise.allSettled(promises);
  const numFulfilled = results.filter(
    (res) => res.status === 'fulfilled',
  ).length;
  const failed = promises.length - numFulfilled;
  return {
    fulfilled: numFulfilled,
    failed,
    errors: results
      .filter((res: PromiseRejectedResult) => res.status === 'rejected')
      .map((res: PromiseRejectedResult) => res.reason),
  };
};

const groupCell = (group: GroupUserAccess) => (<p>{group.name}</p>);

const accountCell = (cellProps: CellProps<Collaborator>) => {
  const { row: { original } } = cellProps;

  return (
    <p>
      All
      {' '}
      {original.privacy_level}
    </p>
  );
};

function RemoveCollaborator({
  type, setModal, collaborators, group,
}: RemoveCollaboratorProps) {
  const { accountId } = useParams<{ accountId: string }>();
  const [loading, setLoading] = useState(false);
  const { openToast } = useToast();
  const [selectedRows, setSelectedRows] = useState<Row<Collaborator>[]>([]);
  const navigate = useNavigate();

  const columns: Column<Collaborator>[] = useMemo(() => {
    const requiredColumns = [
      {
        Header: 'Collaborator Name/Email',
        id: 'name',
        accessor: (member: Collaborator) => member.name,
        width: '60%',
        align: 'left',
        Cell: (cellProps:
        CellProps<Collaborator>) => nameSubtitleWithPictureCell(cellProps),
      },
      type === RemoveCollaboratorType.GROUP
        ? {
          Header: 'Group Name',
          id: 'group_name',
          width: '40%',
          align: 'left',
          Cell: () => groupCell(group),
        }
        : {
          Header: 'Privacy Level',
          id: 'privacy_level',
          width: '40%',
          align: 'left',
          Cell: (cellProps: CellProps<Collaborator>) => accountCell(cellProps),
        },
    ];
    if (collaborators.length > 1) {
      // @ts-ignore
      requiredColumns.unshift(SELECTION_COLUMN);
    }

    return requiredColumns;
  }, []);

  const removeCollaborator = async () => {
    const removedCollaborators = selectedRows.length > 0
      ? selectedRows.map((collab) => collab.original)
      : collaborators;

    let numFulfilled;
    let numFailed;

    setLoading(true);

    if (type === RemoveCollaboratorType.ACCOUNT) {
      // Remove with Collaborator type
      const activeRemovedCollaborators = removedCollaborators
        .filter((c: Collaborator) => c.status === InviteStatus.accepted);
      const invitedRemovedCollaborators = removedCollaborators
        .filter((c: Collaborator) => c.status !== InviteStatus.accepted);

      // Remove any active collaborators
      const { fulfilled: activeFulfilled, failed: activeFailed } = await handleRemove(
        activeRemovedCollaborators,
        (active: Collaborator) => removeCollaboratorFromAccount(
          active.user_id.toString(),
        ),
      );

      // Remove any invited collaborators
      const { fulfilled: invitedFulfilled, failed: invitedFailed } = await handleRemove(
        invitedRemovedCollaborators,
        (invited: Collaborator) => deleteCollaboratorInvite(invited.email),
      );

      numFulfilled = activeFulfilled + invitedFulfilled;
      numFailed = activeFailed + invitedFailed;
    } else {
      // Removing Cohort Access with collaborator object
      const { fulfilled, failed } = await handleRemove(
        removedCollaborators,
        (invited: Collaborator) => removeCohortAccess(
          invited.user_id,
          group.id,
        ),
      );
      numFulfilled = fulfilled;
      numFailed = failed;
    }

    if (numFulfilled === 1 && numFailed === 0) {
      if (type === RemoveCollaboratorType.ACCOUNT) {
        openToast(`${removedCollaborators[0].name ?? removedCollaborators[0].email} was removed from this account.`);
      } else {
        openToast(`${removedCollaborators[0].name} was removed from the "${group.name}" group.`);
      }
    } else {
      openToast(`${numFulfilled} collaborators were removed from this account.`);
    }
    setLoading(false);
    setModal(false);
    if (type === RemoveCollaboratorType.ACCOUNT) {
      navigate(`/accounts/${accountId}/plan/permissions`);
    }
  };

  const accountType = RemoveCollaboratorTypeMapToDisplayString.get(type);

  if (loading) {
    return <Loading />;
  }

  let removeLabel;
  if (type === RemoveCollaboratorType.GROUP) {
    removeLabel = 'Yes, remove from group';
  } else if (type === RemoveCollaboratorType.ACCOUNT) {
    removeLabel = `Yes, remove ${collaborators.length > 0 ? 'from account' : 'this collaborator'}`;
  }

  return (
    <div className={styles.fullPageModal}>
      <ActionContent>

        <TITLE_3>
          Are you sure you want to remove this collaborator from
          {' '}
          {accountType}
          ?
        </TITLE_3>
        <BODY_SHORT_1>
          This collaborator will no longer be associated with
          {' '}
          {accountType}
          .
          Please select the following to confirm.
        </BODY_SHORT_1>
        <Table
          columns={columns}
          data={collaborators}
          loading={loading}
          tableLength={50}
          tableName="removeMembersTable"
          unit="member"
          displayHeader={false}
          search={false}
          pagination={false}
          showOptionalControls={false}
          selectAllInitially={collaborators.length > 1}
          setSelectedRows={setSelectedRows}
          disableSort
          runReset={false}
          setFilteredLength={null}
        />
      </ActionContent>
      <StickyFooter>
        <div className={styles.modalButtons}>
          <Button label="Cancel" onClick={() => setModal(false)} variant="link" />
          <Button
            label={removeLabel}
            onClick={removeCollaborator}
            variant="primary"
          />
        </div>
      </StickyFooter>
    </div>
  );
}

export default RemoveCollaborator;
