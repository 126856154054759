import { useState, useEffect, useCallback } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Cohort, GroupMember, GroupMembersResult } from 'types/cohort';
import { cohortClient } from 'api';
import StrapManagementHeader from 'components/strapManagementHeader';
import GroupsTable from 'groups/groupsTable/groupsTable';
import { GroupPagesParamsType } from 'types/utils';
import { useAccounts } from 'context/account-context';
import { useToast } from 'context/toast-context';
import { Row } from 'react-table';
import GroupMembersTable from './groupMembersTable/groupMembersTable';
import GroupsHeader from './components/groupsHeader';
import { ModalType } from './utils';
import RemoveGroupMembersPage from './removeGroupMembersPage/removeGroupMembersPage';
import styles from './viewGroupsPage.module.scss';

function ViewGroupsPage() {
  const { accounts, account } = useAccounts();
  const { groupId, accountId } = useParams<GroupPagesParamsType>();
  const navigate = useNavigate();

  const { openToast } = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [group, setGroup] = useState<Cohort>();
  const [members, setMembers] = useState<GroupMember[]>([]);
  const [groups, setGroups] = useState<Cohort[]>([]);
  const [showModal, setShowModal] = useState<ModalType>(ModalType.NONE);
  const [selectedMemberRows, setSelectedMemberRows] = useState<Row<GroupMember>[]>([]);

  const fetchMembers = useCallback(async () => {
    try {
      const { records }: GroupMembersResult = await cohortClient
        .getGroupMembersByGroupId(Number(groupId));

      setMembers(records);
    } catch (error) {
      navigate(`/accounts/${accountId}/people/groups`);
    }
  }, [groupId]);

  const fetchGroups = useCallback(async () => {
    if (account) {
      try {
        if (groupId) {
          setGroup(null);
          const retrieveGroup = await cohortClient.getGroupById(Number(groupId));
          setGroup(retrieveGroup);
        } else {
          const retrieveAllGroups = await cohortClient.getGroupsByUserAccess();
          setGroups(retrieveAllGroups);
        }
      } catch (error) {
        setGroups([]);
      }
    }
  }, [account, groupId]);

  useEffect(() => {
    setLoading(true);

    const init = async () => {
      await fetchGroups();
    };

    const loadMembers = async () => {
      await fetchMembers();
    };

    init();
    if (groupId) {
      loadMembers();
    }
    setLoading(false);
    return () => {
      setGroups([]);
      setMembers([]);
    };
  }, [account, groupId, accounts, fetchGroups]);

  const showGroupTable = account && !groupId;

  if (showModal === ModalType.REMOVE_GROUP_MEMBERS) {
    const selectedMembers = selectedMemberRows.map((row) => row.original);
    return (
      <RemoveGroupMembersPage
        setShowModal={setShowModal}
        refresh={fetchMembers}
        selectedMembers={selectedMembers}
        groupName={group.name}
        setSelectedMemberRows={setSelectedMemberRows}
      />
    );
  }

  const handleUpdateGroup = async (property: keyof Cohort, value: string | boolean) => {
    if (value !== group[property as keyof Cohort]) {
      const updateGroup: Cohort = { ...group, [property]: value };
      const updatedGroup = await cohortClient.updateGroup(Number(groupId), updateGroup);
      setGroup(updatedGroup);
      if (property === 'about') {
        openToast('Group description updated!');
      }
    }
  };

  if (showGroupTable) {
    return (
      <>
        <StrapManagementHeader />
        <div className={styles.tableContainer}>
          <div className={styles.table}>
            <GroupsTable groups={groups} loading={loading} />
          </div>
        </div>
      </>
    );
  }
  if (group) {
    if (group.is_primary) {
      return <Navigate to="/404" />;
    }
    return (
      <div className={styles.group}>
        <GroupsHeader
          group={group}
          handleUpdateGroup={handleUpdateGroup}
        />
        <GroupMembersTable
          members={members}
          loading={loading}
          setShowModal={setShowModal}
          selectedMemberRows={selectedMemberRows}
          setSelectedMemberRows={setSelectedMemberRows}
        />
      </div>
    );
  }

  return null;
}

export default ViewGroupsPage;
