import { useAuth } from 'context/auth-context';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function AuthRequired() {
  const { user } = useAuth();
  const location = useLocation();

  return user ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />;
}

export default AuthRequired;
