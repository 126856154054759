import { Col, Row } from 'layout/layout';
import PillarBreakdownBarCard from 'progress/components/pillarBreakdownBarCard/pillarBreakdownBarCard';
import StatisticsCard from 'progress/components/statisticsCard/statisticsCard';
import {
  DEFAULT_PILLAR_METRIC_TYPE, MetricType, Pillars,
} from 'types/analytics';
import StatisticTrendChart from 'progress/components/statisticTrendChart/statisticTrendChart';
import { useState } from 'react';
import layoutStyles from '../layout.module.scss';

function RangeView() {
  const [
    selectedMetricType,
    setSelectedMetricType,
  ] = useState<MetricType>(DEFAULT_PILLAR_METRIC_TYPE[Pillars.RECOVERY]);
  return (
    <>
      <Row center="xs" className={layoutStyles.topCardsContainer}>
        <Col xs={4}>
          <PillarBreakdownBarCard pillar={Pillars.RECOVERY} />
        </Col>
        <Col xs={8}>
          <StatisticsCard
            pillar={Pillars.RECOVERY}
            selectedMetricType={selectedMetricType}
            setSelectedMetricType={setSelectedMetricType}
          />
        </Col>
      </Row>
      <Row center="xs" className={layoutStyles.bottomCardsContainer}>
        <Col xs={4} />
        <Col xs={8}>
          <StatisticTrendChart selectedMetricType={selectedMetricType} />
        </Col>
      </Row>
    </>
  );
}

export default RangeView;
