import { roundToOneDecimal } from 'progress/utils/numberFormatter';

export const formatValue = (value: number, showPercent: boolean) => {
  const formattedValue = value === null ? -1 : roundToOneDecimal(value);
  const emptyValue = 100 - value;
  return [
    {
      id: 'value',
      label: 'value',
      // need to check that if the formattedValue is -1 the value is null or undefined
      value: formattedValue === -1 ? '--' : formattedValue,
      showPercent,
    },
    {
      id: 'emptyCircle',
      label: 'emptyCircle',
      value: emptyValue,
      showPercent: false,
    },
  ];
};
