import { getDelayVal, mswBaseUri } from 'mocks/utils/utils';
import { rest } from 'msw';
import {
  cancelInvitedSeat, getSeats, inviteSeats, removeSeat, getInviteLink,
} from '../actions/seatActions';
import { jsonCheckForPII } from '../transformer/deidentifyTransformer';

export const seatsHandlers = [
  rest.get(mswBaseUri('v1/seats/all'), (req, res) => res(jsonCheckForPII(getSeats()))),
  rest.put(mswBaseUri('v1/seats/:seatId/remove'), (req, res, ctx) => {
    const { seatId } = req.params;
    removeSeat(Number(seatId));
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
    );
  }),
  rest.delete(mswBaseUri('v1/seats/:seatId/invite'), (req, res, ctx) => {
    const { seatId } = req.params;
    cancelInvitedSeat(Number(seatId));
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
    );
  }),
  rest.post(mswBaseUri('v1/seats/invite'), async (req, res, ctx) => {
    const { emails, shipping_type: shippingType } = await req.json();
    inviteSeats(emails, shippingType);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
    );
  }),
  rest.get(mswBaseUri('v1/seats/invite-link'), (req, res, ctx) => {
    const link = getInviteLink(req.url.searchParams.get('seatId'));
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(link),
    );
  }),
];

export default seatsHandlers;
