import { classes } from '@whoop/web-components';
import styles from './tabs.module.scss';

export enum TabThemes {
  DEFAULT = 'default',
  HEADER = 'themeHeader',
  NAVIGATION = 'themeNavigation',
  ACTIVITIES_TABLE = 'themeActivitiesTable',
}

export type TabSection<D> = {
  tab: D;
  displayTitle?: string;
  badgeContent?: string;
};

type TabsProps<D> = {
  tabList: TabSection<D>[];
  selectedTab: D;
  onClick: (tab: D) => void;
  theme?: TabThemes;
};

function Tabs<D>({
  tabList, selectedTab, onClick, theme = TabThemes.DEFAULT,
}: TabsProps<D>) {
  const getThemeClassName = (givenTheme: TabThemes) => {
    if (givenTheme === TabThemes.HEADER) { return styles.themeHeader; }
    if (givenTheme === TabThemes.NAVIGATION) { return styles.themeNavigation; }
    if (givenTheme === TabThemes.ACTIVITIES_TABLE) { return styles.themeActivitiesTable; }
    return styles.default;
  };

  return (
    <div className={classes(styles.tabs, getThemeClassName(theme))}>
      {tabList.map((tab) => (
        <div key={tab.tab.toString()} className={`${styles.tab} ${tab.tab === selectedTab ? styles.activeTab : ''}`}>
          <button
            type="button"
            onClick={() => onClick(tab.tab)}
          >
            {tab.displayTitle ?? tab.tab}
            {tab.badgeContent && (
              <div className={styles.badge}>
                {tab.badgeContent}
              </div>
            )}
          </button>
        </div>
      ))}
    </div>
  );
}

export default Tabs;
