import dayjs from 'dayjs';
import { ActivityDTO, DayHR, YAxis } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import { Serie } from '@nivo/line';
import { BarData, LineData } from 'dataVisualizations/types/types';
import { Colors } from '@whoop/web-components';
import { MetricOverview } from 'types/analytics';
import { formatDate } from 'progress/profile/profileUtils';

// Turns seconds into HH:MM format
export const formatDurationFromSeconds = (seconds: number) => dayjs.duration(seconds, 'seconds').format('HH:mm');

export const getHourFromEpoch = (epochTime: number) => {
  const hourWithAmPm = dayjs(epochTime).format('hh:mm A');
  const [hour, amPm] = hourWithAmPm.split(' ');
  return {
    formattedTime: hourWithAmPm,
    hour,
    amPm,
  };
};

export const formatData = (
  hrData: ActivityDTO | DayHR,
  yAxisType: YAxis,
  maxHr?: number,
): Serie => {
  const dataInFormat: Serie = {
    id: 'hr_data',
    data: [],
  };
  dataInFormat.data = hrData.metrics.map((d: any) => {
    if (yAxisType === YAxis.rawHeartRate) {
      return {
        x: d.timestamp,
        y: d.hr,
      };
    }

    return {
      x: d.timestamp,
      y: !d.hr ? null : Math.floor((d.hr / maxHr) * 100),
    };
  });
  return dataInFormat;
};

// This handles a common range type sent from the backend
// Example "[2021-01-01T00:00:00.000Z, 2021-01-01T08:00:00.000Z)"
export const getDuration = (range: string) => {
  const rangeArr = range.split('\'');
  const lowerBound = dayjs(rangeArr[1]).valueOf();
  const upperBound = dayjs(rangeArr[3]).valueOf();
  const activityDuration = dayjs.duration(upperBound - lowerBound).format('H:mm');
  return activityDuration;
};

export const getGridVals = (max: number, numGridLines: number) => {
  const gridVals = [];
  const step = max / numGridLines;
  for (let i = 0; i <= numGridLines; i += 1) {
    gridVals.push(i * step);
  }
  return gridVals;
};

export const createDayBarLineGraphData = (
  data: MetricOverview,
  formatterParam: (val: number) => number | null,
  metricMaxVal: number = null,
): { dataPoints: BarData[] | LineData[]; maxVal?: number } => {
  if (!data) {
    return {
      dataPoints: null,
    };
  }
  const formatter = formatterParam ?? Math.round;
  const maxVal = Math.max(...data.metrics.map((metric) => formatter(metric.metric_value)));
  let parsedData: BarData[] | LineData[];

  if (data.metrics.length <= 7) {
    parsedData = data.metrics.map((metric) => ({
      // @ts-ignore metric.cycle_start_day is of format [2022, 11, 15] which works fine for dayjs
      cycle_start_day: formatDate(metric.cycle_start_day.join('-')),
      metric_value: formatter(metric.metric_value),
    }));
  } else {
    parsedData = data.metrics.map((metric) => ({
      // @ts-ignore metric.cycle_start_day is of format [2022, 11, 15] which works fine for dayjs
      x: formatDate(metric.cycle_start_day.join('-')),
      y: formatter(metric.metric_value),
    }));
  }
  const maxValBuffer = 10;
  return {
    dataPoints: parsedData,
    maxVal: metricMaxVal ?? maxVal + maxValBuffer,
  };
};

export const getTimeStepFromMin = (approxMin: number) => {
  if (approxMin > 60) {
    return 30;
  }
  if (approxMin > 30) {
    return 20;
  }
  if (approxMin > 20) {
    return 10;
  }
  if (approxMin > 5) {
    return 5;
  }
  return approxMin;
};

const RECOVERY_RED_MAX = 33;
const RECOVERY_YELLOW_MAX = 66;

export const getColorFromRecovery = (value: number, hidden: boolean = false): string => {
  if (hidden) {
    return Colors.gray500;
  }
  if (value <= RECOVERY_RED_MAX) {
    return Colors.red;
  } if (value <= RECOVERY_YELLOW_MAX) {
    return Colors.yellow;
  }
  return Colors.green;
};
