import { PrivacyLevel } from 'types/dashboardUser';
import { client } from './apiClient';

export type Privacy = {
  privacy_level: PrivacyLevel;
};

export const getPrivacy = async (salesforceAccountId: string) => {
  const response = await client.get<Privacy>('enterprise-service/v1/user/privacy', {
    params: { salesforceAccountId },
  });
  return response.data;
};
