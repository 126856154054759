import { convertStrainToString, roundToOneDecimal } from 'progress/utils/numberFormatter';
import { Pillars } from 'types/analytics';
import styles from './pillarPieChart.module.scss';

export const getPieData = (pieValue: number, pillarType: Pillars) => {
  let value = pieValue === null ? -1 : pieValue;
  let emptyValue = 100 - pieValue;
  if (pillarType === Pillars.STRAIN) {
    value = pieValue === null ? -1 : roundToOneDecimal(pieValue);
    emptyValue = 21 - roundToOneDecimal(pieValue);
  }
  return [
    {
      id: pillarType,
      label: pillarType,
      value,
    },
    {
      id: 'emptyCircle',
      label: 'emptyCircle',
      value: emptyValue,
    },
  ];
};

export const getPieChartValue = (value: number, isStrain: boolean) => {
  if (value < 0) {
    return '--';
  }
  return isStrain ? convertStrainToString(value) : `${value}%`;
};

export function CenteredMetric(
  isStrain: boolean,
  value: number,
  centerX: number,
  centerY: number,
) {
  return (
    <g>
      <text
        x={centerX}
        y={centerY - 7}
        textAnchor="middle"
        dominantBaseline="central"
        className={styles.innerArcValue}
      >
        {getPieChartValue(value, isStrain)}
      </text>
    </g>
  );
}

export type PieData = {
  id: string;
  label: string;
  value: number;
};
