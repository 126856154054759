import React, { ReactChild } from 'react';
import InfoIcon from '@material-ui/icons/PriorityHighSharp';
import { P2 } from 'components/text';
import { makeStyles } from '@material-ui/core';
import styles from './genericWarning.module.scss';

const useStyles = makeStyles({
  svg: {
    marginRight: '16px',
    fill: styles.strainBlue,
  },
});

type GenericWarningProps = {
  children: ReactChild[] | ReactChild;
};

function GenericWarning({ children }: GenericWarningProps) {
  const classes = useStyles();

  return (
    <div className={styles.warning}>
      <InfoIcon classes={{ root: classes.svg }} />
      <P2 className={styles.warningMsg}>{children}</P2>
    </div>
  );
}

export default GenericWarning;
