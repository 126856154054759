import { SleepMetric } from 'types/analytics';
import { baseSeats } from './seats';

export const progressBaseSeats = baseSeats.slice(0, 8);

export const sleepBreakdownData = {
  average_duration: 27115809.35714286,
  average_performance: 87.71428571428572,
  average_sleep_consistency: 76.78571428571428,
  average_sleep_efficiency: 0.9354074554783958,
  sleep_averages: [
    {
      activity_id: 5824360,
      duration: 29990742,
      performance: 100,
      first_name: 'Benny',
      last_name: 'Arnold',
      avatar_url: null,
      rank: 1,
      deleted: false,
      created_at: '2022-03-14T00:27:23.617+0000',
      updated_at: '2022-03-15T19:36:17.750+0000',
      cycle_day_joined: '2022-02-18',
      user_id: 7,
      cycle_day: '2022-03-15',
      has_data: true,
      is_waiting_for_data: null,
      sleep_consistency: 83,
      sleep_efficiency: 0.8645820021629333,
    },
    {
      activity_id: 5824361,
      duration: 25990742,
      performance: 90,
      first_name: 'Jane',
      last_name: 'Wallace',
      avatar_url: null,
      rank: 9,
      deleted: false,
      created_at: '2022-03-14T00:27:23.617+0000',
      updated_at: '2022-03-15T19:36:17.750+0000',
      cycle_day_joined: '2022-02-18',
      user_id: 9,
      cycle_day: '2022-03-15',
      has_data: true,
      is_waiting_for_data: null,
      sleep_consistency: 89,
      sleep_efficiency: 0.909,
    },
    {
      first_name: 'Jill',
      last_name: 'Cobb',
      avatar_url: null,
      duration: 27790742,
      performance: 85,
      user_id: 6,
      sleep_consistency: 91,
      sleep_efficiency: 0.777,
    },
    {
      activity_id: 5824361,
      duration: 25990742,
      performance: 90,
      first_name: 'asdf',
      last_name: 'asdf',
      member_identifier: 'MKC33',
      avatar_url: null,
      rank: 4,
      deleted: false,
      created_at: '2022-03-14T00:27:23.617+0000',
      updated_at: '2022-03-15T19:36:17.750+0000',
      cycle_day_joined: '2022-02-18',
      user_id: 7,
      cycle_day: '2022-03-15',
      has_data: true,
      is_waiting_for_data: null,
      sleep_consistency: 95,
      sleep_efficiency: 0.65,
    },
  ],
};

export const strainBreakdownData = {
  average_calories: 2001.3077101120246,
  average_day_strain: 10.839160892015466,
  average_workout_count: 2.6666666666666665,
  strain_averages: [
    {
      day_strain: 7.7494449786036,
      calories: 1483.517759576576,
      activity_strain: null,
      activities: [],
      first_name: 'test',
      last_name: 'name',
      avatar_url: null,
      rank: 1,
      deleted: false,
      created_at: '2022-03-14T00:27:23.617+0000',
      updated_at: '2022-03-15T19:36:17.750+0000',
      cycle_day_joined: '2022-02-18',
      user_id: 123456,
      cycle_day: '2022-03-15',
      has_data: true,
      is_waiting_for_data: null,
    },
    {
      day_strain: 4.997681098363355,
      calories: 969.3858454588291,
      activity_strain: null,
      activities: [],
      first_name: 'another',
      last_name: 'name',
      avatar_url: null,
      rank: 2,
      deleted: false,
      created_at: '2022-03-14T00:27:23.617+0000',
      updated_at: '2022-03-15T15:16:10.477+0000',
      cycle_day_joined: '2022-02-18',
      user_id: 123457,
      cycle_day: '2022-03-15',
      has_data: true,
      is_waiting_for_data: null,
    },
  ],
};

export const recoveryBreakdownData = {
  average_score: 49.6,
  average_hrv: 0.05244259120225907,
  average_rhr: 58.8,
  recovery_averages: [
    {
      avatar_url: undefined,
      email: 'craig.anderson@fosterjefferson.com',
      id: 1,
      user_id: 1,
      strap_serial: '4A4829140',
      status: 'active',
      seat_status: 'active',
      contract_number: 1,
      contract_start_date: '2021-03-22T00:00:00.000+0000',
      contract_end_date: '2023-03-21T00:00:00.000+0000',
      last_activity_at: null,
      activated_on: '2021-03-22T00:00:00.000+0000',
      first_name: 'Craig',
      last_name: 'Anderson',
      rhr: 51,
      hrv: 0.0712705357670784,
      score: 40,
      name: 'Craig Anderson',
    },
    {
      avatar_url: undefined,
      email: 'peter.henderson@fosterjefferson.com',
      id: 2,
      user_id: 2,
      strap_serial: '4A2246723',
      status: 'active',
      seat_status: 'active',
      contract_number: 1,
      contract_start_date: '2021-03-22T00:00:00.000+0000',
      contract_end_date: '2023-03-21T00:00:00.000+0000',
      last_activity_at: null,
      activated_on: '2021-03-22T00:00:00.000+0000',
      first_name: 'Peter',
      last_name: 'Henderson',
      rhr: 65,
      hrv: 0.0352705357670784,
      score: 36,
      name: 'Peter Henderson',
    },
  ],
};

export const sleepAverages = {
  cohort_averages: [
    { average: 72, date: '2022-05-15', metric_name: SleepMetric.PERFORMANCE },
    { average: 90, date: '2022-05-16', metric_name: SleepMetric.PERFORMANCE },
  ],
};

export const outOfOrderSleepAverages = {
  cohort_averages: [
    { average: 100, date: '2022-05-12', metric_name: SleepMetric.PERFORMANCE },
    { average: 90, date: '2022-05-16', metric_name: SleepMetric.PERFORMANCE },
    { average: 60, date: '2022-05-14', metric_name: SleepMetric.PERFORMANCE },
    { average: 72, date: '2022-05-15', metric_name: SleepMetric.PERFORMANCE },
    { average: 85, date: '2022-05-13', metric_name: SleepMetric.PERFORMANCE },
  ],
};

const baseEmptySleepAverages = [
  { average: 0, date: '2022-04-17', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-18', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-19', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-20', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-21', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-22', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-23', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-24', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-25', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-26', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-27', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-28', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-29', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-04-30', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-05-01', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-05-02', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-05-03', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-05-04', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-05-05', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-05-06', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-05-07', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-05-08', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-05-09', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-05-10', metric_name: SleepMetric.PERFORMANCE },
  { average: 0, date: '2022-05-11', metric_name: SleepMetric.PERFORMANCE },
];

export const filledSleepAverages = [
  ...baseEmptySleepAverages,
  {
    average: 100,
    date: '2022-05-12',
    metric_name: SleepMetric.PERFORMANCE,
  },
  {
    average: 85,
    date: '2022-05-13',
    metric_name: SleepMetric.PERFORMANCE,
  },
  {
    average: 60,
    date: '2022-05-14',
    metric_name: SleepMetric.PERFORMANCE,
  },
  {
    average: 72,
    date: '2022-05-15',
    metric_name: SleepMetric.PERFORMANCE,
  },
  {
    average: 90,
    date: '2022-05-16',
    metric_name: SleepMetric.PERFORMANCE,
  },
];

export const filledEmptySleepAverages = [
  ...baseEmptySleepAverages,
  {
    average: 0,
    date: '2022-05-12',
    metric_name: SleepMetric.PERFORMANCE,
  },
  {
    average: 0,
    date: '2022-05-13',
    metric_name: SleepMetric.PERFORMANCE,
  },
  {
    average: 0,
    date: '2022-05-14',
    metric_name: SleepMetric.PERFORMANCE,
  },
  {
    average: 72,
    date: '2022-05-15',
    metric_name: SleepMetric.PERFORMANCE,
  },
  {
    average: 90,
    date: '2022-05-16',
    metric_name: SleepMetric.PERFORMANCE,
  },
];

export const progressMemberProfile = {
  member_profile: {
    first_name: 'Test',
    last_name: 'User',
    user_id: 12345,
    email: 'testuser@whoop.com',
    strap_serial: '4A12345',
    username: '@testuser',
    avatar_url: null,
    data_available_date: '2022-02-18T18:35:56.947+0000',
  },
  cohort_member_details_list: [{
    user_id: 12,
    cohort_id: 123,
    name: 'Test group',
    joined_date: new Date('Thu Aug 25 2022'),
  }],
  pillar_overview: {
    day_kilocalories: 537.5132071836897,
    hrv_rmssd: 0.095287345,
    quality_duration: 21061564,
    recovery_score: 81,
    resting_heart_rate: 51,
    scaled_strain: 6.84644267210995,
    sleep_need: 29371756,
    sleep_performance: 72,
  },
};

export const userProfileOverview = {
  member_profile: {
    first_name: 'Craig',
    last_name: 'Anderson',
    user_id: 12345,
    email: 'craig.anderson@whoop.com',
    strap_serial: '4A12345',
    username: 'craigAnderson',
    avatar_url: null,
    data_available_date: '2022-02-18T18:35:56.947+0000',
  },
  cohort_member_details_list: [{
    user_id: 12,
    cohort_id: 123,
    name: 'Test group',
    joined_date: new Date('Thu Aug 25 2022'),
  }],
};

export const groupAveragesManyPercent = {
  cohort_averages: [
    { average: 92.5, date: '2022-05-15', metric_name: SleepMetric.PERFORMANCE },
    { average: 94, date: '2022-05-16', metric_name: SleepMetric.PERFORMANCE },
  ],
  cohort_id: 12345,
};

export const groupAveragesMany = {
  cohort_averages: [
    { average: 10.0, date: '2022-05-15', metric_name: SleepMetric.PERFORMANCE },
    { average: 10.2, date: '2022-05-16', metric_name: SleepMetric.PERFORMANCE },
  ],
  cohort_id: 12345,
};

export const mockGroupReturnData = [
  {
    name: 'All Group 120938',
    id: 1234,
    community_id: 1234,
    salesforce_account_id: '1234',
    member_count: 12,
    about: 'test',
    collaborators: null,
    is_primary: true,
  },
  {
    name: 'test',
    id: 1235,
    community_id: 1235,
    salesforce_account_id: '1235',
    member_count: 12,
    about: 'test',
    collaborators: null,
    is_primary: false,
  },
];

export const mockNoPrimaryGroup = [
  {
    name: 'test',
    id: 1235,
    community_id: 1235,
    salesforce_account_id: '1235',
    member_count: 12,
    about: 'test',
    collaborators: null,
    is_primary: false,
  },
];

export const complianceData = [
  {
    name: 'Gregg Kingsley',
    first_name: 'Gregg',
    last_name: 'Kingsley',
    sensor_battery_level: 50,
    last_activity_at: '2022-05-29T14:15:13.870+0000',
    latest_uploaded_metric: '2022-05-28T14:15:13.870+0000',
    seat_id: 1,
    user_id: 30991,
    time_off_wrist_seconds: 555,
  },
  {
    name: 'Ashley Donovan',
    first_name: 'Ashley',
    last_name: 'Donovan',
    sensor_battery_level: 25,
    last_activity_at: '2022-05-30T16:44:47.508+0000',
    latest_uploaded_metric: '2022-05-29T14:15:13.870+0000',
    seat_id: 2,
    user_id: 30991,
    time_off_wrist_seconds: 0,
  },
  {
    name: 'Kylie Harriet',
    first_name: 'Kylie',
    last_name: 'Harriet',
    sensor_battery_level: 10,
    last_activity_at: '2022-05-23T16:53:29.327+0000',
    latest_uploaded_metric: '2022-05-22T14:15:13.870+0000',
    seat_id: 2,
    user_id: 30991,
    time_off_wrist_seconds: 1222,
  },
  {
    name: 'Nolan Jones',
    first_name: 'Nolan',
    last_name: 'Jones',
    sensor_battery_level: 75,
    last_activity_at: '2022-05-26T15:20:29.327+0000',
    latest_uploaded_metric: '2022-05-25T14:15:13.870+0000',
    seat_id: 2,
    user_id: 30991,
    time_off_wrist_seconds: 640,
  },
];

export const memberTrendsData = {
  data_trend: [
    {
      cycle_id: 11494893,
      cycle_start_day: [2022, 8, 4],
      pillar_data_list: [
        {
          trend_key: 'RECOVERY',
          trend_value: 56,
        },
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 7.842721710326995,
        },
      ],
    },
    {
      cycle_id: 11522894,
      cycle_start_day: [2022, 8, 5],
      pillar_data_list: [
        {
          trend_key: 'RECOVERY',
          trend_value: 54,
        },
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 6.877926949784547,
        },
      ],
    },
    {
      cycle_id: 11555888,
      cycle_start_day: [2022, 8, 6],
      pillar_data_list: [
        {
          trend_key: 'RECOVERY',
          trend_value: 89,
        },
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 16.491987502787765,
        },
      ],
    },
    {
      cycle_id: 11577786,
      cycle_start_day: [2022, 8, 7],
      pillar_data_list: [
        {
          trend_key: 'RECOVERY',
          trend_value: 20,
        },
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 12.8041408429019,
        },
      ],
    },
    {
      cycle_id: 11600938,
      cycle_start_day: [2022, 8, 8],
      pillar_data_list: [
        {
          trend_key: 'RECOVERY',
          trend_value: 45,
        },
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 11.32071386132096,
        },
      ],
    },
    {
      cycle_id: 11432828,
      cycle_start_day: [2022, 8, 9],
      pillar_data_list: [
        {
          trend_key: 'RECOVERY',
          trend_value: 65,
        },
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 11.672893416701088,
        },
      ],
    },
    {
      cycle_id: 11463928,
      cycle_start_day: [2022, 8, 10],
      pillar_data_list: [
        {
          trend_key: 'RECOVERY',
          trend_value: 58,
        },
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 4.843300000113094,
        },
      ],
    },
  ],
  member_id: 1234,
};

export const breakdownRecoveryRecords = [
  { rhr: 49, hrv: 0.17357085645198822, score: 99 },
  { rhr: 60, hrv: 0.05313081294298172, score: 97 },
  { rhr: 46, hrv: 0.11963166296482086, score: 95 },
  { rhr: 51, hrv: 0.05521512031555176, score: 85 },
  { rhr: 54, hrv: 0.08551025390625, score: 77 },
  { rhr: 46, hrv: 0.14357085645198822, score: 75 },
  { rhr: 52, hrv: 0.08123853802680969, score: 72 },
  { rhr: 65, hrv: 0.04523853802680969, score: 67 },
  { rhr: 59, hrv: 0.05723853802680969, score: 58 },
  { rhr: 56, hrv: 0.0432705357670784, score: 50 },
  { rhr: 51, hrv: 0.0712705357670784, score: 40 },
  { rhr: 65, hrv: 0.0352705357670784, score: 36 },
  { rhr: 62, hrv: 0.0222705357670784, score: 32 },
  { rhr: 72, hrv: 0.0252705357670784, score: 20 },
  { rhr: 70, hrv: 0.0332705357670784, score: 15 },
  { rhr: 55, hrv: 0.0752705357670784, score: 45 },
  { rhr: 46, hrv: 0.1112705357670784, score: 80 },
  { rhr: 54, hrv: 0.082705357670784, score: 51 },
  { rhr: 63, hrv: 0.0272705357670784, score: 30 },
  { rhr: 78, hrv: 0.0182705357670784, score: 5 },
];

export const breakdownStrainRecords = [
  { day_strain: 19.6, calories: 2900, workout_count: 3 },
  { day_strain: 16.3, calories: 1700, workout_count: 2 },
  { day_strain: 12.7, calories: 2230, workout_count: 2 },
  { day_strain: 10.6, calories: 1978, workout_count: 0 },
  { day_strain: 9.3, calories: 2151, workout_count: 0 },
  { day_strain: 7.9, calories: 1692, workout_count: 1 },
  { day_strain: 6.2, calories: 1734, workout_count: 1 },
  { day_strain: 4.1, calories: 1543, workout_count: 0 },
  { day_strain: 14.4, calories: 1800, workout_count: 0 },
  { day_strain: 12.4, calories: 2216, workout_count: 0 },
  { day_strain: 17.5, calories: 3517, workout_count: 4 },
  { day_strain: 15.9, calories: 2350, workout_count: 1 },
  { day_strain: 11.6, calories: 1923, workout_count: 1 },
  { day_strain: 4.9, calories: 2063, workout_count: 0 },
  { day_strain: 4.2, calories: 816, workout_count: 0 },
  { day_strain: 13.9, calories: 2740, workout_count: 2 },
  { day_strain: 6.1, calories: 1867, workout_count: 0 },
  { day_strain: 18.0, calories: 2488, workout_count: 3 },
  { day_strain: 17.3, calories: 4070, workout_count: 3 },
  { day_strain: 7.7, calories: 1989, workout_count: 0 },
];

export const breakdownSleepRecords = [
  {
    duration: 32990742,
    performance: 100,
    sleep_consistency: 70,
    sleep_efficiency: 0.894,
  },
  {
    duration: 30000742,
    performance: 100,
    sleep_consistency: 86,
    sleep_efficiency: 0.92,
  },
  {
    duration: 29990742,
    performance: 99,
    sleep_consistency: 100,
    sleep_efficiency: 0.95,
  },
  {
    duration: 28346582,
    performance: 97,
    sleep_consistency: 93,
    sleep_efficiency: 0.99,
  },
  {
    duration: 27346582,
    performance: 96,
    sleep_consistency: 52,
    sleep_efficiency: 0.74,
  },
  {
    duration: 27722009,
    performance: 95,
    sleep_consistency: 64,
    sleep_efficiency: 0.80,
  },
  {
    duration: 27522009,
    performance: 93,
    sleep_consistency: 87,
    sleep_efficiency: 0.83,
  },
  {
    duration: 26002342,
    performance: 90,
    sleep_consistency: 77,
    sleep_efficiency: 0.87,
  },
  {
    duration: 26502342,
    performance: 88,
    sleep_consistency: 86,
    sleep_efficiency: 0.88,
  },
  {
    duration: 25002342,
    performance: 85,
    sleep_consistency: 62,
    sleep_efficiency: 0.95,
  },
  {
    duration: 24358074,
    performance: 82,
    sleep_consistency: 90,
    sleep_efficiency: 0.894,
  },
  {
    duration: 24448074,
    performance: 81,
    sleep_consistency: 94,
    sleep_efficiency: 0.97,
  },
  {
    duration: 23358074,
    performance: 78,
    sleep_consistency: 65,
    sleep_efficiency: 0.87,
  },
  {
    duration: 22358074,
    performance: 75,
    sleep_consistency: 55,
    sleep_efficiency: 0.85,
  },
  {
    duration: 21358074,
    performance: 74,
    sleep_consistency: 83,
    sleep_efficiency: 0.86,
  },
  {
    duration: 20395409,
    performance: 70,
    sleep_consistency: 88,
    sleep_efficiency: 0.74,
  },
  {
    duration: 17553006,
    performance: 68,
    sleep_consistency: 95,
    sleep_efficiency: 0.70,
  },
  {
    duration: 19553006,
    performance: 65,
    sleep_consistency: 74,
    sleep_efficiency: 0.77,
  },
  {
    duration: 18453006,
    performance: 63,
    sleep_consistency: 70,
    sleep_efficiency: 0.82,
  },
  {
    duration: 17453006,
    performance: 61,
    sleep_consistency: 78,
    sleep_efficiency: 0.85,
  },
];
