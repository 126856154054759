import React, { useState } from 'react';
import { Icon } from '@whoop/web-components';
import { SUBTITLE_1, TITLE_3 } from 'components/text';
import styles from './editableHeaderInput.module.scss';

type EditableHeaderInputProps = {
  onUpdate: (property: string, value: string) => void;
  field: string;
  value: string;
  setValue: (s: string) => void;
  placeholder: string;
};

function EditableHeaderInput({
  onUpdate, field, value, setValue, placeholder,
}: EditableHeaderInputProps) {
  const [showInput, setShowInput] = useState(false);

  if (showInput) {
    return (
      <span className={styles.update}>
        <input
          className={field !== 'name' ? styles.descriptionInput : styles.nameInput}
          type="text"
          title="text"
          placeholder={placeholder}
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && showInput) {
              await onUpdate(field, value);
              setShowInput(false);
            }
          }}
        />
        <Icon
          title="edit"
          variant="normal"
          name="edit"
          className={styles.editIcon}
          onClick={async () => {
            await onUpdate(field, value);
            setShowInput(false);
          }}
        />
      </span>
    );
  }
  return (
    <span
      className={styles.edit}
      tabIndex={0}
      role="button"
      aria-label={field}
      onKeyDown={() => setShowInput(true)}
      onClick={() => setShowInput(true)}
    >
      {field !== 'name'
        ? <SUBTITLE_1>{value || 'Add a description...'}</SUBTITLE_1>
        : <TITLE_3>{value}</TITLE_3>}
      <Icon
        title="edit-pencil"
        variant="normal"
        name="edit"
        className={styles.editIconClick}
      />
    </span>
  );
}

export default EditableHeaderInput;
