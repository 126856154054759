import { Icon } from '@whoop/web-components';
import GenericWarning from 'components/genericWarning';
import StrapManagementHeader from 'components/strapManagementHeader';
import { useAuth } from 'context/auth-context';
import { usePrivacy } from 'context/privacy-context';
import MemberActivationSection from 'progress/summary/RecoverySection/MemberActivationSection/memberActivationSection';
import ProgressPageNav from 'progress/components/ProgressPageNav/progressPageNav';
import RecoverySection from 'progress/summary/RecoverySection/recoverySection';
import SleepSection from 'progress/summary/SleepSection/sleepSection';
import StrainSection from 'progress/summary/StrainSection/strainSection';
import { useState } from 'react';
import { Cohort } from 'types/cohort';
import { PrivacyLevel } from 'types/dashboardUser';
import ProgressPageTabs from 'types/progressPage';
import Breakdown from './breakdown/breakdown';
import styles from './progressPage.module.scss';

type ProgressPageProps = {
  tabSelected: ProgressPageTabs;
};

function ProgressPage({ tabSelected }: ProgressPageProps) {
  const { user } = useAuth();
  const [groupSelected, setGroupSelected] = useState<Cohort>();
  const { isInPrivacyLevel } = usePrivacy();

  const canViewMXPLink = groupSelected?.legacy_team_id && user.admin;

  return (
    <>
      <StrapManagementHeader />
      <ProgressPageNav
        tabSelected={tabSelected}
        groupSelected={groupSelected}
        setGroupSelected={setGroupSelected}
      />
      {groupSelected && (
        <div className={styles.container}>
          {tabSelected === ProgressPageTabs.SUMMARY
            ? (
              <>
                {canViewMXPLink && (
                  <div className={styles.warning}>
                    <GenericWarning>
                      <a href={`${window._env.MXP_URI}/teams/${groupSelected.legacy_team_id}/roster`} target="_blank" rel="noreferrer">Click here</a>
                      {' '}
                      to view this team in MXP.
                    </GenericWarning>
                  </div>
                )}
                <MemberActivationSection />
                {
                  isInPrivacyLevel(PrivacyLevel.aggregate_metrics) ? (
                    <>
                      <SleepSection groupId={groupSelected.id} />
                      <RecoverySection groupId={groupSelected.id} />
                      <StrainSection groupId={groupSelected.id} />
                    </>
                  ) : (
                    <div className={styles.lockedSection}>
                      <Icon name="locked_line" />
                      <h4>
                        You don’t have access to member data
                      </h4>
                      <div>
                        Contact your admin to update your permissions settings
                      </div>
                    </div>
                  )
                }
              </>
            )
            : <Breakdown groupId={groupSelected.id} groupName={groupSelected.name} />}
        </div>
      )}
    </>
  );
}

export default ProgressPage;
