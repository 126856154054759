import { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, MenuList } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { PAGE_STATE } from 'constants/pageState';
import { SeatStatus } from 'types/seat';
import { useNavigate } from 'react-router';
import Dropdown from '../dropdown/index';
import styles from './moreActions.module.scss';
import { getInviteLink } from '../../api/seatApi';
import RowMessage from '../rowMessage';

function MoreActions({
  setPageState, setSelectedRows, row, account,
}) {
  const [loadingLink, setLoadingLink] = useState(false);
  const [rowMessage, setRowMessage] = useState('');
  const [displayCheck, setDisplayCheck] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const navigate = useNavigate();

  const handleRowMessageClick = () => {
    setIsAlertVisible(true);
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 3000);
  };

  const fetchInviteLink = async () => {
    const seatID = row.original.id;
    if (!loadingLink) {
      setDisplayCheck(false);
      setLoadingLink(true);
      setRowMessage('Fetching invite link');
      handleRowMessageClick();
      try {
        const { data } = await getInviteLink(seatID);
        const { seat_invite_link } = data;
        navigator.clipboard.writeText(seat_invite_link); // Copy to clipboard
        setLoadingLink(false);
        setDisplayCheck(true);
        setRowMessage('Link copied to clipboard');
      } catch (err) {
        setDisplayCheck(false);
        setRowMessage('Error fetching invite link');
      }
      handleRowMessageClick();
      setLoadingLink(false);
    }
  };

  const goToHubProfile = () => {
    const seatId = row.original.id;
    navigate(`/accounts/${account.id}/people/hub/${seatId}/viewProfile`);
  };

  const {
    values: { member_status },
  } = row;

  const showHubProfileButton = () => ['active', 'deactivated'].includes(member_status) && row.original.hub_member;

  const onClick = (pageState) => {
    setPageState(pageState);
    setSelectedRows([row]);
  };

  const invitedActions = [
    { label: 'Resend invite', pageState: PAGE_STATE.RESEND_INVITES },
    { label: 'Copy invitation link' },
    { label: 'Withdraw invite', pageState: PAGE_STATE.WITHDRAW_INVITES },
  ];

  const deactivatedActions = { label: 'Re-invite member', pageState: PAGE_STATE.REINIVTE_DEACTIVATED };
  const removeMemberButton = (
    <MenuItem
      className={styles.removeMember}
      onClick={() => onClick(PAGE_STATE.REMOVE_MEMBERS)}
    >
      Remove member
    </MenuItem>
  );
  const viewHubProfileButton = (
    <MenuItem
      className={styles.hubProfile}
      onClick={goToHubProfile}
    >
      Edit Member Profile
    </MenuItem>
  );

  return (
    <div className={styles.moreActionsContainer}>
      <Dropdown
        button={<MoreHorizIcon />}
        buttonProps={{ variant: 'link', 'data-testid': 'more-actions-btn', 'aria-label': 'more-actions-btn' }}
        dropdownStyles={{ borderTop: '3px solid black' }}
        isDisabled={(member_status === SeatStatus.Pending && row.original.hub_member)}
        closeOnClick
      >
        <MenuList className={styles.menuList} disablePadding>
          {member_status === SeatStatus.Invited
            && invitedActions.map((action) => (
              <MenuItem
                key={action.label}
                onClick={() => {
                  if (action.label === 'Copy invitation link') {
                    fetchInviteLink();
                  } else {
                    onClick(action.pageState);
                  }
                }}
              >
                {action.label}
              </MenuItem>
            ))}
          {member_status === SeatStatus.Deactivated
            && (
            <MenuItem
              key={deactivatedActions.label}
              onClick={() => {
                onClick(deactivatedActions.pageState);
              }}
            >
              {deactivatedActions.label}
            </MenuItem>
            )}
          {['active', 'pending'].includes(member_status) && removeMemberButton}
          {showHubProfileButton() && viewHubProfileButton}
        </MenuList>
      </Dropdown>
      <RowMessage
        message={rowMessage}
        visible={isAlertVisible}
        check={displayCheck}
      />
    </div>
  );
}

MoreActions.propTypes = {
  setPageState: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  row: PropTypes.shape({
    values: PropTypes.shape({
      member_status: PropTypes.string,
    }),
    original: PropTypes.shape({
      id: PropTypes.number,
      strap_serial: PropTypes.string,
      hub_member: PropTypes.bool,
    }),
  }).isRequired,
  account: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default MoreActions;
