import { useEffect, useState } from 'react';
import { BarDatum } from '@nivo/bar';
import MemberActivationsLabel, { ActivationType } from 'progress/summary/RecoverySection/MemberActivationSection/MemberActivationsLabel/memberActivationsLabel';
import MemberActivationsChart from 'progress/summary/RecoverySection/MemberActivationSection/MemberActivationsChart/memberActivationsChart';
import styles from 'progress/progressPage.module.scss';
import { getTotalInactive } from 'progress/progressPageUtils';
import { useAccounts } from 'context/account-context';
import componentStyles from './memberActivationSection.module.scss';

function MemberActivationSection() {
  const { account } = useAccounts();
  const [memberActivationsData, setMemberActivationsData] = useState<{
    graphData: BarDatum[];
    totalCount: number;
  }>();

  useEffect(() => {
    // Only getting this info for all groups currently
    // Will need to decide what to do when a specific group is selected
    if (account) {
      setMemberActivationsData({
        graphData: [{
          y: 'activationsData',
          active: account.active_seats,
          pending: account.pending_seats,
          invited: account.invited_seats,
          inactive: getTotalInactive(account),
        }],
        totalCount: account.total_licenses,
      });
    }
  }, [account]);

  if (memberActivationsData) {
    return (
      <>
        <div className={styles.sectionTitle}>
          Membership Activation
        </div>
        <div className={componentStyles.membershipActivationLabels}>
          <MemberActivationsLabel
            rightMargin
            activationType={ActivationType.ACTIVATED}
            value={memberActivationsData.graphData[0].active}
            totalCount={memberActivationsData.totalCount}
          />
          <MemberActivationsLabel
            rightMargin
            activationType={ActivationType.PENDING}
            value={memberActivationsData.graphData[0].pending}
            totalCount={memberActivationsData.totalCount}
          />
          <MemberActivationsLabel
            rightMargin
            activationType={ActivationType.INVITED}
            value={memberActivationsData.graphData[0].invited}
            totalCount={memberActivationsData.totalCount}
          />
          <MemberActivationsLabel
            activationType={ActivationType.INACTIVE}
            value={memberActivationsData.graphData[0].inactive}
            totalCount={memberActivationsData.totalCount}
          />
        </div>
        <div className={componentStyles.memberActivationsChart}>
          <MemberActivationsChart data={memberActivationsData.graphData} />
        </div>
      </>
    );
  }
  return null;
}

export default MemberActivationSection;
