import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Hub, HubParams, HubUser } from 'types/hub';
import Loading from 'loading';
import { getHubsBySalesforceAccountId, getHubUserInformation, updateHub } from 'api/hubApi';
import { Row } from 'react-table';
import { useToast } from 'context/toast-context';
import HubMembersTable from './hubMembersTable/hubMembersTable';
import styles from './hubDetailsPage.module.scss';
import ArchiveHubMember from './hubMembersTable/archiveHubMember/archiveHubMember';
import HubHeader from './hubHeader/hubHeader';

function HubDetailsPage() {
  const { openToast } = useToast();
  const { macAddress } = useParams<HubParams>();
  const [currentHub, setCurrentHub] = useState<Hub>();
  const [hubUsers, setHubUsers] = useState<HubUser[]>([]);
  const [selectedRows, setSelectedRows] = useState<Row<HubUser>[]>([]);
  const [loading, setLoading] = useState(false);
  const [archiveMemberModal, setArchiveMemberModal] = useState(false);

  const getHub = async () => {
    setLoading(true);
    const hubs: Hub[] = await getHubsBySalesforceAccountId();
    const selectedHub: Hub = hubs.filter((hub) => hub.mac_address === macAddress)[0];
    const currentHubUsers: HubUser[] = await getHubUserInformation(macAddress);
    setCurrentHub(selectedHub);
    setHubUsers(currentHubUsers);
    setLoading(false);
  };

  useEffect(() => {
    getHub();
  }, [macAddress]);

  if (loading) {
    return <Loading />;
  }

  if (archiveMemberModal) {
    return (
      <ArchiveHubMember
        selectedUsers={selectedRows?.map((row) => row.original)}
        setModal={setArchiveMemberModal}
        refresh={getHub}
      />
    );
  }

  const handleUpdateHub = async (property: keyof Hub, value: string) => {
    const body = {
      ...currentHub,
      [property]: value,
    };
    try {
      await updateHub(macAddress, body);
      setCurrentHub(body);
      openToast(`Hub ${property} has been updated!`);
    } catch {
      openToast(`There was an error updating the hub ${property}`);
    }
  };

  if (currentHub) {
    return (
      <div className={styles.hubDetailsContainer}>
        <HubHeader currentHub={currentHub} handleUpdateHub={handleUpdateHub} />
        <HubMembersTable
          data={hubUsers}
          loading={loading}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setArchiveMemberModal={setArchiveMemberModal}
        />
      </div>
    );
  }

  return null;
}

export default HubDetailsPage;
