import FooterLogo from 'assets/ui-logo.svg';
import { Button } from '@whoop/web-components';
import { P3 } from 'components/text';
import styles from './footer.module.scss';

type FooterProps = {
  logo: boolean;
  landingPage?: boolean;
};

function Footer(props: FooterProps) {
  const { logo, landingPage = false } = props;
  const helpCenterClick = () => { window.location.href = 'https://support.whoop.com/WHOOP_Unite'; };
  const aboutWhoopUniteClick = () => { window.location.href = 'https://whoop.com/group-sales'; };
  const termsOfUseClick = () => { window.location.href = 'https://whoopunite.com/legal/terms-of-use'; };
  const privacyPolicyClick = () => { window.location.href = 'https://whoopunite.com/legal/privacy'; };

  return (
    <div className={`${styles.footerContainer} ${landingPage ? styles.relativeContainer : ''}`}>
      {logo ? (
        <img
          className={styles.logo}
          src={FooterLogo}
          alt="Whoop Footer Logo"
        />
      ) : (
        <div className={styles.actionContainer}>
          <Button
            label="help center"
            variant="link"
            theme="dark"
            onClick={helpCenterClick}
          />
          <Button
            label="about whoop unite"
            variant="link"
            theme="dark"
            onClick={aboutWhoopUniteClick}
          />
        </div>
      )}
      <div className={styles.footerLinks}>
        <P3>Copyright © 2022 Whoop</P3>
        <Button variant="link" theme="dark" onClick={termsOfUseClick} className={styles.details}>Terms of Use</Button>
        <Button variant="link" theme="dark" onClick={privacyPolicyClick} className={styles.details}>Privacy Policy</Button>
      </div>
    </div>
  );
}

export default Footer;
