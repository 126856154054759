import { HEADING_4, SUBTITLE_1 } from 'components/text';
import InputGroup, { InputGroupType } from 'components/inputGroup/inputGroup';
import { ActivateSensorReducerAction } from 'wizards/hub/activateSensorWizard/utils';
import styles from '../memberProfile.module.scss';
import resetPasswordStyles from './resetHubMemberPassword.module.scss';

type ResetHubMemberPasswordProps = {
  sensorId: string;
  dispatch?: (a: ActivateSensorReducerAction) => void;
  addMember?: boolean;
  newPassword: string;
  confirmedPassword: string;
  passwordErrorMessage: string;
};
function ResetHubMemberPassword({
  sensorId, dispatch, addMember = false, newPassword, confirmedPassword, passwordErrorMessage,
} : ResetHubMemberPasswordProps) {
  return (
    <div>
      {!addMember && (
      <div className={styles.section}>
        <HEADING_4>Sensor ID</HEADING_4>
        <div className={styles.sectionContainer}>
          <div className={styles.sensorValue}>
            <SUBTITLE_1>{sensorId}</SUBTITLE_1>
          </div>
        </div>
      </div>
      )}
      <div className={styles.section}>
        <HEADING_4>Password</HEADING_4>
        <div className={styles.sectionContainer}>
          <InputGroup
            value={newPassword}
            setValue={(value: string) => { dispatch({ type: 'handleInputText', field: 'newPassword', value }); }}
            placeholder="Enter a new password"
            label="New Password"
            id="new-password"
            type={InputGroupType.TEXT}
            passwordField
          />
          <InputGroup
            value={confirmedPassword}
            setValue={(value: string) => { dispatch({ type: 'handleInputText', field: 'confirmedPassword', value }); }}
            placeholder="Confirm new password"
            label="Confirm Password"
            id="confirm-password"
            type={InputGroupType.TEXT}
            error={!!passwordErrorMessage}
            errorClass={resetPasswordStyles.invalidEmailInput}
            passwordField
          />
          {passwordErrorMessage && (
            <div className={resetPasswordStyles.invalidMessage}>{passwordErrorMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ResetHubMemberPassword;
