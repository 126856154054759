import { BarDatum, BarItemProps, ResponsiveBar } from '@nivo/bar';
import { AxisTickProps } from '@nivo/axes';
import { LabeledBarComponent } from 'dataVisualizations/bars/bars';
import { useState } from 'react';
import { DayBarTooltip } from 'dataVisualizations/tooltips/tooltips';
import { getGridVals } from 'dataVisualizations/utils/utils';
import { DayMonthXAxisTick } from 'dataVisualizations/axis/axis';
import { BarData } from 'dataVisualizations/types/types';
import { timeAxisValues } from 'dataVisualizations/axis/utils';

const keyVal = 'metric_value';
const indexBy = 'cycle_start_day';
const layers = ['grid', 'bars', 'axes', 'markers'];

type DayBarChartProps = {
  data: BarData[];
  maxVal: number;
  color: string;
  metricName: string;
  customLabelFormat?: (val: number) => string;
};

function DayBarChart({
  data,
  maxVal,
  color,
  metricName,
  customLabelFormat,
}: DayBarChartProps) {
  const [dayHighlighted, setDayHighlighted] = useState(null);
  const dateAxisValues = timeAxisValues(
    data[0].cycle_start_day,
    data[data.length - 1].cycle_start_day,
  );
  return (
    <ResponsiveBar
      data={data}
      keys={[keyVal]}
      indexBy={indexBy}
      margin={{
        top: 20, right: -70, bottom: 50, left: -70,
      }}
      minValue={0}
      maxValue={maxVal}
      enableLabel
      enableGridX={false}
      enableGridY
      // @ts-ignore
      layers={layers}
      isInteractive
      padding={0.7}
      gridYValues={getGridVals(maxVal, 4)}
      axisLeft={null}
      colors={color}
      axisBottom={{
        tickValues: dateAxisValues,
        tickSize: 0,
        renderTick: (tick: AxisTickProps<string>) => DayMonthXAxisTick(tick, true),
      }}
      tooltip={(node) => DayBarTooltip(node, metricName, customLabelFormat)}
      barComponent={
        (barComponent: BarItemProps<BarDatum>) => LabeledBarComponent(
          barComponent.bar,
          barComponent.tooltip,
          dayHighlighted,
          setDayHighlighted,
          data.length - 1,
          metricName,
          color,
          customLabelFormat,
        )
      }
    />
  );
}

export default DayBarChart;
