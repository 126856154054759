import Logo from 'assets/ui-logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileMenu from 'components/profileMenu';
import { useAccounts } from 'context/account-context';
import styles from './navigationBar.module.scss';
import Tabs, { TabThemes } from '../tabs/tabs';

enum HeaderNavTabs {
  PROGRESS = 'progress',
  PEOPLE = 'people',
  PLAN = 'plan',
}

function NavigationBar() {
  const { account } = useAccounts();
  const location = useLocation();
  const navigate = useNavigate();
  let tabSelected = HeaderNavTabs.PEOPLE;
  const splitPathname = location.pathname.split('/');
  if (splitPathname.length > 3) {
    tabSelected = splitPathname[3] as HeaderNavTabs;
  }

  const tabs = [{ tab: HeaderNavTabs.PROGRESS, nav: 'progress' }, { tab: HeaderNavTabs.PEOPLE, nav: 'people/members' }, { tab: HeaderNavTabs.PLAN, nav: 'plan/summary' }];

  const isAdminPage = location.pathname === '/accounts';

  const handleTabClick = (tab: HeaderNavTabs) => {
    const tabToNavigate = tabs.filter((t) => t.tab === tab)[0];
    navigate(`/accounts/${account.id}/${tabToNavigate.nav}`);
  };

  return (
    <div
      className={styles.navContainer}
    >
      <div className={styles.left}>
        <div className={styles.headerLogo}>
          <img className={styles.whoopLogo} src={Logo} alt="Whoop logo" />
        </div>
      </div>
      {!isAdminPage && (
        <div className={styles.tabs}>
          <Tabs
            tabList={tabs}
            selectedTab={tabSelected}
            onClick={(tab) => handleTabClick(tab)}
            theme={TabThemes.NAVIGATION}
          />
        </div>

      )}
      <div className={styles.right}>
        <ProfileMenu />
      </div>
    </div>
  );
}

export default NavigationBar;
