import { PieCustomLayerProps } from '@nivo/pie';
import styles from './pieChart.module.scss';

export default function GetInnerLayer({
  dataWithArc,
  centerX,
  centerY,
}: PieCustomLayerProps<PieData>) {
  const { showPercent } = dataWithArc[0].data;
  return (
    <text
      x={centerX}
      y={centerY - 7}
      textAnchor="middle"
      dominantBaseline="central"
      className={styles.innerArcValue}
    >
      {`${dataWithArc[0].value}${showPercent && '%'}`}
    </text>
  );
}

export type PieData = {
  id: string;
  label: string;
  value: number;
  showPercent: boolean;
};
