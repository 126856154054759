import { Button } from '@whoop/web-components';
import { useAccounts } from 'context/account-context';
import { useNavigate } from 'react-router-dom';
import { UseStatePropType } from 'types/useStatePropType';
import PeoplePageToggle from 'components/peoplePageToggle/peoplePageToggle';
import { HubListPayload, HubStatus } from 'types/hub';
import { Row } from 'react-table';
import RowsPerPageSelect from 'tableUtils/rowsPerPageSelect/rowsPerPageSelect';
import HUB_STATUSES from 'constants/hubsTable';
import StatusDropdown from 'components/statusDropdown/statusDropdown';
import styles from './hubsTableActions.module.scss';

type HubsTableActionsProps = {
  pageSize: number;
  setPageSize: UseStatePropType<number>;
  selectedRows: Row<HubListPayload>[];
  hubsByStatus: Map<HubStatus, HubListPayload[]>;
  currentStatus: HubStatus;
  setCurrentStatus: UseStatePropType<HubStatus>;
  setShowModal: UseStatePropType<boolean>;
};

function HubsTableActions({
  pageSize,
  setPageSize,
  selectedRows,
  hubsByStatus,
  currentStatus,
  setCurrentStatus,
  setShowModal,
}: HubsTableActionsProps) {
  const { account } = useAccounts();

  const navigate = useNavigate();

  const handleAddNewHubBtn = () => {
    navigate(`/accounts/${account.id}/people/hub/setup`);
  };

  const handleActivateHubsButton = async () => {
    setShowModal(true);
  };

  return (
    <div className={styles.controls}>
      <div className={styles.left}>
        <PeoplePageToggle />
        <StatusDropdown
          statusValues={HUB_STATUSES}
          currentStatus={currentStatus}
          setCurrentStatus={setCurrentStatus}
          rowsByStatus={hubsByStatus}
        />
        <RowsPerPageSelect
          pageSizeOptions={[12, 24, 36]}
          onOptionClick={(size) => setPageSize(size)}
          selectedOption={pageSize}
        />
      </div>

      {!!selectedRows.length && (
        <Button
          label={selectedRows.length === 1 ? 'Activate Hub' : 'Activate Hubs'}
          size="small"
          theme="enterprise"
          variant="primary"
          onClick={handleActivateHubsButton}
          className={styles.deployApp}
        />
      )}

      <Button
        label="Add New Hub"
        size="small"
        theme="enterprise"
        variant="primary"
        onClick={handleAddNewHubBtn}
        className={styles.addNewHub}
      />
    </div>
  );
}

export default HubsTableActions;
