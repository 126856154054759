import { BarDatum } from '@nivo/bar';
import { NamedUser } from './base';

export type GroupIdParams = {
  groupId?: string;
};

export type Metric = StrainMetric | RecoveryMetric | SleepMetric;
export type Record = StrainAnalyticsRecord | RecoveryAnalyticsRecord | SleepAnalyticsRecord;

export const OVERVIEW_TAB = 'overview';

export const enum Pillars {
  STRAIN = 'strain',
  RECOVERY = 'recovery',
  SLEEP = 'sleep',
}

export enum DeviceHealth {
  DEVICE_HEALTH = 'device health',
}

export type DeviceHealthRecord = NamedUser & {
  seat_id: number;
  user_id: number;
  sensor_battery_level: number;
  last_activity_at: string;
  time_off_wrist_seconds: number;
  latest_uploaded_metric: string;
};

export type PillarOptions = Pillars | DeviceHealth;

export const enum RecoveryMetric {
  SCORE = 'SCORE',
  HRV = 'HRV',
  RHR = 'RHR',
}

export const enum StrainMetric {
  DAY_STRAIN = 'DAY_STRAIN',
  RAW_DAY_STRAIN = 'RAW_DAY_STRAIN',
  CALORIES = 'CALORIES',
  WORKOUTS = 'WORKOUTS',
}

export const enum SleepMetric {
  DURATION = 'DURATION',
  PERFORMANCE = 'PERFORMANCE',
  CONSISTENCY = 'CONSISTENCY',
  EFFICIENCY = 'EFFICIENCY',
}

export const enum AnalyticsAggregate {
  AVG = 'avg',
  MAX = 'max',
  MIN = 'min',
}

export const enum Period {
  TODAY = 'TODAY',
  LAST_WEEK = 'PAST 7 DAYS',
  LAST_MONTH = 'PAST 30 DAYS',
  SEVEN_DAYS = '7 DAYS',
  THIRTY_DAYS = '30 DAYS',
  DAILY = 'DAILY',
}

export type AnalyticsData<T, K> = {
  cohort_id: number;
  average: number;
  metric: K;
  records: T[];
};

export type AnalyticsLeaderboard<T, K> = {
  average: number;
  last_updated_at: string;
  name: K;
  offset: number;
  records: T[];
  total_compliant: number;
  total_count: number;
  total_empty: number;
  total_non_compliant: number;
};

export type AnalyticsRecord = NamedUser & {
  avatar_url: string;
  start_date: string;
  end_date: string;
  user_id: number;
};

export type Breakdown = SleepBreakdown | RecoveryBreakdown | StrainBreakdown;

export type SleepBreakdown = {
  average_duration: number;
  average_performance: number;
  average_sleep_consistency: number;
  average_sleep_efficiency: number;
  sleep_averages: SleepAnalyticsRecord[];
};

export type RecoveryBreakdown = {
  average_score: number;
  average_hrv: number;
  average_rhr: number;
  recovery_averages: RecoveryAnalyticsRecord[];
};

export type StrainBreakdown = {
  average_day_strain: number;
  average_calories: number;
  average_workout_count: number;
  strain_averages: StrainAnalyticsRecord[];
};

export interface RecoveryAnalyticsRecord extends AnalyticsRecord {
  hrv: number;
  rhr: number;
  score: number;
  activity_id: number;
}

export interface StrainAnalyticsRecord extends AnalyticsRecord {
  calories: number;
  day_strain: number;
  workout_count: number;
}

export interface SleepAnalyticsRecord extends AnalyticsRecord {
  duration: number;
  performance: number;
  sleep_consistency: number;
  sleep_efficiency: number;
  activity_id: number;
}

export type Activity = {
  activity_id: number;
  cycle_day: string;
  raw_strain: number;
  sport_id: number;
  sport_name: string;
  strain: number;
};

export type AggregateActivity = {
  avg_strain: number;
  count: number;
  sport_id: number;
  sport_name: string;
};

export type GraphData = {
  x: string;
  y: number;
};

export type DayValues = {
  day: string;
  dayNum: number;
};

export type MonthDayValues = {
  day: string;
  month: string;
  dayNum: number;
};

export type BarData = {
  date: string;
  average: number;
};

export type GroupAverage = {
  metric_name: StrainMetric | SleepMetric | RecoveryMetric;
  date: string;
  average: number;
};

export type GroupAverages = {
  cohort_id: number;
  cohort_averages: GroupAverage[];
};

export type ProgressMemberOverview = {
  member_profile: ProgressMemberProfile;
  cohort_member_details_list: ProgressCohort[];
  pillar_overview?: PillarOverview;
};

export type PillarOverview = {
  scaled_strain?: number;
  day_kilocalories?: number;
  recovery_score?: number;
  resting_heart_rate?: number;
  hrv_rmssd?: number;
  quality_duration?: number;
  sleep_need?: number;
  sleep_performance?: number;
};

export type ProgressMemberProfile = {
  first_name: string;
  last_name: string;
  user_id: number;
  email: string;
  strap_serial: string;
  username: string;
  avatar_url: string;
  data_available_date: string;
};

export type ProgressCohort = {
  user_id: number;
  cohort_id: number;
  name: string;
  joined_date: string;
};

export type TrendPillarData = {
  trend_key: string;
  trend_value: number;
};

export type MemberTrend = {
  cycle_id: number;
  cycle_start_day: number[];
  pillar_data_list: TrendPillarData[];
};

export type MemberTrends = {
  memberId: number;
  data_trend: MemberTrend[];
};

export enum TrendKey {
  Strain = 'SCALED_DAY_STRAIN',
  Recovery = 'RECOVERY',
}

export interface CustomBarDatum extends BarDatum {
  count: number;
}

export type StrainRecoveryPointData = {
  date: string;
  value: number;
};

export type StrainRecoveryPoints = {
  pointType: TrendKey;
  pointData: StrainRecoveryPointData[];
};

export enum ProgressProfileTab {
  PROFILE = 'profile',
  DATA = 'data',
  GROUPS = 'groups',
}

export type Metrics = {
  cycle_start_day: number[];
  metric: string;
  metric_value: number;
  metric_details: SleepMetricDetails | null;
};

export type SleepMetricDetails = {
  light_sleep_duration: number;
  rem_duration: number;
  slow_wave_duration: number;
  wake_duration: number;
};

export enum StrainMetricType {
  STRAIN = 'day-strain',
  AVG_HR = 'average-hr',
  CALORIES = 'calories',
}

export enum RecoveryMetricType {
  SCORE = 'score',
  HRV = 'hrv',
  RHR = 'rhr',
  RESPIRATORY_RATE = 'respiratory-rate',
}

export enum SleepMetricType {
  PERFORMANCE = 'performance',
  DURATION = 'duration',
  LATENCY = 'latency',
  CONSISTENCY = 'consistency',
  DISTURBANCES = 'disturbances',
  EFFICIENCY = 'efficiency',
}

export const PILLAR_NAMES = {
  [Pillars.SLEEP]: 'Sleep',
  [Pillars.STRAIN]: 'Strain',
  [Pillars.RECOVERY]: 'Recovery',
};

export const METRIC_DISPLAY_NAMES = {
  [StrainMetricType.CALORIES]: 'Calories',
  [StrainMetricType.STRAIN]: 'Day Strain',
  [StrainMetricType.AVG_HR]: 'Avg. HR',
  [RecoveryMetricType.SCORE]: 'Recovery',
  [RecoveryMetricType.HRV]: 'HRV',
  [RecoveryMetricType.RHR]: 'Resting HR',
  [RecoveryMetricType.RESPIRATORY_RATE]: 'Respiratory Rate',
  [SleepMetricType.PERFORMANCE]: 'Sleep Performance',
  [SleepMetricType.DURATION]: 'Time in Bed',
  [SleepMetricType.LATENCY]: 'Sleep Latency',
  [SleepMetricType.CONSISTENCY]: 'Sleep Consistency',
  [SleepMetricType.DISTURBANCES]: 'Disturbance',
  [SleepMetricType.EFFICIENCY]: 'Sleep Efficiency',
};

export type MetricType = StrainMetricType | RecoveryMetricType | SleepMetricType;

export type MetricOverview = {
  average_value: number;
  metrics: Metrics[];
};

export const PRIMARY_METRIC_MAP = {
  [Pillars.STRAIN]: StrainMetricType.STRAIN,
  [Pillars.RECOVERY]: RecoveryMetricType.SCORE,
  [Pillars.SLEEP]: SleepMetricType.PERFORMANCE,
};

export const PILLAR_TO_METRIC_TYPE = {
  [Pillars.STRAIN]: StrainMetricType,
  [Pillars.RECOVERY]: RecoveryMetricType,
  [Pillars.SLEEP]: SleepMetricType,
};

export const METRIC_TO_PILLAR_MAP = {
  [StrainMetricType.STRAIN]: Pillars.STRAIN,
  [StrainMetricType.CALORIES]: Pillars.STRAIN,
  [StrainMetricType.AVG_HR]: Pillars.STRAIN,
  [RecoveryMetricType.SCORE]: Pillars.RECOVERY,
  [RecoveryMetricType.HRV]: Pillars.RECOVERY,
  [RecoveryMetricType.RHR]: Pillars.RECOVERY,
  [RecoveryMetricType.RESPIRATORY_RATE]: Pillars.RECOVERY,
  [SleepMetricType.PERFORMANCE]: Pillars.SLEEP,
  [SleepMetricType.DURATION]: Pillars.SLEEP,
  [SleepMetricType.LATENCY]: Pillars.SLEEP,
  [SleepMetricType.CONSISTENCY]: Pillars.SLEEP,
  [SleepMetricType.DISTURBANCES]: Pillars.SLEEP,
  [SleepMetricType.EFFICIENCY]: Pillars.SLEEP,
};

export const DEFAULT_PILLAR_METRIC_TYPE = {
  [Pillars.SLEEP]: SleepMetricType.PERFORMANCE,
  [Pillars.STRAIN]: StrainMetricType.STRAIN,
  [Pillars.RECOVERY]: RecoveryMetricType.SCORE,
};
