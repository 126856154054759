import { Col, Row } from 'layout/layout';
import PillarBreakdownBarCard from 'progress/components/pillarBreakdownBarCard/pillarBreakdownBarCard';
import StatisticsCard from 'progress/components/statisticsCard/statisticsCard';
import StatisticTrendChart from 'progress/components/statisticTrendChart/statisticTrendChart';
import { useState } from 'react';
import { DEFAULT_PILLAR_METRIC_TYPE, MetricType, Pillars } from 'types/analytics';
import layoutStyles from '../layout.module.scss';

function RangeView() {
  const [
    selectedMetricType,
    setSelectedMetricType,
  ] = useState<MetricType>(DEFAULT_PILLAR_METRIC_TYPE[Pillars.SLEEP]);
  return (
    <>
      <Row center="xs" className={layoutStyles.topCardsContainer}>
        <Col xs={4}>
          <PillarBreakdownBarCard pillar={Pillars.SLEEP} />
        </Col>
        <Col xs={8}>
          <StatisticsCard
            pillar={Pillars.SLEEP}
            selectedMetricType={selectedMetricType}
            setSelectedMetricType={setSelectedMetricType}
          />
        </Col>
      </Row>
      <Row center="xs" className={layoutStyles.activityStatsContainer}>
        <Col xs={4}>sleep table</Col>
        <Col xs={8}>
          <StatisticTrendChart selectedMetricType={selectedMetricType} />
        </Col>
      </Row>
    </>
  );
}

export default RangeView;
