import { CustomLayerProps } from '@nivo/line';
import { Icon, Colors } from '@whoop/web-components';
import { area } from 'd3-shape';
import styles from './layers.module.scss';

export function AreaLayer({ series, xScale, yScale }: any): React.ReactNode {
  const colors = [
    Colors.white,
    Colors.strainBlueAlpha200,
    Colors.strainBlueAlpha400,
    Colors.strainBlueAlpha600,
    Colors.strainBlueAlpha800,
    Colors.strainBlue,
  ];
  const target = [0, 50, 60, 70, 80, 90, 100];

  const getAreaAboveLine = (floor: number): string => {
    const generateArea = area()
      .x((d: any) => Number(xScale(d.data.x)))
      .y0((d: any) => {
        if (d.data.y > floor) {
          return Number(yScale(d.data.y));
        }
        return Number(yScale(floor));
      })
      .y1((): any => yScale(floor));

    return generateArea(series[0].data);
  };

  return (
    <>
      {
        target.map((range: number, index: number) => (
          <path
            key={range}
            d={getAreaAboveLine(range)}
            fill={colors[index]}
          />
        ))
      }
    </>
  );
}

export function ActivityButtonLayer(
  { series, xScale, yScale }: CustomLayerProps,
): React.ReactNode {
  const yVal = yScale(225) as number - 70;

  return (
    <>
      {series.map((line: any) => {
        if (!line.annotationData) {
          return null;
        }
        const {
          data, annotationData, navigate, color,
        } = line;
        const numElements = data.length;
        const middlePointScaled = xScale(data[Math.round(numElements / 2)].data.x) as number - 36;
        return (
          <g
            onClick={() => { navigate(annotationData.link); }}
            className={styles.clickableAnnotation}
            key={line.id}
          >
            <text
              fill={color}
              textAnchor="middle"
              x={middlePointScaled + 42}
              y={yVal - 8}
            >
              {annotationData.title}
            </text>
            <rect
              x={middlePointScaled}
              y={yVal}
              width="84"
              height="40"
              rx="4"
              fill={color}
            />

            <Icon
              className={styles.activityIcon}
              width="24"
              height="24"
              name={annotationData.activityName}
              x={middlePointScaled + 8}
              y={yVal + 8}
            />
            <text className={styles.durationText} fill={Colors.white} textAnchor="middle" x={middlePointScaled + 58} y={yVal + 26}>{line.annotationData.duration}</text>
          </g>
        );
      })}
    </>
  );
}
