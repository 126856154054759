import InputGroup, { InputGroupType } from 'components/inputGroup/inputGroup';
import { UseStatePropType } from 'types/useStatePropType';
import CustomSwitch from 'accountDetails/settings/customSwitch';
import { useAccounts } from 'context/account-context';
import styles from './addGroup.module.scss';

type AddGroupProps = {
  groupName: string;
  setGroupName: (name: string) => void;
  groupDescription: string;
  setGroupDescription: UseStatePropType<string>;
  isGroupConcealed: boolean;
  setIsGroupConcealed: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function AddGroup({
  groupName,
  setGroupName,
  groupDescription,
  setGroupDescription,
  isGroupConcealed,
  setIsGroupConcealed,
}: AddGroupProps) {
  const { accountConfig } = useAccounts();
  return (
    <>
      <InputGroup
        value={groupName}
        setValue={setGroupName}
        placeholder="Create a short, memorable name..."
        label="Group Name*"
        id="group-name"
        type={InputGroupType.TEXT}
      />
      <InputGroup
        value={groupDescription}
        setValue={setGroupDescription}
        placeholder="Describe what groups these members together in a few words..."
        label="Add a group description"
        id="group-description"
        type={InputGroupType.TEXTAREA}
      />
      {accountConfig.concealed_feature_enabled && (
        <>
          <InputGroup
            label="Group Settings"
            id="group-settings"
            type={InputGroupType.TOGGLE}
          />
          <div className={styles.toggleContainer}>
            <div className={styles.buttonWrap}>
              <CustomSwitch
                onChange={setIsGroupConcealed}
                value={isGroupConcealed}
                checked={isGroupConcealed}
                name="conceal-group"
                customheight={30}
                customwidth={60}
              />
            </div>
            <p>{`CONCEAL MODE ${isGroupConcealed ? 'ON' : 'OFF'} – Member can use the WHOOP app without intervention (For iOS devices only).`}</p>
          </div>
        </>
      )}
    </>
  );
}

export default AddGroup;
