import React, { useState } from 'react';
import { Button } from '@whoop/web-components';
import Loading from 'loading';
import { ShippingMethodType } from 'wizards/invite/types';
import { MEMBERS_COLUMN, SELECTION_COLUMN } from 'tableUtils/tableColumns';
import ActionModal from 'components/actionModal/actionModal';
import createHeading from 'helpers/modalUtils';
import {
  COLLABORATOR_EMAIL_COLUMN,
  PRIVACY_LEVEL_TEXT_COLUMN,
} from 'accountDetails/addCollaborators/addCollaboratorSteps/reviewCollaboratorDetails/reviewCollaboratorDetailsTable';
import { SeatDetail } from 'types/seat';
import { sendInvites } from 'api/seatApi';
import { PAGE_STATE } from 'constants/pageState';
import { Collaborator } from 'types/collaborator';
import { handleSendInvites, HandleSendInvitesPayload } from 'accountDetails/addCollaborators/util';
import { useAccounts } from 'context/account-context';
import { useToast } from 'context/toast-context';

type ResendInviteModalProps = {
  selectedRows: any[];
  setSelectedRows: (selectedRows: any[]) => void;
  refetchData: () => void;
  setPageState: (pageState: string | boolean) => void;
  triggerToast: (message: string) => void;
  isCollaboratorInvite?: boolean;
};

function ResendInviteModal({
  selectedRows,
  setSelectedRows,
  refetchData,
  setPageState,
  triggerToast,
  isCollaboratorInvite,
}: ResendInviteModalProps) {
  const [loading, setLoading] = useState(false);
  const { account } = useAccounts();
  const { openToast } = useToast();

  const toastMessage = (invitesResent: number) => {
    if (invitesResent) {
      return `${invitesResent} invite${
        invitesResent === 1 ? ' has' : 's have'
      } been re-sent and they will receive a new email with an invite link.`;
    }
    return null;
  };

  const handleResendInvites = async () => {
    const bulkInvites = selectedRows.filter(
      (inv) => inv.original.shipping_type === ShippingMethodType.BULK,
    )
      .map((row) => row.original.email);
    const directInvites = selectedRows.filter(
      (inv) => inv.original.shipping_type === ShippingMethodType.DIRECT,
    )
      .map((row) => row.original.email);
    setLoading(true);
    try {
      await sendInvites(bulkInvites, ShippingMethodType.BULK);
      await sendInvites(directInvites, ShippingMethodType.DIRECT);
    } finally {
      setLoading(false);
      setSelectedRows([]);
      setPageState(PAGE_STATE.DASHBOARD);
      await refetchData();
      triggerToast(toastMessage(selectedRows.length));
    }
  };

  const handleResendCollaboratorInvite = async () => {
    const {
      fulfilled,
      failed,
    }: HandleSendInvitesPayload = await handleSendInvites(selectedRows, account.id);

    if (fulfilled === 1 && failed === 0) {
      openToast(`Resent invite to ${selectedRows[0].email}`);
    } else {
      openToast(`Resent ${fulfilled} invites`);
    }
    setLoading(false);
    setPageState(false);
  };

  const columnsToDisplay = isCollaboratorInvite
    ? [SELECTION_COLUMN, COLLABORATOR_EMAIL_COLUMN, PRIVACY_LEVEL_TEXT_COLUMN]
    : [SELECTION_COLUMN, MEMBERS_COLUMN];

  const ActionButton = (
    <Button
      label={`Resend ${selectedRows.length} ${
        selectedRows.length === 1 ? 'invitation' : 'invitations'
      }`}
      onClick={isCollaboratorInvite ? handleResendCollaboratorInvite : handleResendInvites}
      disabled={selectedRows.length < 1}
      variant="primary"
    />
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <ActionModal
      selectedRows={isCollaboratorInvite ? selectedRows.map((row) => ({ original: row }))
        : selectedRows}
      setSelectedRows={setSelectedRows}
      setPageState={setPageState}
      ActionButton={ActionButton}
      title="Resend email invitations?"
      body="These members have not yet accepted their invitations. Resend their
      email invitation, or check to make sure their email is submitted
      correctly."
      heading={createHeading(selectedRows, 'member', 'members')}
      columnList={columnsToDisplay}
      rowAccessor={(row: Collaborator | SeatDetail) => row.email}
    />
  );
}

export default ResendInviteModal;
