import { useState } from 'react';
import { classes, Icon } from '@whoop/web-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from 'progress/profile/profileUtils';
import styles from './activitiesTable.module.scss';

export type SectionData = {
  rowTitle: string;
  rowDetail: string | number;
  fromDate: Date;
  activityId: string;
};

type TableViewProps = {
  sectionTitle: string;
  sectionDetail: string | number;
  sectionData: SectionData[];
  sectionDetailStrain?: boolean;
};

function ActivitySection({
  sectionTitle,
  sectionDetail, sectionData, sectionDetailStrain,
}: TableViewProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const activityOnClickHandler = (data: SectionData) => {
    navigate(`${location.pathname}?activityId=${data.activityId}&from=${formatDate(data.fromDate)}`);
  };

  return (
    <div
      className={styles.tableViewRow}
      aria-expanded={isOpen}
      style={{ maxHeight: isOpen ? `${(36 * (sectionData.length + 1)) + 1}px` : '36px' }}
    >
      <button type="button" className={styles.rowHeader} onClick={() => setIsOpen(!isOpen)}>
        <div className={classes(styles.rowDetail, sectionDetailStrain ? styles.blueText : '')}>{sectionDetail}</div>
        <div className={styles.rowTitle}>{sectionTitle}</div>
        <Icon name="arrow_down" className={styles.arrowIcon} />
      </button>
      <div className={styles.rowContentWrapper}>
        {sectionData.map((d, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={d.rowTitle + index}
            className={styles.rowContent}
            aria-hidden={!isOpen}
            onClick={() => activityOnClickHandler(d)}
            role="presentation"
          >
            <div className={styles.rowContentTitle}>{d.rowTitle}</div>
            <div className={styles.rowContentDetail}>{d.rowDetail}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ActivitySection;
