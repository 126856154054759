import { Col, Row } from 'layout/layout';
import NoDataBanner from 'progress/components/noDataBanner/noDataBanner';
import PillarPieCard from 'progress/components/pillarPieCard/pillarPieCard';
import StatisticsCard from 'progress/components/statisticsCard/statisticsCard';
import { Pillars } from 'types/analytics';
import layoutStyles from '../layout.module.scss';

function DailyView() {
  return (
    <>
      <NoDataBanner pillar={Pillars.SLEEP} />
      <Row center="xs" className={layoutStyles.topCardsContainer}>
        <Col xs={4}>
          <PillarPieCard pillar={Pillars.SLEEP} />
        </Col>
        <Col xs={8}>
          <StatisticsCard pillar={Pillars.SLEEP} />
        </Col>
      </Row>
      <Row center="xs" className={layoutStyles.bottomCardsContainer}>
        <Col xs={12}>
          Sleep Activity
        </Col>
      </Row>
    </>
  );
}

export default DailyView;
