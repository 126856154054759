import React, { ReactChild } from 'react';
import styles from './badge.module.scss';

export enum BadgeColors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LIGHT_BLUE = 'light-blue',
  DANGER = 'danger',
  WARNING = 'warning',
  OFFLINE_WARNING = 'offline-warning',
  NONE = 'none',
  NOTIFICATION_RED = 'notification-red',
}
type BadgeProps = {
  children: ReactChild | ReactChild[];
  color: string;
};

function Badge({ children, color } : BadgeProps) {
  let badgeColor;
  switch (color) {
    case BadgeColors.PRIMARY:
      badgeColor = styles.primary;
      break;
    case BadgeColors.SECONDARY:
      badgeColor = styles.secondary;
      break;
    case BadgeColors.LIGHT_BLUE:
      badgeColor = styles.lightBlue;
      break;
    case BadgeColors.DANGER:
      badgeColor = styles.danger;
      break;
    case BadgeColors.WARNING:
      badgeColor = styles.warning;
      break;
    case BadgeColors.OFFLINE_WARNING:
      badgeColor = styles.offlineWarning;
      break;
    case BadgeColors.NONE:
      badgeColor = styles.none;
      break;
    case BadgeColors.NOTIFICATION_RED:
      badgeColor = styles.notificationRed;
      break;
    default:
      badgeColor = styles.primary;
  }
  return (
    <div className={`${styles.badgeContainer} ${badgeColor}`}>{children}</div>
  );
}

export default Badge;
