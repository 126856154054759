import { client } from 'api/apiClient';

const featureFlagUrl = 'feature-flags/flags/flag';

export const getFeatureFlagForUser = async (
  flagName: string,
) => {
  const response = await client.get<boolean>(
    `${featureFlagUrl}/${flagName}`,
  );
  return response.data;
};

export default getFeatureFlagForUser;
