import { Col, Row } from 'layout/layout';
import { getFormattedRangeDay } from 'progress/profile/profileUtils';
import CardPlaceholder from 'progress/components/cardPlaceholder/cardPlaceholder';
import { Pillars } from 'types/analytics';
import StatisticsCard from 'progress/components/statisticsCard/statisticsCard';
import { useParams } from 'react-router-dom';
import PillarPieCard from 'progress/components/pillarPieCard/pillarPieCard';
import NoDataBanner from 'progress/components/noDataBanner/noDataBanner';
import layoutStyles from '../layout.module.scss';
import { useDateRange } from '../hooks/useDateRange';

function DailyView() {
  const { dateRange } = useDateRange();
  const { pillar } = useParams<{ pillar: Pillars }>();
  const detailDate = getFormattedRangeDay(dateRange);
  return (
    <>
      <NoDataBanner pillar={Pillars.RECOVERY} />
      <Row center="xs" className={layoutStyles.topCardsContainer}>
        <Col xs={4}>
          <PillarPieCard pillar={pillar} />
        </Col>
        <Col xs={8}>
          <StatisticsCard
            pillar={Pillars.RECOVERY}
          />
        </Col>
      </Row>
      <Row center="xs" className={layoutStyles.activityContainer}>
        <Col xs={12}>
          <CardPlaceholder
            icon="meditation"
            cardTitle="Activity Statistics"
            cardDetails={detailDate}
            text="Recovery Activitiy is coming soon..."
            subtext={['We are working diligently on this new feature.',
              'If you have questions or suggestions, please contact your CMS.']}
            showBackground
          />
        </Col>
      </Row>
    </>
  );
}

export default DailyView;
