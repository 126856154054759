import { MenuItem, MenuList } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { HubUser } from 'types/hub';
import Dropdown from 'components/dropdown';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountParams } from 'context/account-context';
import styles from './hubMemberActions.module.scss';

type HubMemberActionsProps = {
  user: HubUser;
};

function HubMemberActions({ user }: HubMemberActionsProps) {
  const navigate = useNavigate();
  const { accountId } = useParams<AccountParams>();
  const invitedActions = [
    { label: 'Archive member', disabled: true, onClick: () => { } },
    { label: 'Assign new sensor', disabled: true, onClick: () => { } },
    { label: 'View member profile', disabled: false, onClick: () => navigate(`/accounts/${accountId}/people/hub/${user.seat_id}/viewProfile`) },
  ];

  return (
    <div className={styles.hubMemberActionsContainer}>
      <Dropdown
        button={<MoreHorizIcon />}
        buttonProps={{ variant: 'link', 'data-testid': 'more-actions-btn' }}
        dropdownStyles={{ borderTop: '3px solid black' }}
        isDisabled={user.status === 'archived'}
      >
        <MenuList className={styles.menuList} disablePadding>
          {user.status === 'active'
            && invitedActions.map((action) => (
              <MenuItem
                className={`${action.label === 'Archive member' && styles.removeMember}`}
                key={action.label}
                onClick={action.onClick}
                disabled={action.disabled}
              >
                {action.label}
              </MenuItem>
            ))}
        </MenuList>
      </Dropdown>
    </div>
  );
}

export default HubMemberActions;
