import StatusBadge from 'components/statusBadge';
import { shortDateWithTimeFormat } from 'helpers/dateUtils';
import { CellProps } from 'react-table';
import { HubUser } from 'types/hub';
import HubMemberActions from './hubMemberActions/hubMemberActions';

export const PATIENT_ID_COLUMN = {
  Header: 'PATIENT ID NUMBER',
  id: 'patient_id_number',
  width: '20%',
  accessor: (user: HubUser) => user.member_identifier,
  align: 'left',
  Cell: ({ row: { original } }: CellProps<HubUser>) => <p>{original.member_identifier}</p>,
};

export const SENSOR_ID_COLUMN = {
  Header: 'SENSOR ID',
  id: 'sensor_id',
  accessor: (user: HubUser) => user.strap_serial,
  align: 'left',
  Cell: ({ row: { original } }: CellProps<HubUser>) => <p>{original.strap_serial}</p>,
};

export const HUB_MEMBER_STATUS_COLUMN = {
  Header: 'STATUS',
  id: 'status',
  align: 'left',
  Cell: ({ row: { original } }: CellProps<HubUser>) => <StatusBadge status={original.status} />,
};

export const LAST_CONNECTED_COLUMN = {
  Header: 'LAST CONNECTED',
  id: 'last_connected',
  align: 'left',
  Cell: ({ row: { original: { last_connected } } }: CellProps<HubUser>) => (
    <p>{last_connected ? shortDateWithTimeFormat(new Date(last_connected)) : '--'}</p>
  ),
};
export const LAST_UPLOADED_COLUMN = {
  Header: 'LAST UPLOADED',
  id: 'last_uploaded',
  align: 'left',
  Cell: ({ row: { original: { last_uploaded } } }: CellProps<HubUser>) => (
    <p>{last_uploaded ? shortDateWithTimeFormat(new Date(last_uploaded)) : '--'}</p>
  ),
};

export const BATTERY_LEVEL_COLUMN = {
  Header: 'BATTERY LEVEL',
  id: 'battery_level',
  align: 'left',
  Cell: ({ row: { original } }: CellProps<HubUser>) => <p>{original.battery_level}</p>,
};
export const OPTIONS_COLUMN = {
  Header: '',
  id: 'remove',
  align: 'right',
  width: '5%',
  disableGlobalFilter: true,
  disableSortBy: true,
  Cell: ({ row: { original } }: CellProps<HubUser>) => (
    <HubMemberActions user={original} />
  ),
};
