import { useEffect, useMemo, useState } from 'react';
import Tabs, { TabThemes } from 'components/tabs/tabs';
import dayjs from 'dayjs';
import { ActivityDetails } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import { Metrics } from 'types/analytics';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import styles from './activitiesTable.module.scss';
import ActivitySection from './activitySection';
import {
  getActivityData, getDayData, getStrainLevelData,
} from './activitiesTableUtils';

dayjs.extend(advancedFormat);

export enum Sections {
  STRAIN = 'strain',
  SPORT = 'sport',
  DAY = 'day',
}

type ActivitiesTableProps = {
  activityData: ActivityDetails[];
  dayStrain: Metrics[];
};

function ActivitiesTable({ activityData, dayStrain }: ActivitiesTableProps) {
  const [sectionSelected, setSectionSelected] = useState<Sections>(Sections.DAY);
  const tabList = Object.values(Sections).map((s) => ({ tab: s }));
  const [uniqueStrainLevels, setUniqueStrainLevels] = useState<Set<string>>(new Set<string>());
  const [uniqueActivities, setUniqueActivities] = useState<Set<string>>(new Set<string>());
  const [uniqueDays, setUniqueDays] = useState<Set<string>>(new Set<string>());

  // Get all unique days and activities and strain levels
  useEffect(() => {
    const strainLevelSet = new Set<string>();
    const activitiesSet = new Set<string>();
    const daysSet = new Set<string>();
    activityData?.forEach((a) => {
      const stringDate = `${a.cycle_day_start[0]}-${a.cycle_day_start[1]}-${a.cycle_day_start[2]}`;
      daysSet.add(dayjs(stringDate, 'YYYY-MM-DD').format('ddd, MMM Do'));
      activitiesSet.add(a.type);
      if (a.scaled_intensity_score < 10) {
        strainLevelSet.add('Light');
      } else if (a.scaled_intensity_score <= 14) {
        strainLevelSet.add('Moderate');
      } else if (a.scaled_intensity_score <= 18) {
        strainLevelSet.add('Strenuous');
      } else if (a.scaled_intensity_score > 18) {
        strainLevelSet.add('All-Out');
      }
    });

    setUniqueStrainLevels(strainLevelSet);
    setUniqueActivities(activitiesSet);
    setUniqueDays(daysSet);
  }, [activityData]);

  // Data for the 'Strain' tab
  const finalStrainData = useMemo(
    () => getStrainLevelData(activityData, uniqueStrainLevels),
    [uniqueStrainLevels],
  );

  // Data for the 'Sport' tab
  const finalActivityData = useMemo(
    () => getActivityData(activityData, uniqueActivities),
    [uniqueActivities],
  );

  // Data for the 'Day' tab
  const finalDayData = useMemo(
    () => getDayData(activityData, dayStrain, uniqueDays),
    [uniqueDays],
  );

  return (
    <div className={styles.activitiesTable}>
      <div className={styles.navBar}>
        <Tabs
          tabList={tabList}
          selectedTab={sectionSelected}
          onClick={(tab: Sections) => setSectionSelected(tab)}
          theme={TabThemes.ACTIVITIES_TABLE}
        />
      </div>
      <div className={styles.sectionsWrapper}>
        {sectionSelected === Sections.DAY
          && finalDayData.map((d) => (
            <ActivitySection
              sectionTitle={d.date}
              sectionDetail={d.day_strain}
              sectionData={d.activities}
              sectionDetailStrain
              key={d.date}
            />
          ))}
        {sectionSelected === Sections.SPORT
          && finalActivityData.map((d) => (
            <ActivitySection
              sectionTitle={d.name}
              sectionDetail={`${d.count}x`}
              sectionData={d.date_list}
              key={d.name}
            />
          ))}
        {sectionSelected === Sections.STRAIN
        && finalStrainData.map((d) => (
          <ActivitySection
            sectionTitle={d.level}
            sectionDetail={`${d.count}x`}
            sectionData={d.level_list}
            key={d.level}
          />
        ))}
      </div>
    </div>
  );
}

export default ActivitiesTable;
