import { client } from 'api/apiClient';
import { ActivityDetails, ActivityDTO, HROverview } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import { getFormattedDate } from 'progress/utils/date';
import {
  DeviceHealthRecord,
  GroupAverages,
  MemberTrends,
  MetricOverview,
  MetricType,
  Period,
  Pillars,
  ProgressMemberOverview,
  RecoveryMetric,
  SleepMetric,
  StrainMetric,
  TrendKey,
} from 'types/analytics';

type AggAnalyticsParams = {
  cohortId: string | number;
  metric: RecoveryMetric | StrainMetric | SleepMetric;
  period: Period;
  startDate: string;
  endDate: string;
};

type AnalyticsParams = {
  date: string;
  metric: RecoveryMetric | StrainMetric | SleepMetric;
  cohortId: string | number;
};

type AveragesParams = {
  cohortId: string | number;
  startDate: string;
  endDate: string;
};

const createParams = (
  params: AggAnalyticsParams | AnalyticsParams | AveragesParams,
  analyticsPillar: Pillars,
  metric: RecoveryMetric | StrainMetric | SleepMetric,
) => {
  let paramsToReturn = {};
  switch (analyticsPillar) {
    case (Pillars.RECOVERY):
      paramsToReturn = { ...params, recoveryMetric: metric };
      break;
    case (Pillars.SLEEP):
      paramsToReturn = { ...params, sleepMetric: metric };
      break;
    case (Pillars.STRAIN):
      paramsToReturn = { ...params, strainMetric: metric };
      break;
    default:
      break;
  }

  return paramsToReturn;
};

export const getCohortBreakdownAnalytics = async <Breakdown>(
  cohortId: string | number,
  startDate: string,
  endDate: string,
  analyticsPillar: Pillars,
) => {
  const params = {
    startDate, endDate, cohortId,
  };

  const response = await client.get<Breakdown>(
    `/enterprise-service/v2/breakdown/${analyticsPillar}`,
    {
      params,
    },
  );
  return response.data;
};

export const getAveragesForGroup = async (
  cohortId: string | number,
  metric: SleepMetric | StrainMetric | RecoveryMetric,
  pillar: Pillars,
) => {
  const today = new Date();

  const params: AveragesParams = {
    cohortId,
    startDate: getFormattedDate(new Date(today.setDate(today.getDate() - 29))),
    endDate: getFormattedDate(new Date()),
  };

  const paramsWithMetric = createParams(params, pillar, metric);

  const response = await client.get<GroupAverages>(`enterprise-service/v1/averages/${pillar}`, {
    params: paramsWithMetric,
  });
  return response.data;
};

export const getMemberOverview = async (memberId: number) => {
  const response = await client.get<ProgressMemberOverview>('enterprise-service/v1/member-overview', {
    params: {
      memberId,
    },
  });
  return response.data;
};

export const getMemberOverviewV2 = async (memberId: number) => {
  const response = await client.get<ProgressMemberOverview>('enterprise-service/v2/member-overview', {
    params: {
      memberId,
    },
  });
  return response.data;
};

export const getDeviceHealthData = async (cohortId: number | string) => {
  const response = await client.get<DeviceHealthRecord[]>('enterprise-service/v1/compliance/cohort', {
    params: {
      cohortId,
    },
  });
  return response.data;
};

export const getMemberTrends = async (
  memberId: number | string,
  startDate: string,
  endDate: string,
  trendKeys: TrendKey[],
) => {
  const response = await client.get<MemberTrends>('enterprise-service/v1/member/trends', {
    params: {
      memberId,
      startDate,
      endDate,
      trendKeys,
    },
  });

  return response.data;
};

export const getDayOverview = async (
  memberId: number,
  date: string,
) => {
  const response = await client.get<HROverview>('enterprise-service/v2/day-overview', {
    params: {
      memberId,
      date,
    },
  });

  return response.data;
};

// This is a date so the startDate and endDate should be formatted YYYY-MM-DD
export const getRecoveryAndStrainTrend = async (
  memberId: number,
  startDate: string,
  endDate: string,
) => {
  const response = await client.get<MemberTrends>('enterprise-service/v2/member/trends', {
    params: {
      memberId,
      startDate,
      endDate,
    },
  });

  return response.data;
};

export type GetMetricOverviewParams = {
  memberId: number;
  startDate: string;
  endDate?: string;
};

export const getMetricOverview = async (
  params: GetMetricOverviewParams,
  pillar: Pillars,
  metric: MetricType,
) => {
  const response = await client.get<MetricOverview>(`enterprise-service/v2/${pillar}-overview/${metric}`, {
    params,
  });

  return response.data;
};

export const getStrainActivities = async (
  memberId: number,
  startTime: string,
  endTime: string,
) => {
  const response = await client.get<ActivityDetails[]>('enterprise-service/v1/strain-activities', {
    params: {
      memberId,
      startTime,
      endTime,
    },
  });

  return response.data;
};

export const getActivityDTO = async (
  memberId: number,
  cycleId: number,
  activityId: string,
) => {
  const response = await client.get<ActivityDTO>(`enterprise-service/v1/strain-activities/${activityId}`, {
    params: {
      memberId,
      cycleId,
    },
  });

  return response.data;
};
