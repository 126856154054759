import { MenuItem, MenuList } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import * as Sentry from '@sentry/react';
import accountDetailStyles from 'accountDetails/accountDetails.module.scss';
import { privacyLevelOptions } from 'accountDetails/addCollaborators/addCollaboratorSteps/reviewCollaboratorDetails/reviewCollaboratorDetailsUtil';
import GlassTile from 'accountDetails/glassTile/glassTile';
import AccountConfigurationSetting from 'accountDetails/settings/accountConfigurationSetting';
import { AccountConfigurationSettingType, AccountDetailsTab } from 'accountDetails/types';
import { adminClient } from 'api/';
import DemoDisabled from 'components/demoDisabled';
import Dropdown from 'components/dropdown';
import FeatureFlag from 'components/featureFlag';
import { HEADING_2, P1 } from 'components/text';
import { useAccounts } from 'context/account-context';
import { usePrivacy } from 'context/privacy-context';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountConfiguration, reidentificationTokenKey } from 'types/admin';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import CustomSwitch from './customSwitch';
import styles from './settings.module.scss';

type SettingsProps = {
  setErrorMessage: (errorMessage: string) => void;
};

type AccountDetailsParams = {
  accountId: string;
  tabTitle: AccountDetailsTab;
};

function Settings(
  { setErrorMessage }: SettingsProps,
) {
  const {
    checkAccess, setAccountConfig, accountConfig, isReidTokenValid,
  } = useAccounts();
  const { updatePrivacyLevel } = usePrivacy();
  const navigate = useNavigate();
  const { accountId } = useParams<AccountDetailsParams>();
  const [isReidentified, setIsReidentified] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      if (accountConfig.deidentified) {
        const tokenState = await isReidTokenValid();
        setIsReidentified(tokenState);
      }
    };

    if (!checkAccess(DashboardRole.WHOOP_CSM)) {
      navigate(`/accounts/${accountId}/plan/summary`);
    }

    init();
  }, [accountId]);

  const updateSettings = async (body: AccountConfiguration): Promise<void> => {
    try {
      const updatedConfig: AccountConfiguration = await
      adminClient.updateAccountConfiguration(body);
      setAccountConfig(updatedConfig);
      await updatePrivacyLevel();
    } catch (err) {
      Sentry.captureException(err);
      setErrorMessage('There was an error updating the account configuration.');
    }
  };

  const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const toggleToChange = e.target.name;
    const newValue = e.target.value === 'false';
    const body: AccountConfiguration = {
      ...accountConfig,
      [toggleToChange]: newValue,
    };
    if (toggleToChange === 'privacy_ceiling_enabled' && !newValue) {
      body.privacy_ceiling_level = PrivacyLevel.no_metrics;
    }
    updateSettings(body);
  };

  const handlePrivacyLevelChange = (value: PrivacyLevel) => {
    updateSettings({
      ...accountConfig,
      privacy_ceiling_level: value,
    });
  };

  const handleReidentificationToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const newValue = e.target.value === 'false';
      if (newValue) {
        const reidentificationTokenObject = await adminClient.getReidentificationToken();
        const { token } = reidentificationTokenObject;
        localStorage.setItem(reidentificationTokenKey, token);
      } else {
        localStorage.removeItem(reidentificationTokenKey);
      }
      setIsReidentified(newValue);
    } catch (err) {
      Sentry.captureException(err);
      setErrorMessage('There was an error temporarily reidentifying this account.');
    }
  };

  const handleResetAccount = () => {
    navigate('reset-account');
  };

  return (Object.keys(accountConfig).length !== 0
    && (
    <div className={accountDetailStyles.componentContainer}>
      <div className={styles.content}>
        <HEADING_2>Plan Settings</HEADING_2>
        <p className={styles.headingText}>
          Make changes to protect the privacy or set management restrictions that
          will apply to all existing and future members of your enterprise plan.
        </p>
        <FeatureFlag flagName="enterprise-fe-concealed-mode-toggle">
          <AccountConfigurationSetting
            isEnabled={accountConfig.concealed_feature_enabled}
            onChangeHandler={handleToggle}
            title="Conceal the Member&apos;s App"
            description={`${accountConfig.concealed_feature_enabled ? 'ON' : 'OFF'} 
              – Member can use the WHOOP app
              without intervention.`}
            name="concealed_feature_enabled"
          />
          {accountConfig.concealed_feature_enabled
            && (
            <div className={styles.toggleContainer}>
              <p>Auto-conceal all members</p>
              <div className={styles.buttonWrap}>
                <CustomSwitch
                  onChange={handleToggle}
                  value={accountConfig.concealed}
                  checked={accountConfig.concealed}
                  name="concealed"
                  customheight={30}
                  customwidth={60}
                />
              </div>
            </div>
            )}
        </FeatureFlag>
        <AccountConfigurationSetting
          isEnabled={accountConfig.hub_enabled}
          onChangeHandler={handleToggle}
          title="Bluetooth Hub Enabled"
          description="A Bluetooth Hub allows members without mobile devices to upload data from their straps."
          name="hub_enabled"
        />
        {
          accountConfig.hub_enabled && (
            <AccountConfigurationSetting
              isEnabled={accountConfig.bluetooth_roaming}
              onChangeHandler={handleToggle}
              title="Bluetooth Roaming Enabled"
              description="Bluetooth roaming allows all straps to connect to any hub in the account"
              name="bluetooth_roaming"
            />
          )
        }

        <FeatureFlag flagName="enterprise-fe-deid-toggle">
          <AccountConfigurationSetting
            isEnabled={accountConfig.deidentified}
            onChangeHandler={handleToggle}
            title="Account Deidentification"
            description="Account wide deidentification for member PII."
            name="deidentified"
          />
          {accountConfig.deidentified
            && (
            <div className={styles.toggleContainer}>
              <p>Temporarily re-identify account members?</p>
              <div className={styles.buttonWrap}>
                <CustomSwitch
                  onChange={handleReidentificationToggle}
                  value={isReidentified}
                  checked={isReidentified}
                  name="reidentification"
                  customheight={30}
                  customwidth={60}
                />
              </div>
            </div>
            )}
        </FeatureFlag>
        <AccountConfigurationSetting
          isEnabled={accountConfig.privacy_ceiling_enabled}
          onChangeHandler={handleToggle}
          title="Privacy Ceiling"
          description="Account wide privacy ceiling for data visualization."
          name="privacy_ceiling_enabled"
        />
        {accountConfig.privacy_ceiling_enabled
          && (
            <div className={styles.privacyDropdown}>
              <P1>Privacy Level : </P1>
              <Dropdown
                button={(
                  <p className={styles.privacyLevel}>
                    {privacyLevelOptions
                      .find((o) => o.value === accountConfig.privacy_ceiling_level)?.name}
                    <ArrowDropDown />
                  </p>
                )}
                dropdownStyles={{ borderTop: '3px solid black', width: '396px' }}
                closeOnClick
                buttonProps={{
                  variant: 'link',
                  className: styles.dropdownBtn,
                  'aria-label': 'privacy_level_btn',
                }}
              >
                <MenuList>
                  {privacyLevelOptions.map((option) => (
                    <MenuItem
                      key={`menu-item-${option.name}`}
                      className={option.value === accountConfig.privacy_ceiling_level
                        ? styles.activeItem
                        : styles.inactiveItem}
                      onClick={() => handlePrivacyLevelChange(option.value)}
                    >
                      {option.value === accountConfig.privacy_ceiling_level ? <CheckIcon /> : ''}
                      <div>
                        <p className={styles.itemLabel}>
                          {option.name}
                        </p>
                        <div className={styles.itemDescription}>
                          {option.description}
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </Dropdown>
            </div>
          )}
        <AccountConfigurationSetting
          isEnabled={accountConfig.strap_onboarding_enabled}
          onChangeHandler={handleToggle}
          title="Strap Based Onboarding Enabled"
          description="Members who onboard through strap based onboarding get added to the team associated with the strap ID"
          name="strap_onboarding_enabled"
        />
        <DemoDisabled>
          <AccountConfigurationSetting
            onChangeHandler={handleResetAccount}
            title="Reset Account"
            description="Delete all members and groups."
            name="reset_account_button"
            type={AccountConfigurationSettingType.Button}
          />
        </DemoDisabled>
      </div>
      <div className={styles.glassTileMargin}>
        <GlassTile
          title="We take measures to protect the privacy of you and your people"
          text="WHOOP Unite takes security, privacy, and compliance seriously. We want you to understand how we use, collect, and share data and the measures we take to protect this data."
          logo
          buttonText="Learn more about Security and Privacy"
          buttonAction={() => window.open('https://www.whoopunite.com/solution/security-and-privacy/', '_blank')}
        />
      </div>
    </div>
    )
  );
}

export default Settings;
