import { client } from 'api/apiClient';
import {
  AccountConfiguration, AdminAccountDetail, ReidentificationTokenResponse, SignupDetails,
} from 'types/admin';
import { Collaborator, CollaboratorInviteLink, InviteStatus } from 'types/collaborator';
import { DashboardRole } from 'types/dashboardUser';
import { WhoopRole } from 'types/whoopRole';

export const getAdminAccounts = async () => {
  const response = await client.get<AdminAccountDetail[]>(
    '/enterprise-service/v1/admin/accounts',
  );
  return response.data;
};

export const getSignupDetailsByInviteToken = async (token: string) => {
  const response = await client.get<SignupDetails>(
    `/enterprise-service/v1/invite/signup-details?token=${token}`,
  );
  return response.data;
};

export const inviteWorkspaceManager = async (
  email: string,
) => {
  await client.post(
    '/enterprise-service/v1/invite',
    {},
    {
      params: {
        role: 'enterprise_workspace_manager',
        email,
      },
    },
  );
};

export const inviteDashboardUser = async (
  collaborator: Collaborator,
  salesforceAccountId: string,
) => {
  const dashboardRole = collaborator.role === DashboardRole.GROUP_MANAGER
    ? WhoopRole.ENTERPRISE_COHORT_MANAGER
    : WhoopRole.ENTERPRISE_WORKSPACE_MANAGER;
  const data = {
    dashboard_role: dashboardRole,
    email: collaborator.email,
    privacy_level: collaborator.privacy_level,
    display_role: collaborator.display_role,
    used: false,
    status: InviteStatus.invited,
    is_primary_owner: collaborator.role === DashboardRole.ACCOUNT_ADMIN,
    salesforce_account_id: salesforceAccountId,
    role: collaborator.role,
  };
  await client.post(
    '/enterprise-service/v1/invite',
    data,
  );
};

export const deleteCollaboratorInvite = async (email: string) => {
  const params = { email };
  const response = await client.delete('/enterprise-service/v1/invite/cancel', { params });
  return response.data;
};

export const getCollaboratorInviteLink = async (email: string, salesforceAccountId: string) => {
  const params = { email, salesforceAccountId };
  const response = await client.get<CollaboratorInviteLink>('/enterprise-service/v1/invite/link', { params });
  return response.data;
};

export const updateInvitedCollaborator = async (user: Collaborator) => {
  const updateInvitePayload = {
    role: user.role,
    display_role: user.display_role,
    status: user.status,
    privacy_level: user.privacy_level,
  };
  const params = { email: user.email };
  const result = await client.put('/enterprise-service/v1/invite', updateInvitePayload, { params });

  return result.data;
};

export const getAccountConfigurations = async () => {
  const response = await client.get<AccountConfiguration>(
    '/enterprise-service/v1/admin/accounts/configuration',
  );
  return response.data;
};

export const updateAccountConfiguration = async (
  body: AccountConfiguration,
): Promise<AccountConfiguration> => {
  const response = await client.put<AccountConfiguration>(
    '/enterprise-service/v1/admin/accounts/configuration',
    body,
  );
  return response.data;
};

export const getReidentificationToken = async () => {
  const response = await client.post<ReidentificationTokenResponse>(
    '/enterprise-service/v1/reidentification/token',
  );
  return response.data;
};

export const isTokenValid = async () => {
  const response = await client.get<boolean>(
    '/enterprise-service/v1/reidentification',
  );
  return response.data;
};
