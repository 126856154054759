import { useEffect, useState } from 'react';
import GraphLabel from 'progress/summary/GraphLabel/graphLabel';
import SectionHeader from 'progress/summary/SectionHeader/sectionHeader';
import styles from 'progress/progressPage.module.scss';
import BarChart from 'progress/summary/BarChart/barChart';
import { Colors } from '@whoop/web-components';
import { analyticsClient } from 'api';
import {
  GroupAverage, GroupAverages, Pillars, SleepMetric,
} from 'types/analytics';
import { createEmptyAverageData, getAvgTrendValue, getAvgValue } from 'progress/progressPageUtils';

type SleepSectionProps = {
  groupId: number | string;
};

function SleepSection({ groupId }: SleepSectionProps) {
  const [sleepData, setSleepData] = useState<GroupAverage[]>();

  useEffect(() => {
    const getSleepData = async () => {
      const data: GroupAverages = await analyticsClient.getAveragesForGroup(
        groupId,
        SleepMetric.PERFORMANCE,
        Pillars.SLEEP,
      );
      const averages: GroupAverage[] = data.cohort_averages;
      const averagesWithEmpty = createEmptyAverageData(averages, SleepMetric.PERFORMANCE);
      setSleepData(averagesWithEmpty);
    };
    getSleepData();
  }, [groupId]);

  if (!sleepData) {
    return null;
  }

  return (
    <>
      <SectionHeader
        title="sleep performance 30-day average"
        details="Sleep Performance is the amount your members slept compared to how much your members needed to sleep for optimal recovery."
        avgTitle="today's avg. sleep performance"
        avgValue={getAvgValue(sleepData)}
        avgTrend={getAvgTrendValue(sleepData)}
        showAvg
      />
      <div className={styles.chart}>
        <BarChart data={sleepData} tooltipDescription="Daily Avg. Sleep Performance" colorToUse={Colors.sleepBlue} />
      </div>
      <div className={styles.chartLabel}>
        <GraphLabel color="sleep" showSquare>
          Daily Avg. Sleep Performance
        </GraphLabel>
      </div>
    </>
  );
}

export default SleepSection;
