import { IconButton, Tooltip } from '@material-ui/core';
import DownloadIcon from 'assets/icons/download.svg';

type DownloadIconProps = {
  action: () => void;
  tooltipText?: string;
};

function DownloadIconButton({ action, tooltipText }: DownloadIconProps) {
  return (
    <Tooltip title={tooltipText} placement="right" arrow>
      <IconButton
        onClick={action}
        onKeyDown={(event) => event.key === 'Enter' && action()}
      >
        <img src={DownloadIcon} alt="download-icon" />
      </IconButton>
    </Tooltip>
  );
}

export default DownloadIconButton;
