import { SeatStatus } from 'types/seat';

const SEAT_STATUSES = [
  SeatStatus.Active,
  SeatStatus.Invited,
  SeatStatus.Pending,
  SeatStatus.Deactivated,
];

export default SEAT_STATUSES;
