import { useAccounts } from 'context/account-context';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CellProps, Column, Row, TableCommonProps,
} from 'react-table';
import {
  dateWithTimeCell,
  nameSubtitleCell,
  normalCell,
  statusBadgeCell,
} from 'tableUtils/tableCells';
import Table from 'components/table';
import { HubListPayload, HubStatus } from 'types/hub';
import { HEADING_2, P1 } from 'components/text';
import { SELECTION_COLUMN } from 'tableUtils/tableColumns';
import { DashboardRole } from 'types/dashboardUser';
import { UseStatePropType } from 'types/useStatePropType';
import HubsTableActions from './hubsTableActions/hubsTableActions';
import styles from './hubsTable.module.scss';

type HubsTableProps = {
  loading: boolean;
  hubs: HubListPayload[];
  hubsByStatus: Map<HubStatus, HubListPayload[]>;
  currentStatus: HubStatus;
  setCurrentStatus: UseStatePropType<HubStatus>;
  selectedRows: Row<HubListPayload>[];
  setSelectedRows: UseStatePropType<Row<HubListPayload>[]>;
  setShowModal: UseStatePropType<boolean>;
};

function HubsTable({
  loading, hubs, hubsByStatus,
  currentStatus, setCurrentStatus, selectedRows, setSelectedRows, setShowModal,
}: HubsTableProps) {
  const { account, checkAccess } = useAccounts();
  const navigate = useNavigate();
  const isWhoopCsm = checkAccess(DashboardRole.WHOOP_CSM);

  const [pageSize, setPageSize] = useState<number>(12);

  const columnsInfo: Column<HubListPayload>[] = [
    {
      Header: 'Hub Name',
      id: 'name',
      accessor: 'name',
      width: '20%',
      Cell: (cellProps: CellProps<HubListPayload>) => {
        const { row } = cellProps;
        return nameSubtitleCell(cellProps, row.original.name);
      },
    },
    {
      Header: 'Mac Address',
      id: 'mac_address',
      accessor: (hub: HubListPayload) => hub.mac_address,
      width: '20%',
      Cell: normalCell,
    },
    {
      Header: 'Last Seen At',
      id: 'last_seen_at',
      accessor: (hub: HubListPayload) => hub.last_seen_at,
      width: '20%',
      Cell: dateWithTimeCell,
    },
    {
      Header: 'Connection',
      id: 'status',
      accessor: (hub: HubListPayload) => hub.status,
      width: '20%',
      Cell: statusBadgeCell,
    },
    {
      Header: 'Members',
      id: 'member_count',
      accessor: (hub: HubListPayload) => hub.member_count,
      width: '20%',
      Cell: normalCell,
    },
  ];

  if (isWhoopCsm) {
    columnsInfo.unshift(SELECTION_COLUMN);
  }

  const columns: Column<HubListPayload>[] = useMemo(() => columnsInfo, []);

  const handleHubClick = (hub: HubListPayload) => {
    navigate(`/accounts/${account.id}/people/hub/${hub.mac_address}`);
  };

  const getRowStyles = (): TableCommonProps => ({
    style: {
      height: '80px',
    },
  });

  const tableHeader = (
    <div className={styles.tableHeader}>
      <HEADING_2 className={styles.heading}>Hubs</HEADING_2>
      <P1 className={styles.subtext}>
        {`${hubs.length} hub${
          hubs.length > 0 ? 's' : ''
        } total`}
      </P1>
    </div>
  );

  return (
    <div className={styles.tableContainer}>
      <Table
        columns={columns}
        loading={loading}
        data={hubs}
        tableName="hubsTable"
        heading="Hubs"
        unit="hub"
        searchPlaceholder="Search by hub"
        getAdditionalRowProps={getRowStyles}
        tableLength={pageSize}
        hideOnSearch
        showEmptyMessage
        drilldownArrow
        onDrilldownClickHandler={isWhoopCsm && handleHubClick}
        initialSortByField="name"
        onRowClickHandler={!isWhoopCsm && handleHubClick}
        setSelectedRows={setSelectedRows}
        optionalControls={(
          <HubsTableActions
            pageSize={pageSize}
            setPageSize={setPageSize}
            selectedRows={selectedRows}
            hubsByStatus={hubsByStatus}
            currentStatus={currentStatus}
            setCurrentStatus={setCurrentStatus}
            setShowModal={setShowModal}
          />
        )}
        customHeader={tableHeader}
      />
    </div>
  );
}

export default HubsTable;
