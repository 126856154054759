// eslint-disable-next-line import/prefer-default-export
export const PAGE_STATE = {
  REMOVE_MEMBERS: 'remove',
  RESEND_INVITES: 'resend invites',
  WITHDRAW_INVITES: 'withdraw invites',
  DASHBOARD: 'dashboard',
  INVITE_MEMBERS: 'invite',
  ADD_TO_GROUP: 'add to group',
  REINIVTE_DEACTIVATED: 'reinvite deactivated',
};

export default PAGE_STATE;
