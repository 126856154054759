import { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Colors } from '@whoop/web-components';
import { AxisTickProps } from '@nivo/axes';
import { CustomDurationTick, CustomZoneTick } from 'dataVisualizations/axis/axis';

type ZonesOfMaxProps = {
  zoneDurations: number[];
};

const margins = {
  top: 0, right: 66, bottom: 0, left: 0,
};

const colors = [
  Colors.blackAlpha300, Colors.hrZoneGrey, Colors.hrZoneBlue,
  Colors.hrZoneTeal, Colors.hrZoneOrange, Colors.hrZoneRed,
];

function ZonesOfMaxBarGraph({ zoneDurations }: ZonesOfMaxProps) {
  const [data, setData] = useState([]);

  const createBarData = (durations: number[]) => {
    const parsedData = durations.map((zone: number, index: number) => (
      { id: index, duration: zone }
    ));
    setData(parsedData);
  };

  useEffect(() => {
    createBarData(zoneDurations);
  }, [zoneDurations]);

  return (
    <ResponsiveBar
      data={data}
      layout="horizontal"
      minValue={0}
      keys={['duration']}
      margin={margins}
      indexBy="id"
      borderRadius={2}
      enableGridX={false}
      enableGridY={false}
      axisBottom={null}
      isInteractive={false}
      padding={0.15}
      axisRight={{
        renderTick: (tick: AxisTickProps<string>) => CustomZoneTick(
          tick.x,
          tick.y,
          tick.tickIndex,
        ),
      }}
      axisLeft={{
        renderTick: (tick: AxisTickProps<string>) => CustomDurationTick(
          tick.x,
          tick.y,
          zoneDurations[tick.tickIndex],
        ),
      }}
      colors={(d) => colors[d.index]}
      enableLabel={false}
      layers={['grid', 'bars', 'axes', 'markers', 'legends', 'annotations']}
    />
  );
}

export default ZonesOfMaxBarGraph;
