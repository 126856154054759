import * as authClient from 'api/authApi';
import * as accountClient from 'api/accountApi';
import * as seatClient from 'api/seatApi';
import * as adminClient from 'api/adminApi';
import * as cohortClient from 'api/cohortApi';
import * as hubClient from 'api/hubApi';
import * as analyticsClient from 'api/analyticsApi';
import * as healthMonitorClient from 'api/healthMonitorApi';
import * as dashboardClient from 'api/dashboardApi';

export {
  authClient,
  accountClient,
  seatClient,
  adminClient,
  cohortClient,
  hubClient,
  analyticsClient,
  healthMonitorClient,
  dashboardClient,
};
