import { Option } from './types';
import OptionSelectButton from './optionSelectButton/optionSelectButton';
import styles from './optionSelect.module.scss';

type OptionSelectProps = {
  options: Option[];
  value: string;
  setValue: (value: string) => void;
};

function OptionSelect({ options, value, setValue }: OptionSelectProps) {
  return (
    <div className={styles.optionsRow}>
      {options.map((option) => (
        <OptionSelectButton
          key={option.value}
          option={option}
          active={value === option.value}
          setValue={setValue}
        />
      ))}
    </div>
  );
}

export default OptionSelect;
