import { getPrivacy, Privacy } from 'api/privacyApi';
import Loading from 'loading';
import React, {
  PropsWithChildren, useContext, useEffect, useMemo, useState,
} from 'react';
import { PrivacyLevel } from 'types/dashboardUser';

export interface PrivacyInterface {
  updatePrivacyLevel: () => void;
  privacyLevel: PrivacyLevel;
}

export const PrivacyContext = React.createContext<PrivacyInterface>({
  privacyLevel: PrivacyLevel.no_metrics,
} as PrivacyInterface);

export const levels = {
  [PrivacyLevel.no_metrics]: 0,
  [PrivacyLevel.aggregate_metrics]: 1,
  [PrivacyLevel.primary_metrics]: 2,
  [PrivacyLevel.performance_metrics]: 3,
  [PrivacyLevel.all_metrics]: 4,
};

export function PrivacyProvider(
  { children, account }: PropsWithChildren<{ account: { id: string } | undefined }>,
) {
  const [privacy, setPrivacy] = useState<Privacy>({ privacy_level: PrivacyLevel.no_metrics });
  const [initialized, setInitialized] = useState<boolean>(false);

  const updatePrivacyLevel = async () => {
    if (account) {
      const p = await getPrivacy(account.id);
      setPrivacy(p);
      setInitialized(true);
    }
  };

  useEffect(() => {
    updatePrivacyLevel();
  }, [account]);

  const context = useMemo(() => ({
    updatePrivacyLevel,
    privacyLevel: privacy.privacy_level,
  }), [updatePrivacyLevel, privacy]);

  return (
    <PrivacyContext.Provider value={context}>
      {initialized ? children : <Loading />}
    </PrivacyContext.Provider>
  );
}

export const usePrivacy = () => {
  const context = useContext(PrivacyContext);

  const isInPrivacyLevel = (privacyLevel: PrivacyLevel) => (
    levels[context.privacyLevel] >= levels[privacyLevel]
  );

  const updatePrivacyLevel = () => context.updatePrivacyLevel();

  return { privacyLevel: context.privacyLevel, isInPrivacyLevel, updatePrivacyLevel };
};
