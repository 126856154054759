import ActionContent from 'components/actionContent/actionContent';
import React, { useEffect, useMemo, useState } from 'react';
import Loading from 'loading';
import { BODY_SHORT_1, TITLE_3 } from 'components/text';
import StickyFooter from 'components/stickyFooter';
import { Button } from '@whoop/web-components';
import { CellProps, Column } from 'react-table';
import { dateCell, normalCell } from 'tableUtils/tableCells';
import Table from 'components/table';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteGroup, getGroupById } from 'api/cohortApi';
import { Cohort } from 'types/cohort';
import { useAccounts } from 'context/account-context';
import { useToast } from 'context/toast-context';
import { DashboardRole } from 'types/dashboardUser';
import { GroupPagesParamsType } from 'types/utils';
import styles from './removeGroup.module.scss';

function RemoveGroup() {
  const { accounts, account, checkAccess } = useAccounts();
  const [group, setGroup] = useState<Cohort>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { openToast } = useToast();
  const { accountId, groupId } = useParams<GroupPagesParamsType>();

  if (!checkAccess(DashboardRole.ACCOUNT_MANAGER)) {
    navigate(`/accounts/${accountId}/people/groups/${groupId}`);
  }

  const handleDeleteGroup = async () => {
    try {
      setLoading(true);
      await deleteGroup(groupId);
      openToast(`The group '${group.name}' has been deleted`);
      navigate(`/accounts/${accountId}/people/groups`);
    } catch (e) {
      setLoading(false);
      openToast('Unable to delete group. Please try again.');
    }
  };

  const columns: Column<Cohort>[] = useMemo(() => [
    {
      Header: 'Group Name',
      id: 'name',
      accessor: (member: Cohort) => member.name,
      width: '50%',
      align: 'left',
      Cell: (cellProps: CellProps<Cohort>) => normalCell(cellProps),
    },
    {
      Header: 'Members',
      id: 'member_count',
      accessor: (member: Cohort) => member.member_count,
      width: '25%',
      align: 'left',
      Cell: (cellProps: CellProps<Cohort>) => normalCell(cellProps),
    },
    {
      Header: 'Date Created',
      id: 'created_at',
      accessor: (member: Cohort) => new Date(member.created_at),
      width: '25%',
      align: 'left',
      Cell: dateCell,
    },
  ], []);

  useEffect(() => {
    setLoading(true);

    const init = async () => {
      if (account) {
        try {
          if (groupId) {
            const retrieveGroup: Cohort = await getGroupById(Number(groupId));
            setGroup(retrieveGroup);
          }
        } catch (error) {
          setGroup(null);
        }
        setLoading(false);
      }
    };

    init();
    return () => {
      setGroup(null);
    };
  }, [account, groupId, accounts]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.fullPage}>
      <ActionContent>
        <TITLE_3>
          Are you sure you want to delete this group?
        </TITLE_3>
        <BODY_SHORT_1>
          This group will be deleted from your account,
          but members in the group will not be deleted from your account.
        </BODY_SHORT_1>
        <Table
          columns={columns}
          data={group ? [group] : []}
          loading={loading}
          tableLength={50}
          tableName="removeMembersTable"
          unit="member"
          displayHeader={false}
          search={false}
          pagination={false}
          showOptionalControls={false}
          disableSort
          runReset={false}
          setFilteredLength={null}
          tableClassName={styles.removeGroupTable}
        />
      </ActionContent>
      <StickyFooter>
        <div className={styles.buttons}>
          <Button label="Cancel" onClick={() => navigate(`/accounts/${accountId}/people/groups/${groupId}`)} variant="link" />
          <Button
            label="Yes, Delete Group"
            onClick={handleDeleteGroup}
            variant="primary"
          />
        </div>
      </StickyFooter>
    </div>
  );
}

export default RemoveGroup;
