import { Button } from '@whoop/web-components';
import FuzzySearch from 'components/fuzzySearch/fuzzySearch';
import { HEADING_4 } from 'components/text';
import AvailableMembersTable from 'groups/availableMembersTable/availableMembersTable';
import { capitalizeWords } from 'groups/utils';
import React, { useEffect, useState } from 'react';
import { Row } from 'react-table';
import { GroupMember } from 'types/cohort';
import { UseStatePropType } from 'types/useStatePropType';
import styles from '../addPage.module.scss';

type AddGroupMembersSearchProps = {
  availableMembers: GroupMember[];
  selectedMembers: GroupMember[];
  setSelectedMembers: UseStatePropType<GroupMember[]>;
  loading: boolean;
};

function AddGroupMembersSearch({
  availableMembers,
  selectedMembers,
  setSelectedMembers,
  loading,
}: AddGroupMembersSearchProps) {
  const [selectedRows, setSelectedRows] = useState<Row<GroupMember>[]>([]);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [filteredAvailableMembers, setFilteredAvailableMembers] = useState<GroupMember[]>([]);

  useEffect(() => {
    setShowMenu(true);
  }, [filteredAvailableMembers]);

  const handleSelect = (member: GroupMember) => {
    setSelectedMembers([...selectedMembers, member]);
    setShowMenu(false);
  };

  const handleRemoveFromSelected = () => {
    const selectedMems = selectedRows.map((row) => row.original);
    setSelectedMembers(selectedMembers.filter((mem) => !selectedMems.includes(mem)));
  };

  return (
    <>
      <div
        className={styles.searchContainer}
      >
        <div className={styles.controlContainer}>
          <div className={styles.heading}><HEADING_4>{`${selectedMembers.length} Members Added`}</HEADING_4></div>
          {selectedRows.length > 0
            && (
              <div className={styles.bulkAction}>
                <Button
                  className={styles.button}
                  label="Remove"
                  size="small"
                  theme="enterprise"
                  variant="normal"
                  onClick={handleRemoveFromSelected}
                />
              </div>
            )}
        </div>
        <FuzzySearch
          searchables={availableMembers.filter((mem) => !selectedMembers.includes(mem))}
          searchKeys={['first_name', 'last_name', 'email', 'strap_serial']}
          limit={5}
          setFilteredSearch={setFilteredAvailableMembers}
          placeholder="Search for a member or strap"
        />
        <ul className={!showMenu ? styles.hide : ''}>
          {filteredAvailableMembers.map((mem) => {
            const fullName = capitalizeWords(`${mem.first_name} ${mem.last_name}`);
            return (
              <li key={mem.user_id}>
                <div
                  tabIndex={0}
                  role="menuitem"
                  onClick={() => handleSelect(mem)}
                  onKeyDown={() => handleSelect(mem)}
                >
                  <div className={styles.nameAndEmail}>
                    <span className={styles.name}>
                      {fullName}
                    </span>
                    <span className={styles.email}>
                      {mem.email}
                    </span>
                  </div>
                  <span className={styles.serial}>
                    {mem.strap_serial}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <AvailableMembersTable
        availableMembers={selectedMembers}
        loading={loading}
        setSelectedRows={setSelectedRows}
      />

    </>
  );
}

export default AddGroupMembersSearch;
