import { analyticsClient } from 'api';
import { usePrivacy } from 'context/privacy-context';
import { useEffect, useState } from 'react';
import {
  Column, Hooks, Row, TableInstance, TableState, useFilters, usePagination, useSortBy, useTable,
} from 'react-table';
import { DeviceHealth, PillarOptions, Pillars } from 'types/analytics';
import { deviceHealthColumns, initialDeviceHealthTableState } from './deviceHealth';
import { getRecoveryData, getRecoveryTableColumns, initialRecoveryTableState } from './recovery';
import { getSleepData, getSleepTableColumns, initialSleepTableState } from './sleep';
import { getStrainData, getStrainTableColumns, initialStrainTableState } from './strain';

export type BreakdownDataRow = {
  user_id?: number;
};

export type LoadingTableInstanceProps = {
  loading: boolean;
};

export type UsePinnedSummaryInstanceProps = {
  summaryRow?: Row<BreakdownDataRow>;
};

export type BreakdownTableInstance = TableInstance<BreakdownDataRow>
& LoadingTableInstanceProps
& UsePinnedSummaryInstanceProps;

/*
  This is a react-table plugin that separates the pinned summary row from the rest.
  We remove the summary row from the "rows" prop
  and append it to the table instance via a prop called "summaryRow".

  This isolates the summary row from any effects caused by other
  react-table plugins down the chain.

  The order of plugins is significant!
  (i.e. placing this first will prevent all plugins from knowing about the summary row)
*/
const usePinnedSummary = (hooks: Hooks<BreakdownDataRow>) => {
  hooks.useInstance.push((instance) => {
    Object.assign(instance, {
      summaryRow: instance.rows.find((r) => !r.original.user_id),
      rows: instance.rows.filter((r) => r.original.user_id),
    });
  });
};

export default function useBreakdownTable(
  groupId: number,
  pillar: PillarOptions,
  start: Date,
  end: Date,
) {
  const { privacyLevel } = usePrivacy();
  const [columns, setColumns] = useState<Column<BreakdownDataRow>[]>([]);
  const [data, setData] = useState<BreakdownDataRow[]>([]);
  const [initialState, setInitialState] = useState<Partial<TableState>>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      switch (pillar) {
        case Pillars.SLEEP: {
          const sleepData = await getSleepData(groupId, start, end, privacyLevel);
          const sleepColumns = getSleepTableColumns(privacyLevel) as Column<BreakdownDataRow>[];
          setColumns(sleepColumns);
          setInitialState(initialSleepTableState);
          setData(sleepData);
          break;
        }
        case Pillars.STRAIN: {
          const strainData = await getStrainData(groupId, start, end, privacyLevel);
          const strainColumns = getStrainTableColumns(privacyLevel) as Column<BreakdownDataRow>[];
          setColumns(strainColumns);
          setInitialState(initialStrainTableState);
          setData(strainData);
          break;
        }
        case Pillars.RECOVERY: {
          const recoveryData = await getRecoveryData(groupId, start, end, privacyLevel);
          const recoveryColumns = getRecoveryTableColumns(
            privacyLevel,
          ) as Column<BreakdownDataRow>[];
          setColumns(recoveryColumns);
          setInitialState(initialRecoveryTableState);
          setData(recoveryData);
          break;
        }
        case DeviceHealth.DEVICE_HEALTH: {
          const deviceHealthData = await analyticsClient.getDeviceHealthData(groupId);
          setColumns(deviceHealthColumns as Column<BreakdownDataRow>[]);
          setInitialState(initialDeviceHealthTableState);
          setData(deviceHealthData);
          break;
        }
        default:
          throw new Error(`unknown pillar type: ${pillar}`);
      }
      setLoading(false);
    }

    fetchData();
  }, [groupId, pillar, start, end, privacyLevel]);

  return {
    ...useTable(
      {
        columns,
        data,
        initialState,
      },
      usePinnedSummary,
      useFilters,
      useSortBy,
      usePagination,
    ),
    loading,
  } as BreakdownTableInstance;
}
