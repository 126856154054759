import { getFormattedMonthDayValues } from 'dataVisualizations/strainRecoveryTrendGraph/strainRecoveryTrendGraphUtils';
import styles from './graphTooltip.module.scss';

type GraphTooltipProps = {
  date?: string;
  description: string;
  value: string | number;
  activationType?: string | number;
};

function GraphTooltip({
  description, value, date = null, activationType = null,
}: GraphTooltipProps) {
  return (
    <div className={styles.tooltipBox}>
      {date
        && (
          <div className={styles.tooltipDateText}>
            {getFormattedMonthDayValues(date)}
          </div>
        )}
      <div className={styles.tooltipLine}>
        <div className={styles.tooltipDescriptionText}>
          {activationType && <span className={`${styles.activationBlock} ${styles[activationType]}`} data-testid="activationSpan" />}
          {description}
        </div>
        <div className={styles.tooltipValueText}>
          {value}
        </div>
      </div>
    </div>
  );
}

export default GraphTooltip;
