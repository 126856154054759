/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@whoop/web-components';
import { useState, useReducer } from 'react';
import ActionContent from 'components/actionContent/actionContent';
import StickyFooter from 'components/stickyFooter';
import FullPageMessage from 'components/fullPageMessage';
import { trackEvent } from 'helpers/analyticsTracking';
import { sendInvites } from 'api/seatApi';
import { PAGE_STATE } from 'constants/pageState';
import { useAccounts } from 'context/account-context';
import { SeatDetail, EmailAndSeatStatus } from 'types/seat';
import InvitesSent from './components/sendInvites/sendInvites';
import { typeEmails, inviteSteps } from './constants';
import TypeEmails from './components/typeEmails/typeEmails';
import CSVUpload from './components/csvUpload/csvUpload';
import styles from './invite.module.scss';
import { inviteFlowReducer } from './util';
import SelectShippingMethod from './components/selectShippingMethod/selectShippingMethod';

type InviteModalProps = {
  refetchData: () => void;
  setPageState: (pageState: string) => void;
  existingMemberSeats: SeatDetail[];
};

function InviteModal(props: InviteModalProps) {
  const {
    refetchData, setPageState, existingMemberSeats,
  } = props;
  const [state, dispatch] = useReducer(inviteFlowReducer, {
    shippingMethod: null,
  });
  const { account, updateAccount } = useAccounts();
  const [inputType, setInputType] = useState(typeEmails.value);
  const [emails, setEmails] = useState([]);
  const [skippedEmails, setSkippedEmails] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [inviteStep, setInviteStep] = useState(inviteSteps.input);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const claimedLicenses = account.active_seats + account.invited_seats + account.pending_seats;
  const unclaimedLicenses = claimedLicenses > account.total_licenses
    ? 0
    : account.total_licenses - claimedLicenses;

  const handleConfirmEmails = () => setInviteStep(inviteSteps.shippingMethod);
  const handleSendInvites = async () => {
    trackEvent('New Member Invite - Send Invitation', {
      accountId: account.id,
    });
    setInviteStep(inviteSteps.send);
    setLoading(true);
    try {
      // List of emails that belong to existing pending/active seats
      // We will skip sending invitations to these emails
      const emailsToSkip: EmailAndSeatStatus[] = [];

      const emailsToInvite = emails.filter((invitedEmail) => {
        // Does the email we want to invite exist for one of our current members
        const emailToSkip = existingMemberSeats.find(
          (existingMember) => existingMember.email === invitedEmail,
        );

        if (emailToSkip) {
          emailsToSkip.push({ email: emailToSkip.email, status: emailToSkip.status });
        }

        // Add email to emailsToInvite if the email doesn't exist for one of our members
        return !emailToSkip;
      });
      setSkippedEmails(emailsToSkip);

      // only send as many emails as there are licenses for
      const emailsUnderLicenseCap = emailsToInvite.slice(0, unclaimedLicenses);
      if (emailsUnderLicenseCap.length !== 0) {
        await sendInvites(emailsUnderLicenseCap, state.shippingMethod);
      }
      setEmails(emailsUnderLicenseCap);
      updateAccount();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  const handleSendMoreInvites = () => {
    setInviteStep(inviteSteps.input);
    setEmails([]);
    setInvalidEmails([]);
    setLoading(false);
    setError(null);
  };

  const cancel = () => setPageState(PAGE_STATE.DASHBOARD);
  const back = () => setInviteStep(inviteSteps.input);
  const backToAccount = () => {
    setPageState(PAGE_STATE.DASHBOARD);
    refetchData();
  };

  const handleInputTypeChange = (newInputType: string) => {
    trackEvent('New Member Invite - Email Type Click', {
      accountId: account.id,
      inputType: newInputType,
    });
    setInputType(newInputType);
  };

  const inputProps = {
    emails,
    setEmails,
    invalidEmails,
    setInvalidEmails,
    setInputType: handleInputTypeChange,
    unclaimedLicenses,
  };

  const numInvitations = emails.length > unclaimedLicenses ? unclaimedLicenses : emails.length;

  const getActionButtonProps = () => {
    let label = `Send ${numInvitations} invitation${
      numInvitations === 1 ? '' : 's'
    }`;
    let disabled = emails.length === 0;
    let onClick: () => void | Promise<any> = () => {};

    if (inviteStep === inviteSteps.input) {
      label = 'Select Shipping Type';
      onClick = handleConfirmEmails;
    }
    if (inviteStep === inviteSteps.shippingMethod) {
      label = 'Send Invitations';
      if (state.shippingMethod) {
        onClick = handleSendInvites;
      } else {
        disabled = true;
      }
    }
    if (inviteStep === inviteSteps.send) {
      label = 'Invite More Members';
      onClick = handleSendMoreInvites;
    }

    return {
      theme: 'enterprise',
      variant: 'primary',
      label,
      disabled,
      onClick,
    };
  };

  const getSecondaryButtonProps = () => {
    let label = 'Cancel';
    let onClick = cancel;
    if (inviteStep === inviteSteps.shippingMethod) {
      label = 'Back';
      onClick = back;
    }
    if (inviteStep === inviteSteps.send) {
      label = 'Back To Account';
      onClick = backToAccount;
    }

    return {
      variant: 'link',
      label,
      onClick,
    };
  };

  return (
    <>
      <ActionContent>
        {inviteStep === inviteSteps.send && (
          <InvitesSent
            loading={loading}
            errors={error ? [error] : null}
            emails={emails}
            skippedEmails={skippedEmails}
          />
        )}
        {inviteStep === inviteSteps.input
          && unclaimedLicenses > 0
          && (inputType === typeEmails.value ? (
            <TypeEmails {...inputProps} />
          ) : (
            <CSVUpload {...inputProps} />
          ))}
        {inviteStep === inviteSteps.shippingMethod && (
          <SelectShippingMethod
            emails={emails}
            state={state}
            dispatch={dispatch}
          />
        )}
        {unclaimedLicenses <= 0 && (
          <div className={styles.messageContainer}>
            <FullPageMessage
              header="Sorry, you don't have enough seats to invite any more members."
              subtext="Please contact your Customer Success Manager for more
                membership seats on your enterprise account and they will contact you for details"
              state="error"
            />
          </div>
        )}
      </ActionContent>

      <StickyFooter>
        <div className={styles.inviteFooter}>
          <div className={styles.availableMemberships}>
            <p>
              Available Memberships:
              {' '}
              {unclaimedLicenses}
            </p>
            {/* Removing until primary flow for this has been implemented */}
            {/* <p className={styles.upgradeText}>Need more seats? Upgrade Now.</p> */}
          </div>
          <div>
            <Button {...getSecondaryButtonProps()} />
            <Button {...getActionButtonProps()} />
          </div>
        </div>
      </StickyFooter>
    </>
  );
}

export default InviteModal;
