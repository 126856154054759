import { DashboardRole } from 'types/dashboardUser';
import { WhoopRole } from 'types/whoopRole';

export const dashboardRoleDisplay = {
  [DashboardRole.ACCOUNT_ADMIN]: 'Account Admin',
  [DashboardRole.WHOOP_CSM]: 'WHOOP CSM',
  [DashboardRole.ACCOUNT_MANAGER]: 'Account Manager',
  [DashboardRole.GROUP_MANAGER]: 'Group Manager',
};

export const mapWhoopRoleToDashboardRole = (
  role: WhoopRole,
  isPrimaryOwner: boolean = false,
) => {
  if (role === WhoopRole.ENTERPRISE_ADMIN) {
    return DashboardRole.WHOOP_CSM;
  }
  if (role === WhoopRole.ENTERPRISE_WORKSPACE_MANAGER && isPrimaryOwner) {
    return DashboardRole.ACCOUNT_ADMIN;
  }
  if (role === WhoopRole.ENTERPRISE_WORKSPACE_MANAGER) {
    return DashboardRole.ACCOUNT_MANAGER;
  }

  return DashboardRole.GROUP_MANAGER;
};

export const mapDashboardRoleToWhoopRole = (
  role: DashboardRole,
) => {
  switch (role) {
    case DashboardRole.WHOOP_CSM:
      return WhoopRole.ENTERPRISE_ADMIN;
    case DashboardRole.ACCOUNT_ADMIN:
      return WhoopRole.ENTERPRISE_WORKSPACE_MANAGER;
    case DashboardRole.ACCOUNT_MANAGER:
      return WhoopRole.ENTERPRISE_WORKSPACE_MANAGER;
    case DashboardRole.GROUP_MANAGER:
      return WhoopRole.ENTERPRISE_COHORT_MANAGER;
    default:
      return WhoopRole.ENTERPRISE_COHORT_MANAGER;
  }
};
