import LandingPageWrapper from 'landingPageWrapper/landingPageWrapper';
import LockIcon from 'assets/icons/lock.svg';
import { Button } from '@whoop/web-components';
import { useNavigate } from 'react-router-dom';
import styles from './accessrestricted.module.scss';

function AccessRestricted() {
  const navigate = useNavigate();
  return (
    <LandingPageWrapper>
      <div className={styles.error}>
        <div className={styles.top}>
          <img className={styles.errorIcon} src={LockIcon} alt="Error" />

          <h1>Access restricted</h1>
          <p>
            You don&apos;t have access to this enterprise team account. If this
            seems incorrect, please submit an access request to WHOOP.
          </p>
          <Button
            label="request access"
            aria-label="request access"
            data-testid="request-access-btn"
            onClick={() => {
              navigate('/request-access');
            }}
            theme="dark"
            variant="secondary"
          />
        </div>
        <div className={styles.bottom}>
          <Button
            label="Return to sign in"
            variant="link"
            className={styles.returnToSignIn}
            onClick={() => {
              navigate('/login');
            }}
            data-testid="return-to-sign-in"
          />
        </div>
      </div>
    </LandingPageWrapper>
  );
}

export default AccessRestricted;
