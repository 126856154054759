import { classes, Icon } from '@whoop/web-components';
import styles from './activityStatTile.module.scss';

type ActivityStatTileProps = {
  icon: string;
  stat: string;
  value: number | string;
  fill?: boolean;
};

function ActivityStatTile({
  icon, stat, value, fill = false,
}: ActivityStatTileProps) {
  return (
    <div className={styles.tile}>
      <Icon className={classes(styles.icon, fill && styles.filledIcon)} name={icon} />
      <div className={styles.stat}>
        {stat}
      </div>
      <div className={styles.value}>
        {value ?? '--'}
      </div>
    </div>
  );
}

export default ActivityStatTile;
