import { Period } from './analytics';

export enum URLDateRangeParams {
  to = 'to',
  from = 'from',
}

export type ProfileTabContext = {
  periodSelected: Period;
};

export type UrlParams = {
  userId: string;
  accountId: string;
};

export type URLDateRange = {
  from: string;
  to?: string;
};
