import { baseGroups } from 'mocks/store/groups';
import { getDelayVal, mswBaseUri } from 'mocks/utils/utils';
import { rest } from 'msw';
import {
  createGroup,
  addGroupMember,
  getGroup,
  getGroupMembers,
  getGroups,
  removeGroupMember,
  getAvailableGroupMembers,
  getAvailableCollaborators,
  addCollaborator,
  updateGroup,
  removeCollaboratorFromGroup,
  deleteGroup,
} from '../actions/groupActions';
import { jsonCheckForPII } from '../transformer/deidentifyTransformer';

export const groupsHandlers = [
  rest.get(mswBaseUri('v1/cohorts'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(getGroups()),
  )),
  rest.get(mswBaseUri('v1/cohorts/access'), (req, res, ctx) => {
    const includePrimary = req.url.searchParams.get('includePrimaryCohort') === 'true';
    const groups = getGroups(includePrimary);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(groups),
    );
  }),
  rest.get(mswBaseUri('v1/cohorts/details'), (req, res, ctx) => {
    const groupId = req.url.searchParams.get('cohortId');
    const groupIdToNum = Number(groupId);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(getGroup(groupIdToNum)),
    );
  }),
  rest.post(mswBaseUri('v1/cohorts'), async (req, res, ctx) => {
    const group = await req.json();
    const newGroup = createGroup(group);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(newGroup),
    );
  }),
  rest.get(mswBaseUri('v1/cohorts/members'), (req, res) => {
    const groupId = req.url.searchParams.get('cohortId');
    const groupIdToNum = Number(groupId);
    return res(jsonCheckForPII(getGroupMembers(groupIdToNum)));
  }),
  rest.get(mswBaseUri('v2/cohorts/members'), (req, res) => {
    const groupId = req.url.searchParams.get('cohortId');
    const groupIdToNum = Number(groupId);
    return res(jsonCheckForPII(getGroupMembers(groupIdToNum).records));
  }),
  rest.post(mswBaseUri('v1/cohorts/member'), (req, res, ctx) => {
    const groupId = req.url.searchParams.get('cohortId');
    const seatId = req.url.searchParams.get('seatId');
    const groupIdToNum = Number(groupId);
    const seatIdToNum = Number(seatId);
    addGroupMember(seatIdToNum, groupIdToNum);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(204),
    );
  }),
  rest.delete(mswBaseUri('v1/cohorts/member'), (req, res, ctx) => {
    const groupId = req.url.searchParams.get('cohortId');
    const groupIdToNum = Number(groupId);
    const seatId = req.url.searchParams.get('seatUserId');
    const seatIdToNum = Number(seatId);
    removeGroupMember(seatIdToNum, groupIdToNum);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(204),
    );
  }),
  rest.delete(mswBaseUri('v1/cohorts'), (req, res, ctx) => {
    const groupId = req.url.searchParams.get('cohortId');
    const groupIdToNum = Number(groupId);
    deleteGroup(groupIdToNum);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(204),
    );
  }),
  rest.get(mswBaseUri('v1/cohorts/available'), (req, res) => {
    const groupId = req.url.searchParams.get('cohortId');
    const groupIdToNum = Number(groupId);
    return res(jsonCheckForPII(getAvailableGroupMembers(groupIdToNum)));
  }),
  rest.get(mswBaseUri('v1/cohorts/collaborators/available'), (req, res, ctx) => {
    const groupId = req.url.searchParams.get('cohortId');
    const groupIdToNum = Number(groupId);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(getAvailableCollaborators(groupIdToNum)),
    );
  }),
  rest.post(mswBaseUri('v1/cohorts/access'), async (req, res, ctx) => {
    const { user_id, cohort_id } = await req.json();
    addCollaborator(user_id, cohort_id);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(204),
    );
  }),
  rest.put(mswBaseUri('v1/cohorts'), async (req, res, ctx) => {
    const groupId = req.url.searchParams.get('cohortId');
    const groupIdToNum = Number(groupId);
    const group = await req.json();
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(updateGroup(groupIdToNum, group)),
    );
  }),
  rest.get(mswBaseUri('v1/cohorts/access/user'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(baseGroups),
  )),
  rest.delete(mswBaseUri('v1/cohorts/access'), async (req, res, ctx) => {
    const { user_id, cohort_id } = await req.json();
    removeCollaboratorFromGroup(user_id, cohort_id);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
    );
  }),
];

export default groupsHandlers;
