import React, { useState } from 'react';
import { adminClient } from 'api';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const sendStates = {
  NOT_SENT: 'Please input the email of the new workspace manager.',
  LOADING: 'Sending invite...',
  SENT: 'The workspace manager has been invited!',
  ERROR: 'There was an error.',
};

function InviteWorkspaceManager({ account, handleClose }) {
  const id = account?.id || null;
  const name = account?.name;
  const [email, setEmail] = useState('');
  const [sendState, setSendState] = useState(sendStates.NOT_SENT);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSendInvite = async () => {
    setSendState(sendStates.LOADING);
    try {
      await adminClient.inviteWorkspaceManager(id, email);
      setSendState(sendStates.SENT);
    } catch (error) {
      setSendState(sendStates.ERROR);
    }
  };

  const handleCloseClick = () => {
    setEmail('');
    setSendState(sendStates.NOT_SENT);
    handleClose();
  };

  return (
    <Dialog
      open={id !== null}
      onClose={handleClose}
      aria-labelledby="invite-wsm-title"
      aria-describedby="invite-wsm-description"
    >
      <DialogTitle id="invite-wsm-title">
        Invite Workspace Manager for
        {' '}
        {name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="invite-wsm-description">
          {sendState}
        </DialogContentText>
        <TextField
          autoFocus
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          disabled={sendState !== sendStates.NOT_SENT}
          onChange={handleEmailChange}
        />
      </DialogContent>
      <DialogActions>
        {sendState === sendStates.NOT_SENT && (
          <>
            <Button onClick={handleCloseClick} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSendInvite} color="primary">
              Send
            </Button>
          </>
        )}
        {[sendStates.SENT, sendStates.ERROR].includes(sendState) && (
          <Button onClick={handleCloseClick} color="primary">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default InviteWorkspaceManager;
