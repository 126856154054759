import { levels } from 'context/privacy-context';
import {
  collaborators, currentDashboardUser, currentDashboardUserCollaborator, dashboardUsers,
} from 'mocks/store/dashboardUsers';
import { reidentificationTokenKey } from 'types/admin';
import { PrivacyLevel } from 'types/dashboardUser';
import { InviteStatus } from '../../types/collaborator';
import {
  assetsOnHand,
  baseAccount, configurations, reidentificationTokenBody,
} from '../store/data';
import { getSeats } from './seatActions';

export const getAccount = () => {
  const seats = getSeats();
  const numActiveSeats = seats.filter((seat) => seat.status === 'active').length;
  return {
    ...baseAccount,
    invited_seats: seats.filter((seat) => seat.status === 'invited').length,
    pending_seats: seats.filter((seat) => seat.status === 'pending').length,
    active_seats: numActiveSeats,
    num_compliant: numActiveSeats - 1,
  };
};

export const getDashboardUser = () => currentDashboardUser;

export const getDashboardUserCollaborator = () => currentDashboardUserCollaborator;

export const getAvailableBulkLicenses = () => ({
  total_licenses: baseAccount.total_licenses,
  available_bulk_license_count: assetsOnHand - getSeats().filter((s) => s.status === 'active' || s.status === 'pending' || (s.status === 'invited' && s.shipping_type === 'bulk')).length,
});

export const getConfigurations = () => configurations;

export const setConfigurations = (configRequest) => {
  configurations.concealed_feature_enabled = configRequest.concealed_feature_enabled;
  configurations.hub_enabled = configRequest.hub_enabled;
  configurations.strap_onboarding_enabled = configRequest.strap_onboarding_enabled;
  configurations.bluetooth_roaming = configRequest.bluetooth_roaming;
  configurations.deidentified = configRequest.deidentified;
  configurations.privacy_ceiling_enabled = configRequest.privacy_ceiling_enabled;
  configurations.privacy_ceiling_level = configRequest.privacy_ceiling_level;
  configurations.concealed = configRequest.concealed;

  return configurations;
};

export const getCollaborators = () => collaborators;

export const getDashboardUsers = () => dashboardUsers;

export const updateInvitedCollaborator = (email, payload) => collaborators.map((user) => {
  if (user.email === email) {
    const updateData = {
      ...user,
      ...payload,
    };
    return updateData;
  }
  return user;
});

export const updateDashboardUsers = (res) => {
  if (currentDashboardUser.user_id === res.user_id) {
    currentDashboardUser.privacy_level = res.privacy_level;
  }

  return dashboardUsers.map((user) => {
    if (user.user_id === res.user_id) {
      const updateData = {
        ...user,
        ...res,
      };
      return updateData;
    }
    return user;
  });
};

export const addCollaborator = (collab) => {
  let users = getCollaborators();
  const newCollaborator = {
    name: `Test User ${collab.id}`,
    email: collab.email,
    dashboard_role: collab.dashboard_role,
    is_primary_owner: false,
    managing_groups: 0,
    avatar_url: '',
    display_role: collab.title,
    salesforce_account_id: collab.salesforce_account_id,
    user_id: collab.id,
    privacy_level: collab.privacy_level,
  };
  users = users.concat(newCollaborator);
  return users;
};

const getIndexOfActiveCollaborator = (array, userId) => {
  const collaborator = array.find((collab) => collab.user_id === parseInt(userId, 10));
  return array.indexOf(collaborator);
};

const getIndexOfInvitedCollaborator = (array, email) => {
  const collaborator = array.find((collab) => collab.email === email);
  return array.indexOf(collaborator);
};

export const removeCollaboratorFromAccount = (userId) => {
  // Remove any permissions user type
  dashboardUsers.splice(getIndexOfActiveCollaborator(dashboardUsers, userId), 1);

  collaborators.splice(getIndexOfActiveCollaborator(collaborators, userId), 1);
};

export const removeInvitedCollaboratorFromAccount = (email) => {
  collaborators.splice(getIndexOfInvitedCollaborator(collaborators, email), 1);
};

export const getCollaboratorLink = () => ({ collaborator_invite_link: 'demo-registration-url' });

export const inviteCollaborator = (data) => {
  collaborators.push({
    email: data.email,
    display_role: data.display_role,
    role: data.role,
    privacy_level: data.privacy_level,
    status: InviteStatus.invited,
  });
};

export const getAllAccessPrivacy = () => {
  if (configurations.privacy_ceiling_enabled) {
    const ceilingPrivacy = levels[configurations.privacy_ceiling_level];
    const userPrivacy = levels[currentDashboardUser.privacy_level];
    if (userPrivacy < ceilingPrivacy) {
      return { privacy_level: currentDashboardUser.privacy_level };
    }
    return { privacy_level: configurations.privacy_ceiling_level };
  }
  return { privacy_level: currentDashboardUser.privacy_level };
};

export const setUserProfile = (userProfile) => {
  currentDashboardUser.first_name = userProfile.first_name;
  currentDashboardUser.last_name = userProfile.last_name;
  currentDashboardUser.name = `${userProfile.first_name} ${userProfile.last_name}`;
  currentDashboardUser.email = `${userProfile.first_name.toLowerCase()}.${userProfile.last_name.toLowerCase()}@whoop.com`;
  currentDashboardUser.user_id = userProfile.id;
  currentDashboardUser.avatar_url = userProfile.avatar_url;
  currentDashboardUser.privacy_level = PrivacyLevel.all_metrics;

  currentDashboardUserCollaborator.first_name = userProfile.first_name;
  currentDashboardUserCollaborator.last_name = userProfile.last_name;
  currentDashboardUserCollaborator.name = `${userProfile.first_name} ${userProfile.last_name}`;
  currentDashboardUserCollaborator.email = `${userProfile.first_name.toLowerCase()}.${userProfile.last_name.toLowerCase()}@whoop.com`;
  currentDashboardUserCollaborator.user_id = userProfile.id;
  currentDashboardUserCollaborator.avatar_url = userProfile.avatar_url;
  currentDashboardUserCollaborator.privacy_level = PrivacyLevel.all_metrics;

  return {
    ...userProfile,
    admin: true,
  };
};

export const getReidentificationToken = () => reidentificationTokenBody;

export const validateReidentificationToken = () => localStorage.getItem(reidentificationTokenKey)
  === reidentificationTokenBody.token;
