import React, {
  useMemo,
  useState,
} from 'react';
import {
  CellProps,
  Column, TableCommonProps,
} from 'react-table';
import { Button } from '@whoop/web-components';
import { Cohort } from 'types/cohort';
import Table from 'components/table';
import { useNavigate } from 'react-router-dom';
import { normalCell, concealedIconNormalCell } from 'tableUtils/tableCells';
import { useAccounts } from 'context/account-context';
import RowsPerPageSelect from 'tableUtils/rowsPerPageSelect/rowsPerPageSelect';
import { DashboardRole } from 'types/dashboardUser';
import PeoplePageToggle from 'components/peoplePageToggle/peoplePageToggle';
import AccountDetailsHeader from 'components/accountDetailsHeader/accountDetailsHeader';
import { trackEvent } from 'helpers/analyticsTracking';
import styles from './groupsTable.module.scss';

type GroupTableProps = {
  groups: Cohort[];
  loading: boolean;
};

function GroupsTable({ groups, loading }: GroupTableProps) {
  const { account, accountConfig, checkAccess } = useAccounts();
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState<number>(10);

  const columns: Column<Cohort>[] = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Group Name',
        accessor: 'name',
        width: '50%',
        align: 'left',
        Cell: (
          cellProps: CellProps<Cohort>,
        ) => concealedIconNormalCell(cellProps, accountConfig?.concealed),
      },
      {
        id: 'memberCount',
        Header: 'Members',
        accessor: (group: Cohort) => `${group.member_count} members`,
        width: '50%',
        align: 'left',
        Cell: normalCell,
      },
    ],
    [],
  );

  const handleGroupClick = (group: Cohort) => {
    navigate(`/accounts/${account.id}/people/groups/${group.id}`);
  };

  const handleCreateGroupBtn = () => {
    trackEvent('Create Group - Button Click', {
      accountId: account.id,
      page: 'groups table',
    });
    navigate(`/accounts/${account.id}/people/groups/add-group`);
  };

  const getRowStyles = (): TableCommonProps => ({
    style: {
      height: '80px',
    },
  });

  const createGroupBtn = (
    <div className={styles.controls}>
      <div className={styles.left}>
        <PeoplePageToggle />
        <RowsPerPageSelect onOptionClick={(size) => setPageSize(size)} selectedOption={pageSize} />
      </div>
      {!!checkAccess && checkAccess(DashboardRole.ACCOUNT_MANAGER) && (
        <Button
          className={styles.button}
          label="Create Group"
          size="small"
          theme="enterprise"
          variant="normal"
          onClick={handleCreateGroupBtn}
        />
      )}
    </div>
  );

  return (
    <div className={styles.tableContainer}>
      <Table
        columns={columns}
        loading={loading}
        data={groups}
        tableName="groupsTable"
        heading="All Groups"
        unit="group"
        searchPlaceholder="Search by group or collaborator"
        getAdditionalRowProps={getRowStyles}
        tableLength={pageSize}
        hideOnSearch
        showEmptyMessage
        drilldownArrow
        onRowClickHandler={handleGroupClick}
        optionalControls={createGroupBtn}
        customHeader={<AccountDetailsHeader />}
      />
    </div>
  );
}

export default GroupsTable;
