import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';
import { MonthDayValues } from 'types/analytics';

export const getNumDays = (range: DateRange) => {
  if (range?.from && range?.to) {
    const numDaysDiff = dayjs(range.to).diff(dayjs(range.from), 'day');
    // add 1 to include start day
    const numDaysSelected = numDaysDiff + 1;
    return numDaysSelected;
  }
  return 1;
};

export const getNumDaysText = (range: DateRange, minNumDays: number) => {
  const numDaysSelected = getNumDays(range);
  if (numDaysSelected < minNumDays) {
    return `Select at least ${minNumDays} days`;
  }
  if (numDaysSelected === 0) {
    return '';
  }
  if (numDaysSelected === 1) {
    return '1 day';
  }
  return `${numDaysSelected} days`;
};

// Format to follow the following spec for Month, day, and year:
// https://m2.material.io/design/communication/data-formats.html#date-and-time
export const formatWeekdayDayMaybeYear = (day: Date) => {
  const currentYear = dayjs().year();
  if (!day) {
    return '';
  }
  return day.getFullYear() === currentYear ? dayjs(day).format('MMM D') : dayjs(day).format('MMM D, YYYY');
};

export const getMonthDayValues = (dateVal: string): MonthDayValues => {
  // the replace changes - to / which uses the correct date
  // info on this - https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
  const date: Date = new Date(dateVal.replace(/-/g, '/'));
  const month: string = date.toLocaleString('en-us', { month: 'short' });
  const day: string = date.toLocaleString('en-us', { weekday: 'short' });
  const dayNum: number = date.getDate();
  return { day, month, dayNum };
};

export const getStartOfWeekRange = (date: Date): DateRange => {
  // if day selected is sunday, need to go back a day
  const SUNDAY_INDEX = 0;
  const dayOfWeek = date.getDay();
  const sundayOfWeek = date.getDate() - dayOfWeek;
  const offsetForMonday = (dayOfWeek === SUNDAY_INDEX ? -6 : 1);
  const monday = new Date(date.setDate(sundayOfWeek + offsetForMonday));
  const followingSunday = new Date(new Date(monday).setDate(monday.getDate() + 6));

  return { from: monday, to: followingSunday };
};

export const getStartOfMonthRange = (date: Date): DateRange => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return { from: firstDay, to: lastDay };
};

export const getFormattedDate = (date: Date): string => {
  // because month returns an index (Jan -> 0)
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const monthVal = `${month < 10 ? '0' : ''}${month}`;
  const dayVal = `${day < 10 ? '0' : ''}${day}`;

  return `${date.getFullYear()}-${monthVal}-${dayVal}`;
};

// returns Tue 5/31 format
export const getBreakdownFormattedDate = (date: Date): string => `${date.toLocaleDateString('en-US', { weekday: 'short' })} ${date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' })}`;
