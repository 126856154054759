import {
  Navigate,
  useLocation,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { OVERVIEW_TAB, Period, Pillars } from 'types/analytics';
import { ProfileTabContext } from 'types/profile';
import OverviewDailyView from 'progress/profile/overview/dailyView';
import OverviewRangeView from 'progress/profile/overview/rangeView';
import StrainRangeView from 'progress/profile/strain/rangeView';
import StrainDailyView from 'progress/profile/strain/dailyView';
import RecoveryRangeView from 'progress/profile/recovery/rangeView';
import RecoveryDailyView from 'progress/profile/recovery/dailyView';
import SleepRangeView from 'progress/profile/sleep/rangeView';
import SleepDailyView from 'progress/profile/sleep/dailyView';
import { useFeatureFlags } from 'components/featureFlag/feature-flag-context';
import { useEffect, useState } from 'react';

function PillarRangeSelect() {
  const { pillar } = useParams();
  const location = useLocation();
  const { getFeatureFlag } = useFeatureFlags();
  const [recoveryFlag, setRecoveryFlag] = useState(null);
  const [sleepFlag, setSleepFlag] = useState(null);
  const [loading, setLoading] = useState(true);
  const { periodSelected }: ProfileTabContext = useOutletContext();

  useEffect(() => {
    async function fetchFF() {
      setLoading(true);
      setRecoveryFlag(await getFeatureFlag('unite-member-recovery'));
      setSleepFlag(await getFeatureFlag('unite-member-sleep'));
      setLoading(false);
    }

    fetchFF();
  }, []);

  if (loading) {
    return null;
  }

  if (pillar === OVERVIEW_TAB) {
    if (periodSelected === Period.DAILY) {
      return (
        <OverviewDailyView />
      );
    }
    return <OverviewRangeView />;
  }
  if (pillar === Pillars.STRAIN) {
    if (periodSelected === Period.DAILY) {
      return (
        <StrainDailyView />
      );
    }
    return <StrainRangeView />;
  }
  if (recoveryFlag && pillar === Pillars.RECOVERY) {
    if (periodSelected === Period.DAILY) {
      return (
        <RecoveryDailyView />
      );
    }
    return <RecoveryRangeView />;
  }
  if (sleepFlag && pillar === Pillars.SLEEP) {
    if (periodSelected === Period.DAILY) {
      return (
        <SleepDailyView />
      );
    }
    return <SleepRangeView />;
  }
  const redirect = location.pathname.split('/').slice(0, -1).concat('overview').join('/');
  return <Navigate to={redirect} replace />;
}

export default PillarRangeSelect;
