import styles from './detailLabel.module.scss';

type DetailLabelProps = {
  data: string;
  title: string;
  showBoldNum?: boolean;
};

function DetailLabel({ data, title, showBoldNum = false }: DetailLabelProps) {
  return (
    <div className={styles.infoItem}>
      <p className={styles.itemTitle}>{title}</p>
      <p className={showBoldNum ? styles.itemNumberDetail : styles.itemDetail}>{data || '--'}</p>
    </div>
  );
}

export default DetailLabel;
