import React, { useState } from 'react';
import { TITLE_3 } from 'components/text';
import { Icon } from '@whoop/web-components';
import { makeStyles, Tooltip } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router';
import { useAccounts } from 'context/account-context';
import { DashboardRole } from 'types/dashboardUser';
import { GroupPagesParamsType } from 'types/utils';
import CustomSwitch from 'accountDetails/settings/customSwitch';
import FeatureFlag from 'components/featureFlag';
import { Cohort } from 'types/cohort';
import EditableHeaderInput from 'components/editableHeaderInput/editableHeaderInput';
import styles from './groupsHeader.module.scss';

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'rgba(26, 34, 39, 0.9)',
    width: '350px',
    height: '40px',
    padding: '8px',
    textAlign: 'center',
    fontFamily: 'ProximaNova',
    fontSize: '14px',
  },
}));

type GroupsHeaderProps = {
  group: Cohort;
  handleUpdateGroup: (property: string, value: string | boolean) => void;
};

function GroupsHeader({
  group,
  handleUpdateGroup,
}: GroupsHeaderProps) {
  const { checkAccess, accountConfig } = useAccounts();
  const classes = useStyles();
  const navigate = useNavigate();
  const [description, setDescription] = useState<string>(group.about);
  const { accountId, groupId } = useParams<GroupPagesParamsType>();
  const [isConcealed, setIsConcealed] = useState<boolean>(
    group.concealed || accountConfig?.concealed,
  );

  const toggleConcealed = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === 'false';
    handleUpdateGroup('concealed', newValue);
    setIsConcealed(newValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.groupRow}>
        <TITLE_3>{group.name}</TITLE_3>
        {checkAccess(DashboardRole.ACCOUNT_MANAGER)
          && (
            <div className={styles.deleteIconContainer}>
              <Icon
                title="delete"
                variant="normal"
                name="delete"
                className={styles.deleteIcon}
                onClick={() => {
                  navigate(`/accounts/${accountId}/people/groups/${groupId}/delete`);
                }}
              />
            </div>
          )}
      </div>
      <EditableHeaderInput
        onUpdate={handleUpdateGroup}
        field="about"
        value={description}
        setValue={setDescription}
        placeholder="Add a description to this group"
      />
      <div className={styles.moreActions}>
        <div className={styles.invitedCollaboratorsContainer}>
          <Icon name="locked_line" />
          <p>Only invited collaborators can access</p>
        </div>
        <FeatureFlag flagName="enterprise-fe-concealed-mode-toggle">
          {accountConfig?.concealed_feature_enabled && (
            <div className={`${styles.invitedCollaboratorsContainer} ${styles.concealedIcon}`}>
              <Icon name="score_hidden" />
              <p>
                Concealed mode is
                {` ${isConcealed ? 'On' : 'Off'} `}
                for this group
              </p>
              <div className={styles.concealedSwitch}>
                <Tooltip
                  title={accountConfig.concealed ? 'Toggling has been disabled because auto-conceal has been turned on for this account' : ''}
                  placement="top"
                  arrow
                  classes={{ tooltip: classes.customTooltip }}
                >
                  <div>
                    <CustomSwitch
                      onChange={toggleConcealed}
                      value={isConcealed}
                      checked={isConcealed}
                      name="groupConcealedToggle"
                      customheight={20}
                      customwidth={40}
                      disabled={accountConfig.concealed}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        </FeatureFlag>
      </div>
    </div>
  );
}

export default GroupsHeader;
