/* eslint-disable react/jsx-props-no-spreading */
import { TableSortByToggleProps } from 'react-table';
import { HEADING_4, P2 } from 'components/text';
import { TableHeaderColumn } from 'types/utils';
import { sortBySvg, headerProps } from '../../tableUtils/tableProps';
import styles from './tableColumnHeader.module.scss';

type TableColumnHeaderProps = {
  column: TableHeaderColumn;
  tableHeaderOnClickHandler?: (column: TableHeaderColumn) => void;
  thProps?: {
    className: string;
  };
};

function TableColumnHeader({ column, tableHeaderOnClickHandler, thProps }: TableColumnHeaderProps) {
  const handleHeaderClick = (e: any) => {
    const sortToggleProps = column.getSortByToggleProps(
      headerProps({ title: undefined }, { column }) as Partial<TableSortByToggleProps>,
    );
    if (sortToggleProps.onClick) {
      sortToggleProps.onClick(e);
      if (tableHeaderOnClickHandler) {
        tableHeaderOnClickHandler(column);
      }
    }
  };

  return (
    <th
      {...thProps}
      {...column.getHeaderProps(
        column.getSortByToggleProps(
          headerProps({ title: undefined }, { column }) as Partial<TableSortByToggleProps>,
        ),
      )}
      onClick={handleHeaderClick}
    >
      <div
        className={`${styles.header} ${!column.isSorted && styles.unsorted}`}
      >
        <HEADING_4>{column.render('Header')}</HEADING_4>
        {column.canSort && sortBySvg(column)}
      </div>
      {column.tooltip && (
        <span>
          <P2>{column.tooltip}</P2>
        </span>
      )}
    </th>
  );
}

export default TableColumnHeader;
