import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useToast } from 'context/toast-context';
import StrapManagementHeader from 'components/strapManagementHeader';
import { useAccounts } from 'context/account-context';
import { HubListPayload, HubStatus } from 'types/hub';
import {
  getHubsInfoBySalesforceAccountId,
} from 'api/hubApi';
import Loading from 'loading';
import { DashboardRole } from 'types/dashboardUser';
import useInterval from 'helpers/pollingUtils';
import HubsTable from './hubsTable/hubsTable';
import styles from './hubsListPage.module.scss';
import ActivateHubsModal from './activate/activate';

const createHubsByStatusMap = (hubs: HubListPayload[]) => {
  const hubsByStatusMap = new Map<HubStatus, HubListPayload[]>([
    [HubStatus.ALL, hubs],
    [HubStatus.ONLINE, []],
    [HubStatus.OFFLINE, []],
    [HubStatus.PENDING, []],
    [HubStatus.AWAITING_ACTIVATION, []],
  ]);

  return hubs.reduce(
    (map: Map<HubStatus, HubListPayload[]>, hub: HubListPayload) => {
      if (map.has(hub.status)) {
        map.get(hub.status).push(hub);
      }

      return map;
    },
    hubsByStatusMap,
  );
};

const filterHub = (hub: HubListPayload, currentStatus: HubStatus) => {
  const validStatuses = [HubStatus.ALL, hub.status];
  return validStatuses.includes(currentStatus);
};

type HubsListPageProps = {
  pollInterval?: number;
};

function HubsListPage({ pollInterval = 10000 }: HubsListPageProps) {
  const {
    accounts,
    account,
    loading: loadingAccounts,
    checkAccess,
  } = useAccounts();
  const { openToast } = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [hubs, setHubs] = useState<HubListPayload[]>([]);
  const [allHubs, setAllHubs] = useState<HubListPayload[]>([]);
  const [currentStatus, setCurrentStatus] = useState(HubStatus.ALL);
  const [showModal, setShowModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const hubsByStatus = useMemo(() => createHubsByStatusMap(allHubs), [allHubs]);

  const getHubs = async () => {
    const currentHubInfo: HubListPayload[] = await getHubsInfoBySalesforceAccountId();
    return currentHubInfo.reduce(
      (map: Map<String, HubListPayload>, hub: HubListPayload) => {
        map.set(hub.mac_address, hub);
        return map;
      },
      new Map<String, HubListPayload>(),
    );
  };

  const fetchHubs = useCallback(async () => {
    if (account) {
      setLoading(true);
      try {
        const allHubsResponse = Array.from((await getHubs()).values());
        setAllHubs(allHubsResponse);
        setHubs(allHubsResponse.filter((hub) => filterHub(hub, currentStatus)));
      } catch (error) {
        setHubs([]);
        openToast('Error loading hubs');
      }
      setLoading(false);
    }
  }, [account, accounts]);

  const updateHubStatus = async () => {
    if (loading || !hubs.length) {
      return;
    }

    const updatedHubs: Map<String, HubListPayload> = await getHubs();
    const statusUpdated: boolean = hubs.reduce(
      (updated: boolean, currentHub: HubListPayload) => {
        if (!updatedHubs.has(currentHub.mac_address)) {
          return updated;
        }

        const updatedHub: HubListPayload = updatedHubs.get(
          currentHub.mac_address,
        );
        return updated || updatedHub.status !== currentHub.status;
      },
      false,
    );

    if (!statusUpdated) {
      return;
    }

    setLoading(true);

    setAllHubs(Array.from(updatedHubs.values()));
    for (let i = 0; i < hubs.length; i += 1) {
      const currentHub = hubs[i];

      if (!updatedHubs.has(currentHub.mac_address)) {
        return;
      }

      const updatedHub: HubListPayload = updatedHubs.get(
        currentHub.mac_address,
      );
      currentHub.status = updatedHub.status;
    }

    setLoading(false);
  };

  if (checkAccess(DashboardRole.WHOOP_CSM)) {
    useInterval(updateHubStatus, pollInterval);
  }

  useEffect(() => {
    setHubs(hubsByStatus.get(currentStatus));
  }, [currentStatus, allHubs]);

  useEffect(() => {
    const init = async () => {
      await fetchHubs();
    };

    init();
  }, [account, accounts]);

  if (loadingAccounts) {
    return <Loading />;
  }

  if (showModal) {
    return (
      <ActivateHubsModal
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setShowModal={setShowModal}
      />
    );
  }

  return (
    <>
      <StrapManagementHeader />
      <div className={styles.tableContainer}>
        <div className={styles.table}>
          <HubsTable
            loading={loading}
            hubs={hubs}
            hubsByStatus={hubsByStatus}
            currentStatus={currentStatus}
            setCurrentStatus={setCurrentStatus}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setShowModal={setShowModal}
          />
        </div>
      </div>
    </>
  );
}

export default HubsListPage;
