import { accountHandlers } from './accountHandlers';
import { analyticsHandlers } from './analyticsHandlers';
import { breakdownHandlers } from './breakdownHandlers';
import { groupsHandlers } from './groupHandlers';
import { hubsHandlers } from './hubHandlers';
import { memberOverviewHandlers } from './memberOverviewHandlers';
import { seatsHandlers } from './seatHandlers';
import strapsHandlers from './strapHandlers';

export default [
  ...accountHandlers,
  ...seatsHandlers,
  ...groupsHandlers,
  ...hubsHandlers,
  ...analyticsHandlers,
  ...strapsHandlers,
  ...breakdownHandlers,
  ...memberOverviewHandlers,
];
