import { Col, Row } from 'layout/layout';
import Card from 'progress/components/Card/card';
import PillarBreakdownBar from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBar';
import {
  PillarBreakdownDataWithMetric,
  PillarBreakdownBarData,
  pillarKeysToDisplay,
  recoveryColors,
  RecoveryKeys,
  recoveryKeys,
  sleepColors,
  SleepKeys,
  sleepKeys,
  strainColors,
  StrainKeys,
  strainKeys,
  PillarBreakdownDayData,
  disabledColors,
  disabledStrainColors,
} from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarConsts';
import PillarBreakdownBarLabel from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarLabel';
import { DateRange } from 'react-day-picker';
import { useEffect, useState } from 'react';
import { analyticsClient } from 'api';
import { useParams } from 'react-router-dom';
import { groupRecoveryBreakdownData, groupSleepBreakdownData, groupStrainBreakdownData } from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarUtils';
import { UrlParams } from 'types/profile';
import { ComputedDatum } from '@nivo/bar';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import { formatDate, getFormattedRangeDay } from 'progress/profile/profileUtils';
import StrainRecoveryTrendGraph from 'dataVisualizations/strainRecoveryTrendGraph/strainRecoveryTrendGraph';
import { getBarDataFromTrend, getDaysInRange } from 'dataVisualizations/strainRecoveryTrendGraph/strainRecoveryTrendGraphUtils';
import {
  Pillars,
  RecoveryMetricType,
  SleepMetricType,
  StrainMetricType,
  StrainRecoveryPoints, TrendKey,
} from 'types/analytics';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import getDisabledColorsToUse from './rangeViewUtils';
import styles from './views.module.scss';

function RangeView() {
  const { userId } = useParams<UrlParams>();
  const { dateRange } = useDateRange();
  const [strainBreakdownData,
    setStrainBreakdownData] = useState<PillarBreakdownBarData[]>([{}]);
  const [recoveryBreakdownData,
    setRecoveryBreakdownData] = useState<PillarBreakdownBarData[]>([{}]);
  const [sleepBreakdownData,
    setSleepBreakdownData] = useState<PillarBreakdownBarData[]>([{}]);
  const [trendData, setTrendData] = useState<StrainRecoveryPoints[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [daysToHighlight, setDaysToHighlight] = useState<string[]>([]);
  const [strainBreakdownDays, setStrainBreakdownDays] = useState<PillarBreakdownDayData>({});
  const [recoveryBreakdownDays, setRecoveryBreakdownDays] = useState<PillarBreakdownDayData>({});
  const [strainColorsToUse, setStrainColorsToUse] = useState(strainColors);
  const [recoveryColorsToUse, setRecoveryColorsToUse] = useState(recoveryColors);
  const [sleepColorsToUse, setSleepColorsToUse] = useState(sleepColors);

  const formattedDateRange = getFormattedRangeDay(dateRange);

  const resetState = () => {
    setStrainBreakdownData([{}]);
    setRecoveryBreakdownData([{}]);
    setSleepBreakdownData([{}]);
    setTrendData([]);
  };

  const getData = async (range: DateRange) => {
    setLoading(true);
    const {
      getRecoveryAndStrainTrend, getMetricOverview,
    } = analyticsClient;
    const params = {
      memberId: parseInt(userId, 10),
      startDate: formatDate(range.from),
      endDate: formatDate(range.to),
    };

    try {
      const { data_trend } = await getRecoveryAndStrainTrend(
        params.memberId,
        params.startDate,
        params.endDate,
      );
      const dayStrain = await getMetricOverview(
        params,
        Pillars.STRAIN,
        StrainMetricType.STRAIN,
      );
      const daySleep = await getMetricOverview(
        params,
        Pillars.SLEEP,
        SleepMetricType.PERFORMANCE,
      );
      const dayRecovery = await getMetricOverview(
        params,
        Pillars.RECOVERY,
        RecoveryMetricType.SCORE,
      );
      const {
        breakdownDays: foundStrainBreakdownDays,
        barData: strainBarData,
      } = groupStrainBreakdownData(dayStrain);
      setStrainBreakdownDays(foundStrainBreakdownDays);
      setStrainBreakdownData([strainBarData]);

      const {
        breakdownDays: foundRecoveryBreakdownDays,
        barData: recoveryBarData,
      } = groupRecoveryBreakdownData(
        dayRecovery,
      );
      setRecoveryBreakdownDays(foundRecoveryBreakdownDays);
      setRecoveryBreakdownData([recoveryBarData]);

      const { barData: sleepBarData } = groupSleepBreakdownData(daySleep);
      setSleepBreakdownData([sleepBarData]);

      if (data_trend.length > 0) {
        const { strainData, recoveryData } = getBarDataFromTrend(
          getDaysInRange(dateRange.from, dateRange.to),
          data_trend,
        );
        setTrendData([
          { pointType: TrendKey.Strain, pointData: strainData },
          { pointType: TrendKey.Recovery, pointData: recoveryData },
        ]);
      }
    } catch {
      resetState();
    }
    setLoading(false);
  };

  const resetColors = () => {
    setStrainColorsToUse(strainColors);
    setRecoveryColorsToUse(recoveryColors);
    setSleepColorsToUse(sleepColors);
  };

  useEffect(() => {
    resetColors();
    setDaysToHighlight([]);
    getData(dateRange);
  }, [dateRange]);

  // This will be used to highlight values on the strain vs. recovery graph when a bar is selected
  const barClickHandler = (datum: ComputedDatum<PillarBreakdownDataWithMetric>): void => {
    const pillar = datum.data.metric;
    const selectedVal = datum.id;

    if (pillar === 'day_strain') {
      if (daysToHighlight === strainBreakdownDays[selectedVal as StrainKeys]) {
        setDaysToHighlight([]);
        resetColors();
      } else {
        setDaysToHighlight(strainBreakdownDays[selectedVal as StrainKeys]);
        setStrainColorsToUse(
          getDisabledColorsToUse(disabledStrainColors, strainColors, selectedVal as StrainKeys),
        );
        setRecoveryColorsToUse(disabledColors);
        setSleepColorsToUse(disabledColors);
      }
    } else if (pillar === 'recovery') {
      if (daysToHighlight === recoveryBreakdownDays[selectedVal as RecoveryKeys]) {
        setDaysToHighlight([]);
        resetColors();
      } else {
        setDaysToHighlight(recoveryBreakdownDays[selectedVal as RecoveryKeys]);
        setRecoveryColorsToUse(
          getDisabledColorsToUse(disabledColors, recoveryColors, selectedVal as StrainKeys),
        );
        setStrainColorsToUse(disabledStrainColors);
        setSleepColorsToUse(disabledColors);
      }
    }
  };

  return (
    <>
      <Row className={styles.pillarBarRow}>
        <Col xs={4}>
          <Card
            id="Day Strain Breakdown"
            loading={loading}
            className={styles.pillarBarCard}
          >
            <CardHeader
              title="Day Strain Breakdown"
              details={formattedDateRange}
            />
            <div className={styles.pillarBreakdownBar}>
              <PillarBreakdownBar
                data={strainBreakdownData}
                colors={strainColorsToUse}
                keys={strainKeys}
                barClickHandler={barClickHandler}
              />
            </div>
            <div className={styles.labels}>
              {strainKeys.map((key: StrainKeys) => (
                <PillarBreakdownBarLabel
                  key={key}
                  count={strainBreakdownData[0][key]}
                  displayVals={pillarKeysToDisplay[key]}
                />
              ))}
            </div>
          </Card>
        </Col>
        <Col xs={4}>
          <Card
            id="Recovery Breakdown"
            loading={loading}
            className={styles.pillarBarCard}
          >
            <CardHeader
              title="Recovery Breakdown"
              details={formattedDateRange}
            />
            <div className={styles.pillarBreakdownBar}>
              <PillarBreakdownBar
                data={recoveryBreakdownData}
                colors={recoveryColorsToUse}
                keys={recoveryKeys}
                barClickHandler={barClickHandler}
              />
            </div>
            <div className={styles.labels}>
              {recoveryKeys.map((key: RecoveryKeys) => (
                <PillarBreakdownBarLabel
                  key={key}
                  count={recoveryBreakdownData[0][key]}
                  displayVals={pillarKeysToDisplay[key]}
                />
              ))}
            </div>
          </Card>
        </Col>
        <Col xs={4}>
          <Card
            id="Sleep Breakdown"
            loading={loading}
            className={styles.pillarBarCard}
          >
            <CardHeader
              title="Sleep Breakdown"
              details={formattedDateRange}
            />
            <div className={styles.pillarBreakdownBar}>
              <PillarBreakdownBar
                data={sleepBreakdownData}
                colors={sleepColorsToUse}
                keys={sleepKeys}
              />
            </div>
            <div className={styles.labels}>
              {sleepKeys.map((key: SleepKeys) => (
                <PillarBreakdownBarLabel
                  key={key}
                  count={sleepBreakdownData[0][key]}
                  displayVals={pillarKeysToDisplay[key]}
                />
              ))}
            </div>
          </Card>
        </Col>
      </Row>
      <Row className={styles.strainRecoveryTrendGraphContainer}>
        <Col xs={12}>
          <Card
            id="Strain & Recovery"
            loading={loading}
            showBackground={false}
          >
            <CardHeader
              title="Strain & Recovery"
              details={formattedDateRange}
            />
            <div className={styles.strainRecoveryTrendGraph}>
              <StrainRecoveryTrendGraph
                data={trendData}
                daysInRange={getDaysInRange(dateRange.from, dateRange.to).length}
                daysToHighlight={daysToHighlight}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RangeView;
