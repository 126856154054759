import { UseStatePropType } from 'types/useStatePropType';

export type FormData = {
  email: string;
};

export enum PageState {
  Form,
  CheckEmail,
}

export type ForgotFormProps = {
  setPageState: (setPageState: PageState) => void;
  setEmail: UseStatePropType<string>;
};
