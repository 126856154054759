import React, { useState, useEffect, useMemo } from 'react';
import { cohortClient } from 'api';
import { Cohort } from 'types/cohort';
import { P1 } from 'components/text';
import {
  Cell, Column, Row, TableCommonProps,
} from 'react-table';
import Table from 'components/table';
import { Alert } from '@whoop/web-components';
import * as Sentry from '@sentry/react';
import { useAccounts } from 'context/account-context';
import styles from './groupList.module.scss';

type GroupListProps = {
  selectedGroup: Cohort;
  setSelectedGroup: (originalRow: Cohort) => void;
};

function GroupList({
  selectedGroup,
  setSelectedGroup,
}: GroupListProps) {
  const { account } = useAccounts();
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<Cohort[]>([]);
  const [error, setError] = useState<string>();

  const columns: Column<Cohort>[] = useMemo(
    () => [
      {
        Header: 'Group Name',
        id: 'name',
        accessor: 'name',
        width: '100%',
        align: 'left',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: function AccountCell(cellProps: Cell<Cohort>) {
          const { row } = cellProps;
          return <P1>{row.original.name}</P1>;
        },
      },
    ],
    [],
  );

  useEffect(() => {
    const getAllGroups = async () => {
      if (account.id) {
        setLoading(true);
        try {
          const data: Cohort[] = await cohortClient.getCohortsBySalesforceAccountId(
            false,
          );
          setGroups(data);
        } catch (err) {
          Sentry.captureException(err);
          setError('Error loading groups');
          setGroups([]);
        }
        setLoading(false);
      }
    };
    getAllGroups();
  }, [account]);

  const getRowClassName = (row: Row<Cohort>): TableCommonProps => ({
    className:
        selectedGroup?.id === row.original.id ? styles.selectedRow : '',
  });

  return (
    <>
      {error && (
        <Alert theme="default" type="error">
          {error}
        </Alert>
      )}
      <Table
        columns={columns}
        data={groups}
        initialSortByField="name"
        loading={loading}
        tableName="Groups List"
        heading="Groups"
        unit="group"
        searchPlaceholder="Search by group name"
        onRowClickHandler={setSelectedGroup}
        noDataFoundMessage="No groups found"
        getAdditionalRowProps={getRowClassName}
        tableLength={10}
      />
    </>
  );
}

export default GroupList;
