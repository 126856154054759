import { useNavigate } from 'react-router-dom';
import { Button } from '@whoop/web-components';
import SuccessIcon from 'assets/icons/checkGreen.svg';
import styles from '../resetPassword.module.scss';

function SuccessfulReset() {
  const navigate = useNavigate();

  return (
    <div className={styles.checkEmail}>
      <div className={styles.checkEmailInfoText}>
        <img className={styles.logo} src={SuccessIcon} alt="Success" />

        <h1>Password Updated</h1>
        <p>
          Your password has been successfully updated.
        </p>
      </div>
      <Button
        label="Return to Sign In"
        variant="normal"
        data-testid="backToLoginBtn"
        className={styles.checkEmailReturnToSignIn}
        onClick={() => navigate('/login')}
      />
    </div>
  );
}

export default SuccessfulReset;
