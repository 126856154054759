import { useEffect, useState } from 'react';
import GraphLabel from 'progress/summary/GraphLabel/graphLabel';
import SectionHeader from 'progress/summary/SectionHeader/sectionHeader';
import { N9 } from 'components/text';
import styles from 'progress/progressPage.module.scss';
import BarChart from 'progress/summary/BarChart/barChart';
import {
  GroupAverage, GroupAverages, Pillars, RecoveryMetric,
} from 'types/analytics';
import { analyticsClient } from 'api';
import { createEmptyAverageData, getAvgTrendValue, getAvgValue } from 'progress/progressPageUtils';
import componentStyles from './recoverySection.module.scss';

type RecoverySectionProps = {
  groupId: number | string;
};

function RecoverySection({ groupId }: RecoverySectionProps) {
  const [recoveryData, setRecoveryData] = useState<GroupAverage[]>();

  useEffect(() => {
    const getRecoveryData = async () => {
      const data: GroupAverages = await analyticsClient.getAveragesForGroup(
        groupId,
        RecoveryMetric.SCORE,
        Pillars.RECOVERY,
      );
      const averages: GroupAverage[] = data.cohort_averages;
      const getAveragesWithEmpty = createEmptyAverageData(averages, RecoveryMetric.SCORE);
      setRecoveryData(getAveragesWithEmpty);
    };
    getRecoveryData();
  }, [groupId]);

  if (!recoveryData) {
    return null;
  }

  return (
    <>
      <SectionHeader
        title="recovery 30-day average"
        details="Recovery is a measure of your body’s readiness to take on Strain and perform."
        avgTitle="today's avg. recovery"
        avgValue={getAvgValue(recoveryData)}
        avgTrend={getAvgTrendValue(recoveryData)}
        showAvg
      />
      <div className={styles.chart}>
        <BarChart data={recoveryData} tooltipDescription="Daily Avg. Recovery" />
      </div>
      <div className={styles.chartLabel}>
        <GraphLabel color="green" showSquare>
          Green
          <span className={componentStyles.recoveryLabelNums}>
            <N9>67%+</N9>
          </span>
        </GraphLabel>
        <GraphLabel color="yellow" showSquare>
          Yellow
          <span className={componentStyles.recoveryLabelNums}>
            <N9>34-66%+</N9>
          </span>
        </GraphLabel>
        <GraphLabel color="red" showSquare>
          Red
          <span className={componentStyles.recoveryLabelNums}>
            <N9>{'<33%'}</N9>
          </span>
        </GraphLabel>
      </div>
    </>
  );
}

export default RecoverySection;
