import {
  ReactChild, SyntheticEvent, useEffect, useState,
} from 'react';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import { UseStatePropType } from 'types/useStatePropType';
import useStyles from './toast.styles';

const setColor = (variant: string) => {
  switch (variant) {
    case 'error':
      return 'var(--whoop-color-red)';
    default:
      return 'var(--whoop-color-background-blue)';
  }
};

type ToastProps = {
  open: boolean;
  setOpen: UseStatePropType<boolean>;
  variant?: string;
  children: ReactChild;
};

function Toast({
  open, setOpen, variant, children,
} : ToastProps) {
  const classes = useStyles({ color: setColor(variant) });
  const [active, setActive] = useState(false);

  const handleClose = (_event: SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setActive(true);
      }, 1000);
      setTimeout(() => {
        setOpen(false);
      }, 9000);
    }
  });

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
      }}
    >
      <>
        <div className={classes.children}>{children}</div>
        <div className={`${classes.progress} ${!open && classes.hidden}`}>
          <div className={`${classes.bar} ${active && classes.open}`} />
        </div>
      </>
    </Snackbar>
  );
}

export default Toast;
