import { Colors, Icon } from '@whoop/web-components';
import ReactTooltip from 'react-tooltip';
import styles from './iconTooltip.module.scss';
import { MoreInfoText } from './iconTooltipTypes';

type IconTooltipProps = {
  name: string;
  moreInfoText: MoreInfoText;
};

function IconTooltip({ name, moreInfoText }: IconTooltipProps) {
  return (
    <div className={styles.infoTooltip} data-tip data-for={name}>
      <Icon className={styles.infoCompSize} name="info_circle" />
      <ReactTooltip id={name} place="bottom" backgroundColor={Colors.white} className={styles.tooltipContent}>
        <div className={styles.alignTextLeft}>
          <div className={styles.headerText}>
            {moreInfoText.header}
          </div>
          <div className={styles.detailsText}>
            {moreInfoText.details}
          </div>
        </div>
      </ReactTooltip>
    </div>

  );
}

export default IconTooltip;
