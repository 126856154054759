import Card from 'progress/components/Card/card';
import { getFormattedRangeDay } from 'progress/profile/profileUtils';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import {
  MetricType, METRIC_DISPLAY_NAMES, METRIC_TO_PILLAR_MAP, Pillars, StrainMetricType,
} from 'types/analytics';
import useMetric from 'api/hooks/useMetric';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import { createDayBarLineGraphData } from 'dataVisualizations/utils/utils';
import DayBarChart from 'dataVisualizations/dayBarChart/dayBarChart';
import DayLineChart from 'dataVisualizations/dayLineChart/dayLineChart';
import { BarData, LineData } from 'dataVisualizations/types/types';
import { METRIC_NUMBER_FORMATTER, METRIC_LABEL_FORMATTER } from 'progress/utils/numberFormatter';
import { Colors } from '@whoop/web-components';
import styles from './statisticTrendChart.module.scss';

// For pillar trend bar chart and line graphs
const PILLAR_TO_TREND_COLOR = {
  [Pillars.STRAIN]: Colors.strainBlue,
  [Pillars.RECOVERY]: '#67AEE6',
  [Pillars.SLEEP]: Colors.sleepBlue,
};

type StatisticTrendChartProps = {
  selectedMetricType: MetricType;
};

function StatisticTrendChart({ selectedMetricType }: StatisticTrendChartProps) {
  const { dateRange } = useDateRange();
  const detailDate = getFormattedRangeDay(dateRange);
  const pillar = METRIC_TO_PILLAR_MAP[selectedMetricType];
  const { data, isLoading } = useMetric(
    selectedMetricType,
    dateRange,
  );

  const numberFormatter = METRIC_NUMBER_FORMATTER[selectedMetricType];
  const labelFormatter = METRIC_LABEL_FORMATTER[selectedMetricType];
  const metricDisplayName = METRIC_DISPLAY_NAMES[selectedMetricType];
  const color = PILLAR_TO_TREND_COLOR[pillar];

  const maxValInput = selectedMetricType === StrainMetricType.STRAIN ? 21 : null;

  const { dataPoints, maxVal } = createDayBarLineGraphData(data, numberFormatter, maxValInput);

  return (
    <Card
      id={`${selectedMetricType}-trend`}
      loading={isLoading}
      showBackground={false}
    >
      <CardHeader
        title={METRIC_DISPLAY_NAMES[selectedMetricType]}
        details={detailDate}
      />
      <div className={styles.rangeChart}>
        {dataPoints?.length <= 7 && (
          <DayBarChart
            data={dataPoints as BarData[]}
            maxVal={maxVal}
            color={color}
            metricName={metricDisplayName}
            customLabelFormat={labelFormatter}
          />
        )}
        {dataPoints?.length > 7 && (
          <DayLineChart
            data={dataPoints as LineData[]}
            maxVal={maxVal}
            color={color}
            metricName={metricDisplayName}
            customLabelFormat={labelFormatter}
          />
        )}
      </div>
    </Card>
  );
}

export default StatisticTrendChart;
