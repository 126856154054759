import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';
import { MoreInfoText } from 'progress/components/IconTooltip/iconTooltipTypes';
import {
  Pillars, StrainMetricType,
} from 'types/analytics';
import { analyticsClient } from 'api';
import { GetMetricOverviewParams } from 'api/analyticsApi';

export type ActivityChip = {
  name: string;
  id: number;
  cycleId: number;
};

export const formatDate = (date?: Date | string) => (date ? dayjs(date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'));

export const getFormattedRangeDay = (dateRange: DateRange) => {
  if (dateRange.to) {
    const fromMonth = dayjs(dateRange.from).format('MMM');
    const toMonth = dayjs(dateRange.to).format('MMM');
    if (fromMonth !== toMonth) {
      return `${dayjs(dateRange.from).format('MMM D')} - ${dayjs(dateRange.to).format('MMM D')}`;
    }
    return `${dayjs(dateRange.from).format('MMM D')} - ${dayjs(dateRange.to).format('D')}`;
  }
  return dayjs(dateRange.from).format('MMM D');
};

export const strainMoreInfo: MoreInfoText = {
  header: 'What is Day Strain?',
  details: 'Day Strain measures the Strain you have accumulated over the course of an entire day. \n \n While individual workouts receive Strain Scores to indicate solitary levels of cardiovascular effort (for a discrete period of time), Day Strain provides you with a full picture of the Strain you are putting on your body each day.',
};

export const recoveryMoreInfo: MoreInfoText = {
  header: 'What is Recovery?',
  details: 'Recovery reflects how well prepared your body is to take on Strain, and is a measure of your body\'s "return to baseline" after a stressor. The size of these stressors - which can range from illness,  exercise, psychological stress or sleep deprivation - determines how much your body needs to recover.',
};

export const sleepMoreInfo: MoreInfoText = {
  header: 'What is Sleep Performance?',
  details: 'With WHOOP, you can accurately track your sleep each night. The Sleep section of the app provides in-depth sleep performance monitoring by tracking your time in bed, hours asleep, sleep need, sleep performance, wake events, sleep efficiency, and stages of sleep. Additionally, you receive daily optimal sleep recommendations based on your recent sleep performance.',
};

export const getMoreInfo = (pillar: Pillars) => {
  if (pillar === Pillars.STRAIN) { return strainMoreInfo; }
  if (pillar === Pillars.RECOVERY) { return recoveryMoreInfo; }
  if (pillar === Pillars.SLEEP) { return sleepMoreInfo; }
  return null;
};

export const getStrainMetric = async (userId: string, range: DateRange, type: StrainMetricType) => {
  const { getMetricOverview } = analyticsClient;
  const memberId = parseInt(userId, 10);
  const params: GetMetricOverviewParams = {
    memberId,
    startDate: formatDate(range.from),
  };
  if (range.to) {
    params.endDate = formatDate(range.to);
  }
  return getMetricOverview(params, Pillars.STRAIN, type);
};

export const convertToNumberLocale = (num: number) => {
  if (num) {
    return Math.round(num).toLocaleString('en-US');
  }
  return null;
};

export const daysBetweenRange = (range: DateRange) => dayjs(range.to).diff(dayjs(range.from), 'days', false) + 1;

export const isValidDateRange = (range: DateRange) => {
  const currentDate = dayjs().startOf('day').toDate();
  if (range.from) {
    if (range.to) {
      // DaysBetweenDates must either be 7 or 30 to be considered valid
      // any positive value it means we don't support this range
      // any negative value means range.from isAfter range.to
      const daysBetweenDates = daysBetweenRange(range);
      if (daysBetweenDates === 7 || daysBetweenDates === 30) {
        return true;
      }
      // daysBetweenDates is not 7 days or 30 days
      // Since invalid set date as current day
      return false;
    }
    // single date
    // range.from must be before the currentDate
    if (dayjs(range.from).isBefore(dayjs(currentDate))) {
      return true;
    }
  }

  return false;
};
