import React, { useEffect, useState } from 'react';
import ViewMemberProfile from 'hub/memberProfilePage/viewMemberProfile/viewMemberProfile';
import EditMemberProfile from 'hub/memberProfilePage/editMemberProfile/editMemberProfile';
import Loading from 'loading';
import { HubUserProfile } from 'types/hub';
import { hubClient } from 'api';
import { useParams } from 'react-router-dom';
import PulsingLogo from 'components/spinner';
import { HubUserProfileReducerState, parseHubData } from './editMemberProfile/utils';
import styles from './memberProfilePage.module.scss';
import ActivateSensorWizard from '../../wizards/hub/activateSensorWizard/activateSensorWizard';

type Params = {
  seatId: string;
  accountId: string;
};

type MemberProfilePageProps = {
  activateSensor: boolean;
  addMember?: boolean;
};

function MemberProfilePage({ activateSensor, addMember = false }: MemberProfilePageProps) {
  const [profileData, setProfileData] = useState<HubUserProfileReducerState>();
  const [loading, setLoading] = useState(false);
  const { accountId, seatId } = useParams<Params>();
  const [editProfile, setEditProfile] = useState(false);

  useEffect(() => {
    const getProfileDetails = async () => {
      try {
        if (addMember) { return; }
        setLoading(true);
        const data: HubUserProfile = await hubClient.getHubUserAccountDetails(seatId);
        const parsedData: HubUserProfileReducerState = parseHubData(data);
        setProfileData(parsedData);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    getProfileDetails();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className={styles.contentContainer}>
      {loading && <PulsingLogo overlay />}
      {profileData && activateSensor && (
        <ActivateSensorWizard
          sensorId={profileData.sensorId}
          accountId={accountId}
          seatId={seatId}
          addMember={addMember}
        />
      )}
      {addMember && (
        <ActivateSensorWizard
          accountId={accountId}
          addMember={addMember}
        />
      )}
      {!activateSensor && editProfile && (
        <EditMemberProfile
          profile={profileData}
          setEditProfile={setEditProfile}
          setProfileData={setProfileData}
          seatId={seatId}
        />
      )}
      {!activateSensor && !editProfile && (
        <ViewMemberProfile
          setEditProfile={setEditProfile}
          profile={profileData}
          showEditProfileButton
        />
      )}
    </div>
  );
}

export default MemberProfilePage;
