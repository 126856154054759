import {
  BreakdownDTO,
  BreakdownMetricType,
  getCaloriesBreakdown,
  getStrainBreakdown,
  getWorkoutCountBreakdown,
} from 'api/breakdownApi';
import { getGroupMembers } from 'api/cohortApi';
import TeamAvatar from 'assets/icons/TeamAvatar.svg';
import { Column, TableState } from 'react-table';
import { breakdownTableNameWithAvatarCell } from 'tableUtils/tableCells';
import { GroupMember } from 'types/cohort';
import { PrivacyLevel } from 'types/dashboardUser';
import { roundedNumber } from './cells';

export interface StrainBreakdownData {
  user_id?: number;
  first_name: string;
  last_name: string;
  avatar_url?: string;
  member_identifier?: string;
  strain?: number;
  calories?: number;
  workouts?: number;
}

type ColumnName = 'name' | 'strain' | 'calories' | 'workouts';

const columns: Record<ColumnName, Column<StrainBreakdownData>> = {
  name: {
    Header: 'Name',
    accessor: (data) => (data.first_name + data.last_name).toUpperCase(),
    id: 'name',
    Cell: breakdownTableNameWithAvatarCell,
  },
  strain: {
    Header: 'Strain',
    accessor: 'strain',
    Cell: ({ value }: { value?: number }) => (value ? Math.round(value * 10) / 10 : null),
  },
  calories: {
    Header: 'Calories',
    accessor: 'calories',
    Cell: roundedNumber,
  },
  workouts: {
    Header: 'Workouts',
    accessor: 'workouts',
    Cell: ({ value }: { value?: number }) => (value ? Math.round(value * 100) / 100 : null),
  },
};

export function getStrainTableColumns(privacyLevel: PrivacyLevel) {
  switch (privacyLevel) {
    case PrivacyLevel.all_metrics:
    case PrivacyLevel.performance_metrics:
      return [
        columns.name,
        columns.strain,
        columns.calories,
        columns.workouts,
      ];
    case PrivacyLevel.primary_metrics:
      return [
        columns.name,
        columns.strain,
      ];
    default:
      return [];
  }
}

function createMap<T extends BreakdownMetricType>(dto: BreakdownDTO<T>) {
  return dto.user_data.reduce(
    (map, data) => map.set(data.user_id, data.data_value),
    new Map<number, number | null>(),
  );
}

function createStrainBreakdownData(groupMembers: GroupMember[], strain: BreakdownDTO<'dayStrain'>, calories?: BreakdownDTO<'calories'>, workouts?: BreakdownDTO<'workoutCount'>) {
  if (groupMembers.length < 1) {
    return [];
  }

  const groupAverage: StrainBreakdownData = {
    first_name: 'Team',
    last_name: 'Average',
    avatar_url: TeamAvatar,
    strain: strain.average,
    calories: calories?.average,
    workouts: workouts?.average,
  };

  /**
   * Create maps of userId -> metric
   * This allows us to join the data together efficiently
   */
  const strainMap = createMap(strain);
  const caloriesMap = calories ? createMap(calories) : null;
  const workoutCountMap = workouts ? createMap(workouts) : null;

  const userRows: StrainBreakdownData[] = groupMembers.map((m) => ({
    user_id: m.user_id,
    first_name: m.first_name,
    last_name: m.last_name,
    avatar_url: m.avatar_url,
    member_identifier: m.member_identifier,
    strain: strainMap.get(m.user_id),
    calories: caloriesMap?.get(m.user_id),
    workouts: workoutCountMap?.get(m.user_id),
  }));

  return [groupAverage, ...userRows];
}

export async function getStrainData(
  groupId: number,
  start: Date,
  end: Date,
  privacyLevel: PrivacyLevel,
) {
  switch (privacyLevel) {
    case PrivacyLevel.all_metrics:
    case PrivacyLevel.performance_metrics: {
      const metrics = await Promise.all([
        getGroupMembers(groupId),
        getStrainBreakdown(groupId, start, end),
        getCaloriesBreakdown(groupId, start, end),
        getWorkoutCountBreakdown(groupId, start, end),
      ]);

      return createStrainBreakdownData(...metrics);
    }
    case PrivacyLevel.primary_metrics: {
      const metrics = await Promise.all([
        getGroupMembers(groupId),
        getStrainBreakdown(groupId, start, end),
      ]);

      return createStrainBreakdownData(...metrics);
    }
    default:
      return [];
  }
}

export const initialStrainTableState: Partial<TableState> = {
  sortBy: [{ id: 'strain', desc: true }],
};
