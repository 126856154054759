import { client } from 'api/apiClient';
import {
  AcHubApp,
  AddHubMemberRequest,
  Hub, HubEgg, HubListPayload, HubUser, HubUserProfile,
} from 'types/hub';

export const getHubUserAccountDetails = async (seatId: string) => {
  const params = { seatId };
  const response = await client.get<HubUserProfile>(
    '/enterprise-service/v1/hub/user/account',
    { params },
  );

  return response.data;
};

export const createHub = async (hubEgg: HubEgg) => {
  const response = await client.post<Hub>('/enterprise-service/v1/hub', hubEgg);

  return response.data;
};

export const getHubsBySalesforceAccountId = async () => {
  const response = await client.get<Hub[]>('/enterprise-service/v1/hub');
  return response.data;
};

export const getApps = async (filter: String) => {
  const params = { filter };
  const response = await client.get<AcHubApp[]>('/enterprise-service/v1/ac/apps', { params });
  return response.data;
};

export const deployApp = async (app: AcHubApp, hubMacAddresses: string[]) => {
  const response = await client.post(`/enterprise-service/v1/ac/apps/${app.name}/${app.version}/install`, { mac_addresses: hubMacAddresses });
  return response.data;
};

export const getHubsInfoBySalesforceAccountId = async () => {
  const response = await client.get<HubListPayload[]>('/enterprise-service/v1/hub/info');
  return response.data;
};

export const getHubUserInformation = async (macAddress: string) => {
  const response = await client.get<HubUser[]>(`/enterprise-service/v1/hub/${macAddress}/users`);
  return response.data;
};

export const updateHub = async (macAddress: string, body: Hub) => {
  const response = await client.put(`/enterprise-service/v1/hub/${macAddress}`, body);
  return response.data;
};

export const addHubMember = async (hubMember: AddHubMemberRequest) => {
  const response = await client.post('/enterprise-service/v1/hub/member', hubMember);
  return response.data;
};

export const editHubUserAccountDetails = async (seatId: string, updateProfile: HubUserProfile) => {
  const params = { seatId };
  const response = await client.put('/enterprise-service/v1/hub/user/account', updateProfile, { params });
  return response.data;
};

export const resetHubAccountPassword = async (seatId: string, newPassword: string) => {
  const params = { seatId };
  const response = await client.post('enterprise-service/v1/hub/password/reset', { new_password: newPassword }, { params });
  return response.data;
};

export const archiveHubMember = async (seatId: number) => {
  const params = { seatId };
  const response = await client.post('enterprise-service/v1/hub/user/archive', {}, { params });
  return response.data;
};
