import { CSMUser, InviteStatus } from 'types/collaborator';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';

export const convertToCollaborator = (csm: CSMUser) => ({
  name: `${csm.first_name} ${csm.last_name}`,
  user_id: csm.user_id,
  email: csm.email,
  avatar_url: csm.avatar_url,
  status: InviteStatus.accepted,
  role: DashboardRole.WHOOP_CSM,
  privacy_level: PrivacyLevel.all_metrics,
});

export default convertToCollaborator;
