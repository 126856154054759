export enum ActivateSensorSteps {
  RESET_PASSWORD = 'reset_password',
  SET_PASSWORD = 'set_password',
  CREATE_PROFILE = 'create_profile',
  REVIEW = 'review',
  ERROR = 'error',
}

const reviewDetails = {
  number: 3,
  title: 'Review',
  description: 'Please review and then select ‘Save’',
};

export const StepLabels = {
  [ActivateSensorSteps.RESET_PASSWORD]: {
    number: 1,
    title: 'Reset Password',
    description: 'The old sensor will be returned to your account. Please select the following options to confirm.',
  },
  [ActivateSensorSteps.SET_PASSWORD]: {
    number: 1,
    title: 'Set Password',
    description: 'Please set a password for the new account',
  },
  [ActivateSensorSteps.CREATE_PROFILE]: {
    number: 2,
    title: 'Create Profile',
    description: 'Please fill out the fields below and then select ‘Next’',
  },
  [ActivateSensorSteps.REVIEW]: reviewDetails,
  [ActivateSensorSteps.ERROR]: reviewDetails,
};

export type ActivateSensorReducerState = {
  step: ActivateSensorSteps;
  error: Error;
  hubs: string[];
  sensorIds: string[];
  firstName: string;
  lastName: string;
  username: string;
  sensorId: string;
  dateOfBirth: string;
  gender: string;
  height: string;
  weight: string;
  hub: string;
  email: string;
  patientIdNumber: string;
  newPassword: string;
  confirmedPassword: string;
  loading: boolean;
  passwordErrorMessage: string;
};

export type ActivateSensorReducerAction =
    | { type: 'next' }
    | { type: 'previous' }
    | { type: 'loading'; value: boolean }
    | { type: 'handleInputText'; field: string; value: string }
    | { type: 'error'; payload: Error }
    | { type: 'done' };

export const activateSensorReducer = (
  state: ActivateSensorReducerState,
  action: ActivateSensorReducerAction,
): ActivateSensorReducerState => {
  switch (action.type) {
    case 'next':
      if (state.step === ActivateSensorSteps.RESET_PASSWORD
        || state.step === ActivateSensorSteps.SET_PASSWORD) {
        if (state.newPassword !== state.confirmedPassword) {
          return {
            ...state,
            passwordErrorMessage: 'The passwords do not match',
          };
        }
        if (state.newPassword.length < 8) {
          return {
            ...state,
            passwordErrorMessage: 'The password must be at least 8 characters long',
          };
        }
        if (state.newPassword === state.confirmedPassword) {
          return {
            ...state,
            step: ActivateSensorSteps.CREATE_PROFILE,
            passwordErrorMessage: '',
          };
        }

        return {
          ...state,
          error: null,
          passwordErrorMessage: null,
        };
      }
      if (state.step === ActivateSensorSteps.CREATE_PROFILE) {
        return {
          ...state,
          error: null,
          step: ActivateSensorSteps.REVIEW,
        };
      }

      return state;
    case 'previous':
      if (state.step === ActivateSensorSteps.CREATE_PROFILE) {
        return {
          ...state,
          step: ActivateSensorSteps.RESET_PASSWORD,
        };
      }
      if (state.step === ActivateSensorSteps.REVIEW
        || state.step === ActivateSensorSteps.ERROR) {
        return {
          ...state,
          step: ActivateSensorSteps.CREATE_PROFILE,
        };
      }

      return state;
    case 'handleInputText':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'error':
      return {
        ...state,
        error: action.payload,
        step: ActivateSensorSteps.ERROR,
      };
    case 'loading':
      return {
        ...state,
        loading: action.value,
      };
    default:
      return state;
  }
};
