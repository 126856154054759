import React, { useEffect, useState } from 'react';
import StickyFooter from 'components/stickyFooter';
import { TITLE_3, BODY_SHORT_1 } from 'components/text';
import { Button } from '@whoop/web-components';
import { useNavigate, useParams } from 'react-router-dom';
import { resetAccount } from 'api/accountApi';
import { AccountDetailsParams } from 'accountDetails/types';
import { useAccounts } from 'context/account-context';
import { useToast } from 'context/toast-context';
import { DashboardRole } from 'types/dashboardUser';
import Loading from 'loading';
import styles from './resetAccount.module.scss';

enum SecondaryText {
  INVITED_PENDING_ERROR = 'invited_pending_error',
  HUB_ENABLED_ERROR = 'hub_enabled_error',
  CONCEALED_ERROR = 'concealed_error',
  NO_ERROR = 'no_error',
}

function ResetAccount() {
  const [awaitingAccountReset, setAwaitingAccountReset] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [secondaryText, setSecondaryText] = useState<SecondaryText>(SecondaryText.NO_ERROR);
  const { checkAccess, updateAccount } = useAccounts();
  const { accountId } = useParams<AccountDetailsParams>();
  const { openToast } = useToast();
  const navigate = useNavigate();

  const errorMessageType = (secondaryTextType: SecondaryText) => {
    if (secondaryTextType === SecondaryText.CONCEALED_ERROR) {
      return (
        <BODY_SHORT_1>
          Conceal mode is turned on for this account.
          Please turn off the conceal mode in the Settings page before you reset this account.
        </BODY_SHORT_1>
      );
    } if (secondaryTextType === SecondaryText.HUB_ENABLED_ERROR) {
      return (
        <BODY_SHORT_1>
          Bluetooth Hub is enabled on this account.
          Please turn off the Bluetooth Hub in the Settings page before you reset this account.
        </BODY_SHORT_1>
      );
    } if (secondaryTextType === SecondaryText.INVITED_PENDING_ERROR) {
      return (
        <BODY_SHORT_1>
          There are invited and/or pending members on this account.
          Please remove these members in the People tab before you reset this account.
        </BODY_SHORT_1>
      );
    }
    return (
      <>
        <BODY_SHORT_1>Resetting this account will</BODY_SHORT_1>
        <ul>
          <li>Delete all members on this account</li>
          <li>Delete all groups on this account</li>
        </ul>
      </>
    );
  };

  useEffect(() => {
    const init = async () => {
      if (!checkAccess(DashboardRole.WHOOP_CSM)) {
        navigate(`/accounts/${accountId}/plan/summary`);
      }
    };

    init();
  }, [accountId]);

  const handleCancelButton = () => {
    navigate(`/accounts/${accountId}/plan/settings`);
  };

  const handleConfirmResetButton = async () => {
    setHasError(false);
    setSecondaryText(SecondaryText.NO_ERROR);
    if (!awaitingAccountReset) {
      setAwaitingAccountReset(true);
      try {
        await resetAccount(accountId);
        updateAccount();
        navigate(`/accounts/${accountId}/plan/settings`);
        openToast('Successfully reset account');
      } catch (e) {
        if (e.response.status === 400) {
          setHasError(true);
          const message = e.response.data;
          if (message.includes('Cannot reset hub enabled accounts')) {
            setSecondaryText(SecondaryText.HUB_ENABLED_ERROR);
          } else if (message.includes('Cannot reset concealed accounts')) {
            setSecondaryText(SecondaryText.CONCEALED_ERROR);
          } else if (message.includes('Cannot reset account because there are invited and/or pending seats')) {
            setSecondaryText(SecondaryText.INVITED_PENDING_ERROR);
          }
        } else {
          openToast('Unable to reset account. Please try again.');
        }
      }
      setAwaitingAccountReset(false);
    }
  };

  const confirmResetButton = (
    <Button
      className={styles.button}
      label="Yes, Reset Account"
      name="confirm_reset_account"
      size="small"
      variant="primary"
      onClick={handleConfirmResetButton}
    />
  );

  if (awaitingAccountReset) {
    return <Loading />;
  }

  return (
    <div className={styles.fullPageModal}>
      <div className={styles.modalContent}>
        <TITLE_3>{hasError ? 'Cannot reset account.' : 'Are you sure you want to reset this account?'}</TITLE_3>
        {errorMessageType(secondaryText)}
      </div>

      <StickyFooter>
        <div className={styles.modalButtons}>
          <Button label="Cancel" onClick={handleCancelButton} variant="link" />
          <div className={styles.resetButton}>{confirmResetButton}</div>
        </div>
      </StickyFooter>
    </div>
  );
}

export default ResetAccount;
