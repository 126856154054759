import { RadioToggle } from '@whoop/web-components';
import { useAccounts } from 'context/account-context';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './peoplePageToggle.module.scss';

enum PeoplePageOptions {
  MEMBERS = 'members',
  GROUPS = 'groups',
  HUBS = 'hub',
}

function PeoplePageToggle() {
  const { account, accountConfig } = useAccounts();
  const navigate = useNavigate();
  const location = useLocation();

  const baseOptions = [{
    label: 'Members',
    value: PeoplePageOptions.MEMBERS,
  },
  {
    label: 'Groups',
    value: PeoplePageOptions.GROUPS,
  }];

  const hubOption = {
    label: 'Hubs',
    value: PeoplePageOptions.HUBS,
  };

  let pageSelected = PeoplePageOptions.MEMBERS;
  const splitPathname = location.pathname.split('/');
  if (splitPathname.length > 4) {
    pageSelected = splitPathname[4] as PeoplePageOptions;
  }

  const handlePageSelection = (page: PeoplePageOptions) => {
    navigate(`/accounts/${account.id}/people/${page}`);
  };

  return (
    <RadioToggle
      options={accountConfig?.hub_enabled ? [...baseOptions, hubOption] : baseOptions}
      value={pageSelected}
      onChange={handlePageSelection}
      className={styles.pageSelector}
      size="small"
    />
  );
}

export default PeoplePageToggle;
