import { getDelayVal, mswBaseUri } from 'mocks/utils/utils';
import {
  getHubs,
  getHubsInfo,
  getHubsUsers,
  archiveHubMember,
  getHubMemberProfile,
  setHubMemberProfile,
  createHub,
  updateHub,
  addHubMember,
  getApps,
} from 'mocks/actions/hubActions';
import { rest } from 'msw';
import { jsonCheckForPII } from '../transformer/deidentifyTransformer';

export const hubsHandlers = [
  rest.get(mswBaseUri('v1/hub'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(getHubs()),
  )),
  rest.post(mswBaseUri('v1/hub/member'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(204),
    ctx.json(addHubMember()),
  )),
  rest.get(mswBaseUri('v1/ac/apps'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(204),
    ctx.json(getApps()),
  )),
  rest.get(mswBaseUri('v1/hub/info'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(getHubsInfo()),
  )),
  rest.get(mswBaseUri('v1/hub/:mac_address/users'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(getHubsUsers()),
  )),
  rest.put(mswBaseUri('v1/hub/:mac_address'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(updateHub(req.body)),
  )),
  rest.post(mswBaseUri('v1/hub/user/archive'), (req, res, ctx) => {
    const seatId = req.url.searchParams.get('seatId');
    archiveHubMember(seatId);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(204),
    );
  }),
  rest.post(mswBaseUri('v1/hub'), async (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(204),
    ctx.json(createHub(await req.json())),
  )),
  rest.get(mswBaseUri('v1/hub/user/account'), (req, res) => res(
    jsonCheckForPII(getHubMemberProfile()),
  )),
  rest.put(mswBaseUri('v1/hub/user/account'), async (req, res, ctx) => {
    setHubMemberProfile(await req.json());
    return res(
      ctx.delay(getDelayVal(100)),
      ctx.status(204),
    );
  }),
];

export default hubsHandlers;
