import Dropdown from 'components/dropdown';
import { HEADING_4 } from 'components/text';
import { Icon } from '@whoop/web-components';
import { MenuItem, MenuList } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import styles from './rowsPerPageSelect.module.scss';

export const initialPageSizes: number[] = [
  5,
  10,
  20,
  25,
  50,
  100,
];

type RowsPerPageSelectProps = {
  pageSizeOptions?: number[];
  selectedOption: number;
  onOptionClick: (row: number) => void;
};

function RowsPerPageSelect({
  pageSizeOptions = initialPageSizes,
  selectedOption,
  onOptionClick,
}: RowsPerPageSelectProps) {
  return (
    <Dropdown
      button={(
        <div className={styles.rowsPerPage}>
          <HEADING_4>
            {`Rows Per Page: ${selectedOption}`}
          </HEADING_4>
          <Icon name="triangle_down" className={styles.logo} />
        </div>
    )}
      buttonProps={{ variant: 'link', className: styles.dropdownBtn }}
      dropdownStyles={{
        borderTop: '3px solid black',
        width: '120px',
        minWidth: '120px',
        transform: 'translate(-44px)',
      }}
      closeOnClick
    >
      <div className={styles.numberInput}>
        <MenuList disablePadding>
          {pageSizeOptions.map((item) => (
            <MenuItem
              data-testid={`menu-item-${item}`}
              key={`menu-item-${item}`}
              className={
          item === selectedOption
            ? styles.activeItem
            : styles.inactiveItem
        }
              onClick={() => onOptionClick(item)}
            >
              <div className={styles.checkContainer}>
                {item === selectedOption ? <CheckIcon /> : ''}
              </div>
              <p className={`${styles.itemLabel}`}>{item}</p>
            </MenuItem>
          ))}
        </MenuList>
      </div>
    </Dropdown>
  );
}

export default RowsPerPageSelect;
