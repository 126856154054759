import Dropdown from 'components/dropdown';
import DropdownList from 'components/dropdown/dropdownList/index';
import { useAuth } from 'context/auth-context';
import { useNavigate } from 'react-router-dom';
import { useAccounts } from 'context/account-context';
import CheckIcon from '@material-ui/icons/Check';
import { AccountDetail } from 'types/account';
import { reidentificationTokenKey } from 'types/admin';
import Avatar from './avatar';
import styles from './profileMenu.module.scss';

function ProfileMenu() {
  const { user, logout } = useAuth();
  const { accounts, account } = useAccounts();
  const navigate = useNavigate();

  const userDropdownItems = [
    {
      name: 'Sign out',
      onClick: logout,
      dataTestId: 'sign-out-btn',
      active: false,
    },
  ];

  const allAccountsItem = {
    name: 'All Accounts',
    onClick: () => {
      localStorage.removeItem(reidentificationTokenKey);
      navigate('/accounts');
    },
    dataTestId: 'admin-back-btn',
    active: false,
  };

  const filterOptions = () => accounts.map((a: AccountDetail) => ({
    name: a.account_name,
    onClick: () => navigate(`/accounts/${a.id}`),
    dataTestId: `account-btn-${a.account_name}`,
    active: a.id === account.id,
  }));

  if (user.admin) {
    userDropdownItems.push(allAccountsItem);
  } else if (account && filterOptions().length > 1) {
    userDropdownItems.push(...filterOptions());
  }

  return (
    <Dropdown
      button={(
        <div className={styles.buttonContainer} data-testid="user-profile-btn">
          <Avatar src={user.avatar_url} />
        </div>
      )}
      buttonProps={{
        size: 'small',
        variant: 'link',
        'aria-label': 'profile-menu',
      }}
    >
      <DropdownList items={userDropdownItems} activeIcon={<CheckIcon />} />
    </Dropdown>
  );
}

export default ProfileMenu;
