import { BODY_SHORT_1 } from 'components/text';
import Toast from 'components/toast';
import React, {
  createContext, ReactChild, useContext, useMemo, useState,
} from 'react';

export const ToastContext = createContext({
  openToast: null,
});

type ToastProviderPropTypes = {
  children: ReactChild[] | ReactChild;
};

export function ToastProvider({ children }: ToastProviderPropTypes) {
  const [toastOpen, setToastOpen] = useState(false);
  const [message, setMessage] = useState('');

  const openToast = (msg: string) => {
    setMessage(msg);
    setToastOpen(true);
  };

  const value = useMemo(() => ({ openToast }), [openToast]);

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Toast open={toastOpen} setOpen={setToastOpen}>
        <BODY_SHORT_1>{message}</BODY_SHORT_1>
      </Toast>
    </ToastContext.Provider>
  );
}

export const useToast = () => useContext(ToastContext);
