import { useState, useMemo, useEffect } from 'react';
import {
  Datum, PointTooltipProps, ResponsiveLine, Serie,
} from '@nivo/line';
import { Colors } from '@whoop/web-components';
import { AxisTickProps } from '@nivo/axes';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ScaleSpec } from '@nivo/scales';
import { CartesianMarkerProps } from '@nivo/core';
import { labelXAxis, PercentOfMaxAxis } from 'dataVisualizations/axis/axis';
import { AreaLayer } from 'dataVisualizations/layers/layers';
import { CustomHoverPoint, MaxHeartRatePoint } from 'dataVisualizations/points/points';
import { HeartRateToolTip } from 'dataVisualizations/tooltips/tooltips';
import { formatData, getTimeStepFromMin } from 'dataVisualizations/utils/utils';
import { getXAxisTickMark } from 'dataVisualizations/axis/utils';
import { commonProps, getTimeAxisProps, rawHeartRateAxisProps } from './heartRateLineGraphConsts';
import { ActivityDTO, XAxis, YAxis } from './hrLineGraphTypes';

type HeartRateLineGraphProps = {
  hrData: ActivityDTO;
};

function HeartRateLineGraph({ hrData }: HeartRateLineGraphProps) {
  const durationStep = 20;
  // set timestep based on length of data
  const approxNumMin = Math.floor(hrData.metrics.length / 10);
  const timeStep = getTimeStepFromMin(approxNumMin);
  const yAxisType = YAxis.rawHeartRate;
  const xAxisType = XAxis.time;
  const showMaxHr = false;
  const showAvgHr = false;
  const [avgHr, setAvgHr] = useState(null);
  const [maxActivityHr, setMaxActivityHr] = useState(null);
  // any for now till I get real data to type
  const [data, setData] = useState<Serie>(null);
  const [xTickDuration, setXTickDuration] = useState<number[]>([]);
  const [xTickTime, setXTickTime] = useState<number[]>([]);
  // User max heart rate WIP don't know how the data will be formated
  const maxHr = hrData.max_heart_rate;

  const getAvgHr = (hrDataGraphData: Datum[]) => Math.round(
    hrDataGraphData.reduce((total: number, next: Datum) => total + Number(next.y), 0)
    / hrDataGraphData.length,
  );

  useEffect(() => {
    const graphData = formatData(hrData, yAxisType, maxHr);
    setData(graphData);
    const [timeTickMark, durationTickMark] = getXAxisTickMark(
      graphData,
      timeStep,
      durationStep,
    );
    setXTickTime(timeTickMark);
    setXTickDuration(durationTickMark);
    setAvgHr(getAvgHr(graphData.data));
    setMaxActivityHr(Math.max(...graphData.data.map((o: Datum) => Number(o.y))));
  }, [hrData, yAxisType]);

  const percentOfMaxAxis = [0, 50, 60, 70, 80, 90, 100];

  const layers: any = useMemo(() => {
    const baseLayers = [
      ...yAxisType === YAxis.rawHeartRate ? ['areas'] : [AreaLayer],
      'grid',
      'axes',
      'crosshair',
      'lines',
      CustomHoverPoint,
      'slices',
      'mesh',
      'legends',
    ];
    if (showAvgHr) baseLayers.push('markers');
    if (showMaxHr) baseLayers.push('points');

    return baseLayers;
  }, [yAxisType, showAvgHr, showMaxHr]);

  const graphCommonProps = {
    data: [data],
    colors: Colors.strainBlue,
    markers: [
      {
        axis: 'y',
        value: avgHr,
        lineStyle: { stroke: Colors.brandingRed, strokeWidth: 2 },
      },
    ] as CartesianMarkerProps[],
    pointSymbol: (pointSymbol: any) => MaxHeartRatePoint(pointSymbol, maxActivityHr),
    tooltip: (tooltip: PointTooltipProps) => HeartRateToolTip(
      tooltip,
      data.data.length,
    ),
  };

  const timeAxisProps = getTimeAxisProps(xTickTime);

  const durationAxisProps = {
    gridXValues: xTickDuration,
    axisBottom: {
      renderTick: (tick: AxisTickProps<string>) => labelXAxis(
        tick,
        XAxis.duration,
        xTickDuration,
      ),
    },
  };

  const percentOfMaxAxisProps = {
    yScale: {
      type: 'linear',
      min: 0,
      max: 100,
    } as ScaleSpec,
    gridYValues: percentOfMaxAxis,
    axisLeft: {
      tickValues: percentOfMaxAxis,
      renderTick: (tick: AxisTickProps<string>) => PercentOfMaxAxis(tick),
    },
  };

  return (
    <ResponsiveLine
      {...commonProps}
      {...graphCommonProps}
      {...(yAxisType === YAxis.rawHeartRate ? rawHeartRateAxisProps : percentOfMaxAxisProps)}
      {...(xAxisType === XAxis.time ? timeAxisProps : durationAxisProps)}
      layers={layers}
      margin={
        {
          top: 10, right: 10, bottom: 50, left: 36,
        }
      }
    />
  );
}

export default HeartRateLineGraph;
