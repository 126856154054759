import {
  HEADING_2,
  SUBTITLE_3,
  TITLE_3,
} from 'components/text';
import ActionContent from 'components/actionContent/actionContent';
import React, { useCallback, useEffect, useState } from 'react';
import {
  addCollaboratorsToGroup,
  createToastMessage,
  getActionButtonProps,
  getSecondaryButtonProps,
  ToastType,
} from 'groups/utils';
import { cohortClient } from 'api';
import { useNavigate, useParams } from 'react-router-dom';
import { GroupPagesParamsType } from 'types/utils';
import { CohortCollaborator } from 'types/cohort';
import Loading from 'loading';
import ActionFooter from 'components/actionFooter/actionFooter';
import { useToast } from 'context/toast-context';
import styles from '../addPage.module.scss';
import AddCollaboratorsSearch from './components/addCollaboratorsSearch';

function AddCollaboratorsPage() {
  const { accountId, groupId } = useParams<GroupPagesParamsType>();
  const { openToast } = useToast();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [availableCollaborators, setAvailableCollaborators] = useState<CohortCollaborator[]>([]);
  const [selectedCollaborators, setSelectedCollaborators] = useState<CohortCollaborator[]>([]);

  const fetchCollaborators = useCallback(async () => {
    const collabs = await cohortClient.getAvailableCollaborators(Number(groupId));

    setAvailableCollaborators(collabs);
  }, [groupId]);

  useEffect(() => {
    setLoading(true);
    const init = async () => {
      await fetchCollaborators();
      setLoading(false);
    };

    init();
  }, []);

  const handleAddCollaborators = async () => {
    const collabUserIds = selectedCollaborators.map((collab) => collab.user_id);
    const response = await addCollaboratorsToGroup(Number(groupId), collabUserIds);
    openToast(createToastMessage(
      response.fulfilled,
      response.failed,
      'group',
      ToastType.ADD_COLLABORATOR,
    ));
    navigate(`/accounts/${accountId}/people/groups/${groupId}`);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.container}>
        <ActionContent>
          <TITLE_3 className={styles.title}>Add collaborators to this group</TITLE_3>
          <HEADING_2 className={styles.heading}>
            Add collaborators
          </HEADING_2>
          <SUBTITLE_3 className={styles.subtitle}>
            Collaborators can be added to more than one group.
          </SUBTITLE_3>
          <AddCollaboratorsSearch
            availableCollaborators={availableCollaborators}
            loading={loading}
            selectedCollaborators={selectedCollaborators}
            setSelectedCollaborators={setSelectedCollaborators}
          />
        </ActionContent>
      </div>
      <ActionFooter
        getPrimaryButtonProps={() => getActionButtonProps(
          selectedCollaborators,
          handleAddCollaborators,
        )}
        getSecondaryButtonProps={() => getSecondaryButtonProps(() => navigate(`/accounts/${accountId}/people/groups/${groupId}`))}
      />
    </>
  );
}

export default AddCollaboratorsPage;
