import { client } from 'api/apiClient';
import { AxiosResponse } from 'axios';
import { Collaborator, CSMUser } from 'types/collaborator';
import { DashboardUser } from 'types/dashboardUser';
import { WhoopRole } from 'types/whoopRole';

export const getAccessForUser = async (
  dashboardUserId: string,
): Promise<DashboardUser> => {
  const results: AxiosResponse<DashboardUser> = await client.get(
    `/enterprise-service/v1/dashboard?dashboardUserId=${dashboardUserId}`,
  );
  return results.data;
};

export const getAllCollaborators = async (): Promise<Collaborator[]> => {
  const results: AxiosResponse<Collaborator[]> = await client.get(
    '/enterprise-service/v1/dashboard/collaborators',
  );
  return results.data;
};

export const updateDashboardUserRoles = async (
  user: Collaborator,
  salesforce_account_id?: string,
  dashboard_role?: WhoopRole,
  is_primary_owner?: boolean,
): Promise<DashboardUser> => {
  const dashboardUser: DashboardUser = {
    display_role: user.display_role,
    dashboard_role,
    is_primary_owner,
    user_id: user.user_id,
    salesforce_account_id,
    privacy_level: user.privacy_level,
    role: user.role,
  };

  const results: AxiosResponse<DashboardUser> = await client.put<DashboardUser>(
    '/enterprise-service/v1/dashboard/permissions',
    dashboardUser,
  );
  return results.data;
};

export const removeCollaboratorFromAccount = async (
  collaboratorUserId: string,
) => {
  const params = {
    collaboratorUserId,
  };
  await client.delete<void>('/enterprise-service/v1/dashboard', { params });
};

export const getAllCSMs = async () => {
  const result = await client.get<CSMUser[]>('/enterprise-service/v1/csm');
  return result.data;
};

export const addDashboardAccess = async (dashboardUser: DashboardUser) => {
  const result = await client.post<DashboardUser>('/enterprise-service/v1/dashboard/permissions', dashboardUser);
  return result.data;
};
