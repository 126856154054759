import { Icon } from '@whoop/web-components';
import { useAsyncDebounce } from 'react-table';
import { UseStatePropType } from 'types/useStatePropType';
import styles from './searchBar.module.scss';

type SearchBarProps = {
  placeholder: string;
  setSearchTerm: UseStatePropType<string>;
  value: string;
  setValue: UseStatePropType<string>;
  hasBorder?: boolean;
};

function SearchBar({
  placeholder, setSearchTerm, value, setValue, hasBorder,
} : SearchBarProps) {
  const updateSearchFilter = useAsyncDebounce((asyncValue) => {
    setSearchTerm(asyncValue || '');
  }, 200);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value);
    updateSearchFilter(e.target.value);
  };

  return (
    <div className={hasBorder ? `${styles.container} ${styles.border}` : styles.container}>
      <div className={styles.iconContainer}>
        <Icon className={styles.searchIcon} name="search_magnifier" />
      </div>
      <input
        className={styles.globalSearch}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default SearchBar;
