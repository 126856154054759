import { Option } from '../types';
import styles from './optionSelectButton.module.scss';

type OptionSelectButtonProps = {
  option: Option;
  active: boolean;
  setValue: (value: string) => void;
};

function OptionSelectButton({ option, active, setValue }: OptionSelectButtonProps) {
  const className = `${styles.optionSelectButton} ${active && styles.active} ${option.disabled && styles.disabled}`;

  const onClick = () => {
    if (option.disabled) {
      return;
    }
    setValue(option.value);
  };

  return (
    <button
      className={className}
      type="button"
      onClick={onClick}
    >
      <div className={styles.header}>{option.displayValue}</div>
      <div className={styles.description}>{option.description}</div>
    </button>
  );
}

export default OptionSelectButton;
