import { ReactChild } from 'react';
import PropTypes from 'prop-types';
import Footer from 'components/footer/footer';
import logo from 'assets/ui-logo.svg';
import styles from './landingPageWrapper.module.scss';

type LandingPageWrapperProps = {
  children: ReactChild | ReactChild[];
  includeFooter?: boolean;
  showLogo?: boolean;
};

function LandingPageWrapper(
  { children, includeFooter = true, showLogo = true }: LandingPageWrapperProps,
) {
  return (
    <div className={styles.layer}>
      <div className={styles.container}>
        {showLogo && (
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={logo} alt="Whoop Header Logo" />
        </div>
        )}
        <div className={styles.children}>{children}</div>
        {includeFooter && (<Footer logo={false} landingPage />)}
      </div>
    </div>
  );
}

LandingPageWrapper.propTypes = {
  children: PropTypes.node,
};

export default LandingPageWrapper;
