export const STORIED_SUMMARY_DATA = {
  recovery: [
    35,
    38,
    42,
    45,
    50,
    15,
    36,
    40,
    45,
    55,
    64,
    51,
    18,
    40,
    46,
    49,
    56,
    55,
    62,
    21,
    51,
    58,
    60,
    65,
    62,
    67,
    24,
    55,
    62,
    70,
    63,
    65,
    74,
    26,
    56,
    62,
    61,
    64,
    73,
    78,
  ],
  sleep: [
    30,
    37,
    34,
    40,
    46,
    28,
    40,
    45,
    55,
    60,
    63,
    68,
    35,
    55,
    60,
    61,
    65,
    62,
    68,
    40,
    62,
    68,
    70,
    73,
    71,
    74,
    44,
    65,
    70,
    68,
    73,
    78,
    81,
    40,
    70,
    76,
    80,
    83,
    78,
    88,
  ],
  strain: [
    5.8,
    6.5,
    4.1,
    5.3,
    7.6,
    10.323115976928854,
    6.656683103895393,
    7.746741682763319,
    10.275349287895622,
    4.253061038375098,
    8.95094317276648,
    9.92523206750555,
    7.426599794189842,
    9.547505034834707,
    8.38440146483817,
    6.14,
    4.79471630452288,
    5.648139772734556,
    9.321083175211797,
    7.239113335811243,
    8.56344519613179,
    9.90428874,
    5.543332851222686,
    4.2746004957465455,
    6.285319058526483,
    7.9942217209234006,
    9.127865289676055,
    8.23,
    6.8,
    9.964289086549385,
    3.952130011421149,
    6.5,
    9.815179328317097,
    12,
    9,
    7,
    9,
    4,
    8,
    6,
  ],
};

export const SURGEON_SUMMARY_DATA = {
  recovery: [
    92,
    90,
    84,
    75,
    67,
    66,
    28,
    42,
    92,
    83,
    74,
    65,
    44,
    24,
    16,
    87,
    79,
    73,
    68,
    26,
    19,
    34,
    82,
    71,
    64,
    53,
    48,
    35,
    86,
    74,
    91,
    89,
    68,
    60,
    45,
    30,
    74,
    82,
    76,
    72,
  ],
  sleep: [
    95,
    92,
    86,
    87,
    80,
    73,
    55,
    67,
    98,
    87,
    80,
    78,
    70,
    65,
    58,
    93,
    90,
    91,
    79,
    68,
    56,
    80,
    88,
    83,
    78,
    75,
    70,
    65,
    90,
    86,
    88,
    85,
    80,
    74,
    65,
    75,
    90,
    88,
    83,
    80,
  ],
  strain: [
    8.278166648575287,
    9.11473917657278,
    10.323115976928854,
    6.656683103895393,
    7.746741682763319,
    10.275349287895622,
    4.253061038375098,
    8.95094317276648,
    9.92523206750555,
    7.426599794189842,
    9.547505034834707,
    8.38440146483817,
    7.145770242138179,
    4.79471630452288,
    5.648139772734556,
    9.321083175211797,
    7.239113335811243,
    8.56344519613179,
    9.90428874,
    5.543332851222686,
    4.2746004957465455,
    6.285319058526483,
    7.9942217209234006,
    9.127865289676055,
    11.237991688810482,
    10.75127825278868,
    9.964289086549385,
    3.952130011421149,
    10.204275094298117,
    9.815179328317097,
    12,
    9,
    7,
    9,
    4,
    8,
    10,
  ],
};
