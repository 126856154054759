import { hubClient, seatClient } from 'api';
import { HEADING_4, SUBTITLE_3 } from 'components/text';
import { useEffect } from 'react';
import { Hub } from 'types/hub';
import InputGroup, { InputGroupType } from 'components/inputGroup/inputGroup';
import { SeatDetail } from 'types/seat';
import DetailLabel from 'components/DetailLabel/detailLabel';
import { ActivateSensorReducerAction } from 'wizards/hub/activateSensorWizard/utils';
import getAvailableStrapSerials from 'api/strapsClient';
import { useAccounts } from 'context/account-context';
import { HubUserProfileReducerAction, HubUserProfileReducerState } from '../editMemberProfile/utils';
import styles from '../memberProfile.module.scss';

type CommonProps = {
  state: HubUserProfileReducerState;
  viewProfile: boolean;
  addMember?: boolean;
  customDispatch?: (a: ActivateSensorReducerAction) => void;
};

type ConditionalProps =
    | { viewProfile: true; dispatch? : never }
    | { viewProfile: false; dispatch: (a: HubUserProfileReducerAction) => void };

type MemberProfileCompProps = CommonProps & ConditionalProps;

function MemberProfileComp({
  dispatch, state, viewProfile = false, addMember = false, customDispatch,
} : MemberProfileCompProps) {
  const {
    firstName,
    email,
    sensorId,
    lastName,
    username,
    dateOfBirth,
    gender,
    height,
    weight,
    hub,
    hubs,
    sensorIds,
    patientIdNumber,
  } = state;

  const { accountConfig } = useAccounts();

  // Creating an array of ['50 lbs', '51 lbs',..., '450 lbs']
  const weightOptionsInitial = [...Array(400).keys()];
  const weightOptions = weightOptionsInitial.map((weightVal: number) => `${weightVal + 50} lbs`);

  // Creating an array of ['3 ft', '3 ft 1 in',..., '8 ft 11 in']
  const heightOptions = [];
  for (let ft = 3; ft <= 8; ft += 1) {
    for (let inches = 0; inches <= 11; inches += 1) {
      if (inches === 0) {
        heightOptions.push(`${ft} ft`);
      } else {
        heightOptions.push(`${ft} ft ${inches} in`);
      }
    }
  }

  const handleInputChange = (field: string, value: string) => {
    // This sends the changes back to the wizard
    if (customDispatch) {
      customDispatch({
        type: 'handleInputText',
        field,
        value,
      });
    }
    dispatch({
      type: 'handleInputText',
      field,
      value,
    });
  };

  useEffect(() => {
    const getDropdownOptions = async () => {
      dispatch({ type: 'loading', value: true });
      if (customDispatch && !viewProfile) {
        const allHubs: Hub[] = await hubClient.getHubsBySalesforceAccountId();
        const filteredHubs: string[] = allHubs.map((hubToFilter: Hub) => hubToFilter.name);
        dispatch({ type: 'setHubs', hubs: filteredHubs });
      }

      if (!addMember) {
        const seats = await seatClient.getAllSeats();
        const filteredSensorIds: string[] = seats.data
          .filter((seat: SeatDetail) => seat.strap_serial) // Filters out null values
          .map((seat: SeatDetail) => seat.strap_serial);
        const uniqueSensorIds: string[] = [...new Set(filteredSensorIds)];

        dispatch({ type: 'setSensorIds', sensorIds: uniqueSensorIds });
      } else {
        const availableSensorIds = await getAvailableStrapSerials();
        dispatch({ type: 'setSensorIds', sensorIds: availableSensorIds });
      }
      dispatch({ type: 'loading', value: false });
    };
    if (!viewProfile) {
      getDropdownOptions();
    }
  }, []);

  return (
    <div>
      <div className={styles.profileContent}>
        <div className={`${viewProfile ? '' : styles.editProfileSection} ${styles.section}`}>
          <HEADING_4>Account Information</HEADING_4>
          <div className={styles.sectionContainer}>
            {viewProfile
              ? (
                <>
                  <DetailLabel title="Patient ID Number" data={patientIdNumber} />
                  {!addMember && (
                  <>
                    <DetailLabel title="Last Name" data={lastName} />
                    <DetailLabel title="Username" data={username} />
                  </>
                  )}
                </>
              )
              : (
                <div className={styles.inputGroup}>
                  <InputGroup
                    value={patientIdNumber ?? ''}
                    setValue={(value: string) => { handleInputChange('patientIdNumber', value); }}
                    placeholder="Enter member's patient id number"
                    label="Patient ID Number"
                    id="patient-id-number"
                    type={InputGroupType.TEXT}
                  />
                  {!addMember && (
                  <>
                    <InputGroup
                      value={lastName ?? ''}
                      setValue={(value: string) => { handleInputChange('lastName', value); }}
                      placeholder="Enter member's last name"
                      label="Last Name"
                      id="last-name"
                      type={InputGroupType.TEXT}
                      disabled
                    />
                    <InputGroup
                      value={username ?? ''}
                      setValue={(value: string) => { handleInputChange('username', value); }}
                      placeholder="Enter member's username"
                      label="Username"
                      id="username"
                      type={InputGroupType.TEXT}
                      disabled
                    />
                  </>
                  )}
                </div>
              )}
          </div>
          <div className={styles.sectionContainer}>
            {viewProfile
              && (
                <>
                  {!addMember && (
                  <>
                    <DetailLabel title="First Name" data={firstName} />
                    <DetailLabel title="Email" data={email} />
                  </>
                  )}
                  <DetailLabel title="Sensor ID" data={sensorId} />
                </>
              )}
            {!viewProfile && (
            <div className={styles.inputGroup}>
              {!addMember && (
              <>
                <InputGroup
                  value={firstName ?? ''}
                  setValue={(value: string) => { handleInputChange('firstName', value); }}
                  placeholder="Enter member's first name"
                  label="First Name"
                  id="first-name"
                  type={InputGroupType.TEXT}
                  disabled
                />
                <InputGroup
                  value={email ?? ''}
                  setValue={(value: string) => { handleInputChange('email', value); }}
                  placeholder="Enter member's email"
                  label="Email"
                  id="email"
                  type={InputGroupType.TEXT}
                  disabled
                />
              </>
              )}
              <div className={styles.dropdownWidth}>
                <InputGroup
                  value={sensorId ?? ''}
                  setValue={(value: string) => { handleInputChange('sensorId', value); }}
                  placeholder="Sensor ID"
                  label="Sensor ID"
                  id="sensor-id"
                  type={InputGroupType.DROPDOWN}
                  options={sensorIds}
                  disabled={!addMember}
                />
              </div>
            </div>
            )}
          </div>
        </div>

        <div className={`${viewProfile ? '' : styles.editProfileSection} ${styles.section}`}>
          <HEADING_4>Biological Information</HEADING_4>
          <div className={styles.sectionContainer}>
            {viewProfile
              ? (
                <>
                  <DetailLabel title="Date of Birth" data={dateOfBirth} />
                  <DetailLabel title="Height" data={height} />
                </>
              )
              : (
                <div className={styles.inputGroup}>
                  <InputGroup
                    value={dateOfBirth}
                    setValue={(value: string) => { handleInputChange('dateOfBirth', value); }}
                    placeholder="Date of Birth"
                    label="Date of Birth"
                    id="date-of-birth"
                    type={InputGroupType.DATE}
                  />
                  <div className={styles.dropdownWidth}>
                    <InputGroup
                      value={height ?? ''}
                      setValue={(value: string) => { handleInputChange('height', value); }}
                      placeholder="Height"
                      label="Height"
                      id="height"
                      type={InputGroupType.DROPDOWN}
                      options={heightOptions}
                    />
                  </div>
                </div>
              )}
          </div>
          <div className={styles.sectionContainer}>
            {viewProfile
              ? (
                <>
                  <DetailLabel title="Gender" data={gender} />
                  <DetailLabel title="Weight" data={weight} />
                </>
              )
              : (
                <div className={styles.inputGroup}>
                  <div className={styles.dropdownWidth}>
                    <InputGroup
                      value={gender ?? ''}
                      setValue={(value: string) => { handleInputChange('gender', value); }}
                      placeholder="gender"
                      label="gender"
                      id="gender"
                      type={InputGroupType.DROPDOWN}
                      options={['male', 'female', 'non-binary', 'other']}
                    />
                  </div>
                  <div className={`${styles.dropdownWidth} ${styles.weightInput}`}>
                    <InputGroup
                      value={weight ?? ''}
                      setValue={(value: string) => { handleInputChange('weight', value); }}
                      placeholder="weight"
                      label="weight"
                      id="weight"
                      type={InputGroupType.DROPDOWN}
                      options={weightOptions}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className={`${viewProfile ? '' : styles.editProfileSection} ${styles.section} ${styles.lastSection}`}>
          <HEADING_4>Hub Information</HEADING_4>
          {accountConfig.bluetooth_roaming
          && (
          <SUBTITLE_3>
            Bluetooth roaming enabled on account: member cannot be assigned to new hubs
          </SUBTITLE_3>
          )}
          <div className={styles.sectionContainer}>
            {/* If has `customDispatch` property, is coming from activate sensor wizard */}
            {viewProfile || !customDispatch || accountConfig.bluetooth_roaming
              ? (
                <DetailLabel title="Hub" data={hub} />
              )
              : (
                <div className={styles.inputGroup}>
                  <div className={styles.dropdownWidth}>
                    <InputGroup
                      value={hub ?? ''}
                      setValue={(value: string) => { handleInputChange('hub', value); }}
                      placeholder=""
                      label="hub"
                      id="hub"
                      type={InputGroupType.DROPDOWN}
                      options={hubs}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberProfileComp;
