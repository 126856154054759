import { Colors } from '@whoop/web-components';
// @nivo/tooltip and @nivo/scales are dependencies of nivo
// eslint-disable-next-line import/no-extraneous-dependencies
import { CrosshairType } from '@nivo/tooltip';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ScaleSpec } from '@nivo/scales';
import { AxisTickProps } from '@nivo/axes';
import { linearGradientDef } from '@nivo/core';
import { labelXAxis, RawHeartRateAxis } from 'dataVisualizations/axis/axis';
import { XAxis } from './hrLineGraphTypes';

export const rawHeartRateAxis = [0, 25, 50, 75, 100, 125, 150, 175, 200, 225];

export const commonProps = {
  lineWidth: 1,
  enableGridX: false,
  enableArea: true,
  enableGridY: true,
  useMesh: true,
  animate: false,
  pointLabelYOffset: 0,
  crosshairType: 'x' as CrosshairType,
  theme: {
    background: Colors.white,
    grid: {
      line: {
        strokeWidth: 1,
        stroke: Colors.blackAlpha50,
      },
    },
    crosshair: {
      line: {
        stroke: Colors.black,
        strokeWidth: 1,
        strokeDasharray: '2.5',
      },
    },
    axis: {
      legend: {
        text: {
          fontSize: '13px',
          fontWeight: 700,
          fontFamily: 'DINPro',
          lineHeight: '14px',
        },
      },
    },
  },
  defs: [
    linearGradientDef('gradientA', [
      { offset: 0, color: 'inherit' },
      { offset: 100, color: 'inherit', opacity: 0 },
    ]),
  ],
  // Adding as '*' to fix type error
  fill: [{ match: '*' as '*', id: 'gradientA' }],
};

export const getTimeAxisProps = (xTickTime: number[]) => (
  {
    gridXValues: xTickTime,
    axisBottom: {
      renderTick: (tick: AxisTickProps<string>) => labelXAxis(
        tick,
        XAxis.time,
        xTickTime,
      ),
    },
  }
);

export const rawHeartRateAxisProps = {
  yScale: {
    type: 'linear',
    min: 0,
    max: 225,
  } as ScaleSpec,
  gridYValues: rawHeartRateAxis,
  axisLeft: {
    tickValues: rawHeartRateAxis,
    renderTick: (tick: AxisTickProps<string>) => RawHeartRateAxis(tick),
  },
};
