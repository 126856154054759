const { makeStyles } = require('@material-ui/styles');

const emailsInputStyles = makeStyles(() => ({
  input: {
    height: '100%',
    alignItems: 'start',
    borderBottom: 'none',
    fontFamily: 'ProximaNova',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '19px',
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
  },

  inputBox: {
    border: '1px solid rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '12px 16px',
    height: 'fit-content',
    minHeight: '64px',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    flex: 'none',
    order: 1,
    alignSelf: 'stretch',
    flexGrow: 1,
    margin: '8px 0px',
  },

  emailsInputLabel: {
    fontFamily: 'ProximaNova',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '11px',
    lineHeight: '14px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: '#969696',
  },

  textArea: {
    flex: 1,
    minWidth: '200px',
    margin: 'auto 4px',
  },

  chip: {
    margin: '4px',
    borderRadius: '4px',
    textTransform: 'uppercase',
    fontFamily: 'ProximaNova',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '0.1em',
    height: '32px',
  },

  errorChip: {
    border: '1px solid #f44336',
    color: '#f44336',
  },

  crossIcon: {
    color: '#757575',
    height: '12px',
    width: '12px',
    margin: '0 8px',
  },

  chipLabel: {
    paddingRight: '0px',
  },

  errorText: {
    padding: '4px 20px 4px 20px',
    color: '#f44336',
    fontSize: '14px',
  },
}));

export default emailsInputStyles;
