import { styled, Switch } from '@material-ui/core';

type CustomSwitchProps = {
  customwidth: number;
  customheight: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
  checked: boolean;
  name: string;
};

const CustomSwitch = styled((props: CustomSwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(
  ({ theme, customheight, customwidth }: any) => ({
    float: 'right',
    width: customwidth,
    height: customheight,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      color: '#969696',
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: `translateX(${customheight}px)`,
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#0093E7',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: customheight - 4,
      height: customheight - 4,
    },
    '& .MuiSwitch-track': {
      borderRadius: 24,
      backgroundColor: '#EBEBEB',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }),
);

export default CustomSwitch;
