import { capitalizeWords } from 'groups/utils';
import { NamedUser } from 'types/base';

export const formatDecimalToPercent = (decimal: number) => {
  const finiteDec = Number.isFinite(decimal) ? decimal : 0;
  const percent = finiteDec * 100;
  const toNearestHundredths = percent.toFixed(2);
  const displayedNumber = toNearestHundredths.replace('.00', '');
  return `${displayedNumber}%`;
};

export const getIdentifier = (data: NamedUser) => data.member_identifier || capitalizeWords(`${data.first_name} ${data.last_name}`);

export const downloadFile = (data: string) => {
  // Create CSV file object and feed our
  // csv_data into it
  const CSVFile = new Blob([data], { type: 'text/csv' });

  // Create to temporary link to initiate
  // download process
  const tempLink = document.createElement('a');

  // Download csv file
  tempLink.download = `patient_ids-${+new Date()}.csv`;
  const url = window.URL.createObjectURL(CSVFile);
  tempLink.href = url;

  // This link should not be displayed
  tempLink.style.display = 'none';
  document.body.appendChild(tempLink);

  // Automatically click the link to trigger download
  tempLink.click();
  document.body.removeChild(tempLink);
};
