import LandingPageWrapper from 'landingPageWrapper/landingPageWrapper';
import ErrorIcon from 'assets/icons/xRed.svg';
import { Button } from '@whoop/web-components';
import { useNavigate } from 'react-router-dom';
import styles from './errorPage.module.scss';

type ErrorPageProps = {
  is404?: boolean;
  includeFooter?: boolean;
};

function ErrorPage({ is404 = true, includeFooter = true }: ErrorPageProps) {
  const navigate = useNavigate();
  return (
    <LandingPageWrapper includeFooter={includeFooter}>
      <div className={styles.error}>
        <div>
          <img className={styles.errorIcon} src={ErrorIcon} alt="Error" />
          <h1>{is404 ? '404 Not Found' : 'Sorry, there was an error.'}</h1>
          <p>{is404 ? 'Sorry, we could not find the page you were looking for' : 'Please try again'}</p>
          {is404 && (
            <Button
              label="Home Page"
              variant="secondary"
              theme="dark"
              onClick={() => { navigate('/accounts'); }}
            />
          )}
        </div>
      </div>
    </LandingPageWrapper>
  );
}

export default ErrorPage;
