import { Button, Icon } from '@whoop/web-components';
import FuzzySearch from 'components/fuzzySearch/fuzzySearch';
import { HEADING_4 } from 'components/text';
import React, { useEffect, useState } from 'react';
import { Row } from 'react-table';
import { CohortCollaborator } from 'types/cohort';
import { UseStatePropType } from 'types/useStatePropType';
import styles from '../../addPage.module.scss';
import AvailableCollaboratorsTable from './availableCollaboratorsTable';

type AddCollaboratorsSearchProps = {
  availableCollaborators: CohortCollaborator[];
  loading: boolean;
  selectedCollaborators: CohortCollaborator[];
  setSelectedCollaborators: UseStatePropType<CohortCollaborator[]>;
};

function AddCollaboratorsSearch({
  availableCollaborators,
  loading,
  selectedCollaborators,
  setSelectedCollaborators,
}: AddCollaboratorsSearchProps) {
  const [selectedRows, setSelectedRows] = useState<Row<CohortCollaborator>[]>([]);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [filteredAvailableCollaborators,
    setFilteredAvailableCollaborators] = useState<CohortCollaborator[]>([]);

  const handleRemoveFromSelected = () => {
    const selectedCollabs = selectedRows.map((row) => row.original);
    setSelectedCollaborators(selectedCollaborators
      .filter((collab) => !selectedCollabs.includes(collab)));
  };

  const handleSelect = (collaborator: CohortCollaborator) => {
    setSelectedCollaborators([...selectedCollaborators, collaborator]);
    setShowMenu(false);
  };

  useEffect(() => {
    setShowMenu(true);
  }, [filteredAvailableCollaborators]);

  return (
    <>
      <div
        className={styles.searchContainer}
      >
        <div className={styles.controlContainer}>
          <div className={styles.heading}><HEADING_4>{`${selectedCollaborators.length} Collaborators Added`}</HEADING_4></div>
          {selectedRows.length > 0 && (
            <div className={styles.bulkAction}>
              <Button
                className={styles.button}
                label="Remove"
                size="small"
                theme="enterprise"
                variant="normal"
                onClick={handleRemoveFromSelected}
              />
            </div>
          )}
        </div>
        <FuzzySearch
          searchables={availableCollaborators
            .filter((collab) => !selectedCollaborators.includes(collab))}
          searchKeys={['first_name', 'last_name', 'email']}
          limit={5}
          setFilteredSearch={setFilteredAvailableCollaborators}
          placeholder="Search for a collaborator"
        />
        <ul className={!showMenu ? styles.hide : ''}>
          {filteredAvailableCollaborators.map((collab) => (
            <li
              key={collab.user_id}
              tabIndex={0}
              role="menuitem"
              onClick={() => handleSelect(collab)}
              onKeyDown={() => handleSelect(collab)}
            >
              <div>
                <div className={styles.avatar}>
                  {collab.avatar_url ? <img src={collab.avatar_url} alt="Avatar" /> : <Icon name="my_account" />}
                </div>
                <div className={styles.nameAndEmail}>
                  <span className={styles.name}>
                    {`${collab.first_name} ${collab.last_name}`}
                  </span>
                  <span className={styles.email}>
                    {collab.email}
                  </span>
                  <span className={styles.privacyLevel}>
                    {collab.privacy_level}
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <AvailableCollaboratorsTable
        availableCollaborators={selectedCollaborators}
        loading={loading}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
}

export default AddCollaboratorsSearch;
