import React, { ReactChild, ReactElement } from 'react';
import StickyFooter from 'components/stickyFooter';
import { Button } from '@whoop/web-components';
import { UseStatePropType } from 'types/useStatePropType';
import { Row } from 'react-table';
import styles from './fullPageModal.module.scss';

type FullPageModalProps = {
  setSelectedRows: UseStatePropType<Row<any>[]>;
  setPageState: UseStatePropType<any>;
  ActionButton: ReactElement;
  children: ReactChild[] | ReactChild;
};

function FullPageModal({
  setSelectedRows, setPageState, ActionButton, children,
}: FullPageModalProps) {
  const handleCancelAction = () => {
    setPageState(null);
    setSelectedRows([]);
  };

  return (
    <div className={styles.fullPageModal}>
      <div className={styles.modalContent}>{children}</div>
      <StickyFooter>
        <div className={styles.modalButtons}>
          <Button label="Cancel" onClick={handleCancelAction} variant="link" />
          {ActionButton}
        </div>
      </StickyFooter>
    </div>
  );
}

export default FullPageModal;
