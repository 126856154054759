import dayjs from 'dayjs';
import { V2_MOCK_METRIC_MAP } from 'mocks/store/progress/pillarOverviewData';
import { MetricType } from 'types/analytics';
import { getNumDays } from './analyticsActions';

export const getMetricDataV2 = (
  metricType: MetricType,
  startDate: string,
  endDate: string,
) => {
  const numDays = getNumDays(startDate, endDate);
  const clonedMockValues = [...V2_MOCK_METRIC_MAP[metricType].metrics].slice(0, numDays);
  for (let i = 0; i < numDays; i += 1) {
    // Update the cycle_start_day property to be within the date range passed in from the request
    const cycleDay = dayjs(startDate).add(i, 'day');
    const cycleStartDay = [cycleDay.get('month') + 1, cycleDay.get('date'), cycleDay.get('year')];
    clonedMockValues[i].cycle_start_day = cycleStartDay;
  }
  const sum = clonedMockValues.reduce((acc, val) => acc + val.metric_value, 0);
  return {
    average_value: sum / numDays,
    metrics: clonedMockValues,
  };
};
