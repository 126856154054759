import Pagination from 'components/pagination';
import TableColumnHeader from 'components/tableColumnHeader';
import LoadingBar from 'components/tableLoadingBar';
import { useEffect, useState } from 'react';
import {
  Cell,
  Column,
  HeaderGroup,
  Row, TableCommonProps,
  useGlobalFilter, useMountedLayoutEffect, usePagination, useRowSelect, useSortBy,
  useTable,
} from 'react-table';
import { UseStatePropType } from 'types/useStatePropType';
import RowsPerPageSelect from 'tableUtils/rowsPerPageSelect/rowsPerPageSelect';
import { InviteStatus } from 'types/collaborator';
import styles from './collaboratorsTable.module.scss';

type TableProps<D extends {}> = {
  columns: Column<D>[];
  data: D[];
  loading: boolean;
  setSelectedRows: UseStatePropType<Row<D>[]>;
  updateRow?: (row: D) => void;
  keywordFilter: string;
  resetPage: InviteStatus;
  getAdditionalRowProps: (row: Row<D>) => TableCommonProps;
  scrollToTop: () => void;
};

function CollaboratorsTable<D extends {}>({
  columns,
  data,
  loading,
  setSelectedRows,
  updateRow,
  keywordFilter,
  resetPage,
  getAdditionalRowProps = () => ({ className: '' }),
  scrollToTop,
}: TableProps<D>) {
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    setPageSize,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, pageSize, selectedRowIds },
    setGlobalFilter,
    selectedFlatRows,
  } = useTable<D>(
    {
      columns,
      data,
      loading,
      autoResetGlobalFilter: false,
      autoResetPage: false,
      disableSortBy: true,
      initialState: {
        pageIndex: 0,
        pageSize: 12,
      },
      updateRow,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const [allRows, setAllRows] = useState<Row<D>[]>(rows);

  useEffect(() => {
    // Reset the page if we are searching or changing tabs
    if (canPreviousPage) {
      gotoPage(0);
    }
    if (setGlobalFilter) {
      setGlobalFilter(keywordFilter);
    }
  }, [setGlobalFilter, keywordFilter, resetPage]);

  useEffect(() => {
    setAllRows(rows);
  }, [data]);

  useMountedLayoutEffect(() => {
    setSelectedRows(allRows.filter((row) => Object.keys(selectedRowIds).includes(row.id)));
  }, [selectedFlatRows]);

  const paginationRangeStart: number = 1 + pageSize * pageIndex;
  const paginationRangeEnd: number = canNextPage
    ? (pageIndex + 1) * pageSize
    : (rows.length);

  return (
    <div
      className={styles.tableContainer}
    >
      {data.length === 0 ? (
        <p className={styles.noData}>There are no collaborators to show</p>
      ) : (
        <table
          className={styles.collabTable}
          {...getTableBodyProps()}
          role="table"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                key="TableHeader"
                className={styles.headerGroup}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: Column<D>) => (
                  <TableColumnHeader
                    column={column as HeaderGroup}
                    key={column.id}
                  />
                ))}
              </tr>
            ))}
            <LoadingBar show={loading} />
          </thead>
          <tbody className={styles.collabTableBody} {...getTableBodyProps()}>
            {page.map((row: Row<D>) => {
              prepareRow(row);
              const { cells } = row;
              return (
                <tr
                  key={row.id}
                  {...row.getRowProps(getAdditionalRowProps(row))} // highlight on select
                >
                  {cells.map((cell: Cell<D>, index) => (
                    <td key={cell.row.id + index.toString()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {page.length > 0 && (
        <div className={styles.paginationSection}>
          <Pagination
            previousPage={() => {
              scrollToTop();
              previousPage();
            }}
            canPreviousPage={canPreviousPage}
            nextPage={() => {
              scrollToTop();
              nextPage();
            }}
            canNextPage={canNextPage}
            rangeStart={paginationRangeStart}
            rangeEnd={paginationRangeEnd}
            total={rows.length}
          />
          <div className={styles.rowSelect}>
            <RowsPerPageSelect
              onOptionClick={(size) => setPageSize(size)}
              selectedOption={pageSize}
              pageSizeOptions={[6, 12, 24]}
            />
          </div>

        </div>
      )}
    </div>
  );
}

export default CollaboratorsTable;
