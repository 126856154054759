import React, { useMemo } from 'react';
import { headerProps, rowStyles } from 'tableUtils/tableProps';
import { HEADING_5, SUBTITLE_2 } from 'components/text';
import { STATUS_COLUMN } from 'tableUtils/tableColumns';
import { makeStyles } from '@material-ui/core/styles';
import Table from 'components/table';
import { Column } from 'react-table';
import { EmailAndSeatStatus } from 'types/seat';

const useStyles = makeStyles({
  stickyHeader: {
    overflow: 'scroll',
    backgroundColor: 'var(--whoop-color-white)',
    position: 'sticky',
    zIndex: 1,
    width: 'fit-content',
    top: '64px',
  },
  headerGroup: {
    height: '48px',
  },
  table: {
    marginTop: '40px',
  },
});

type InvitesSentTableProps = {
  emails: EmailAndSeatStatus[];
  inviteType: string;
};

function InvitesSentTable({
  emails, inviteType,
}: InvitesSentTableProps) {
  const classes = useStyles();

  const columns = useMemo(() => {
    const usedColumns = [
      {
        Header: `${inviteType || 'Member'} Emails`,
        accessor: (row: { email: string }) => row.email,
        width: '25%',
        align: 'left',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (cellProps: { row: { original: { email: string } } }) => {
          const { row } = cellProps;
          return <SUBTITLE_2>{row.original.email || '--'}</SUBTITLE_2>;
        },
      },
    ];
    // @ts-ignore
    usedColumns.push({ ...STATUS_COLUMN, align: 'left' }); // Pushing column to prevent lint error
    return usedColumns;
  }, [inviteType]);

  const getTableColumnHeader = (column: Column) => (
    // @ts-ignore
    <th key={column.id} {...column.getHeaderProps(headerProps)}>
      {/* @ts-ignore */}
      <HEADING_5>{column.render('Header')}</HEADING_5>
    </th>
  );

  return (
    <div className={classes.table}>
      <Table
        columns={columns}
        loading={false}
        data={emails}
        tableName="invitesSentTable"
        search={false}
        getAdditionalRowProps={rowStyles}
        pagination={false}
        // eslint-disable-next-line react/jsx-no-useless-fragment
        customHeader={<></>}
        getTableColumnHeader={getTableColumnHeader}
        tableLength={100}
      />
    </div>
  );
}

export default InvitesSentTable;
