import PulsingLogo from 'components/spinner';
import styles from './loading.module.scss';

function LoadingPage({ overlay = false }) {
  return (
    <div
      data-testid="loading"
      className={overlay ? styles.overlayContainer : styles.fullPageContainer}
    >
      <PulsingLogo overlay={overlay} />
    </div>
  );
}

export default LoadingPage;
