export enum HubSeatStatus {
  Active = 'active',
  Pending = 'pending',
  Archived = 'archived',
}

export enum HubStatus {
  ALL = 'ALL',
  PENDING = 'PENDING',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  AWAITING_ACTIVATION = 'AWAITING_ACTIVATION',
}

export type Hub = {
  salesforce_account_id: string;
  mac_address: string;
  contract_number?: number;
  last_seen_at?: Date;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  status: HubStatus;
  network_id: string;
  roaming_enabled: boolean;
};

export type HubListPayload = {
  mac_address: string;
  last_seen_at?: Date;
  name: string;
  description: string;
  member_count: number;
  status: HubStatus;
};
export const isHubListPayload = (
  obj: any,
): obj is HubListPayload => (obj as HubListPayload).mac_address !== undefined;

export type ParsedHubUserProfile = {
  firstName: string;
  lastName: string;
  username: string;
  sensorId: string;
  dateOfBirth: string;
  gender: string;
  height: string;
  weight: string;
  hub: string;
  email: string;
  patientIdNumber: string;
};

export type HubUserProfile = {
  member_identifier: string;
  first_name: string;
  last_name?: string;
  username?: string;
  email?: string;
  strap_serial?: string;
  birthday: string;
  gender: string;
  height: number;
  weight: number;
  hub_names: string[];
};

export type AddHubMemberRequest = {
  member_identifier: string;
  password: string;
  strap_serial?: string;
  birthday: string;
  gender: string;
  height: number;
  weight: number;
  hub_names: string[];
};

export type HubEgg = {
  mac_address: string;
  salesforce_account_id: string;
  name: string;
  description?: string;
};

export type HubUser = {
  member_identifier?: string;
  strap_serial: string;
  status: HubSeatStatus;
  last_connected?: Date;
  battery_level?: string;
  last_uploaded?: Date;
  activated_on?: string;
  seat_id: number;
};

export type HubParams = {
  macAddress: string;
  accountId: string;
};

export type AcHubApp = {
  name: string;
  version: string;
};
