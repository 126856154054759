/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@whoop/web-components';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './dropdown.module.scss';

const StyledMenu = withStyles({
  paper: {
    padding: '5px 0 5px 0',
    cursor: 'pointer',
    'border-radius': '0',
    'min-width': '170px',
  },
})((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    getContentAnchorEl={null}
    marginThreshold={5}
    {...props}
  />
));

function Dropdown(props) {
  const {
    buttonStyle = styles.button, dropdownStyles,
    button, buttonProps, children, isDisabled, closeOnClick = false,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const popupRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div ref={popupRef} className={styles.dropdownContainer}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        label={button}
        size="small"
        className={buttonStyle}
        {...buttonProps}
        disabled={isDisabled}
      />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={() => { if (closeOnClick) handleClose(); }}
        onClose={handleClose}
        PaperProps={{ style: dropdownStyles }}
        data-testid="dropdown-styled-menu"
      >
        {children}
      </StyledMenu>
    </div>
  );
}

Dropdown.propTypes = {
  buttonStyle: PropTypes.string,
  dropdownStyles: PropTypes.shape({
    borderTop: PropTypes.string,
    width: PropTypes.string,
    minWidth: PropTypes.string,
    transform: PropTypes.string,
    borderRadius: PropTypes.string,
  }),

  button: PropTypes.element.isRequired,

  buttonProps: PropTypes.shape({
    variant: PropTypes.string,
    'data-testid': PropTypes.string,
    className: PropTypes.string,
    'aria-label': PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    theme: PropTypes.string,
  }).isRequired,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),

  isDisabled: PropTypes.bool,
  closeOnClick: PropTypes.bool,
};

Dropdown.defaultProps = {
  closeOnClick: false,
  isDisabled: false,
  dropdownStyles: {},
  children: [],
};

export default Dropdown;
