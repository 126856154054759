import { useState } from 'react';
import { sendInvites } from 'api/seatApi';
import { Button } from '@whoop/web-components';
import { PAGE_STATE } from 'constants/pageState';
import Loading from 'loading';
import { ShippingMethodType } from 'wizards/invite/types';
import { Row } from 'react-table';
import { Member } from 'types/member';
import { MEMBERS_COLUMN, SELECTION_COLUMN } from 'tableUtils/tableColumns';
import ActionModal from 'components/actionModal/actionModal';
import { SeatDetail } from 'types/seat';
import createHeading from 'helpers/modalUtils';
import { useAccounts } from 'context/account-context';

type InviteDeactivatedProps = {
  selectedRows: any[];
  setSelectedRows: (selectedRows: Row<Member>[]) => void;
  refetchData: () => void;
  setPageState: (pageState: string) => void;
  triggerToast: (message: string) => void;
};

function InviteDeactivatedModal({
  selectedRows,
  setSelectedRows,
  refetchData,
  setPageState,
  triggerToast,
}: InviteDeactivatedProps) {
  const [loading, setLoading] = useState(false);
  const { updateAccount } = useAccounts();

  const toastMessage = (invitesResent: number) => {
    if (invitesResent) {
      return `${invitesResent} invite${
        invitesResent === 1 ? ' has' : 's have'
      } been sent and they will receive an email with an invite link.`;
    }
    return null;
  };

  const handleResendInvites = async () => {
    const bulkInvites = selectedRows.filter(
      (inv) => inv.original.shipping_type === ShippingMethodType.BULK,
    )
      .map((row) => row.original.email);
    const directInvites = selectedRows.filter(
      (inv) => inv.original.shipping_type === ShippingMethodType.DIRECT,
    )
      .map((row) => row.original.email);
    setLoading(true);
    try {
      await sendInvites(bulkInvites, ShippingMethodType.BULK);
      await sendInvites(directInvites, ShippingMethodType.DIRECT);
    } finally {
      setLoading(false);
      setSelectedRows([]);
      setPageState(PAGE_STATE.DASHBOARD);
      await refetchData(); // Fetch Seat Data
      updateAccount(); // Update License Count
      triggerToast(toastMessage(selectedRows.length));
    }
  };

  const ActionButton = (
    <Button
      label="Send"
      onClick={handleResendInvites}
      disabled={selectedRows.length < 1}
      variant="primary"
    />
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <ActionModal
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setPageState={setPageState}
      ActionButton={ActionButton}
      title="Re-invite deactivated member?"
      body="These members are currently deactivated. Send them a new invitation to rejoin."
      heading={createHeading(selectedRows, 'member', 'members')}
      columnList={[SELECTION_COLUMN, MEMBERS_COLUMN]}
      rowAccessor={(row: SeatDetail) => row.id}
    />
  );
}

export default InviteDeactivatedModal;
