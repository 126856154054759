import React, { useState } from 'react';
import { Button } from '@whoop/web-components';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { countries } from 'register/constants';
import styles from '../register.module.scss';

const useStyles = makeStyles({
  options: {
    '&:hover': {
      backgroundColor: '#2e2e2e',
    },
    "&[aria-selected='true']": {
      backgroundColor: '#2e2e2e',
    },
    color: 'white',
  },
  paper: {
    backgroundColor: '#171717',
  },
});

function SetupProfile({ handleRegistration, usernameError }) {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    mode: 'onChange',
  });

  const watchFirstName = watch('firstName', '');
  const watchLastName = watch('lastName', '');
  const watchUsername = watch('username', '');

  const [countryKey, setCountryKey] = useState('US');

  const firstNameValidationError = errors?.firstName
    || (dirtyFields.watchFirstName && watchFirstName.length === 0);

  const lastNameValidationError = errors?.lastName
    || (dirtyFields.watchLastName && watchLastName.length === 0);

  const usernameValidationError = errors?.username
    || (dirtyFields.watchUsername && watchUsername.length === 0);

  const firstNameInputClass = `${styles.inputField} ${
    firstNameValidationError && styles.inputFieldInvalid
  }`;

  const lastNameInputClass = `${styles.inputField} ${
    lastNameValidationError && styles.inputFieldInvalid
  }`;

  const usernameInputClass = `${styles.inputField} ${
    usernameValidationError && styles.inputFieldInvalid
  }`;

  const onSubmit = (values) => {
    const profile = {
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.username,
      countryKey,
    };
    handleRegistration(profile);
  };

  return (
    <>
      <div className={styles.infoText}>
        <p>STEP 2/3</p>
        <h1>Setup your profile</h1>
      </div>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <label id="firstNameLabel" className={styles.inputFieldLabel}>
          First Name
        </label>
        <input
          aria-labelledby="firstNameLabel"
          data-testid="first-name-input"
          className={firstNameInputClass}
          {...register('firstName', { required: true })}
        />
        <div className={styles.invalidMessage}>
          {firstNameValidationError && 'First name cannot be blank'}
        </div>

        <label id="lastNameLabel" className={styles.inputFieldLabel}>
          Last Name
        </label>
        <input
          aria-labelledby="lastNameLabel"
          data-testid="last-name-input"
          className={lastNameInputClass}
          {...register('lastName', { required: true })}
        />
        <div className={styles.invalidMessage}>
          {lastNameValidationError && 'Last name cannot be blank'}
        </div>

        <label id="usernameLabel" className={styles.inputFieldLabel}>
          Create a Username
        </label>
        <input
          aria-labelledby="usernameLabel"
          data-testid="username-input"
          className={usernameInputClass}
          {...register('username', { required: true })}
        />
        <div className={styles.invalidMessage}>
          {usernameValidationError && 'Username cannot be blank'}
          {usernameError && 'A user with this username already exists'}
        </div>

        <label id="countryLabel" className={styles.inputFieldLabel}>
          Country
        </label>
        <Autocomplete
          options={countries}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => setCountryKey(value.key)}
          ListboxProps={{ style: { maxHeight: '10rem' } }}
          classes={{
            option: classes.options,
            paper: classes.paper,
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <input
                data-testid="country-input"
                type="text"
                style={{ width: '19rem' }}
                {...params.inputProps}
                className={styles.inputField}
              />
            </div>
          )}
        />

        <Button
          label="next"
          aria-label="Next"
          variant="primary"
          theme="enterprise"
          type="submit"
          data-testid="next-btn"
          disabled={!isValid}
        />
      </form>
    </>
  );
}

SetupProfile.propTypes = {
  handleRegistration: PropTypes.func.isRequired,
  usernameError: PropTypes.bool.isRequired,
};

export default SetupProfile;
