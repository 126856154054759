import React, { ReactChild } from 'react';
import styles from './actionContent.module.scss';

type ActionContentTypes = {
  children: ReactChild[] | ReactChild;
};
function ActionContent({ children }: ActionContentTypes) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default ActionContent;
