import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';

export const defaultRoleOptions = [
  {
    name: DashboardRole.GROUP_MANAGER,
    active: false,
    description: 'Group Managers can access specific group(s) assigned to them by an Account Admin or Account Manager.',
    value: DashboardRole.GROUP_MANAGER,
  },
];

export const defaultRoleOptionsWithCollaboratorValues = [
  {
    name: DashboardRole.GROUP_MANAGER,
    active: false,
    description: 'Group Managers can access specific group(s) assigned to them by an Account Admin or Account Manager.',
    value: DashboardRole.GROUP_MANAGER,
  },
];

export const privacyLevelOptions = [
  {
    name: 'All Metrics',
    active: false,
    value: PrivacyLevel.all_metrics,
    description: (
      <ul>
        <li>All WHOOP data and metrics available</li>
        <li>Group and individual data</li>
      </ul>),
  },
  {
    name: 'Performance Metrics',
    active: false,
    value: PrivacyLevel.performance_metrics,
    description: (
      <ul>
        <li>Detailed Strain & Recovery metrics with limited Sleep data</li>
        <li>Group and individual data</li>
      </ul>),
  },
  {
    name: 'Primary Metrics',
    active: false,
    value: PrivacyLevel.primary_metrics,
    description: (
      <ul>
        <li>Main pillar metrics: Strain, Recovery, and Sleep</li>
        <li>Group and individual data</li>
      </ul>),
  },
  {
    name: 'Aggregate Metrics',
    active: false,
    value: PrivacyLevel.aggregate_metrics,
    description: (
      <ul>
        <li>Main pillar metrics: Strain, Recovery, and Sleep</li>
        <li>Group averages only</li>
      </ul>),
  },
  {
    name: 'No Metrics',
    active: true,
    value: PrivacyLevel.no_metrics,
    description: 'No access to any data or metrics. Collaborators will only be able to manage members in groups',
  },
];
