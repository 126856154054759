import { client } from 'api/apiClient';
import { AccountDetail, AccountLicenses } from 'types/account';

const accountUrl = 'enterprise-service/v1/accounts';

export const getAccounts = async () => {
  const response = await client.get<AccountDetail[]>(
    `${accountUrl}`,
  );
  return response.data;
};

export const getAvailableBulkLicenses = async () => {
  const response = await client.get<AccountLicenses>(
    `${accountUrl}/license-count`,
  );
  return response.data;
};

export const resetAccount = async (salesforceAccountId: String) => {
  const params = { salesforceAccountId };
  await client.post(`${accountUrl}/reset-account`, { params });
};
