import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@whoop/web-components';
import { HEADING_5 } from 'components/text';
import { makeStyles } from '@material-ui/core/styles';
import EmailsInputHeader from '../emailsInputHeader/emailsInputHeader';
import { typeEmails, uploadEmails } from '../../constants';
import { parseEmails } from '../../util';

const useStyles = makeStyles({
  chip: {
    position: 'relative',
    width: '100%',
  },
  deleteIcon: {
    position: 'absolute',
    right: 0,
  },
  dropzone: {
    height: '96px',
    padding: '32px',
    border: '1px dashed #000000',
    marginBottom: '16px',
  },
});

type CSVUploadProps = {
  emails: string[];
  setEmails: (emails: string[]) => void;
  invalidEmails: string[];
  setInvalidEmails: (emails: string[]) => void;
  setInputType: (inputType: string) => void;
};

function CSVUpload({
  emails,
  setEmails,
  invalidEmails,
  setInvalidEmails,
  setInputType,
}: CSVUploadProps) {
  const classes = useStyles();

  const parseCSV = useCallback(
    (e) => {
      const text = e.target.result;
      const { validParsedEmails, invalidParsedEmails } = parseEmails(text);
      const validDistinctEmails = validParsedEmails.filter(
        (email) => !emails.includes(email),
      );
      const invalidDistinctEmails = invalidParsedEmails.filter(
        (email) => !invalidEmails.includes(email),
      );
      setEmails([...validDistinctEmails]);
      setInvalidEmails([...invalidDistinctEmails]);
      setInputType(typeEmails.value);
    },
    [emails, invalidEmails, setEmails, setInvalidEmails],
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = parseCSV;
      reader.readAsText(acceptedFiles[0]);
    },
    [parseCSV],
  );
  const dropzone = useDropzone({ onDrop, multiple: false });
  const { getRootProps, getInputProps, isDragActive } = dropzone;

  return (
    <>
      <EmailsInputHeader
        inputType={uploadEmails.value}
        setInputType={setInputType}
      />
      <HEADING_5>CSV UPLOAD</HEADING_5>
      <p>
        Upload contacts in a csv. Check to make sure there’s only one email per
        cell. Any text that’s not formatted email@example.com will not be added.
      </p>
      <div {...getRootProps()} className={classes.dropzone}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <>
            <p>Drag and drop a file here, or click to select a file</p>
            <Button theme="enterprise" variant="secondary" label="upload csv" />
          </>
        )}
      </div>
    </>
  );
}

export default CSVUpload;
