import { client } from 'api/apiClient';
import { ShippingMethodType } from 'wizards/invite/types';
import { SeatDetail, Seat, SeatStatus } from 'types/seat';

const seatUrl = 'enterprise-service/v1/seats';

type SeatInviteUrl = {
  seat_invite_link: String;
};

export const removeMember = async (seatId: number) => {
  const response = await client.put<Seat>(
    `${seatUrl}/${seatId}/remove`,
  );
  return response.data;
};

export const getAllSeats = async () => {
  const { data } = await client.get<SeatDetail[]>(`${seatUrl}/all`);
  const newList = data.map((seat) => ({
    ...seat,
    // If a seatStatus comes in as hub_pending, set it to pending in the FE
    status: seat.status.toString() === 'hub_pending' ? SeatStatus.Pending : seat.status,
  }));
  return { data: newList };
};

export const cancelInvite = async (seatId: number) => {
  await client.delete(`${seatUrl}/${seatId}/invite`);
};

export const acceptEnterpriseInvite = async (token: string) => client.put<SeatDetail>(`${seatUrl}/accept?token=${token}`);

export const sendInvites = async (
  emails: string[],
  shippingType?: ShippingMethodType,
) => {
  await client.post(
    `${seatUrl}/invite`,
    { emails, shipping_type: shippingType },
  );
};

export const getInviteLink = async (seatId: number) => {
  const params = { seatId };
  return client.get<SeatInviteUrl>(`${seatUrl}/invite-link`, { params });
};
