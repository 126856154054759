import React, {
  useMemo, useCallback, useRef,
} from 'react';
import { headerProps, cellProps, rowStyles } from 'tableUtils/tableProps';
import { HEADING_5 } from 'components/text';
import {
  useTable,
  useRowSelect,
  useMountedLayoutEffect,
  Row,
} from 'react-table';
import { UseStatePropType } from 'types/useStatePropType';
import { SeatDetail } from 'types/seat';
import { HubListPayload } from 'types/hub';
import { Collaborator } from 'types/collaborator';
import styles from './simpleTable.module.scss';

/* eslint-disable react/jsx-props-no-spreading */

type SimpleTableProps = {
  setSelectedRows: UseStatePropType<any[]>;
  selectedRows: Row<any>[];
  columnList: any[];
  rowAccessor: (row: SeatDetail | HubListPayload | Collaborator) => number | string;
};

function SimpleTable({
  selectedRows, setSelectedRows, columnList, rowAccessor,
}: SimpleTableProps) {
  const savedRows = useRef(selectedRows.map((row) => row.original));
  const columns = useMemo(
    () => columnList,
    [],
  );

  const initialSelectedRowIds = useMemo(() => {
    const rowIds: { [key: number | string]: true } = {};
    savedRows.current.forEach((row) => {
      rowIds[rowAccessor(row)] = true;
    });
    return rowIds;
  }, [selectedRows]);

  const getRowId = (row: any, relativeIndex: any) => rowAccessor(row).toString() || relativeIndex;

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { selectedRowIds },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: savedRows.current,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      initialState: { selectedRowIds: initialSelectedRowIds },
      getRowId: useCallback(getRowId, []),
    },
    useRowSelect,
  );

  useMountedLayoutEffect(() => {
    if (setSelectedRows) {
      setSelectedRows(selectedFlatRows);
    }
  }, [setSelectedRows, selectedRowIds]);

  return (
    <table className={styles.table} {...getTableBodyProps()}>
      <thead className={styles.stickyHeader}>
        {headerGroups.map((headerGroup) => (
          <tr
            key="simpleTableHeader"
            className={styles.headerGroup}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              <th key={column.id} {...column.getHeaderProps(headerProps)}>
                <HEADING_5>{column.render('Header')}</HEADING_5>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr key={row.id} {...row.getRowProps(rowStyles(row))}>
              {row.cells.map((cell) => (
                <td
                  key={cell.row.id}
                  // @ts-ignore
                  {...cell.getCellProps([cellProps(cell.column)])}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default SimpleTable;
