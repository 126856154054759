import { Icon } from '@whoop/web-components';
import Badge, { BadgeColors } from 'components/badge/badge';
import PropTypes from 'prop-types';
import { HubSeatStatus, HubStatus } from 'types/hub';
import { SeatStatus } from 'types/seat';
import styles from './status-badge.module.scss';

type StatusBadgeProps = {
  status: SeatStatus | HubSeatStatus | HubStatus;
};

function StatusBadge({ status }: StatusBadgeProps) {
  let badge: JSX.Element;
  switch (status) {
    case SeatStatus.Active || HubSeatStatus.Active:
      badge = <Badge color={BadgeColors.PRIMARY}>Activated</Badge>;
      break;
    case SeatStatus.Deactivated:
      badge = <Badge color={BadgeColors.SECONDARY}>Deactivated</Badge>;
      break;
    case SeatStatus.Unknown:
      badge = <Badge color={BadgeColors.DANGER}>Needs Action</Badge>;
      break;
    case SeatStatus.Invited:
      badge = <Badge color={BadgeColors.WARNING}>Invited</Badge>;
      break;
    case HubSeatStatus.Pending:
      badge = <Badge color={BadgeColors.WARNING}>Pending</Badge>;
      break;
    case HubSeatStatus.Archived:
      badge = <Badge color={BadgeColors.SECONDARY}>Archived</Badge>;
      break;
    case HubStatus.ONLINE:
      badge = <Badge color={BadgeColors.PRIMARY}>Online</Badge>;
      break;
    case HubStatus.PENDING:
      badge = <Badge color={BadgeColors.SECONDARY}>Pending</Badge>;
      break;
    case HubStatus.AWAITING_ACTIVATION:
      badge = <Badge color={BadgeColors.LIGHT_BLUE}>Awaiting Activation</Badge>;
      break;
    case HubStatus.OFFLINE:
      badge = (
        <Badge color={BadgeColors.OFFLINE_WARNING}>
          <Icon className={styles.offlineIcon} name="attention" />
          Offline
        </Badge>
      );
      break;
    default:
      badge = <Badge color="secondary">--</Badge>;
  }

  return <span className={styles.statusBadge}>{badge}</span>;
}

StatusBadge.propTypes = {
  status: PropTypes.string,
};

export default StatusBadge;
