import React, { ReactElement, Ref } from 'react';
import { MenuItem, MenuList } from '@material-ui/core';
import normalize from '../util';
import styles from './dropdownList.module.scss';

type DropdownListProps = {
  items: {
    name: string;
    active: boolean;
    onClick: () => void;
    dataTestId?: string;
  }[];
  activeIcon: ReactElement;
};

const DropdownList = React.forwardRef((
  { items, activeIcon }: DropdownListProps,
  ref: Ref<HTMLUListElement>,
) => (
  <MenuList disablePadding ref={ref}>
    {items.map((item) => (
      <MenuItem
        data-testid={item.dataTestId}
        key={`menu-item-${item.name}`}
        className={
          item.active
            ? styles.activeItem
            : styles.inactiveItem
        }
        onClick={item.onClick}
      >
        {item.active ? activeIcon : ''}
        <p className={`${styles.itemLabel}`}>{normalize(item.name)}</p>
      </MenuItem>
    ))}
  </MenuList>
));

export default DropdownList;
