import React, { useState } from 'react';
import {
  Chip, InputLabel, TextField,
} from '@material-ui/core';
import {
  checkEmail, isEmail, parseEmails,
} from 'wizards/invite/util';
import { Icon } from '@whoop/web-components';
import { UseStatePropType } from 'types/useStatePropType';
import emailsInputStyles from './emailsInputStyles';

type EmailsInputProps = {
  label: string;
  emails: string[];
  setEmails?: UseStatePropType<string[]>;
  invalidEmails?: string[];
  setInvalidEmails?: UseStatePropType<string[]>;
  contextualError?: string;
  disabled?: boolean;
};

function EmailsInput({
  label,
  emails,
  setEmails = () => {},
  invalidEmails = [],
  setInvalidEmails = () => {},
  contextualError = '',
  disabled = false,
}: EmailsInputProps) {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const classes = emailsInputStyles();

  const handleDeleteEmail = (item: string) => {
    setEmails(emails.filter((i) => i !== item));
  };

  const handleDeleteInvalidEmail = (item: string) => {
    setInvalidEmails(invalidEmails.filter((i) => i !== item));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setError(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Tab', ',', ' '].includes(e.key)) {
      e.preventDefault();

      const possibleEmail = value.trim();

      if (possibleEmail) {
        const emailError = checkEmail(emails, possibleEmail);
        if (emailError) {
          setError(emailError);
        } else {
          setEmails([...emails, possibleEmail]);
          setValue('');
        }
      }
    }

    if (['Backspace', 'Delete'].includes(e.key) && value === '') {
      if (invalidEmails.length > 0) {
        setInvalidEmails(invalidEmails.slice(0, -1));
      } else {
        setEmails(emails.slice(0, -1));
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const pastedText = e.clipboardData.getData('text');
    const { validParsedEmails, invalidParsedEmails } = parseEmails(pastedText);
    const validDistinctEmails = validParsedEmails.filter(
      (email: string) => !emails.includes(email),
    );
    const invalidDistinctEmails = invalidParsedEmails.filter(
      (email: string) => !invalidEmails.includes(email),
    );
    setEmails([...emails, ...validDistinctEmails]);
    setInvalidEmails([...invalidEmails, ...invalidDistinctEmails]);
  };

  const displayChips = (chipEmails: string[]) => (
    <>
      {chipEmails.map((email: string) => (
        <Chip
          key={email}
          label={email}
          className={`${classes.chip} ${!isEmail(email) && classes.errorChip}`}
          onDelete={isEmail(email)
            ? () => handleDeleteEmail(email)
            : () => handleDeleteInvalidEmail(email)}
          data-testid={isEmail(email) ? `valid-email-${email}` : `invalid-email-${email}`}
          deleteIcon={<Icon className={classes.crossIcon} name="remove_cross" />}
          classes={{
            label: classes.chipLabel,
          }}
        />
      ))}
    </>
  );

  const errorText = contextualError || error;

  return (
    <>
      <InputLabel htmlFor="emailsInput" className={classes.emailsInputLabel}>{label}</InputLabel>
      <TextField
        id="emailsInput"
        value={value}
        error={!!(error || contextualError)}
        classes={{ root: classes.inputBox }}
        multiline
        disabled={disabled}
        InputProps={{
          startAdornment: displayChips(emails.concat(invalidEmails)),
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event),
          onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(event),
          onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => handlePaste(event),
          disableUnderline: true,
          placeholder: emails.length + invalidEmails.length > 0 ? '' : 'Separate email addresses with commas',
          classes: { root: classes.input, input: classes.textArea },
        }}
      />
      {errorText && <div className={classes.errorText}>{errorText}</div>}
    </>
  );
}

export default EmailsInput;
