import { getDelayVal, mswBaseUri } from 'mocks/utils/utils';
import {
  caloriesBreakdown,
  hrvBreakdown,
  recoveryBreakdown,
  rhrBreakdown,
  sleepConsistencyBreakdown,
  sleepDurationBreakdown,
  sleepEfficiencyBreakdown,
  sleepPerformanceBreakdown,
  strainBreakdown,
  workoutCountBreakdown,
} from 'mocks/actions/breakdownActions';
import { rest } from 'msw';

export const breakdownHandlers = [
  rest.get(mswBaseUri('v3/breakdown/strain'), (_req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(strainBreakdown),
  )),

  rest.get(mswBaseUri('v3/breakdown/calories'), (_req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(caloriesBreakdown),
  )),

  rest.get(mswBaseUri('v3/breakdown/workout-count'), (_req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(workoutCountBreakdown),
  )),

  rest.get(mswBaseUri('v3/breakdown/recovery-score'), (_req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(recoveryBreakdown),
  )),

  rest.get(mswBaseUri('v3/breakdown/rhr'), (_req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(rhrBreakdown),
  )),

  rest.get(mswBaseUri('v3/breakdown/hrv'), (_req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(hrvBreakdown),
  )),

  rest.get(mswBaseUri('v3/breakdown/sleep-consistency'), (_req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(sleepConsistencyBreakdown),
  )),

  rest.get(mswBaseUri('v3/breakdown/sleep-efficiency'), (_req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(sleepEfficiencyBreakdown),
  )),

  rest.get(mswBaseUri('v3/breakdown/sleep-performance'), (_req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(sleepPerformanceBreakdown),
  )),

  rest.get(mswBaseUri('v3/breakdown/sleep-duration'), (_req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(sleepDurationBreakdown),
  )),
];

export default breakdownHandlers;
