import { AccountDetail } from 'types/account';
import {
  GroupAverage, MonthDayValues, Pillars, RecoveryMetric, SleepMetric, StrainMetric,
} from 'types/analytics';
import { Colors } from '@whoop/web-components';
import { AxisTickProps } from '@nivo/axes';
import styles from './progressPageUtils.module.scss';
import { getFormattedDate, getMonthDayValues } from './utils/date';

const RECOVERY_RED_MAX = 33;
const RECOVERY_YELLOW_MAX = 66;
export const STRAIN_MAX = 21;
export const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

export const getTotalInactive = (accountInfo: AccountDetail) => {
  const inactive = accountInfo.total_licenses
    - accountInfo.active_seats - accountInfo.invited_seats - accountInfo.pending_seats;
  return inactive > 0 ? inactive : 0;
};

export const getAvgValue = (data: GroupAverage[], strain: boolean = false) => {
  if (data.length > 0) {
    if (strain && data[data.length - 1].average) {
      return +(data[data.length - 1].average).toFixed(1);
    }
    return Math.round(data[data.length - 1].average);
  }
  return null;
};

export const getAvgTrendValue = (data: GroupAverage[], strain: boolean = false) => {
  if (data.length > 1) {
    if (strain) {
      return +(data[data.length - 1].average - data[data.length - 2].average).toFixed(1);
    }
    return Math.round(data[data.length - 1].average - data[data.length - 2].average);
  }
  return null;
};

export const createEmptyAverageData = (
  averages: GroupAverage[],
  metricName: StrainMetric | RecoveryMetric | SleepMetric,
): GroupAverage[] => {
  let averagesToUse;
  if (averages.length < 30) {
    const emptyAverages: GroupAverage[] = [];
    const today = new Date();
    let nextDate = new Date(today.setDate(today.getDate() - 30));
    for (let i = averages.length; i < 30; i += 1) {
      nextDate = new Date(nextDate.setDate(nextDate.getDate() + 1));
      emptyAverages.push({
        metric_name: metricName,
        average: 0,
        date: getFormattedDate(nextDate),
      });
    }
    averagesToUse = [...emptyAverages, ...averages];
  } else {
    averagesToUse = averages;
  }
  averagesToUse.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));
  return averagesToUse;
};

export const getColorFromVal = (value: number, hidden: boolean = false): string => {
  if (value <= RECOVERY_RED_MAX) {
    return hidden ? Colors.redAlpha200 : Colors.red;
  } if (value <= RECOVERY_YELLOW_MAX) {
    return hidden ? Colors.yellowAlpha200 : Colors.yellow;
  }
  return hidden ? Colors.greenAlpha200 : Colors.green;
};

export const getColorForPillar = (pillar: Pillars, value?: number) => {
  if (pillar === Pillars.STRAIN) { return Colors.strainBlue; }
  if (pillar === Pillars.RECOVERY) { return getColorFromVal(value); }
  if (pillar === Pillars.SLEEP) { return Colors.sleepBlue; }
  return Colors.gray500;
};

export function DayMonthXAxisTick(
  tick: AxisTickProps<string>,
  showDay: boolean = false,
  showMonth: boolean = true,
) {
  const { x, y, value } = tick;
  const { day, month, dayNum }: MonthDayValues = getMonthDayValues(value);
  let secondLineText = '';
  if (showDay && showMonth) {
    secondLineText = `${month} ${dayNum}`;
  } else {
    secondLineText = `${dayNum}`;
  }
  return (
    <g transform={`translate(${x},${y + 14})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        className={`${styles.tickFormatting} ${showDay ? styles.profileTickFormatting : ''}`}
        fill="#808080"
      >
        <tspan x="0" dy={showDay ? '4px' : '0px'}>
          {showDay ? day : month}
        </tspan>
        <tspan x="0" dy={showDay ? '18px' : '14px'}>
          {secondLineText}
        </tspan>
      </text>
    </g>
  );
}

const getAxisTickColor = (useColors: boolean, strain: boolean, percentage: number) => {
  if (useColors) {
    return strain ? Colors.strainBlue : getColorFromVal(percentage);
  }
  return '#808080';
};

export function PillarYAxisTick(
  tick: AxisTickProps<string>,
  useColors: boolean,
  strain: boolean,
  customFill?: string,
) {
  const { x, y, value } = tick;
  const percentage = useColors
    ? Number(((Number(value) / STRAIN_MAX) * 100).toFixed()) : Number.parseInt(value, 10);

  // setting the x value depending on strain or not strain axis
  // and useColors (profile page) adjusts for font size
  let transformXVal = !strain && useColors ? x + 16 : x - 16;
  transformXVal = useColors ? transformXVal + 8 : transformXVal;
  transformXVal = useColors && strain ? transformXVal - 16 : transformXVal;

  return (
    <g transform={`translate(${transformXVal},${y})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        className={`${styles.tickFormatting} ${useColors ? styles.profileTickFormatting : ''}`}
        fill={customFill ?? getAxisTickColor(useColors, strain, percentage)}
      >
        {strain ? value : `${percentage}%`}
      </text>
    </g>
  );
}
