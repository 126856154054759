import {
  N3, N6, OVERLINE_2, TITLE_2,
} from 'components/text';
import Loading from 'loading';
import { useAccounts } from 'context/account-context';
import styles from './strapManagementHeader.module.scss';

function StrapManagementHeader() {
  const { account, loading } = useAccounts();
  if (loading) {
    return <Loading />;
  }

  const joinedDate = account?.joined_date
    ? new Date(account.joined_date).getUTCFullYear()
    : null;

  const active = account.active_seats;
  const invited = account.invited_seats;
  const pending = account.pending_seats;
  const activeAndInvitedAndPendingMemberships = active + invited + pending;
  const total = account.total_licenses;
  const inactive = total - activeAndInvitedAndPendingMemberships;

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <TITLE_2 className={styles.title}>{account.account_name}</TITLE_2>
        {joinedDate && (
          <OVERLINE_2 className={styles.subtext}>
            joined
            {' '}
            {joinedDate}
          </OVERLINE_2>
        )}
      </div>

      <div className={styles.membershipAssignedContainer}>
        <span className={styles.sensorCountContainer}>
          <N3>{total}</N3>
          <N6>TOTAL ACCT. MEMBERSHIPS </N6>
          <OVERLINE_2 className={styles.subtext}>
            {`${active} activated / ${pending} pending / ${invited} invited`}
          </OVERLINE_2>
          <OVERLINE_2 className={styles.subtext}>
            {inactive}
            {' '}
            remaining (available)
          </OVERLINE_2>
        </span>
      </div>
    </div>
  );
}

export default StrapManagementHeader;
