import { BODY_SHORT_1 } from 'components/text';

export const STEPS = {
  WELCOME: 'welcome',
  SIGN_IN: 'sign_in',
  CHANGE_EMAIL: 'change_email',
};

export const createAccordionSection = (email: String) => [
  {
    content:
  <BODY_SHORT_1>
    {email}
    {' '}
    can&apos;t be used on both app.whoop.com and Whoop Unite.
    You can free it up by changing your email for app.whoop.com.
  </BODY_SHORT_1>,
    id: 'why-change-email',
    title: 'Why do I have to change my email?',
  },
  {
    content:
  <BODY_SHORT_1>
    Adding &quot;+staff&quot; after your email username is called email aliasing.
    It is a common and safe practice to ensure future messages and
    communications are automatically forwarded to
    {' '}
    {email}
  </BODY_SHORT_1>,
    id: 'aliasing',
    title: 'Why do I have to add "+staff"?',
  },
  {
    content:
  <BODY_SHORT_1>
    Yes, you can still sign into app.whoop.com with the updated email and
    your same password.
  </BODY_SHORT_1>,
    id: 'can-still-sign-in',
    title: 'Can I still use app.whoop.com?',
  },
  {
    content:
  <BODY_SHORT_1>
    Yes, you can, as long as the new email is different from the
    current one and not used on any previous WHOOP accounts.
  </BODY_SHORT_1>,
    id: 'different-email',
    title: 'Can I change my email to something else?',
  },
];
