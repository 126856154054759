import { client } from 'api/apiClient';
import {
  Profile,
  Login,
  UploadUserAvatar,
  User,
} from 'types/auth';

export const login = async (email: string, password: string) => {
  const response = await client.post<Login>('/enterprise-service/v1/login', {
    email,
    password,
  });

  return response.data;
};

export const validateInviteAndlogin = async (
  email: string,
  password: string,
  inviteToken: string,
) => {
  const response = await client.put<Login>(
    `/enterprise-service/v1/invite/user?token=${inviteToken}`,
    {
      email,
      password,
    },
  );

  return response.data;
};

export const getProfile = async () => {
  const response = await client.get<Profile>('/enterprise-service/v1/user');

  return response.data;
};

export const validateAndCreateUser = async (user: User, token: string) => {
  const {
    email,
    password,
    firstName,
    lastName,
    username,
    country,
    tos_accepted,
    type,
    can_upload_data,
  } = user;
  const response = await client.post<number>(
    `/enterprise-service/v1/invite/user?token=${token}`,
    {
      email,
      password,
      username,
      first_name: firstName,
      last_name: lastName,
      city: '',
      admin_division: '',
      country,
      tos_accepted,
      type,
      can_upload_data,
    },
  );
  return response.data;
};

export const uploadUserAvatar = async (userId: string, image: Blob) => {
  const response = await client.put<UploadUserAvatar>(
    `/users-service/v1/users/user/avatar?userId=${userId}`,
    image,
    {
      headers: {
        'Content-Type': image.type,
      },
    },
  );
  return response.data;
};

export const sendForgotPasswordEmail = async (email: string): Promise<void> => {
  await client.post(
    '/enterprise-service/v1/login/password/forgot',
    {},
    { params: { email } },
  );
};

export const resetPassword = async (
  token: string,
  new_password: string,
): Promise<void> => {
  await client.post(
    '/enterprise-service/v1/login/password/reset',
    { new_password },
    { params: { token } },
  );
};
