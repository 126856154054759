import { CheckSharp, ClearSharp } from '@material-ui/icons';
import styles from '../register.module.scss';

function PasswordStrengthSpec({ label, success }) {
  return (
    <div className={styles.spec}>
      {success ? (
        <CheckSharp
          data-testid="successful"
          fontSize="inherit"
          style={{ color: '#19EC06' }}
        />
      ) : (
        <ClearSharp
          data-testid="failure"
          fontSize="inherit"
          style={{ color: 'red' }}
        />
      )}
      <p>{label}</p>
    </div>
  );
}

export default PasswordStrengthSpec;
