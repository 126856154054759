import { cohortClient } from 'api';
import LoadingPage from 'loading';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GroupIdParams } from 'types/analytics';

function GroupsRedirect() {
  const { groupId } = useParams<GroupIdParams>();
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      const retrieveGroup = await cohortClient.getGroupById(Number(groupId));
      navigate(`/accounts/${retrieveGroup.salesforce_account_id}/people/groups/${groupId}`);
    };
    init();
  }, [groupId]);

  return (<LoadingPage />);
}

export default GroupsRedirect;
