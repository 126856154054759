/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { WhoopButtonProps } from 'types/collaborator';
import StickyFooter from 'components/stickyFooter';
import { Button } from '@whoop/web-components';
import { useNavigate } from 'react-router-dom';
import styles from './actionFooter.module.scss';

type ActionFooterTypes<S> = {
  state?: S;
  getPrimaryButtonProps: (state: S) => WhoopButtonProps;
  getSecondaryButtonProps: (state: S) => WhoopButtonProps;
  dispatch?: (action: { type: 'next' }) => void;
};

function ActionFooter<S extends unknown>({
  state,
  getPrimaryButtonProps,
  getSecondaryButtonProps,
  dispatch,
}: ActionFooterTypes<S>) {
  const navigate = useNavigate();
  return (
    <StickyFooter>
      <div className={styles.actionFooter}>
        <div>
          <Button
            onClick={() => navigate(-1)}
            {...getSecondaryButtonProps(state)}
          />
          <Button
            onClick={() => dispatch({ type: 'next' })}
            {...getPrimaryButtonProps(state)}
          />
        </div>
      </div>
    </StickyFooter>
  );
}

export default ActionFooter;
