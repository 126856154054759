import {
  createElement, Fragment, ReactNode, useLayoutEffect, useState,
} from 'react';
import { useFeatureFlags } from './feature-flag-context';

type FeatureFlagProps = {
  flagName: string;
  children: ReactNode;
};

function FeatureFlag({ flagName, children }: FeatureFlagProps) {
  const { getFeatureFlag } = useFeatureFlags();
  const [isFlagEnabled, setIsFlagEnabled] = useState(false);

  useLayoutEffect(() => {
    async function fetchFlag() {
      try {
        const flag = await getFeatureFlag(flagName);
        setIsFlagEnabled(flag);
      } catch (e) {
        setIsFlagEnabled(false);
      }
    }

    fetchFlag();
  }, [flagName, getFeatureFlag]);

  return createElement(Fragment, undefined, isFlagEnabled ? children : null);
}

export default FeatureFlag;
