import ActionFooter from 'components/actionFooter/actionFooter';
import Table from 'components/table';
import { SUBTITLE_3, TITLE_3 } from 'components/text';
import { useToast } from 'context/toast-context';
import {
  capitalizeWords,
  createToastMessage,
  getActionRemoveButtonProps,
  getSecondaryButtonProps,
  ModalType,
  removeMembersFromGroup,
  ToastType,
} from 'groups/utils';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CellProps, Column, Row, TableCommonProps,
} from 'react-table';
import { dateCell, nameSubtitleCell } from 'tableUtils/tableCells';
import { SELECTION_COLUMN } from 'tableUtils/tableColumns';
import { GroupMember } from 'types/cohort';
import { UseStatePropType } from 'types/useStatePropType';
import styles from './removeGroupMembersPage.module.scss';

type RemoveGroupMembersPageProps = {
  setShowModal: (type: ModalType) => void;
  refresh: () => void;
  selectedMembers: GroupMember[];
  groupName: string;
  setSelectedMemberRows: UseStatePropType<Row<GroupMember>[]>;
};

function RemoveGroupMembersPage({
  setShowModal, refresh, selectedMembers, groupName, setSelectedMemberRows,
}: RemoveGroupMembersPageProps) {
  const [membersToRemove, setMembersToRemove] = useState<GroupMember[]>(selectedMembers);

  // Cleanup when unmounted
  useEffect(() => () => {
    setSelectedMemberRows([]);
  }, []);

  const { openToast } = useToast();
  const columns: Column<GroupMember>[] = useMemo(() => [
    SELECTION_COLUMN,
    {
      Header: 'Member Name',
      id: 'name',
      accessor: (member: GroupMember) => capitalizeWords(`${member.first_name} ${member.last_name}`),
      width: '30%',
      align: 'left',
      Cell: (cellProps: CellProps<GroupMember>) => nameSubtitleCell(cellProps),
    },
    {
      Header: 'Group Name',
      id: 'groupName',
      width: '30%',
      align: 'left',
      Cell: <span>{groupName}</span>,
    },
    {
      Header: 'Activated On',
      id: 'activatedOn',
      width: '20%',
      align: 'left',
      accessor: (member) => new Date(member.activated_on),
      Cell: dateCell,
    },
  ], []);

  const handleRemoveMembers = async () => {
    if (membersToRemove.length > 0) {
      const userIds = membersToRemove.map((member) => member.user_id);
      const response = await removeMembersFromGroup(membersToRemove[0].cohort_id, userIds);
      setShowModal(ModalType.NONE);
      openToast(
        createToastMessage(
          response.fulfilled,
          response.failed,
          groupName,
          ToastType.REMOVE_MEMBER,
        ),
      );
      refresh();
    }
  };

  const getRowStyles = (): TableCommonProps => ({
    style: {
      height: '80px',
    },
  });

  const handleSelection = (memberRows: Row<GroupMember>[]) => {
    setMembersToRemove(memberRows.map((row) => row.original));
  };

  const plural = membersToRemove.length > 1;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <TITLE_3>
          Are you sure you want to remove
          {plural ? ' these members ' : ' this member '}
          from this group?
        </TITLE_3>
        <SUBTITLE_3 className={styles.subtitle}>
          {plural ? 'These members ' : 'This member '}
          will no longer be in this group.
        </SUBTITLE_3>

        <SUBTITLE_3 className={styles.selected}>{`${membersToRemove.length} selected members`}</SUBTITLE_3>
        <Table
          columns={selectedMembers.length > 1 ? columns : columns.slice(1)}
          data={selectedMembers}
          loading={false}
          tableLength={50}
          tableName="removeMembersTable"
          unit="member"
          displayHeader={false}
          search={false}
          getAdditionalRowProps={getRowStyles}
          setSelectedRows={handleSelection}
          pagination={false}
          selectAllInitially={selectedMembers.length > 1}
        />
      </div>
      <ActionFooter
        getPrimaryButtonProps={() => getActionRemoveButtonProps(
          membersToRemove,
          handleRemoveMembers,
        )}
        getSecondaryButtonProps={
            () => getSecondaryButtonProps(() => setShowModal(ModalType.NONE))
          }
      />
    </div>
  );
}

export default RemoveGroupMembersPage;
