import { addGroupAccess, addMemberToGroup, removeMemberFromGroup } from 'api/cohortApi';
import { GroupMember, CohortCollaborator } from 'types/cohort';

export enum ToastType {
  ADD_COLLABORATOR,
  ADD_MEMBER,
  REMOVE_MEMBER,
}

export const capitalizeWords = (str: string, splitter: string = ' ') => {
  const words = str.split(splitter);
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
};

export const createToastMessage = (
  fulfilled: number,
  failed: number,
  groupName: string,
  toastType: ToastType,
): string => {
  let unit = 'member';
  let action = 'added';
  if (toastType === ToastType.ADD_COLLABORATOR) {
    unit = 'collaborator';
  }
  if (toastType === ToastType.REMOVE_MEMBER) {
    action = 'removed';
  }
  let message: string = `${capitalizeWords(action)} ${fulfilled} ${unit}${
    fulfilled > 1 ? 's' : ''
  } ${toastType === ToastType.REMOVE_MEMBER ? 'from' : 'to'} ${groupName}.`;

  if (failed > 0) {
    message += ` ${failed} ${unit}${failed > 1 ? 's' : ''} failed to be ${action}.`;
  }

  return message;
};

export const getActionButtonProps = (
  selectedMembers: GroupMember[] | CohortCollaborator[],
  onClick: () => void,
) => {
  const label = 'Save';
  const isDisabled = selectedMembers.length === 0;
  return {
    theme: 'enterprise',
    variant: 'primary',
    label,
    disabled: isDisabled,
    onClick,
  };
};

export const getActionRemoveButtonProps = (selectedMembers: GroupMember[], onClick: () => void) => {
  const label = `Yes, Remove ${selectedMembers.length} ${selectedMembers.length > 1 ? 'members' : 'member'}`;
  const isDisabled = selectedMembers.length === 0;
  return {
    theme: 'default',
    variant: 'primary',
    label,
    disabled: isDisabled,
    onClick,
  };
};

export const getSecondaryButtonProps = (cancel: () => void) => ({
  variant: 'link',
  label: 'Cancel',
  onClick: cancel,
});

export type BulkPromisesResponse = {
  fulfilled: number;
  failed: number;
};

export const addMembersToGroup = async (
  groupId: number,
  memberIds: number[],
): Promise<BulkPromisesResponse> => {
  const promises = memberIds.map((memberId) => addMemberToGroup(groupId, memberId));

  const results = await Promise.allSettled(promises);
  const numFulfilled = results.filter(
    (res) => res.status === 'fulfilled',
  ).length;
  const failed = promises.length - numFulfilled;

  return {
    fulfilled: numFulfilled,
    failed,
  };
};

export const addCollaboratorsToGroup = async (
  groupId: number,
  userIds: number[],
): Promise<BulkPromisesResponse> => {
  const promises = userIds.map((userId) => addGroupAccess({ cohort_id: groupId, user_id: userId }));

  const results = await Promise.allSettled(promises);
  const numFulfilled = results.filter(
    (res) => res.status === 'fulfilled',
  ).length;
  const failed = promises.length - numFulfilled;

  return {
    fulfilled: numFulfilled,
    failed,
  };
};

export const removeMembersFromGroup = async (
  groupId: number,
  userIds: number[],
): Promise<BulkPromisesResponse> => {
  const promises = userIds.map((userId) => removeMemberFromGroup(groupId, userId));

  const results = await Promise.allSettled(promises);
  const numFulfilled = results.filter(
    (res) => res.status === 'fulfilled',
  ).length;
  const failed = promises.length - numFulfilled;

  return {
    fulfilled: numFulfilled,
    failed,
  };
};

export enum ModalType {
  NONE,
  ADD_GROUP_MEMBERS,
  ADD_COLLABORATORS,
  REMOVE_GROUP_MEMBERS,
}
