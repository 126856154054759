import { configurations, reidentificationTokenBody } from 'mocks/store/data';
import { context, compose } from 'msw';
import hash from 'hash.js';
import { reidentificationTokenKey } from 'types/admin';

// Turn field into Hash
const encode = (field) => hash.sha256().update(field).digest('hex').substring(0, 6)
  .toUpperCase();

// List of all PII fields to check against each object
const piiFields = ['email', 'avatar_url', 'first_name', 'last_name', 'username', 'birthday', 'invited_email'];

// iterate over object/array looking for Pii fields
export const recursiveDeid = (data, fieldName = '') => {
  if (data == null) {
    if (typeof data === 'object') return null;
    return undefined;
  }
  if (Array.isArray(data)) {
    return data.map((d) => recursiveDeid(d));
  }
  if (typeof data === 'object') {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = recursiveDeid(data[key], key);
      return acc;
    }, {});
  }
  if (piiFields.includes(fieldName)) {
    return encode(data);
  }
  return data;
};

export const encodeData = () => configurations.deidentified
  && localStorage.getItem(reidentificationTokenKey) !== reidentificationTokenBody.token;

// handles sending back the data depending if deidentification is enabled or disabled
export const jsonCheckForPII = (jsonData) => {
  let newJsonData = jsonData;

  if (encodeData()) {
    newJsonData = recursiveDeid(jsonData);
  }

  return compose(
    context.delay(400),
    context.status(200),
    context.json(newJsonData),
  );
};

export default jsonCheckForPII;
