import React, { useState, useRef } from 'react';
import { CameraAltOutlined, EditOutlined } from '@material-ui/icons';
import { Button } from '@whoop/web-components';
import { uploadUserAvatar } from 'api/authApi';
import PulsingLogo from 'components/spinner';
import ProfileImageUploadModal from './profileImageUploadModal';
import styles from '../register.module.scss';
import { PAGE_STATE } from '../constants';

function OptionalForms({ userId, setStep }) {
  const inputFile = useRef(null);

  const [profileImageTmp, setProfileImageTmp] = useState({});
  const [profileImage, setProfileImage] = useState({});
  const [showProfileImageModal, setShowProfileImageModal] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleProfileImageUpload = (evt) => {
    if (evt.target.files.length !== 0) {
      setProfileImageTmp(URL.createObjectURL(evt.target.files[0]));
      setShowProfileImageModal(true);
      inputFile.current.value = null;
    }
  };

  const handleProfileImageSave = async () => {
    setLoading(true);
    setProfileImage(profileImageTmp);
    setShowProfileImageModal(false);

    try {
      const blob = await fetch(profileImageTmp).then((r) => r.blob());
      await uploadUserAvatar(userId, blob);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleFileInput = () => {
    inputFile.current.click();
  };

  return (
    <>
      {loading && <PulsingLogo overlay />}

      {showProfileImageModal && (
        <ProfileImageUploadModal
          data-testid="profile-img-modal"
          src={profileImageTmp}
          handleClose={() => setShowProfileImageModal(false)}
          handleSave={handleProfileImageSave}
        />
      )}
      <div className={styles.infoText}>
        <p>STEP 3/3</p>
      </div>

      <div className={styles.formContainer}>
        <label id="profileImageLabel" className={styles.inputFieldLabel}>
          Choose a profile image (optional)
        </label>

        <div className={styles.center}>
          <div className={styles.profileImageWrap} onClick={handleFileInput}>
            <input
              type="file"
              accept="image/png, image/jpeg"
              className={styles.profileImageUpload}
              onChange={handleProfileImageUpload}
              ref={inputFile}
              data-testid="profile-img-input"
            />
            {Object.keys(profileImage).length !== 0 ? (
              <>
                {showEditIcon && <EditOutlined className={styles.editIcon} />}
                <img
                  src={profileImage}
                  className={styles.profileImageSaved}
                  alt="Profile Icon"
                  onMouseEnter={() => setShowEditIcon(true)}
                  onMouseLeave={() => setShowEditIcon(false)}
                />
              </>
            ) : (
              <div>
                <CameraAltOutlined />
                <span>Add Photo</span>
              </div>
            )}
          </div>
        </div>
        <Button
          label="next"
          aria-label="Next"
          variant="primary"
          theme="enterprise"
          type="submit"
          data-testid="next-btn"
          onClick={() => setStep(PAGE_STATE.WELCOME)}
        />
      </div>
    </>
  );
}

export default OptionalForms;
