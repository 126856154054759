import { useEffect, useState } from 'react';
import { SeatStatus } from 'types/seat';
import { HEADING_2, P1 } from 'components/text';
import { useAccounts } from 'context/account-context';
import { getCohortsBySalesforceAccountId } from 'api/cohortApi';
import { getAllSeats } from 'api/seatApi';
import { Cohort } from 'types/cohort';
import DownloadIconButton from 'components/downloadIcon';
import { DashboardRole } from 'types/dashboardUser';
import styles from './accountDetailsHeader.module.scss';

type AccountDetailsHeaderProps = {
  handleDownloadData?: () => void;
};

function AccountDetailsHeader({ handleDownloadData }: AccountDetailsHeaderProps) {
  const {
    account, accountConfig, checkAccess, loading: loadingAccounts,
  } = useAccounts();
  const [allSeats, setAllSeats] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const getSeatData = async () => {
      if (!loadingAccounts) {
        try {
          const { data } = await getAllSeats();
          setAllSeats(data);
          const currentGroups = await getCohortsBySalesforceAccountId(false);
          setGroups(currentGroups.filter((group: Cohort) => !group.is_primary));
        } catch (err) {
          setAllSeats([]);
          setGroups([]);
        }
      }
    };
    getSeatData();
  }, [loadingAccounts, account]);

  // Cleanup function
  useEffect(() => () => {
    setAllSeats([]);
    setGroups([]);
  }, []);

  return (
    <div className={styles.tableHeader}>
      <div className={styles.membersRow}>
        <HEADING_2 className={styles.heading}>All Members</HEADING_2>
        {handleDownloadData
         && accountConfig?.hub_enabled
         && checkAccess(DashboardRole.ACCOUNT_ADMIN)
         && (
         <div className={styles.downloadIconContainer}>
           <DownloadIconButton
             action={handleDownloadData}
             tooltipText="Download roster"
           />
         </div>
         )}
      </div>
      <P1 className={styles.subtext}>
        {`${allSeats.filter((seat) => seat.status === SeatStatus.Active).length} activated members, ${groups.length} groups`}
      </P1>
    </div>
  );
}

export default AccountDetailsHeader;
