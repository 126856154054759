import { Colors } from '@whoop/web-components';
import DayBarChart from 'dataVisualizations/dayBarChart/dayBarChart';
import DayLineChart from 'dataVisualizations/dayLineChart/dayLineChart';
import PillarBreakdownBar from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBar';
import {
  PillarBreakdownBarData,
  pillarKeysToDisplay, strainColors, StrainKeys, strainKeys,
} from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarConsts';
import PillarBreakdownBarLabel from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarLabel';
import { groupStrainBreakdownData } from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarUtils';
import { BarData, LineData } from 'dataVisualizations/types/types';
import { createDayBarLineGraphData } from 'dataVisualizations/utils/utils';
import dayjs from 'dayjs';
import { Col, Row } from 'layout/layout';
import Card from 'progress/components/Card/card';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import PillarStatTile from 'progress/components/pillarStatTile/pillarStatTile';
import {
  getFormattedRangeDay,
} from 'progress/profile/profileUtils';
import { convertToNumberLocale, roundToOneDecimal } from 'progress/utils/numberFormatter';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StrainMetricType } from 'types/analytics';
import { UrlParams } from 'types/profile';
import ActivitiesTable from 'progress/components/ActivitiesTable/activitiesTable';
import { getStrainActivities } from 'api/analyticsApi';
import { ActivityDetails } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import useMetric from 'api/hooks/useMetric';
import styles from './views.module.scss';

enum GraphType {
  STRAIN = 'dayStrain',
  AVERAGE_HR = 'average-hr',
  CALORIES = 'calories',
}

const READABLE_GRAPH_TYPES = {
  [GraphType.STRAIN]: 'Day Strain',
  [GraphType.AVERAGE_HR]: 'Average HR',
  [GraphType.CALORIES]: 'Calories',
};

function RangeView() {
  const { userId } = useParams<UrlParams>();
  const { dateRange, comparisonDateRange } = useDateRange();
  const [graphType, setGraphType] = useState<GraphType>(GraphType.STRAIN);
  const [strainBreakdownData,
    setStrainBreakdownData] = useState<PillarBreakdownBarData[]>([{}]);
  const [graphData, setGraphData] = useState<BarData[] | LineData[]>(null);
  const [maxGraphVal, setMaxGraphVal] = useState<number>(null);
  const [strainActivities, setStrainActivities] = useState<ActivityDetails[]>([null]);
  const [isLoadingStrainActivity, setIsLoadingStrainActivity] = useState<boolean>(true);
  const detailDate = getFormattedRangeDay(dateRange);
  const comparisonDetailDate = `${getFormattedRangeDay(dateRange)} vs ${getFormattedRangeDay(comparisonDateRange)}`;

  const {
    data: strain,
    isLoading: isLoadingStrain,
  } = useMetric(StrainMetricType.STRAIN, dateRange);
  const {
    data: averageHR,
    isLoading: isLoadingAverageHR,
  } = useMetric(StrainMetricType.AVG_HR, dateRange);
  const {
    data: calories,
    isLoading: isLoadingCalories,
  } = useMetric(StrainMetricType.CALORIES, dateRange);

  const selectGraphDataToUse = () => {
    let dataToUse;
    let formatter;
    let staticMaxVal;
    if (graphType === GraphType.STRAIN) {
      dataToUse = strain;
      formatter = roundToOneDecimal;
      staticMaxVal = 21;
    } else if (graphType === GraphType.AVERAGE_HR) {
      dataToUse = averageHR;
      formatter = Math.round;
    } else if (graphType === GraphType.CALORIES) {
      dataToUse = calories;
      formatter = Math.round;
    }
    const { dataPoints, maxVal: computedMax } = createDayBarLineGraphData(
      dataToUse,
      formatter,
      staticMaxVal,
    );
    setGraphData(dataPoints);
    setMaxGraphVal(computedMax);
  };

  const getStrainActivityData = async () => {
    setIsLoadingStrainActivity(true);
    try {
      const activities = await getStrainActivities(
        parseInt(userId, 10),
        dayjs(dateRange.from).format(),
        dayjs(dateRange.to).format(),
      );

      setStrainActivities(activities);
    } catch {
      setStrainActivities(null);
    }
    setIsLoadingStrainActivity(false);
  };

  const loadingStrainStats = isLoadingStrain
    || isLoadingAverageHR
    || isLoadingCalories;

  useEffect(() => {
    getStrainActivityData();
  }, [dateRange]);

  useEffect(() => {
    if (strain) {
      const { barData } = groupStrainBreakdownData(strain);
      setStrainBreakdownData([barData]);
    }

    if (strain && averageHR && calories) {
      selectGraphDataToUse();
    }
  }, [strain, averageHR, calories]);

  useEffect(() => {
    selectGraphDataToUse();
  }, [graphType]);

  const convertStrainToString = (num: number) => {
    if (num === 0) {
      return null;
    }
    return num.toFixed(1);
  };

  const pickCustomLabelFormat = () => {
    switch (graphType) {
      case GraphType.STRAIN:
        return convertStrainToString;
      case GraphType.AVERAGE_HR:
        return null;
      case GraphType.CALORIES:
        return convertToNumberLocale;
      default:
        return null;
    }
  };

  return (
    <>
      <Row center="xs" className={styles.pillarCardsContainer}>
        <Col xs={4}>
          <Card
            id="Day Strain Breakdown"
            loading={isLoadingStrain}
            className={styles.pillarBarCard}
          >
            <CardHeader
              title="Day Strain Breakdown"
              details={detailDate}
            />
            <div className={styles.pillarBreakdownBar}>
              <PillarBreakdownBar
                data={strainBreakdownData}
                colors={strainColors}
                keys={strainKeys}
              />
            </div>
            <div className={styles.labels}>
              {strainKeys.map((key: StrainKeys) => (
                <PillarBreakdownBarLabel
                  key={key}
                  count={strainBreakdownData[0][key]}
                  displayVals={pillarKeysToDisplay[key]}
                />
              ))}
            </div>
          </Card>
        </Col>
        <Col xs={8}>
          <Card
            id="strain-stats"
            loading={loadingStrainStats}
          >
            <CardHeader
              title="Strain Statistics"
              details={comparisonDetailDate}
            />
            <div className={styles.statTiles}>
              <PillarStatTile
                metric={StrainMetricType.STRAIN}
                isSelected={graphType === GraphType.STRAIN}
                setSelectedMetricType={() => {
                  setGraphType(GraphType.STRAIN);
                }}
              />
              <PillarStatTile
                metric={StrainMetricType.AVG_HR}
                isSelected={graphType === GraphType.AVERAGE_HR}
                setSelectedMetricType={() => {
                  setGraphType(GraphType.AVERAGE_HR);
                }}
              />
              <PillarStatTile
                metric={StrainMetricType.CALORIES}
                isSelected={graphType === GraphType.CALORIES}
                setSelectedMetricType={() => {
                  setGraphType(GraphType.CALORIES);
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Row center="xs" className={styles.activityStatsContainer}>
        <Col xs={4}>
          <Card
            id="all-activities-table"
            loading={isLoadingStrainActivity}
          >
            <CardHeader title="All Activities" details={getFormattedRangeDay(dateRange)} />
            <ActivitiesTable
              activityData={strainActivities}
              dayStrain={strain?.metrics}
            />
          </Card>
        </Col>
        <Col xs={8}>
          <Card
            id="metric-type-range"
            loading={loadingStrainStats}
            showBackground={false}
          >
            <CardHeader
              title={READABLE_GRAPH_TYPES[graphType]}
              details={detailDate}
            />
            <div className={styles.rangeChart}>
              {graphData?.length <= 7 && (
                <DayBarChart
                  data={graphData as BarData[]}
                  maxVal={maxGraphVal}
                  color={Colors.strainBlue}
                  metricName={READABLE_GRAPH_TYPES[graphType]}
                  customLabelFormat={pickCustomLabelFormat()}
                />
              )}
              {graphData?.length > 7 && (
                <DayLineChart
                  data={graphData as LineData[]}
                  maxVal={maxGraphVal}
                  color={Colors.strainBlue}
                  metricName={READABLE_GRAPH_TYPES[graphType]}
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RangeView;
