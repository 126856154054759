import Card from 'progress/components/Card/card';
import { getFormattedRangeDay, getMoreInfo } from 'progress/profile/profileUtils';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import PieChart from 'dataVisualizations/pieChart/pieChart';
import {
  Pillars, MetricType, PRIMARY_METRIC_MAP, METRIC_DISPLAY_NAMES,
} from 'types/analytics';
import useMetric from 'api/hooks/useMetric';
import { getColorForPillar, STRAIN_MAX } from 'progress/progressPageUtils';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import styles from './pillarPieCard.module.scss';

type PillarPieCardProps = {
  pillar: Pillars;
};

function PillarPieCard({ pillar }: PillarPieCardProps) {
  const { dateRange } = useDateRange();
  const detailDate = getFormattedRangeDay(dateRange);
  const pillarMetricType: MetricType = PRIMARY_METRIC_MAP[pillar];
  const { data: { average_value } = { average_value: 0 }, isLoading } = useMetric(
    pillarMetricType,
    dateRange,
  );
  const pillarColor = getColorForPillar(pillar, average_value);
  const formattedValue = pillar === Pillars.STRAIN
    ? (average_value / STRAIN_MAX) * 100
    : average_value;

  return (
    <Card
      loading={isLoading}
      id={pillarMetricType}
    >
      <CardHeader
        title={METRIC_DISPLAY_NAMES[pillarMetricType]}
        details={detailDate}
        moreInfoText={getMoreInfo(pillar)}
      />
      <div className={styles.pillarPieChart}>
        <PieChart
          showPercent={[Pillars.SLEEP, Pillars.RECOVERY].includes(pillar)}
          pieValue={formattedValue}
          color={pillarColor}
        />
      </div>
    </Card>
  );
}

export default PillarPieCard;
