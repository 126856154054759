import {
  useEffect, useMemo, useState,
} from 'react';
import Table from 'components/table';
import {
  CellProps, Column, Row, TableCommonProps,
} from 'react-table';
import { dateCell, nameSubtitleCell, normalCell } from 'tableUtils/tableCells';
import {
  HubMemberStatus,
  Seat, SeatDetail, SeatStatus, ShippingType,
} from 'types/seat';
import StatusBadge from 'components/statusBadge';
import Dropdown from 'components/dropdown';
import { ACTION_BAR } from 'constants/accountTable';
import SEAT_STATUSES from 'constants/strapsTable';
import { UseStatePropType } from 'types/useStatePropType';
import { addUserIdColumn, STRAPS_TABLE_SELECTION_COLUMN } from 'tableUtils/tableColumns';
import { Button } from '@whoop/web-components';
import { PAGE_STATE } from 'constants/pageState';
import MoreActions from 'components/moreActions/moreActions';
import { AccountParams, useAccounts } from 'context/account-context';
import { useNavigate, useParams } from 'react-router-dom';
import RowsPerPageSelect from 'tableUtils/rowsPerPageSelect/rowsPerPageSelect';
import { DashboardRole } from 'types/dashboardUser';
import LoadingPage from 'loading';
import PeoplePageToggle from 'components/peoplePageToggle/peoplePageToggle';
import AccountDetailsHeader from 'components/accountDetailsHeader/accountDetailsHeader';
import { trackEvent } from 'helpers/analyticsTracking';
import StatusDropdown from 'components/statusDropdown/statusDropdown';
import FilterDropdown from 'components/filterDropdown/filterDropdown';
import { MenuItem } from '@material-ui/core';
import { downloadFile } from 'tableUtils/utils';
import styles from './strapsTable.module.scss';

type StrapsTableProps = {
  loading: boolean;
  data: SeatDetail[];
  fetchData: () => void;
  seatsByStatus: Map<SeatStatus, Seat>;
  setPageState: UseStatePropType<string>;
  selectedRows: Row<SeatDetail>[];
  setSelectedRows: UseStatePropType<Row<SeatDetail>[]>;
  initialStatus?: SeatStatus;
};

function StrapsTable({
  loading,
  data,
  fetchData,
  seatsByStatus,
  setPageState,
  selectedRows,
  setSelectedRows,
  initialStatus,
}: StrapsTableProps) {
  const {
    checkAccess, account, accountConfig, isReidTokenValid,
  } = useAccounts();
  const navigate = useNavigate();
  const { accountId } = useParams<AccountParams>();

  const [
    currentSeatStatus,
    setCurrentSeatStatus,
  ] = useState<SeatStatus>(initialStatus || SeatStatus.Active);
  const [shippingType, setShippingType] = useState<ShippingType>(ShippingType.ALL);
  const [hubMemberStatus, setHubMemberStatus] = useState<HubMemberStatus>(HubMemberStatus.ALL);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(null);
  const [currentPageSize, setCurrentPageSize] = useState<number>(12);

  const showHubMemberDropdown = () => accountConfig
  && accountConfig.hub_enabled
  && [SeatStatus.Active, SeatStatus.Deactivated].includes(currentSeatStatus);

  const tableData = useMemo(() => data.filter((seat) => {
    const correctSeatStatus = seat.status === currentSeatStatus;
    if (currentSeatStatus === SeatStatus.Invited && shippingType !== ShippingType.ALL) {
      return correctSeatStatus && seat.shipping_type === shippingType;
    }

    if (showHubMemberDropdown() && hubMemberStatus !== HubMemberStatus.ALL) {
      const hubMember = hubMemberStatus === HubMemberStatus.HUB_MEMBER;
      return correctSeatStatus && (hubMember === seat.hub_member);
    }

    return seat.status === currentSeatStatus;
  }), [currentSeatStatus, shippingType, hubMemberStatus, loading]);

  if (!checkAccess) {
    return <LoadingPage />;
  }

  const statusBadgeCell = (cellProps: CellProps<SeatDetail>) => (
    <StatusBadge status={cellProps.row.original.status} />
  );

  const moreActionsCell = (cellProps: CellProps<SeatDetail>) => (
    <MoreActions
      row={cellProps.row}
      setPageState={setPageState}
      setSelectedRows={setSelectedRows}
      account={account}
    />
  );

  const optionalColumn = (status: SeatStatus): Column<SeatDetail> => {
    if (status === SeatStatus.Active) {
      return {
        id: 'activated_on',
        Header: 'Activated On',
        width: '15%',
        align: 'left',
        sortType: 'datetime',
        accessor: (member) => new Date(member.activated_on),
        Cell: dateCell,
      } as Column<SeatDetail>;
    }
    if (status === SeatStatus.Pending) {
      return {
        id: 'joined_on',
        Header: 'Joined On',
        width: '15%',
        align: 'left',
        sortType: 'datetime',
        accessor: (member) => new Date(member.joined_on),
        Cell: dateCell,
      } as Column<SeatDetail>;
    }
    return { // For Invited Status
      id: 'shipping_type',
      Header: 'Shipping Type',
      width: '15%',
      align: 'left',
      accessor: (member) => (member.shipping_type === ShippingType.EXISTING_MEMBER
        ? 'Existing Member' : member.shipping_type[0].toUpperCase() + member.shipping_type.slice(1)),
      Cell: normalCell,
    } as Column<SeatDetail>;
  };

  const columns: Column<SeatDetail>[] = useMemo(() => {
    const memberName = {
      id: 'member_name',
      Header: currentSeatStatus === SeatStatus.Invited ? 'Member Email' : 'Member Name',
      accessor: (member) => (currentSeatStatus === SeatStatus.Invited ? member.email : (member.member_identifier || `${member.first_name || '--'} ${member.last_name || '--'}`)),
      width: '25%',
      align: 'left',
      Cell: (cellProps: CellProps<SeatDetail>) => nameSubtitleCell(cellProps, '', currentSeatStatus === SeatStatus.Invited),
    } as Column<SeatDetail>;
    const sensorId = {
      id: 'sensor_id',
      Header: 'Sensor ID',
      accessor: 'strap_serial',
      width: '15%',
      align: 'left',
      Cell: normalCell,
    } as Column<SeatDetail>;
    const memberStatus = {
      id: 'member_status',
      Header: 'Status',
      accessor: 'status',
      width: '10%',
      align: 'left',
      Cell: statusBadgeCell,
    } as Column<SeatDetail>;
    const optionalField = optionalColumn(currentSeatStatus);
    const moreActions = {
      id: 'more_actions',
      disableSortBy: true,
      Cell: moreActionsCell,
    };
    const email = {
      id: 'email',
      accessor: (member) => member.email,
    } as Column<SeatDetail>;

    const columnList: Column<SeatDetail>[] = [
      STRAPS_TABLE_SELECTION_COLUMN(),
      memberName,
      sensorId,
      memberStatus,
      moreActions,
      email,
    ];
    addUserIdColumn(checkAccess(DashboardRole.WHOOP_CSM)
    && (!accountConfig.deidentified || isTokenValid)
    && currentSeatStatus !== SeatStatus.Invited, columnList, 2);

    if (currentSeatStatus !== SeatStatus.Deactivated
      && !(currentSeatStatus === SeatStatus.Pending && accountConfig?.hub_enabled)) {
      columnList.splice(4, 0, optionalField);
    }
    return columnList;
  }, [currentSeatStatus, accountConfig, isTokenValid]);

  useEffect(() => {
    const tokenValidation = async () => {
      const tokenValid = await isReidTokenValid();
      setIsTokenValid(tokenValid);
    };

    if (!checkAccess(DashboardRole.ACCOUNT_MANAGER)) {
      navigate(`/accounts/${accountId}/people/groups`);
    }
    fetchData();
    if (checkAccess(DashboardRole.WHOOP_CSM)) {
      tokenValidation();
    }
  }, [account]);

  const getRowStyles = (): TableCommonProps => ({
    style: {
      height: '80px',
    },
  });

  const shippingTypeOptions = Object.values(ShippingType).map((value: ShippingType) => ({
    name: value,
    active: value === shippingType,
    onClick: () => {
      setShippingType(value);
    },
  }));

  const hubMemberOptions = Object.values(HubMemberStatus).map((value: HubMemberStatus) => ({
    name: value,
    active: value === hubMemberStatus,
    onClick: () => {
      setHubMemberStatus(value);
    },
  }));

  const showAddHubMemberButton = () => accountConfig && accountConfig.hub_enabled
  && selectedRows.length === 0;

  const handleAddHubMemberButtonClick = () => {
    navigate(`/accounts/${account.id}/people/hub/member/add`);
  };

  const showActivateSensorButton = () => accountConfig && accountConfig.hub_enabled
    && currentSeatStatus === SeatStatus.Pending
    && selectedRows.length === 1;

  const activateSensor = () => {
    if (!selectedRows.length) { return; }
    const seatDetail = selectedRows[0].original;
    navigate(`/accounts/${account.id}/people/hub/${seatDetail.id}/activate`);
  };

  const handleAddMembersButtonClick = () => {
    trackEvent('New Member Invite - Button Click', {
      accountId,
    });
    setPageState(PAGE_STATE.INVITE_MEMBERS);
  };

  const handleCreateGroupButtonClick = () => {
    trackEvent('Create Group - Button Click', {
      accountId,
      page: 'members table',
    });
    navigate(`/accounts/${accountId}/people/groups/add-group`);
  };

  const handleRemoveMembersButtonClick = (pageState: string) => {
    trackEvent('Remove Member - Button Click', {
      accountId,
      pageState,
    });
    setPageState(pageState);
  };

  const handleDownloadPatientIdCrosswalk = () => {
    const csvRows = [['patient_id', 'user_id']];
    data.filter((seat) => seat.member_identifier).forEach((seat) => {
      csvRows.push([
        seat.member_identifier,
        seat.user_id.toString(),
      ]);
    });
    const finalCsvData = csvRows.reduce((csvData, row) => `${csvData + row.join(',')}\n`, '');
    downloadFile(finalCsvData);
  };

  const buttons = (
    <div className={styles.tableControlButtons}>
      {currentSeatStatus === SeatStatus.Invited && selectedRows.length > 0 && (
        <>
          <Button
            label="RESEND INVITATIONS"
            size="small"
            onClick={() => setPageState(PAGE_STATE.RESEND_INVITES)}
            variant="normal"
            theme="enterprise"
            className={styles.whiteBtn}
          />
          <Button
            label="CANCEL INVITATIONS"
            size="small"
            onClick={() => setPageState(PAGE_STATE.WITHDRAW_INVITES)}
            variant="normal"
            theme="enterprise"
            className={styles.blueBtn}
          />
        </>
      )}
      {
        (currentSeatStatus === SeatStatus.Active
          || (currentSeatStatus === SeatStatus.Pending && !accountConfig.hub_enabled))
        && selectedRows.length > 0 && (
          <Button
            label="REMOVE MEMBERS"
            size="small"
            onClick={() => handleRemoveMembersButtonClick(PAGE_STATE.REMOVE_MEMBERS)}
            variant="normal"
            theme="enterprise"
            className={styles.blueBtn}
          />
        )
      }
      {showActivateSensorButton() && selectedRows.length === 1 && (
        <Button
          label={ACTION_BAR.ACTIVATE_SENSOR}
          size="small"
          onClick={activateSensor}
          variant="primary"
          theme="enterprise"
          className={styles.blueBtn}
        />
      )}
      {selectedRows.length === 0 && (
        <>
          <Button
            label={ACTION_BAR.CREATE_GROUP}
            size="small"
            onClick={handleCreateGroupButtonClick}
            variant="normal"
            theme="enterprise"
            className={styles.whiteBtn}
          />
          {showAddHubMemberButton() && (
          <Dropdown
            buttonStyle={styles.blueBtn}
            button={(
              <p>
                {ACTION_BAR.ADD_MEMBERS}
              </p>
            )}
            buttonProps={{ variant: 'normal', theme: 'enterprise', 'aria-label': 'select_pillar_btn' }}
            closeOnClick
          >
            <div className={styles.item}>
              <MenuItem
                key={ACTION_BAR.ADD_MEMBERS}
                onClick={handleAddMembersButtonClick}
              >
                {ACTION_BAR.ADD_MEMBERS.toLowerCase()}
              </MenuItem>
              <MenuItem
                key={ACTION_BAR.ADD_HUB_MEMBER}
                onClick={handleAddHubMemberButtonClick}
              >
                {ACTION_BAR.ADD_HUB_MEMBER.toLowerCase()}
              </MenuItem>
            </div>
          </Dropdown>
          )}
          {!showAddHubMemberButton() && (
          <Button
            label={ACTION_BAR.ADD_MEMBERS}
            size="small"
            onClick={handleAddMembersButtonClick}
            variant="normal"
            theme="enterprise"
            className={styles.blueBtn}
          />
          )}
        </>
      )}
    </div>
  );

  const controlBtns = (
    <div className={styles.control}>
      <div className={styles.leftControl}>
        <PeoplePageToggle />
        <StatusDropdown
          currentStatus={currentSeatStatus}
          statusValues={SEAT_STATUSES}
          setCurrentStatus={setCurrentSeatStatus}
          rowsByStatus={seatsByStatus}
        />
        {showHubMemberDropdown() && (
        <FilterDropdown
          heading={`${ACTION_BAR.MEMBERS}: ${hubMemberStatus}`}
          options={hubMemberOptions}
        />
        )}
        {currentSeatStatus === SeatStatus.Invited && (
        <FilterDropdown
          heading={`${ACTION_BAR.SHIPPING_TYPE}: ${shippingType}`}
          options={shippingTypeOptions}
        />
        )}

        <RowsPerPageSelect
          onOptionClick={(size) => setCurrentPageSize(size)}
          selectedOption={currentPageSize}
          pageSizeOptions={[6, 12, 24]}
        />
      </div>
      <div className={styles.rightControl}>
        {buttons}
      </div>
    </div>
  );

  return (
    <div className={styles.tableContainer}>
      <Table
        columns={columns}
        data={tableData}
        initialSortByField="activated_on"
        descendingDefaultSort
        loading={loading}
        tableName="members"
        heading="Team Members"
        unit="member"
        searchPlaceholder="Search for a member or device ID..."
        noDataFoundMessage="No members found"
        tableLength={currentPageSize}
        getAdditionalRowProps={getRowStyles}
        hiddenColumns={['email']}
        optionalControls={controlBtns}
        setSelectedRows={setSelectedRows}
        customHeader={(
          <AccountDetailsHeader
            handleDownloadData={
              handleDownloadPatientIdCrosswalk
            }
          />
)}
      />
    </div>
  );
}

export default StrapsTable;
