import { getDelayVal, mswBaseUri } from 'mocks/utils/utils';
import getAvailableStrapSerials from 'mocks/actions/strapActions';
import { rest } from 'msw';

const strapsHandlers = [
  rest.get(mswBaseUri('v1/straps'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(getAvailableStrapSerials()),
  )),
];

export default strapsHandlers;
