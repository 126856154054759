import { useQuery } from '@tanstack/react-query';
import { getMetricOverview, GetMetricOverviewParams } from 'api/analyticsApi';
import { useMemo } from 'react';
import { formatDate } from 'progress/profile/profileUtils';
import { DateRange } from 'react-day-picker';
import { useParams } from 'react-router-dom';
import { MetricType, METRIC_TO_PILLAR_MAP } from 'types/analytics';
import { UrlParams } from 'types/profile';

export default function useMetric(metric: MetricType, range: DateRange) {
  const { userId } = useParams<UrlParams>();
  const pillar = METRIC_TO_PILLAR_MAP[metric];

  const queryParams = useMemo(() => {
    const param: GetMetricOverviewParams = {
      memberId: parseInt(userId, 10),
      startDate: formatDate(range.from),
    };
    if (range.to) {
      param.endDate = formatDate(range.to);
    }
    return param;
  }, [userId, range]);

  return useQuery({
    queryKey: [queryParams, pillar, metric],
    queryFn: () => getMetricOverview(queryParams, pillar, metric),
  });
}
