import Badge, { BadgeColors } from 'components/badge/badge';
import { secondsToHoursMinutes, shortDateWithTimeFormat, warnForLastActivity } from 'helpers/dateUtils';
import { Column, TableState } from 'react-table';
import { breakdownTableNameWithAvatarCell } from 'tableUtils/tableCells';
import { DeviceHealthRecord } from 'types/analytics';

const THREE_HOURS = 3 * 60 * 60;

export function BatteryLevelCell({ value }: { value: number | null }) {
  return value
    ? (
      <Badge color={value > 25 ? BadgeColors.NONE : BadgeColors.NOTIFICATION_RED}>
        {`${value}%`}
      </Badge>
    )
    : null;
}

export function LastUploadDateCell({ value }: { value: string | null }) {
  return value
    ? (
      <Badge color={warnForLastActivity(value) ? BadgeColors.NOTIFICATION_RED : BadgeColors.NONE}>
        {shortDateWithTimeFormat(new Date(value))}
      </Badge>
    )
    : null;
}

export function TimeoffWristCell({ value }: { value: number | null }) {
  return value != null
    ? (
      <Badge color={value > THREE_HOURS ? BadgeColors.NOTIFICATION_RED : BadgeColors.NONE}>
        {secondsToHoursMinutes(value)}
      </Badge>
    )
    : null;
}

export const deviceHealthColumns: Column<DeviceHealthRecord>[] = [
  {
    Header: 'Name',
    accessor: (data) => (data.first_name + data.last_name).toUpperCase(),
    id: 'name',
    Cell: breakdownTableNameWithAvatarCell,
  },
  {
    Header: 'Battery level',
    accessor: 'sensor_battery_level',
    Cell: BatteryLevelCell,
  },
  {
    Header: 'Last data upload',
    accessor: 'latest_uploaded_metric',
    Cell: LastUploadDateCell,
  },
  {
    Header: 'Time off wrist in last 24 hours',
    accessor: 'time_off_wrist_seconds',
    Cell: TimeoffWristCell,
  },
];

export const initialDeviceHealthTableState: Partial<TableState> = {
  sortBy: [{ id: 'sensor_battery_level', desc: true }],
};
