import {
  HEADING_2,
} from 'components/text';
import { UseStatePropType } from 'types/useStatePropType';
import EmailsInput from 'components/emailsInput/emailsInput';
import styles from './addCollaboratorEmails.module.scss';

type AddCollaboratorEmailsProps = {
  emails: string[];
  setEmails: UseStatePropType<string[]>;
  invalidEmails: string[];
  setInvalidEmails: UseStatePropType<string[]>;
};

function AddCollaboratorEmails({
  emails, setEmails, invalidEmails, setInvalidEmails,
}: AddCollaboratorEmailsProps) {
  return (
    <div className={styles.formFields}>
      <div className={styles.title}>
        <HEADING_2>
          1
          <span className={styles.overTwo}>/2</span>
          {' '}
          ADD COLLABORATORS
        </HEADING_2>
      </div>
      <EmailsInput
        label="ADD COLLABORATOR EMAILS"
        emails={emails}
        setEmails={setEmails}
        invalidEmails={invalidEmails}
        setInvalidEmails={setInvalidEmails}
      />
    </div>
  );
}

export default AddCollaboratorEmails;
