import Card from 'progress/components/Card/card';
import { getFormattedRangeDay } from 'progress/profile/profileUtils';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import {
  Pillars, MetricType, PRIMARY_METRIC_MAP, METRIC_DISPLAY_NAMES,
} from 'types/analytics';
import useMetric from 'api/hooks/useMetric';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import PillarBreakdownBar from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBar';
import { groupBreakdownData } from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarUtils';
import {
  PillarBreakdownBarData,
  PillarKeys, pillarKeysToDisplay, PILLAR_COLOR_MAP, PILLAR_KEY_MAP,
} from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarConsts';
import PillarBreakdownBarLabel from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarLabel';
import styles from './pillarBreakdownBarCard.module.scss';

type PillarBreakdownBarCardProps = {
  pillar: Pillars;
};

function PillarBreakdownBarCard({ pillar }: PillarBreakdownBarCardProps) {
  const { dateRange } = useDateRange();
  const detailDate = getFormattedRangeDay(dateRange);
  const pillarMetricType: MetricType = PRIMARY_METRIC_MAP[pillar];
  const { data, isLoading } = useMetric(
    pillarMetricType,
    dateRange,
  );
  let breakdownBarData: PillarBreakdownBarData[] = [];
  if (data) {
    const { barData } = groupBreakdownData(pillar, data);
    breakdownBarData = [barData];
  }
  const colors = PILLAR_COLOR_MAP[pillar];
  const pillarKeys = PILLAR_KEY_MAP[pillar];

  return (
    <Card
      id={`${pillarMetricType}-breakdown`}
      loading={isLoading}
      className={styles.pillarBarCard}
    >
      <CardHeader
        title={`${METRIC_DISPLAY_NAMES[pillarMetricType]} Breakdown`}
        details={detailDate}
      />
      <div className={styles.pillarBreakdownBar}>
        <PillarBreakdownBar
          data={breakdownBarData}
          colors={colors}
          keys={pillarKeys}
        />
      </div>
      {data && (
      <div className={styles.labels}>
        {pillarKeys.map((key: PillarKeys) => (
          <PillarBreakdownBarLabel
            key={key}
            count={breakdownBarData[0][key]}
            displayVals={pillarKeysToDisplay[key]}
          />
        ))}
      </div>
      )}
    </Card>
  );
}

export default PillarBreakdownBarCard;
