import { useState } from 'react';
import LandingPageWrapper from 'landingPageWrapper/landingPageWrapper';
import { ResetPageState } from 'resetPassword/resetTypes';
import ResetPasswordForm from './components/resetPasswordForm';
import SuccessfulReset from './components/successfulReset';

function ResetPassword() {
  const [pageState, setPageState] = useState<ResetPageState>(ResetPageState.Form);

  return (
    <LandingPageWrapper>
      {pageState === ResetPageState.Form && (
        <ResetPasswordForm setPageState={setPageState} />
      )}
      {pageState === ResetPageState.Reset && (
        <SuccessfulReset />
      )}
    </LandingPageWrapper>
  );
}

export default ResetPassword;
