import { MetricOverview, Pillars } from 'types/analytics';
import { PillarBreakdownBarData, PillarBreakdownDayData } from './pillarBreakdownBarConsts';

export const groupStrainBreakdownData = (data: MetricOverview) => {
  const breakdownDays = {
    allOut: [] as string[],
    strenuous: [] as string[],
    moderate: [] as string[],
    light: [] as string[],
  };
  data.metrics.forEach((metric) => {
    if (metric.metric_value === null) {
      return;
    }
    const dateString = new Date(metric.cycle_start_day as unknown as Date).toLocaleDateString();
    if (metric.metric_value < 10) {
      breakdownDays.light.push(dateString);
    } else if (metric.metric_value <= 14) {
      breakdownDays.moderate.push(dateString);
    } else if (metric.metric_value <= 18) {
      breakdownDays.strenuous.push(dateString);
    } else if (metric.metric_value > 18) {
      breakdownDays.allOut.push(dateString);
    }
  });

  const barData = {
    metric: 'day_strain',
    allOut: breakdownDays.allOut.length,
    strenuous: breakdownDays.strenuous.length,
    moderate: breakdownDays.moderate.length,
    light: breakdownDays.light.length,
  };

  return { breakdownDays, barData };
};

export const groupRecoveryBreakdownData = (data: MetricOverview) => {
  const breakdownDays = {
    green: [] as string[],
    yellow: [] as string[],
    red: [] as string[],
  };
  data.metrics.forEach((metric) => {
    if (metric.metric_value === null) {
      return;
    }

    const dateString = new Date(metric.cycle_start_day as unknown as Date).toLocaleDateString();
    if (metric.metric_value < 33) {
      breakdownDays.red.push(dateString);
    } else if (metric.metric_value <= 67) {
      breakdownDays.yellow.push(dateString);
    } else if (metric.metric_value > 67) {
      breakdownDays.green.push(dateString);
    }
  });

  const barData = {
    metric: 'recovery',
    green: breakdownDays.green.length,
    yellow: breakdownDays.yellow.length,
    red: breakdownDays.red.length,
  };

  return { breakdownDays, barData };
};

export const groupSleepBreakdownData = (data: MetricOverview) => {
  const breakdownDays = {
    optimal: [] as string[],
    sufficient: [] as string[],
    poor: [] as string[],
  };
  data.metrics.forEach((metric) => {
    if (metric.metric_value === null) {
      return;
    }

    const dateString = new Date(metric.cycle_start_day as unknown as Date).toLocaleDateString();
    if (metric.metric_value < 70) {
      breakdownDays.poor.push(dateString);
    } else if (metric.metric_value <= 85) {
      breakdownDays.sufficient.push(dateString);
    } else if (metric.metric_value > 85) {
      breakdownDays.optimal.push(dateString);
    }
  });

  const barData = {
    metric: 'recovery',
    optimal: breakdownDays.optimal.length,
    sufficient: breakdownDays.sufficient.length,
    poor: breakdownDays.poor.length,
  };

  return { breakdownDays, barData };
};

const PILLAR_TO_GROUP_BREAKDOWN_FUNCTION = {
  [Pillars.STRAIN]: groupStrainBreakdownData,
  [Pillars.RECOVERY]: groupRecoveryBreakdownData,
  [Pillars.SLEEP]: groupSleepBreakdownData,
};

export const groupBreakdownData = (
  pillar: Pillars,
  data: MetricOverview,
): { breakdownDays: PillarBreakdownDayData; barData: PillarBreakdownBarData } => {
  const groupingFunction = PILLAR_TO_GROUP_BREAKDOWN_FUNCTION[pillar];
  return groupingFunction(data);
};
