import {
  convertHRVtoString, convertStrainToString, convertToNumberLocale, getSleepDuration,
} from 'progress/utils/numberFormatter';
import {
  MetricType, RecoveryMetricType, SleepMetricType, StrainMetricType,
} from 'types/analytics';
import ComparisonArrow, { ArrowDirection, Comparison, ComparisonType } from '../comparionArrow/comparisonArrow';
import styles from './pillarStatTile.module.scss';

export const METRIC_ICONS = {
  [StrainMetricType.CALORIES]: 'calories',
  [StrainMetricType.STRAIN]: 'strain',
  [StrainMetricType.AVG_HR]: 'heart_rate',
  [RecoveryMetricType.SCORE]: 'meditation',
  [RecoveryMetricType.HRV]: 'hrv',
  [RecoveryMetricType.RHR]: 'rhr',
  [RecoveryMetricType.RESPIRATORY_RATE]: 'respiratory_rate',
  [SleepMetricType.PERFORMANCE]: 'sleep_outline',
  [SleepMetricType.DURATION]: 'sleep_need',
  [SleepMetricType.LATENCY]: 'sleep_latency',
  [SleepMetricType.CONSISTENCY]: 'bedtime',
  [SleepMetricType.DISTURBANCES]: 'disturbances',
  [SleepMetricType.EFFICIENCY]: 'sleep_efficiency',
};

export const compareTrend = (value: number, avg: number, directTrend: boolean = true) => {
  const comparison: Comparison = {
    arrowDirection: ArrowDirection.EQUAL,
  };
  if (value > avg) {
    comparison.arrowDirection = ArrowDirection.UP;
    comparison.comparisonType = directTrend ? ComparisonType.POSITIVE : ComparisonType.NEGATIVE;
  }
  if (value < avg) {
    comparison.arrowDirection = ArrowDirection.DOWN;
    comparison.comparisonType = directTrend ? ComparisonType.NEGATIVE : ComparisonType.POSITIVE;
  }
  return comparison;
};

export const formatMetric = (
  metricValue: number,
  metricType: MetricType,
  isComparisonValue: boolean = false,
) => {
  switch (metricType) {
    // percent
    case SleepMetricType.EFFICIENCY:
    case SleepMetricType.PERFORMANCE:
    case RecoveryMetricType.SCORE: {
      return isComparisonValue ? (
        `${Math.round(metricValue)}%`
      ) : (
        <>
          {`${Math.round(metricValue)}`}
          <div className={styles.percent}>%</div>
        </>
      );
    }
    // decimal
    case RecoveryMetricType.RESPIRATORY_RATE:
    case StrainMetricType.STRAIN: {
      return convertStrainToString(metricValue);
    }
    // locale
    case StrainMetricType.CALORIES: {
      return convertToNumberLocale(metricValue);
    }
    // milliseconds
    case RecoveryMetricType.HRV: {
      return convertHRVtoString(metricValue);
    }
    // time (sleep)
    case SleepMetricType.LATENCY:
    case SleepMetricType.DURATION: {
      return getSleepDuration(metricValue);
    }
    // number
    default: {
      return Math.round(metricValue).toString();
    }
  }
};

export const comparisonCorrelation = (
  metricType: MetricType,
  averageValue: number,
  comparisonValue: number,
) => {
  let comparisonType: Comparison;
  switch (metricType) {
    // inverse
    case SleepMetricType.LATENCY:
    case SleepMetricType.DISTURBANCES:
    case RecoveryMetricType.RESPIRATORY_RATE:
    case RecoveryMetricType.RHR: {
      comparisonType = compareTrend(averageValue, comparisonValue, false);
      break;
    }
    // direct
    case SleepMetricType.DURATION:
    case SleepMetricType.EFFICIENCY:
    case SleepMetricType.CONSISTENCY:
    case SleepMetricType.PERFORMANCE:
    case RecoveryMetricType.HRV:
    case RecoveryMetricType.SCORE:
    case StrainMetricType.STRAIN:
    case StrainMetricType.AVG_HR:
    case StrainMetricType.CALORIES: {
      comparisonType = compareTrend(averageValue, comparisonValue);
      break;
    }
    // no correlation
    default: {
      comparisonType = {
        arrowDirection: ArrowDirection.EQUAL,
      };
    }
  }

  return <ComparisonArrow compare={comparisonType} />;
};
