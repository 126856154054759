import { BarDatum, BarTooltipProps } from '@nivo/bar';
import { PointTooltipProps, SliceTooltipProps } from '@nivo/line';
import { Colors, classes } from '@whoop/web-components';
import {
  PillarBreakdownBarData,
  pillarKeysToDisplay, RecoveryKeys, SleepKeys, StrainKeys,
} from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarConsts';
import { getFormattedMonthDayValues, toRecoveryScorePercentage } from 'dataVisualizations/strainRecoveryTrendGraph/strainRecoveryTrendGraphUtils';
import { getHourFromEpoch } from 'dataVisualizations/utils/utils';
import { getColorFromVal } from 'progress/progressPageUtils';
import { TrendKey } from 'types/analytics';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TooltipWrapper } from '@nivo/tooltip';
import { getMonthDayValues } from 'progress/utils/date';
import styles from './tooltips.module.scss';

export function PillarBreakdownBarToolTip(node: BarTooltipProps<PillarBreakdownBarData>) {
  const { id, value } = node;
  const displayVals = pillarKeysToDisplay[id as StrainKeys | RecoveryKeys | SleepKeys];
  return (
    <div className={styles.pillarBreakdownBarToolTip}>
      <div className={styles.tooltipLine}>
        <div style={{ color: displayVals.color }}>
          {`${value}x`}
        </div>
        <div className={styles.name}>
          &nbsp;
          {displayVals.name}
        </div>
        <div className={styles.description}>
          &nbsp;
          {`(${displayVals.description})`}
        </div>
      </div>
    </div>
  );
}

export function StrainRecoveryTooltip(
  { slice }: SliceTooltipProps,
  label?: string,
  customColor?: string,
) {
  return (
    <div className={styles.strainRecoveryTooltip}>
      {slice.points.map((point, idx) => {
        const color = point.serieId === TrendKey.Recovery
          ? getColorFromVal(toRecoveryScorePercentage(point.data.y)) : Colors.strainBlue;
        return (
          <div className={styles.tooltipContent} key={point.id}>
            <p
              style={{ color: customColor ?? color }}
            >
              {point.serieId === TrendKey.Recovery ? `${toRecoveryScorePercentage(point.data.y)}%` : point.data.y}
              {label}
            </p>
            {idx === slice.points.length - 1
              && (
                <div
                  className={styles.date}
                >
                  {getFormattedMonthDayValues(point.data.x.toString())}
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
}

const tooltipBlock = (
  metricName: string,
  value: number | string,
  timeVal: string,
  secondMetric?: { metricName: string; value: string },
) => (
  <div className={
    classes(styles.analyticsTooltipContainer, secondMetric && styles.strainRecoveryFixedWidth)
  }
  >
    <div className={styles.date}>
      {timeVal}
    </div>
    <div className={styles.metricLine}>
      <div className={styles.metricName}>
        {metricName}
      </div>
      <div className={styles.metricVal}>
        {value}
      </div>
    </div>
    {secondMetric && (
      <div className={styles.metricLine}>
        <div className={styles.metricName}>
          {secondMetric.metricName}
        </div>
        <div className={styles.metricVal}>
          {secondMetric.value}
        </div>
      </div>
    )}
  </div>
);

// Tooltip for line chart that show the recovery/strain values as well as the date of the point
// This doesn't get hit when there are no points at the slice location
export function DefaultStrainRecoveryTooltip(
  { slice }: SliceTooltipProps,
) {
  // Strain point will always contain data if this tooltip is being used
  const strainPoint = slice.points.find((point) => point.serieId === TrendKey.Strain);
  const recoveryPoint = slice.points.find((point) => point.serieId === TrendKey.Recovery);

  const { month, dayNum } = getMonthDayValues(strainPoint.data.x as string);

  return (tooltipBlock(
    'Strain',
    strainPoint.data.y as number,
    `${month} ${dayNum}`,
    { metricName: 'Recovery', value: recoveryPoint ? `${toRecoveryScorePercentage(recoveryPoint?.data.y)}%` : '--' },
  ));
}

export function HeartRateToolTip(
  tooltip: PointTooltipProps,
  maxX: number,
) {
  const { point } = tooltip;
  const hourFromEpoch = getHourFromEpoch(Number(point.data.x));
  // If the point is close to the right side of the graph, move the tooltip to the left
  const xPosition = point.index < maxX - 200 ? 20 : -170;

  return (
    <TooltipWrapper anchor="top" position={[xPosition, -70]}>
      {tooltipBlock('Heart Rate', `${point.data.y} bpm`, hourFromEpoch.formattedTime)}
    </TooltipWrapper>
  );
}

export function DayLineTooltip(
  tooltip: PointTooltipProps,
  metricName: string,
  numPoints: number,
  customLabelFormat?: (val: number) => string,
) {
  const { point } = tooltip;
  const { length: metricNameLength } = metricName;
  // Move to the left based on how long the metric name is so that it doesn't cutoff the dashed line
  let leftOffset = -150;
  if (metricNameLength > 12) {
    leftOffset -= (10 * (metricNameLength - 12));
  }
  // If the point is close to the right side of the graph, move the tooltip to the left
  const xPosition = point.index < numPoints - 6 ? 20 : leftOffset;
  const { month, dayNum } = getMonthDayValues(point.data.x as string);

  return (
    <TooltipWrapper anchor="top" position={[xPosition, -70]}>
      {tooltipBlock(metricName, (customLabelFormat ? customLabelFormat(point.data.y as number) : point.data.y as number), `${month} ${dayNum}`)}
    </TooltipWrapper>
  );
}

export function DayBarTooltip(
  node: BarTooltipProps<BarDatum>,
  metricName: string,
  customLabelFormat: (val: number) => string = null,
) {
  const { value, indexValue } = node;
  const { month, dayNum } = getMonthDayValues(indexValue as string);
  return tooltipBlock(metricName, (customLabelFormat ? customLabelFormat(value) : value), `${month} ${dayNum}`);
}
