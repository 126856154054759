export const BULK_ACTION = {
  RESET_PASSWORD: 'RESET PASSWORDS',
  REMOVE_SELECTED: 'REMOVE SELECTED',
  REPLACE_STRAPS: 'REPLACE STRAPS',
  SELECTED: 'SELECTED',
};

export const TABLE_COLUMN_HEADER = {
  MEMBERS: 'MEMBER NAME',
  HUBS: 'HUB',
  STRAP_ID: 'SENSOR ID',
  STATUS: 'STATUS',
  ACTIVATION: 'ACTIVATED ON',
  RENEWAL: 'EXPIRES ON',
};

export const TABLE_HEADER = {
  TEAM_MEMBERS: 'TEAM MEMBERS',
  ADD_TEAM_MEMBERS: 'ADD_TEAM_MEMBERS',
};

export const ACTION_BAR = {
  STATUS: 'STATUS',
  SORT: 'SORT',
  ADD_TEAM_MEMBERS: 'ADD TEAM MEMBERS',
  ADD_MEMBERS: 'ADD MEMBERS',
  ADD_HUB_MEMBER: 'ADD HUB MEMBER',
  ROWS_PER_PAGE: 'ROWS PER PAGE',
  ACTIVATE_SENSOR: 'ACTIVATE SENSOR',
  CREATE_GROUP: 'CREATE GROUP',
  SHIPPING_TYPE: 'SHIPPING TYPE',
  MEMBERS: 'MEMBERS',
};

export const DEFAULT_PAGE_SIZE = 100;

export const DEFAULT_STATUS_FILTERS = [
  'active',
  'invited',
  'pending',
];
