import React, { useMemo, useState } from 'react';
import { TITLE_3, BODY_SHORT_1 } from 'components/text';
import { UseStatePropType } from 'types/useStatePropType';
import { Column, Row, TableCommonProps } from 'react-table';
import { HubUser } from 'types/hub';
import ActionFooter from 'components/actionFooter/actionFooter';
import { dateCell, normalCell } from 'tableUtils/tableCells';
import Table from 'components/table';
import { useToast } from 'context/toast-context';
import { SELECTION_COLUMN } from 'tableUtils/tableColumns';
import { BulkPromisesResponse } from 'groups/utils';
import { archiveHubMember } from 'api/hubApi';
import styles from './archiveHubMember.module.scss';

type ArchiveHubMemberProps = {
  selectedUsers: HubUser[];
  setModal: UseStatePropType<boolean>;
  refresh: () => void;
};

function ArchiveHubMember({ selectedUsers, setModal, refresh }: ArchiveHubMemberProps) {
  const { openToast } = useToast();
  const [usersToArchive, setUsersToArchive] = useState<HubUser[]>(selectedUsers);

  const columns: Column<HubUser>[] = useMemo(() => [
    SELECTION_COLUMN,
    {
      Header: 'Patient ID Number',
      id: 'member_identifier',
      accessor: 'member_identifier',
      width: '30%',
      align: 'left',
      Cell: normalCell,
    },
    {
      Header: 'Sensor ID',
      id: 'strap_serial',
      accessor: 'strap_serial',
      width: '30%',
      align: 'left',
      Cell: normalCell,
    },
    {
      Header: 'Activated On',
      id: 'activatedOn',
      width: '20%',
      align: 'left',
      accessor: (user: HubUser) => new Date(user.activated_on),
      Cell: dateCell,
    },
  ], []);

  const createToastMessage = (
    fulfilled: number,
    failed: number,
  ): string => {
    let msg = '';
    if (fulfilled) {
      msg += `${fulfilled} user${fulfilled === 1 ? ' has' : 's have'
      } been archived.`;
    }
    if (failed) {
      msg += `Failed to archive ${failed} user${failed > 1 ? 's' : ''}`;
    }
    return msg;
  };

  const archiveHubMembers = async (
    seatIds: number[],
  ): Promise<BulkPromisesResponse> => {
    const promises = seatIds.map((seatId) => archiveHubMember(seatId));

    const results = await Promise.allSettled(promises);
    const numFulfilled = results.filter(
      (res) => res.status === 'fulfilled',
    ).length;
    const failed = promises.length - numFulfilled;

    return {
      fulfilled: numFulfilled,
      failed,
    };
  };

  const handleArchiveMember = async () => {
    if (usersToArchive.length > 0) {
      const seatIds = usersToArchive.map((user) => user.seat_id);
      const response = await archiveHubMembers(seatIds);
      setModal(false);
      openToast(
        createToastMessage(
          response.fulfilled,
          response.failed,
        ),
      );
      refresh();
    }
  };

  const getRowStyles = (): TableCommonProps => ({
    style: {
      height: '80px',
    },
  });

  const handleSelection = (userRows: Row<HubUser>[]) => {
    setUsersToArchive(userRows.map((row) => row.original));
  };

  const getMemberString = (capitalize: boolean) => `${capitalize ? 'T' : ' t'}${usersToArchive.length > 1 ? 'hese' : 'his'} member${usersToArchive.length > 1 ? 's' : ''}`;

  return (
    <>
      <div className={styles.container}>
        <div>
          <TITLE_3>
            {' '}
            Are you sure you want to archive
            {getMemberString(false)}
            ?
          </TITLE_3>
          <div className={styles.details}>
            <BODY_SHORT_1>
              {getMemberString(true)}
              {' '}
              will no longer be associated with your account.
            </BODY_SHORT_1>
          </div>
          <Table
            columns={selectedUsers.length > 1 ? columns : columns.slice(1)}
            data={selectedUsers}
            loading={false}
            tableLength={50}
            tableName="removeMembersTable"
            unit="user"
            displayHeader={false}
            search={false}
            getAdditionalRowProps={getRowStyles}
            setSelectedRows={handleSelection}
            pagination={false}
            selectAllInitially={selectedUsers.length > 1}
          />
        </div>
      </div>

      <ActionFooter
        getPrimaryButtonProps={() => ({
          variant: 'primary',
          label: `Yes, archive${getMemberString(false)}`,
          onClick: handleArchiveMember,
        })}
        getSecondaryButtonProps={() => ({
          variant: 'link',
          label: 'Cancel',
          onClick: () => setModal(false),
        })}
      />
    </>
  );
}

export default ArchiveHubMember;
