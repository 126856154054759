import axios from 'axios';
import env from 'constants/env';

type Params = {
  key: string;
  value: string | number | boolean;
};

export const paramsSerializer = (params: Params) => {
  const serialized = Object.entries(params).reduce(
    (acc, [key, value], index) => `${acc}${index === 0 ? '' : '&'}${key}=${encodeURIComponent(
      value,
    )}`,
    '',
  );
  return serialized;
};

export const client = axios.create({
  baseURL: env?.BASE_URI,
  paramsSerializer,
});
