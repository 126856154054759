/* eslint-disable @typescript-eslint/no-loss-of-precision */
// disabled becuase some of the numbers will lose precision when compiled
// figured this is okay becuase all of this is for mock data
import {
  MetricOverview,
  MemberTrends,
  StrainMetricType,
  RecoveryMetricType,
  SleepMetricType,
} from 'types/analytics';

export const mockSleepPerformance: MetricOverview = {
  average_value: 70.72413793103448,
  metrics: [
    {
      cycle_start_day: [2022, 12, 2],
      metric: 'sleepPerformance',
      metric_value: 48.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 3],
      metric: 'sleepPerformance',
      metric_value: 63.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 4],
      metric: 'sleepPerformance',
      metric_value: 56.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 5],
      metric: 'sleepPerformance',
      metric_value: 87.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 6],
      metric: 'sleepPerformance',
      metric_value: 87.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 7],
      metric: 'sleepPerformance',
      metric_value: 49.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 8],
      metric: 'sleepPerformance',
      metric_value: 76.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 9],
      metric: 'sleepPerformance',
      metric_value: 71.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 10],
      metric: 'sleepPerformance',
      metric_value: 53.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 11],
      metric: 'sleepPerformance',
      metric_value: 78.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 12],
      metric: 'sleepPerformance',
      metric_value: 94.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 13],
      metric: 'sleepPerformance',
      metric_value: 80.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 14],
      metric: 'sleepPerformance',
      metric_value: 85.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 15],
      metric: 'sleepPerformance',
      metric_value: 76.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 16],
      metric: 'sleepPerformance',
      metric_value: 81.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 17],
      metric: 'sleepPerformance',
      metric_value: 44.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 18],
      metric: 'sleepPerformance',
      metric_value: 79.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 19],
      metric: 'sleepPerformance',
      metric_value: 85.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 20],
      metric: 'sleepPerformance',
      metric_value: 88.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 21],
      metric: 'sleepPerformance',
      metric_value: 81.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 22],
      metric: 'sleepPerformance',
      metric_value: 74.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 23],
      metric: 'sleepPerformance',
      metric_value: 21.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 24],
      metric: 'sleepPerformance',
      metric_value: 100.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 25],
      metric: 'sleepPerformance',
      metric_value: 90,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 26],
      metric: 'sleepPerformance',
      metric_value: 100.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 27],
      metric: 'sleepPerformance',
      metric_value: 14.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 28],
      metric: 'sleepPerformance',
      metric_value: 70.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 29],
      metric: 'sleepPerformance',
      metric_value: 65.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 30],
      metric: 'sleepPerformance',
      metric_value: 80.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 31],
      metric: 'sleepPerformance',
      metric_value: 66.0,
      metric_details: null,
    },
  ],
};

export const mockStrainMetric: MetricOverview = {
  average_value: 11.438005021567022,
  metrics: [
    {
      cycle_start_day: [2022, 10, 2],
      metric: 'dayStrain',
      metric_value: 6.485911031630222,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 3],
      metric: 'dayStrain',
      metric_value: 11.592008383150523,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 4],
      metric: 'dayStrain',
      metric_value: 10.428198362087919,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 5],
      metric: 'dayStrain',
      metric_value: 11.60553644267238,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 6],
      metric: 'dayStrain',
      metric_value: 15.897330205405645,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 7],
      metric: 'dayStrain',
      metric_value: 16.29137287027289,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 8],
      metric: 'dayStrain',
      metric_value: 18.034546562912045,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 9],
      metric: 'dayStrain',
      metric_value: 9.491108989799667,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 10],
      metric: 'dayStrain',
      metric_value: 12.972143919082303,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 11],
      metric: 'dayStrain',
      metric_value: 4.569652559801577,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 12],
      metric: 'dayStrain',
      metric_value: 13.604306706111208,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 13],
      metric: 'dayStrain',
      metric_value: 17.51767971586545,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 14],
      metric: 'dayStrain',
      metric_value: 15.655100188172671,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 15],
      metric: 'dayStrain',
      metric_value: 17.642279201802396,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 16],
      metric: 'dayStrain',
      metric_value: 15.255000000000001,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 17],
      metric: 'dayStrain',
      metric_value: 11.242867028202584,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 18],
      metric: 'dayStrain',
      metric_value: 5.481885685676747,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 19],
      metric: 'dayStrain',
      metric_value: 6.717117097778823,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 20],
      metric: 'dayStrain',
      metric_value: 7.282874079845396,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 21],
      metric: 'dayStrain',
      metric_value: 8.637926465213893,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 22],
      metric: 'dayStrain',
      metric_value: 20.5,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 23],
      metric: 'dayStrain',
      metric_value: 4.085159297975056,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 24],
      metric: 'dayStrain',
      metric_value: 6.915066025491744,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 25],
      metric: 'dayStrain',
      metric_value: 5.276138699213353,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 26],
      metric: 'dayStrain',
      metric_value: 5.119323277008109,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 27],
      metric: 'dayStrain',
      metric_value: 13.274225032764623,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 28],
      metric: 'dayStrain',
      metric_value: 17.608392416057907,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 29],
      metric: 'dayStrain',
      metric_value: 20.512525313805853,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 30],
      metric: 'dayStrain',
      metric_value: 9.285174064291207,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 31],
      metric: 'dayStrain',
      metric_value: 16.038290981784463,
      metric_details: null,
    },
  ],
};

export const mockCaloriesMetric: MetricOverview = {
  average_value: 2572.709418293948,
  metrics: [
    {
      cycle_start_day: [2022, 10, 2],
      metric: 'calories',
      metric_value: 2185.321873506214,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 3],
      metric: 'calories',
      metric_value: 2214.0135318325765,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 4],
      metric: 'calories',
      metric_value: 2478.3730151320506,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 5],
      metric: 'calories',
      metric_value: 2323.300538509799,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 6],
      metric: 'calories',
      metric_value: 3179.085550982911,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 7],
      metric: 'calories',
      metric_value: 3883.099586968212,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 8],
      metric: 'calories',
      metric_value: 3559.8310248117828,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 9],
      metric: 'calories',
      metric_value: 1966.3060263055688,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 10],
      metric: 'calories',
      metric_value: 2532.437559751434,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 11],
      metric: 'calories',
      metric_value: 1861.2821444042781,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 12],
      metric: 'calories',
      metric_value: 2720.7393026260756,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 13],
      metric: 'calories',
      metric_value: 3897.9167755885514,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 14],
      metric: 'calories',
      metric_value: 3305.768394105521,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 15],
      metric: 'calories',
      metric_value: 3651.6047928119024,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 16],
      metric: 'calories',
      metric_value: 2432.0000000000005,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 17],
      metric: 'calories',
      metric_value: 1647.934047487452,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 18],
      metric: 'calories',
      metric_value: 1908.0329734554255,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 19],
      metric: 'calories',
      metric_value: 1894.1616547039316,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 20],
      metric: 'calories',
      metric_value: 2063.042664391133,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 21],
      metric: 'calories',
      metric_value: 1618.5024983568355,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 22],
      metric: 'calories',
      metric_value: 5342.000000000001,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 23],
      metric: 'calories',
      metric_value: 1881.676023640094,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 24],
      metric: 'calories',
      metric_value: 1933.2393259664793,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 25],
      metric: 'calories',
      metric_value: 1916.258015094706,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 26],
      metric: 'calories',
      metric_value: 1924.0728491350978,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 27],
      metric: 'calories',
      metric_value: 2792.7736242232313,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 28],
      metric: 'calories',
      metric_value: 3848.4115454708412,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 29],
      metric: 'calories',
      metric_value: 5622.031100621415,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 30],
      metric: 'calories',
      metric_value: 1961.944871833174,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 31],
      metric: 'calories',
      metric_value: 3064.7024005138624,
      metric_details: null,
    },
  ],
};

export const mockAverageHRMetric: MetricOverview = {
  average_value: 75.28571428571429,
  metrics: [
    {
      cycle_start_day: [2022, 10, 2],
      metric: 'averageHeartRate',
      metric_value: 65,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 3],
      metric: 'averageHeartRate',
      metric_value: 72,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 4],
      metric: 'averageHeartRate',
      metric_value: 70,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 5],
      metric: 'averageHeartRate',
      metric_value: 76,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 6],
      metric: 'averageHeartRate',
      metric_value: 80,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 7],
      metric: 'averageHeartRate',
      metric_value: 79,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 8],
      metric: 'averageHeartRate',
      metric_value: 92,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 9],
      metric: 'averageHeartRate',
      metric_value: 75,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 10],
      metric: 'averageHeartRate',
      metric_value: 68,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 11],
      metric: 'averageHeartRate',
      metric_value: 63,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 12],
      metric: 'averageHeartRate',
      metric_value: 70,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 13],
      metric: 'averageHeartRate',
      metric_value: 78,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 14],
      metric: 'averageHeartRate',
      metric_value: 83,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 15],
      metric: 'averageHeartRate',
      metric_value: 85,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 16],
      metric: 'averageHeartRate',
      metric_value: 75,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 17],
      metric: 'averageHeartRate',
      metric_value: 85,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 18],
      metric: 'averageHeartRate',
      metric_value: 67,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 19],
      metric: 'averageHeartRate',
      metric_value: 67,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 20],
      metric: 'averageHeartRate',
      metric_value: 67,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 21],
      metric: 'averageHeartRate',
      metric_value: 80,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 22],
      metric: 'averageHeartRate',
      metric_value: 84,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 23],
      metric: 'averageHeartRate',
      metric_value: 79,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 24],
      metric: 'averageHeartRate',
      metric_value: 70,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 25],
      metric: 'averageHeartRate',
      metric_value: 66,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 26],
      metric: 'averageHeartRate',
      metric_value: 70,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 27],
      metric: 'averageHeartRate',
      metric_value: 74,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 28],
      metric: 'averageHeartRate',
      metric_value: 85,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 29],
      metric: 'averageHeartRate',
      metric_value: 96,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 30],
      metric: 'averageHeartRate',
      metric_value: 73,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 31],
      metric: 'averageHeartRate',
      metric_value: 73,
      metric_details: null,
    },
  ],
};

export const mockMemberTrendsData: MemberTrends = {
  memberId: 20333,
  data_trend: [
    {
      cycle_id: 13172511,
      cycle_start_day: [2022, 10, 2],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 13.485911031630222,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 85,
        },
      ],
    },
    {
      cycle_id: 13203931,
      cycle_start_day: [2022, 10, 3],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 11.592008383150523,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 64,
        },
      ],
    },
    {
      cycle_id: 13234851,
      cycle_start_day: [2022, 10, 4],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 10.428198362087919,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 74,
        },
      ],
    },
    {
      cycle_id: 13278750,
      cycle_start_day: [2022, 10, 5],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 11.60553644267238,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 79,
        },
      ],
    },
    {
      cycle_id: 13312163,
      cycle_start_day: [2022, 10, 6],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 15.897330205405645,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 63,
        },
      ],
    },
    {
      cycle_id: 13341318,
      cycle_start_day: [2022, 10, 7],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 16.29137287027289,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 41,
        },
      ],
    },
    {
      cycle_id: 13375630,
      cycle_start_day: [2022, 10, 8],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 18.034546562912045,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 24,
        },
      ],
    },
    {
      cycle_id: 13393662,
      cycle_start_day: [2022, 10, 9],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 9.491108989799667,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 77,
        },
      ],
    },
    {
      cycle_id: 13420659,
      cycle_start_day: [2022, 10, 10],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 12.972143919082303,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 86,
        },
      ],
    },
    {
      cycle_id: 13442698,
      cycle_start_day: [2022, 10, 11],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 4.569652559801577,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 45,
        },
      ],
    },
    {
      cycle_id: 13472182,
      cycle_start_day: [2022, 10, 12],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 13.604306706111208,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 86,
        },
      ],
    },
    {
      cycle_id: 13502493,
      cycle_start_day: [2022, 10, 13],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 17.51767971586545,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 64,
        },
      ],
    },
    {
      cycle_id: 13534561,
      cycle_start_day: [2022, 10, 14],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 15.655100188172671,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 33,
        },
      ],
    },
    {
      cycle_id: 13566859,
      cycle_start_day: [2022, 10, 15],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 17.642279201802396,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 56,
        },
      ],
    },
    {
      cycle_id: 13566858,
      cycle_start_day: [2022, 10, 16],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 15.255000000000001,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 72,
        },
      ],
    },
    {
      cycle_id: 13616329,
      cycle_start_day: [2022, 10, 17],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 11.242867028202584,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 59,
        },
      ],
    },
    {
      cycle_id: 13643282,
      cycle_start_day: [2022, 10, 18],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 5.481885685676747,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 83,
        },
      ],
    },
    {
      cycle_id: 13671568,
      cycle_start_day: [2022, 10, 19],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 6.717117097778823,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 74,
        },
      ],
    },
    {
      cycle_id: 13705273,
      cycle_start_day: [2022, 10, 20],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 7.282874079845396,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 84,
        },
      ],
    },
    {
      cycle_id: 13756544,
      cycle_start_day: [2022, 10, 21],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 8.637926465213893,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 30,
        },
      ],
    },
    {
      cycle_id: 13756543,
      cycle_start_day: [2022, 10, 22],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 20.5,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 94,
        },
      ],
    },
    {
      cycle_id: 13834107,
      cycle_start_day: [2022, 10, 23],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 4.085159297975056,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 50,
        },
      ],
    },
    {
      cycle_id: 13844738,
      cycle_start_day: [2022, 10, 24],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 6.915066025491744,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 63,
        },
      ],
    },
    {
      cycle_id: 13873923,
      cycle_start_day: [2022, 10, 25],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 5.276138699213353,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 66,
        },
      ],
    },
    {
      cycle_id: 13908391,
      cycle_start_day: [2022, 10, 26],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 5.119323277008109,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 73,
        },
      ],
    },
    {
      cycle_id: 13946922,
      cycle_start_day: [2022, 10, 27],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 13.274225032764623,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 54,
        },
      ],
    },
    {
      cycle_id: 13983355,
      cycle_start_day: [2022, 10, 28],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 17.608392416057907,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 58,
        },
      ],
    },
    {
      cycle_id: 14013606,
      cycle_start_day: [2022, 10, 29],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 20.512525313805853,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 24,
        },
      ],
    },
    {
      cycle_id: 14042567,
      cycle_start_day: [2022, 10, 30],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 9.285174064291207,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 66,
        },
      ],
    },
    {
      cycle_id: 14064391,
      cycle_start_day: [2022, 10, 31],
      pillar_data_list: [
        {
          trend_key: 'SCALED_DAY_STRAIN',
          trend_value: 16.038290981784463,
        },
        {
          trend_key: 'RECOVERY',
          trend_value: 37,
        },
      ],
    },
  ],
};

export const mockRecoveryScore: MetricOverview = {
  average_value: 80,
  metrics: [
    {
      cycle_start_day: [2022, 10, 2],
      metric: 'recoveryScore',
      metric_value: 85,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 3],
      metric: 'recoveryScore',
      metric_value: 64,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 4],
      metric: 'recoveryScore',
      metric_value: 74,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 5],
      metric: 'recoveryScore',
      metric_value: 79,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 6],
      metric: 'recoveryScore',
      metric_value: 63,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 7],
      metric: 'recoveryScore',
      metric_value: 41,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 8],
      metric: 'recoveryScore',
      metric_value: 24,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 9],
      metric: 'recoveryScore',
      metric_value: 77,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 10],
      metric: 'recoveryScore',
      metric_value: 86,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 11],
      metric: 'recoveryScore',
      metric_value: 45,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 12],
      metric: 'recoveryScore',
      metric_value: 86,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 13],
      metric: 'recoveryScore',
      metric_value: 64,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 14],
      metric: 'recoveryScore',
      metric_value: 33,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 15],
      metric: 'recoveryScore',
      metric_value: 56,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 16],
      metric: 'recoveryScore',
      metric_value: 72,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 17],
      metric: 'recoveryScore',
      metric_value: 80,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 18],
      metric: 'recoveryScore',
      metric_value: 83,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 19],
      metric: 'recoveryScore',
      metric_value: 74,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 20],
      metric: 'recoveryScore',
      metric_value: 84,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 21],
      metric: 'recoveryScore',
      metric_value: 30,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 22],
      metric: 'recoveryScore',
      metric_value: 94,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 23],
      metric: 'recoveryScore',
      metric_value: 50,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 24],
      metric: 'recoveryScore',
      metric_value: 63,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 25],
      metric: 'recoveryScore',
      metric_value: 66,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 26],
      metric: 'recoveryScore',
      metric_value: 73,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 27],
      metric: 'recoveryScore',
      metric_value: 54,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 28],
      metric: 'recoveryScore',
      metric_value: 58,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 29],
      metric: 'recoveryScore',
      metric_value: 24,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 30],
      metric: 'recoveryScore',
      metric_value: 66,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 31],
      metric: 'recoveryScore',
      metric_value: 37,
      metric_details: null,
    },
  ],
};

export const mockSleepScores: MetricOverview = {
  average_value: 83,
  metrics: [
    {
      cycle_start_day: [2022, 10, 2],
      metric: 'sleepPerformance',
      metric_value: 100,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 3],
      metric: 'sleepPerformance',
      metric_value: 100,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 4],
      metric: 'sleepPerformance',
      metric_value: 100,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 5],
      metric: 'sleepPerformance',
      metric_value: 72,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 6],
      metric: 'sleepPerformance',
      metric_value: 76,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 7],
      metric: 'sleepPerformance',
      metric_value: 75,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 8],
      metric: 'sleepPerformance',
      metric_value: 39,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 9],
      metric: 'sleepPerformance',
      metric_value: 56,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 10],
      metric: 'sleepPerformance',
      metric_value: 97,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 11],
      metric: 'sleepPerformance',
      metric_value: 83,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 12],
      metric: 'sleepPerformance',
      metric_value: 81,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 13],
      metric: 'sleepPerformance',
      metric_value: 66,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 14],
      metric: 'sleepPerformance',
      metric_value: 53,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 15],
      metric: 'sleepPerformance',
      metric_value: 69,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 16],
      metric: 'sleepPerformance',
      metric_value: 87,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 17],
      metric: 'sleepPerformance',
      metric_value: 97,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 18],
      metric: 'sleepPerformance',
      metric_value: 66,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 19],
      metric: 'sleepPerformance',
      metric_value: 71,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 20],
      metric: 'sleepPerformance',
      metric_value: 74,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 21],
      metric: 'sleepPerformance',
      metric_value: 55,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 22],
      metric: 'sleepPerformance',
      metric_value: 99,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 23],
      metric: 'sleepPerformance',
      metric_value: 86,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 24],
      metric: 'sleepPerformance',
      metric_value: 77,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 25],
      metric: 'sleepPerformance',
      metric_value: 64,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 26],
      metric: 'sleepPerformance',
      metric_value: 78,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 27],
      metric: 'sleepPerformance',
      metric_value: 77,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 28],
      metric: 'sleepPerformance',
      metric_value: 65,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 29],
      metric: 'sleepPerformance',
      metric_value: 65,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 30],
      metric: 'sleepPerformance',
      metric_value: 69,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 10, 31],
      metric: 'sleepPerformance',
      metric_value: 81,
      metric_details: null,
    },
  ],
};

export const mockHRV: MetricOverview = {
  average_value: 0.06263455588902746,
  metrics: [
    {
      cycle_start_day: [2022, 12, 14],
      metric: 'hrv',
      metric_value: 0.05815625935792923,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 15],
      metric: 'hrv',
      metric_value: 0.06250383704900742,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 16],
      metric: 'hrv',
      metric_value: 0.05894247442483902,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 17],
      metric: 'hrv',
      metric_value: 0.05839620158076286,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 18],
      metric: 'hrv',
      metric_value: 0.04098279029130936,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 19],
      metric: 'hrv',
      metric_value: 0.07191263884305954,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 20],
      metric: 'hrv',
      metric_value: 0.06189486011862755,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 21],
      metric: 'hrv',
      metric_value: 0.07058443129062653,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 22],
      metric: 'hrv',
      metric_value: 0.06356801837682724,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 23],
      metric: 'hrv',
      metric_value: 0.08613255620002747,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 24],
      metric: 'hrv',
      metric_value: 0.0755961686372757,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 25],
      metric: 'hrv',
      metric_value: 0.0721312,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 26],
      metric: 'hrv',
      metric_value: 0.06670384854078293,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 27],
      metric: 'hrv',
      metric_value: 0.08934519439935684,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 28],
      metric: 'hrv',
      metric_value: 0.05667895823717117,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 29],
      metric: 'hrv',
      metric_value: 0.057934314012527466,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 30],
      metric: 'hrv',
      metric_value: 0.06262524425983429,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 31],
      metric: 'hrv',
      metric_value: 0.04925883933901787,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 1],
      metric: 'hrv',
      metric_value: 0.06062731891870499,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 2],
      metric: 'hrv',
      metric_value: 0.07127958536148071,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 3],
      metric: 'hrv',
      metric_value: 0.05837374925613403,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 4],
      metric: 'hrv',
      metric_value: 0.06655752658843994,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 5],
      metric: 'hrv',
      metric_value: 0.05577341094613075,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 6],
      metric: 'hrv',
      metric_value: 0.05662143975496292,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 7],
      metric: 'hrv',
      metric_value: 0.053808558732271194,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 8],
      metric: 'hrv',
      metric_value: 0.07165606319904327,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 9],
      metric: 'hrv',
      metric_value: 0.07621,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 10],
      metric: 'hrv',
      metric_value: 0.060170479118824005,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 11],
      metric: 'hrv',
      metric_value: 0.05852638930082321,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 12],
      metric: 'hrv',
      metric_value: 0.04915640875697136,
      metric_details: null,
    },
  ],
};

export const mockRHR: MetricOverview = {
  average_value: 55.758620689655174,
  metrics: [
    {
      cycle_start_day: [2023, 1, 12],
      metric: 'rhr',
      metric_value: 53.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 13],
      metric: 'rhr',
      metric_value: 52.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 14],
      metric: 'rhr',
      metric_value: 52.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 15],
      metric: 'rhr',
      metric_value: 66.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 16],
      metric: 'rhr',
      metric_value: 53.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 17],
      metric: 'rhr',
      metric_value: 53.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 18],
      metric: 'rhr',
      metric_value: 55.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 19],
      metric: 'rhr',
      metric_value: 49.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 20],
      metric: 'rhr',
      metric_value: 58.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 21],
      metric: 'rhr',
      metric_value: 59.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 22],
      metric: 'rhr',
      metric_value: 62.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 23],
      metric: 'rhr',
      metric_value: 50.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 24],
      metric: 'rhr',
      metric_value: 51.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 25],
      metric: 'rhr',
      metric_value: 60.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 26],
      metric: 'rhr',
      metric_value: 59.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 27],
      metric: 'rhr',
      metric_value: 56.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 28],
      metric: 'rhr',
      metric_value: 76.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 29],
      metric: 'rhr',
      metric_value: 69.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 30],
      metric: 'rhr',
      metric_value: 50.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 31],
      metric: 'rhr',
      metric_value: 56.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 2, 1],
      metric: 'rhr',
      metric_value: 53.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 2, 2],
      metric: 'rhr',
      metric_value: 55.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 2, 3],
      metric: 'rhr',
      metric_value: 51.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 2, 4],
      metric: 'rhr',
      metric_value: 52.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 2, 5],
      metric: 'rhr',
      metric_value: 50.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 2, 6],
      metric: 'rhr',
      metric_value: 50.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 2, 7],
      metric: 'rhr',
      metric_value: 51.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 2, 8],
      metric: 'rhr',
      metric_value: 53.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 2, 9],
      metric: 'rhr',
      metric_value: 54.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 2, 10],
      metric: 'rhr',
      metric_value: 59.0,
      metric_details: null,
    },
  ],
};

export const mockRespiratoryRate: MetricOverview = {
  average_value: 15.147443498883929,
  metrics: [
    {
      cycle_start_day: [2022, 12, 14],
      metric: 'respiratoryRate',
      metric_value: 16.93359375,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 15],
      metric: 'respiratoryRate',
      metric_value: 16.23046875,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 16],
      metric: 'respiratoryRate',
      metric_value: 15.41015625,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 17],
      metric: 'respiratoryRate',
      metric_value: 14.94140625,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 18],
      metric: 'respiratoryRate',
      metric_value: 15.791015625,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 19],
      metric: 'respiratoryRate',
      metric_value: 16.728515625,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 20],
      metric: 'respiratoryRate',
      metric_value: 15.64453125,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 21],
      metric: 'respiratoryRate',
      metric_value: 15.87890625,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 22],
      metric: 'respiratoryRate',
      metric_value: 15.234375,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 23],
      metric: 'respiratoryRate',
      metric_value: 16.69921875,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 24],
      metric: 'respiratoryRate',
      metric_value: 15.234375,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 25],
      metric: 'respiratoryRate',
      metric_value: 13,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 26],
      metric: 'respiratoryRate',
      metric_value: 14.82421875,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 27],
      metric: 'respiratoryRate',
      metric_value: 14.20166015625,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 28],
      metric: 'respiratoryRate',
      metric_value: 15.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 29],
      metric: 'respiratoryRate',
      metric_value: 14.00390625,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 30],
      metric: 'respiratoryRate',
      metric_value: 15.17578125,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 31],
      metric: 'respiratoryRate',
      metric_value: 15.4248046875,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 1],
      metric: 'respiratoryRate',
      metric_value: 14.58984375,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 2],
      metric: 'respiratoryRate',
      metric_value: 14.1796875,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 3],
      metric: 'respiratoryRate',
      metric_value: 13.7109375,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 4],
      metric: 'respiratoryRate',
      metric_value: 13.76953125,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 5],
      metric: 'respiratoryRate',
      metric_value: 15.5859375,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 6],
      metric: 'respiratoryRate',
      metric_value: 15.01953125,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 7],
      metric: 'respiratoryRate',
      metric_value: 13.9453125,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 8],
      metric: 'respiratoryRate',
      metric_value: 15.087890625,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 9],
      metric: 'respiratoryRate',
      metric_value: 14,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 10],
      metric: 'respiratoryRate',
      metric_value: 14.98046875,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 11],
      metric: 'respiratoryRate',
      metric_value: 14.8828125,
      metric_details: null,
    },
    {
      cycle_start_day: [2023, 1, 12],
      metric: 'respiratoryRate',
      metric_value: 15.01953125,
      metric_details: null,
    },
  ],
};

export const sleepDuration: MetricOverview = {
  average_value: 2.6936738086956523e7,
  metrics: [
    {
      cycle_start_day: [2022, 11, 3],
      metric: 'sleepDuration',
      metric_value: 2.0417446e7,
      metric_details: {
        light_sleep_duration: 1.0077502e7,
        rem_duration: 4187348.0,
        slow_wave_duration: 4649413.0,
        wake_duration: 1530542.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 4],
      metric: 'sleepDuration',
      metric_value: 2.4397343e7,
      metric_details: {
        light_sleep_duration: 8692351.0,
        rem_duration: 8721258.0,
        slow_wave_duration: 5399248.0,
        wake_duration: 1617168.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 5],
      metric: 'sleepDuration',
      metric_value: 31116949,
      metric_details: {
        light_sleep_duration: 14468037,
        rem_duration: 7335092,
        slow_wave_duration: 7045304,
        wake_duration: 2310225,
      },
    },
    {
      cycle_start_day: [2022, 11, 6],
      metric: 'sleepDuration',
      metric_value: 32068616,
      metric_details: {
        light_sleep_duration: 16632847,
        rem_duration: 6844130,
        slow_wave_duration: 7075186,
        wake_duration: 1559411,
      },
    },
    {
      cycle_start_day: [2022, 11, 7],

      metric: 'sleepDuration',
      metric_value: 22205517,
      metric_details: {
        light_sleep_duration: 10799431,
        rem_duration: 5255863,
        slow_wave_duration: 4822664,
        wake_duration: 1357289,
      },
    },
    {
      cycle_start_day: [2022, 11, 8],

      metric: 'sleepDuration',
      metric_value: 27569652,
      metric_details: {
        light_sleep_duration: 12329980,
        rem_duration: 6237699,
        slow_wave_duration: 6728630,
        wake_duration: 2310286,
      },
    },
    {
      cycle_start_day: [2022, 11, 9],

      metric: 'sleepDuration',
      metric_value: 27886931,
      metric_details: {
        light_sleep_duration: 11463676,
        rem_duration: 7479484,
        slow_wave_duration: 6670885,
        wake_duration: 2310244,
      },
    },
    {
      cycle_start_day: [2022, 11, 10],
      metric: 'sleepDuration',
      metric_value: 23993614,
      metric_details: {
        light_sleep_duration: 13860598,
        rem_duration: 4967071,
        slow_wave_duration: 3840805,
        wake_duration: 1357269,
      },
    },
    {
      cycle_start_day: [2022, 11, 11],
      metric: 'sleepDuration',
      metric_value: 2.9099154e7,
      metric_details: {
        light_sleep_duration: 1.4265861e7,
        rem_duration: 4822651.0,
        slow_wave_duration: 6584216.0,
        wake_duration: 3465382.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 12],
      metric: 'sleepDuration',
      metric_value: 2.60412e7,
      metric_details: {
        light_sleep_duration: 1.2937456e7,
        rem_duration: 5052715.0,
        slow_wave_duration: 4418387.0,
        wake_duration: 3667518.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 13],
      metric: 'sleepDuration',
      metric_value: 1.65241e7,
      metric_details: {
        light_sleep_duration: 7710470.0,
        rem_duration: 4099707.0,
        slow_wave_duration: 3609808.0,
        wake_duration: 1126244.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 14],
      metric: 'sleepDuration',
      metric_value: 2.8146461e7,
      metric_details: {
        light_sleep_duration: 1.5045571e7,
        rem_duration: 4157477.0,
        slow_wave_duration: 5660141.0,
        wake_duration: 3320987.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 15],
      metric: 'sleepDuration',
      metric_value: 2.5695109e7,
      metric_details: {
        light_sleep_duration: 1.1232612e7,
        rem_duration: 6122190.0,
        slow_wave_duration: 6179971.0,
        wake_duration: 2194765.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 16],
      metric: 'sleepDuration',
      metric_value: 2.8888548e7,
      metric_details: {
        light_sleep_duration: 1.2943924e7,
        rem_duration: 8348508.0,
        slow_wave_duration: 5856374.0,
        wake_duration: 1757670.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 17],
      metric: 'sleepDuration',
      metric_value: 3.2483856e7,
      metric_details: {
        light_sleep_duration: 1.4432068e7,
        rem_duration: 6882418.0,
        slow_wave_duration: 6997949.0,
        wake_duration: 4197814.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 18],
      metric: 'sleepDuration',
      metric_value: 2.463575e7,
      metric_details: {
        light_sleep_duration: 1.0145612e7,
        rem_duration: 5818842.0,
        slow_wave_duration: 5730267.0,
        wake_duration: 2963799.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 19],

      metric: 'sleepDuration',
      metric_value: 27742726,
      metric_details: {
        light_sleep_duration: 11897857,
        rem_duration: 6467729,
        slow_wave_duration: 6641996,
        wake_duration: 2772311,
      },
    },
    {
      cycle_start_day: [2022, 11, 20],
      metric: 'sleepDuration',
      metric_value: 3.1602384e7,
      metric_details: {
        light_sleep_duration: 1.179743e7,
        rem_duration: 8352387.0,
        slow_wave_duration: 8073198.0,
        wake_duration: 3406620.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 21],
      metric: 'sleepDuration',
      metric_value: 1.6187632e7,
      metric_details: {
        light_sleep_duration: 9333187.0,
        rem_duration: 2459423.0,
        slow_wave_duration: 2763584.0,
        wake_duration: 1643149.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 22],
      metric: 'sleepDuration',
      metric_value: 3.336549e7,
      metric_details: {
        light_sleep_duration: 1.555732e7,
        rem_duration: 7435020.0,
        slow_wave_duration: 7324317.0,
        wake_duration: 3085096.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 23],
      metric: 'sleepDuration',
      metric_value: 3.1160229e7,
      metric_details: {
        light_sleep_duration: 1.1986561e7,
        rem_duration: 7808294.0,
        slow_wave_duration: 7762721.0,
        wake_duration: 3642370.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 24],
      metric: 'sleepDuration',
      metric_value: 3.7884722e7,
      metric_details: {
        light_sleep_duration: 1.595015e7,
        rem_duration: 1.0516212e7,
        slow_wave_duration: 7135650.0,
        wake_duration: 4315311.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 25],
      metric: 'sleepDuration',
      metric_value: 3.0006616e7,
      metric_details: {
        light_sleep_duration: 1.0746322e7,
        rem_duration: 9685514.0,
        slow_wave_duration: 7834487.0,
        wake_duration: 1761548.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 26],
      metric: 'sleepDuration',
      metric_value: 2.9818172e7,
      metric_details: {
        light_sleep_duration: 8813443.0,
        rem_duration: 1.0610535e7,
        slow_wave_duration: 8391822.0,
        wake_duration: 2026257.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 27],
      metric: 'sleepDuration',
      metric_value: 2.2758342e7,
      metric_details: {
        light_sleep_duration: 8659373.0,
        rem_duration: 7385105.0,
        slow_wave_duration: 4912083.0,
        wake_duration: 1818318.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 28],
      metric: 'sleepDuration',
      metric_value: 2.8245452e7,
      metric_details: {
        light_sleep_duration: 1.3932392e7,
        rem_duration: 7035562.0,
        slow_wave_duration: 4695516.0,
        wake_duration: 2604770.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 29],
      metric: 'sleepDuration',
      metric_value: 2.8209789e7,
      metric_details: {
        light_sleep_duration: 1.2235304e7,
        rem_duration: 6840120.0,
        slow_wave_duration: 6286684.0,
        wake_duration: 2868514.0,
      },
    },
    {
      cycle_start_day: [2022, 11, 30],
      metric: 'sleepDuration',
      metric_value: 2.2262071e7,
      metric_details: {
        light_sleep_duration: 9946333.0,
        rem_duration: 5039075.0,
        slow_wave_duration: 5298088.0,
        wake_duration: 2000254.0,
      },
    },
    {
      cycle_start_day: [2022, 12, 1],
      metric: 'sleepDuration',
      metric_value: 3.382111e7,
      metric_details: {
        light_sleep_duration: 1.2568527e7,
        rem_duration: 9148423.0,
        slow_wave_duration: 8746054.0,
        wake_duration: 3381526.0,
      },
    },
    {
      cycle_start_day: [2022, 12, 2],
      metric: 'sleepDuration',
      metric_value: 1.7894e7,
      metric_details: {
        light_sleep_duration: 8932740.0,
        rem_duration: 2727027.0,
        slow_wave_duration: 4780212.0,
        wake_duration: 1468916.0,
      },
    },
  ],
};

export const sleepLatency: MetricOverview = {
  average_value: 83368.75862068965,
  metrics: [
    {
      cycle_start_day: [2022, 12, 2],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 3],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 4],
      metric: 'sleepLatency',
      metric_value: 115358.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 5],
      metric: 'sleepLatency',
      metric_value: 467197.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 6],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 7],
      metric: 'sleepLatency',
      metric_value: 327806.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 8],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 9],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 10],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 11],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 12],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 13],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 14],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 15],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 16],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 17],
      metric: 'sleepLatency',
      metric_value: 85556.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 18],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 19],
      metric: 'sleepLatency',
      metric_value: 560444.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 20],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 21],
      metric: 'sleepLatency',
      metric_value: 57679.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 22],
      metric: 'sleepLatency',
      metric_value: 143235.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 23],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 24],
      metric: 'sleepLatency',
      metric_value: 460467.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 25],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 26],
      metric: 'sleepLatency',
      metric_value: 56717.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 27],
      metric: 'sleepLatency',
      metric_value: 143235.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 28],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 29],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 30],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 31],
      metric: 'sleepLatency',
      metric_value: 0.0,
      metric_details: null,
    },
  ],
};

export const sleepConsistency: MetricOverview = {
  average_value: 60.6551724137931,
  metrics: [
    {
      cycle_start_day: [2022, 12, 2],
      metric: 'sleepConsistency',
      metric_value: 44.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 3],
      metric: 'sleepConsistency',
      metric_value: 58.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 4],
      metric: 'sleepConsistency',
      metric_value: 68.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 5],
      metric: 'sleepConsistency',
      metric_value: 57.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 6],
      metric: 'sleepConsistency',
      metric_value: 63.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 7],
      metric: 'sleepConsistency',
      metric_value: 75.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 8],
      metric: 'sleepConsistency',
      metric_value: 76.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 9],
      metric: 'sleepConsistency',
      metric_value: 68.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 10],
      metric: 'sleepConsistency',
      metric_value: 53.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 11],
      metric: 'sleepConsistency',
      metric_value: 41.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 12],
      metric: 'sleepConsistency',
      metric_value: 43.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 13],
      metric: 'sleepConsistency',
      metric_value: 55.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 14],
      metric: 'sleepConsistency',
      metric_value: 59.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 15],
      metric: 'sleepConsistency',
      metric_value: 66.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 16],
      metric: 'sleepConsistency',
      metric_value: 78.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 17],
      metric: 'sleepConsistency',
      metric_value: 83.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 18],
      metric: 'sleepConsistency',
      metric_value: 61.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 19],
      metric: 'sleepConsistency',
      metric_value: 55.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 20],
      metric: 'sleepConsistency',
      metric_value: 73.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 21],
      metric: 'sleepConsistency',
      metric_value: 78.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 22],
      metric: 'sleepConsistency',
      metric_value: 68.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 23],
      metric: 'sleepConsistency',
      metric_value: 77.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 24],
      metric: 'sleepConsistency',
      metric_value: 17.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 25],
      metric: 'sleepConsistency',
      metric_value: 60.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 26],
      metric: 'sleepConsistency',
      metric_value: 39.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 27],
      metric: 'sleepConsistency',
      metric_value: 42.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 28],
      metric: 'sleepConsistency',
      metric_value: 58.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 29],
      metric: 'sleepConsistency',
      metric_value: 76.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 30],
      metric: 'sleepConsistency',
      metric_value: 65.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 31],
      metric: 'sleepConsistency',
      metric_value: 63.0,
      metric_details: null,
    },
  ],
};
export const sleepDisturbances: MetricOverview = {
  average_value: 11.89655172413793,
  metrics: [
    {
      cycle_start_day: [2022, 12, 2],
      metric: 'sleepDisturbances',
      metric_value: 9.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 3],
      metric: 'sleepDisturbances',
      metric_value: 11.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 4],
      metric: 'sleepDisturbances',
      metric_value: 11.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 5],
      metric: 'sleepDisturbances',
      metric_value: 11.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 6],
      metric: 'sleepDisturbances',
      metric_value: 14.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 7],
      metric: 'sleepDisturbances',
      metric_value: 16.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 8],
      metric: 'sleepDisturbances',
      metric_value: 16.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 9],
      metric: 'sleepDisturbances',
      metric_value: 12.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 10],
      metric: 'sleepDisturbances',
      metric_value: 10.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 11],
      metric: 'sleepDisturbances',
      metric_value: 6.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 12],
      metric: 'sleepDisturbances',
      metric_value: 16.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 13],
      metric: 'sleepDisturbances',
      metric_value: 13.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 14],
      metric: 'sleepDisturbances',
      metric_value: 11.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 15],
      metric: 'sleepDisturbances',
      metric_value: 7.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 16],
      metric: 'sleepDisturbances',
      metric_value: 13.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 17],
      metric: 'sleepDisturbances',
      metric_value: 5.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 18],
      metric: 'sleepDisturbances',
      metric_value: 17.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 19],
      metric: 'sleepDisturbances',
      metric_value: 9.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 20],
      metric: 'sleepDisturbances',
      metric_value: 17.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 21],
      metric: 'sleepDisturbances',
      metric_value: 16.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 22],
      metric: 'sleepDisturbances',
      metric_value: 16.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 23],
      metric: 'sleepDisturbances',
      metric_value: 5.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 24],
      metric: 'sleepDisturbances',
      metric_value: 22.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 25],
      metric: 'sleepDisturbances',
      metric_value: 8.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 26],
      metric: 'sleepDisturbances',
      metric_value: 9.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 27],
      metric: 'sleepDisturbances',
      metric_value: 2.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 28],
      metric: 'sleepDisturbances',
      metric_value: 16.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 29],
      metric: 'sleepDisturbances',
      metric_value: 16.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 30],
      metric: 'sleepDisturbances',
      metric_value: 12.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 31],
      metric: 'sleepDisturbances',
      metric_value: 7.0,
      metric_details: null,
    },
  ],
};
export const sleepEfficiency: MetricOverview = {
  average_value: 89.27118130387932,
  metrics: [
    {
      cycle_start_day: [2022, 12, 2],
      metric: 'sleepEfficiency',
      metric_value: 91.87425231933594,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 3],
      metric: 'sleepEfficiency',
      metric_value: 86.1312484741211,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 4],
      metric: 'sleepEfficiency',
      metric_value: 87.3163070678711,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 5],
      metric: 'sleepEfficiency',
      metric_value: 94.72156524658203,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 6],
      metric: 'sleepEfficiency',
      metric_value: 89.67762756347656,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 7],
      metric: 'sleepEfficiency',
      metric_value: 67.34026336669922,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 8],
      metric: 'sleepEfficiency',
      metric_value: 88.87706756591797,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 9],
      metric: 'sleepEfficiency',
      metric_value: 92.16995239257812,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 10],
      metric: 'sleepEfficiency',
      metric_value: 94.11911010742188,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 11],
      metric: 'sleepEfficiency',
      metric_value: 96.04334259033203,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 12],
      metric: 'sleepEfficiency',
      metric_value: 88.55174255371094,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 13],
      metric: 'sleepEfficiency',
      metric_value: 89.57394409179688,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 14],
      metric: 'sleepEfficiency',
      metric_value: 91.88568878173828,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 15],
      metric: 'sleepEfficiency',
      metric_value: 91.61770629882812,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 16],
      metric: 'sleepEfficiency',
      metric_value: 91.80960845947266,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 17],
      metric: 'sleepEfficiency',
      metric_value: 95.49071502685547,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 18],
      metric: 'sleepEfficiency',
      metric_value: 92.53946685791016,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 19],
      metric: 'sleepEfficiency',
      metric_value: 87.93956756591797,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 20],
      metric: 'sleepEfficiency',
      metric_value: 87.41095733642578,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 21],
      metric: 'sleepEfficiency',
      metric_value: 89.8899154663086,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 22],
      metric: 'sleepEfficiency',
      metric_value: 88.07522583007812,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 23],
      metric: 'sleepEfficiency',
      metric_value: 91.85810089111328,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 24],
      metric: 'sleepEfficiency',
      metric_value: 86.53739929199219,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 25],
      metric: 'sleepEfficiency',
      metric_value: 83.0,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 26],
      metric: 'sleepEfficiency',
      metric_value: 90.41231536865234,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 27],
      metric: 'sleepEfficiency',
      metric_value: 95.20710754394531,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 28],
      metric: 'sleepEfficiency',
      metric_value: 75.06996154785156,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 29],
      metric: 'sleepEfficiency',
      metric_value: 87.25588989257812,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 30],
      metric: 'sleepEfficiency',
      metric_value: 85.08795166015625,
      metric_details: null,
    },
    {
      cycle_start_day: [2022, 12, 31],
      metric: 'sleepEfficiency',
      metric_value: 94.38025665283203,
      metric_details: null,
    },
  ],
};

export const V2_MOCK_METRIC_MAP = {
  [StrainMetricType.STRAIN]: mockStrainMetric,
  [StrainMetricType.CALORIES]: mockCaloriesMetric,
  [StrainMetricType.AVG_HR]: mockAverageHRMetric,
  [RecoveryMetricType.SCORE]: mockRecoveryScore,
  [RecoveryMetricType.HRV]: mockHRV,
  [RecoveryMetricType.RHR]: mockRHR,
  [RecoveryMetricType.RESPIRATORY_RATE]: mockRespiratoryRate,
  [SleepMetricType.PERFORMANCE]: mockSleepPerformance,
  [SleepMetricType.DURATION]: sleepDuration,
  [SleepMetricType.LATENCY]: sleepLatency,
  [SleepMetricType.CONSISTENCY]: sleepConsistency,
  [SleepMetricType.DISTURBANCES]: sleepDisturbances,
  [SleepMetricType.EFFICIENCY]: sleepEfficiency,
};
