import { TITLE_3 } from 'components/text';
import { PulsingLogo } from '@whoop/web-components';
import { EmailAndSeatStatus, SeatStatus } from 'types/seat';
import InvitesSentTable from '../invitesSentTable/invitesSentTable';

type InvitesSentProps = {
  loading: boolean;
  emails: string[];
  errors: string[];
  skippedEmails: EmailAndSeatStatus[];
  inviteType?: string;
};

function InvitesSent({
  loading,
  emails,
  errors,
  skippedEmails,
  inviteType = null,
}: InvitesSentProps) {
  if (loading) {
    return (
      <>
        <TITLE_3>Sending invitations...</TITLE_3>
        <p>Please be patient while we send your invitations.</p>
        <PulsingLogo color="dark" size="medium" />
      </>
    );
  }

  if (errors) {
    return (
      <>
        <TITLE_3>Error sending invites!</TITLE_3>
        <p>There was an error sending invitations to your team members:</p>
        {errors.map((err) => (
          <p key={err}>{err}</p>
        ))}
      </>
    );
  }

  return (
    <div>
      <TITLE_3>{ emails.length > 0 ? 'Invitations sent!' : 'Accounts already exist.'}</TITLE_3>
      { emails.length > 0 && (
      <>
        <p>
          The following
          {' '}
          {inviteType ? `${inviteType}s` : 'team members'}
          {' '}
          have been
          added to your account and have received invitations with instructions
          for signing up. It may take up to 10 minutes to receive email invites.
          Contact your CSM if members do not receive their invite,
          or copy the invitation link by clicking on the icon with
          three dots in the &quot;invited&quot; members table.
        </p>
        <InvitesSentTable
          emails={emails.map((email) => ({ email, status: SeatStatus.Invited }))}
          inviteType={inviteType}
        />
      </>
      )}
      { skippedEmails.length > 0 && (
        <>
          {skippedEmails.map((seat) => seat.status === SeatStatus.Pending).includes(true) && (
          <p>
            Members who are pending have already signed up
            and will receive their device soon.
          </p>
          )}
          {skippedEmails.map((seat) => seat.status === SeatStatus.Active).includes(true) && (
          <p>Members who are activated have already set up their device.</p>
          )}
          <InvitesSentTable
            emails={skippedEmails}
            inviteType={inviteType}
          />
        </>
      )}
    </div>
  );
}

InvitesSent.defaultProps = {
  inviteType: null,
};

export default InvitesSent;
