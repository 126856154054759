import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PulsingLogo from 'components/spinner';
import Footer from 'components/footer/footer';
import { Logo, Button } from '@whoop/web-components';
import FullPageMessage from 'components/fullPageMessage';
import { acceptEnterpriseInvite } from '../api/seatApi';
import styles from './acceptPage.module.scss';

function AcceptPage() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onWhoopLinkClick = () => { window.location.href = 'https://www.whoop.com/'; };

  useEffect(() => {
    const callAcceptInviteAPI = async () => {
      try {
        await acceptEnterpriseInvite(token);
      } catch (e) {
        setError(e.response);
      }
      setLoading(false);
    };

    callAcceptInviteAPI();
  }, [token]);

  const whoopLinkButton = (
    <Button
      label="www.whoop.com"
      variant="link"
      theme="primary"
      onClick={onWhoopLinkClick}
    />
  );

  let content = (
    <FullPageMessage
      header="You've been added to the enterprise plan."
      subtext="Your billing plan has been updated to be fully covered by your employer."
      state="success"
      button={whoopLinkButton}
    />
  );

  if (loading) {
    content = <PulsingLogo overlay />;
  }

  if (error) {
    if (error.status === 405) {
      content = (
        <FullPageMessage
          header="Sorry, this invitation is no longer available."
          subtext="The invitation that you received has been revoked. Please contact the account manager to request a new invitation."
          state="error"
          button={whoopLinkButton}
        />
      );
    } else {
      content = (
        <FullPageMessage
          header="Sorry, we had a problem adding you to the enterprise plan."
          subtext="Your account manager has been notified of this issue, and should be in
            touch with you once resolved."
          state="error"
          button={whoopLinkButton}
        />
      );
    }
  }

  return (
    <div className={styles.acceptPage}>
      <div className={styles.header}>
        <Logo />
      </div>
      <div className={styles.content}>
        <div className={styles.acceptMessage}>{content}</div>
      </div>
      <Footer logo />
    </div>
  );
}

export default AcceptPage;
