import { rest } from 'msw';
import {
  getMetricDataV2,
} from 'mocks/actions/memberOverviewActions';
import { mswBaseUri } from 'mocks/utils/utils';
import { MetricType } from 'types/analytics';

export const memberOverviewHandlers = [
  rest.get(mswBaseUri('v2/:pillar/:metricType'), (req, res, ctx) => {
    const { metricType } = req.params;
    const startDate = req.url.searchParams.get('startDate');
    const endDate = req.url.searchParams.get('endDate');
    return res(
      ctx.delay(50),
      ctx.status(200),
      ctx.json(getMetricDataV2(<MetricType>metricType, startDate, endDate)),
    );
  }),
];

export default memberOverviewHandlers;
