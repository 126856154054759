import { PointSymbolProps } from '@nivo/line';
import { Colors } from '@whoop/web-components';
import { toRecoveryScorePercentage } from 'dataVisualizations/strainRecoveryTrendGraph/strainRecoveryTrendGraphUtils';
import { getColorFromRecovery } from 'dataVisualizations/utils/utils';
import { getColorFromVal } from 'progress/progressPageUtils';
import { TrendKey } from 'types/analytics';
import styles from './points.module.scss';

export function CustomHoverPoint(props: any) {
  const { currentPoint } = props;
  if (currentPoint) {
    if (currentPoint.color === Colors.gray500) {
      currentPoint.color = Colors.gray700;
    }
    return (
      <g>
        <circle
          r={6}
          strokeWidth="2px"
          stroke={Colors.whiteAlpha900}
          fill={currentPoint.color}
          cx={currentPoint.x}
          cy={currentPoint.y}
        />
      </g>
    );
  }
}

export function MaxHeartRatePoint(pointSymbol: PointSymbolProps, maxHr: number) {
  const { datum } = pointSymbol;
  if (Number(datum.yFormatted) === maxHr) {
    return <circle r="3" fill={Colors.brandingRed} />;
  }
  return <g />;
}

// Line chart point that changes color based on selection/recovery value
export function PillarPoint(
  pointSymbol: PointSymbolProps,
  daysToHighlight: string[],
  showLabel: boolean = false,
) {
  const daysHighlighted = daysToHighlight;
  const { datum } = pointSymbol;
  if (!datum.y) {
    return null;
  }

  const highlightedDay = daysHighlighted.indexOf(datum.x as string) > -1;

  const disabledDay = !highlightedDay && daysHighlighted.length > 0;
  const strainColor = disabledDay ? Colors.gray500 : Colors.strainBlue;
  const color = datum.key === TrendKey.Recovery
    ? getColorFromRecovery(toRecoveryScorePercentage(datum.y), disabledDay) : strainColor;
  const fill = highlightedDay ? color : 'white';
  const lengthOfLabel = (datum.y as number).toString().replace('.', '').length;
  return (
    <>
      {showLabel && (
        <text
          y={-15}
          x={lengthOfLabel === 3 ? -15 : -10}
          className={styles.pillarPoint}
          fill={color}
        >
          {datum.key === TrendKey.Recovery ? `${toRecoveryScorePercentage(datum.y)}%` : datum.y}
        </text>
      )}
      <circle r="5" stroke={color} fill={fill} strokeWidth="2.5" />
    </>
  );
}

// TODO : This should be removed when old progress member profile page is removed
// Line chart point that changes color based on selection/recovery value. And hides when > 30 days
export function PillarPointOld(
  pointSymbol: PointSymbolProps,
  daysToHighlight: string[],
  hidePoint: boolean = false,
  showLabel: boolean = false,
) {
  const daysHighlighted = daysToHighlight;
  const { datum } = pointSymbol;
  if (!datum.y) {
    return null;
  }

  const highlightedDay = daysHighlighted.indexOf(datum.x as string) > -1;
  if (!highlightedDay && hidePoint) {
    return null;
  }

  const hiddenDay = !highlightedDay && daysHighlighted.length > 0;
  const strainColor = hiddenDay ? Colors.strainBlueAlpha200 : Colors.strainBlue;
  const color = datum.key === TrendKey.Recovery
    ? getColorFromVal(toRecoveryScorePercentage(datum.y), hiddenDay) : strainColor;
  const fill = highlightedDay ? color : 'white';
  const lengthOfLabel = (datum.y as number).toString().replace('.', '').length;
  return (
    <>
      {showLabel
        && (
          <text
            y={-15}
            x={lengthOfLabel === 3 ? -15 : -10}
            className={styles.pillarPoint}
            fill={
              datum.key === TrendKey.Recovery
                ? getColorFromVal(toRecoveryScorePercentage(datum.y))
                : Colors.strainBlue
            }
          >
            {datum.key === TrendKey.Recovery ? `${toRecoveryScorePercentage(datum.y)}%` : datum.y}
          </text>
        )}
      <circle r="5" stroke={color} fill={fill} strokeWidth="2.5" />
    </>
  );
}
