import { ReactChild } from 'react';
import styles from './graphLabel.module.scss';

type GraphLabelProps = {
  color?: string;
  showSquare: boolean;
  children: ReactChild[] | ReactChild;
};

function GraphLabel({ color, children, showSquare = false }: GraphLabelProps) {
  return (
    <div>
      <div className={styles.label}>
        <div className={`${showSquare ? styles.labelSquare : ''} ${styles[color]}`} data-testid="graphLabelBlock" />
        {children}
      </div>
    </div>
  );
}

export default GraphLabel;
