export const strainScore = [
  19.6,
  16.3,
  12.7,
  10.6,
  9.3,
  7.9,
  6.2,
  4.1,
  14.4,
  12.4,
  17.5,
  15.9,
  11.6,
  4.9,
  4.2,
  13.9,
  6.1,
  18.0,
  17.3,
  7.7,
];

export const calories = [
  2900,
  1700,
  2230,
  1978,
  2151,
  1692,
  1734,
  1543,
  1800,
  2216,
  3517,
  2350,
  1923,
  2603,
  816,
  2740,
  1867,
  2488,
  4070,
  1989,
];

export const workoutCounts = [
  3,
  2,
  2,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  4,
  1,
  1,
  0,
  0,
  2,
  0,
  3,
  3,
  0,
];

export const recoveryScore = [
  99,
  97,
  95,
  85,
  77,
  75,
  72,
  67,
  58,
  50,
  40,
  36,
  32,
  20,
  15,
  45,
  80,
  51,
  30,
  5,
];

export const rhr = [
  49,
  60,
  46,
  51,
  54,
  46,
  52,
  65,
  59,
  56,
  51,
  65,
  62,
  72,
  70,
  55,
  46,
  54,
  63,
  78,
];

export const hrv = [
  0.17357085645198822,
  0.05313081294298172,
  0.11963166296482086,
  0.05521512031555176,
  0.08551025390625,
  0.14357085645198822,
  0.08123853802680969,
  0.04523853802680969,
  0.05723853802680969,
  0.0432705357670784,
  0.0712705357670784,
  0.0352705357670784,
  0.0222705357670784,
  0.0252705357670784,
  0.0332705357670784,
  0.0752705357670784,
  0.1112705357670784,
  0.082705357670784,
  0.0272705357670784,
  0.0182705357670784,
];

export const sleepConsistency = [
  70,
  86,
  100,
  93,
  52,
  64,
  87,
  77,
  86,
  62,
  90,
  94,
  65,
  55,
  83,
  88,
  95,
  74,
  70,
  78,
];

export const sleepEfficiency = [
  0.894,
  0.92,
  0.95,
  0.99,
  0.74,
  0.80,
  0.83,
  0.87,
  0.88,
  0.95,
  0.894,
  0.97,
  0.87,
  0.85,
  0.86,
  0.74,
  0.70,
  0.77,
  0.82,
  0.85,
];

export const sleepPerformance = [
  100,
  100,
  99,
  97,
  96,
  95,
  93,
  90,
  88,
  85,
  82,
  81,
  78,
  75,
  74,
  70,
  68,
  65,
  63,
  61,
];

export const sleepDuration = [
  32990742,
  30000742,
  29990742,
  28346582,
  27346582,
  27722009,
  27522009,
  26002342,
  26502342,
  25002342,
  24358074,
  24448074,
  23358074,
  22358074,
  21358074,
  20395409,
  17553006,
  19553006,
  18453006,
  17453006,
];
