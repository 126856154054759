import { classes } from '@whoop/web-components';
import { ReactNode } from 'react';
import styles from './card.module.scss';

type CardProps = {
  id: string;
  children: ReactNode;
  showBackground?: boolean;
  loading?: boolean;
  className?: string;
  overflow?: boolean;
};

function Card({
  id, children, showBackground = true, loading = false, className, overflow = false,
}: CardProps) {
  return (
    <div
      data-testid={`${id.replace(/\s+/g, '-').toLowerCase()}-card`}
      className={classes(
        styles.card,
        showBackground && styles.showBackground,
        loading && styles.loadingAnimation,
        overflow && styles.overflow,
        className,
      )}
    >
      {!loading && (children)}
    </div>
  );
}

export default Card;
