import React, { useEffect } from 'react';
import { PulsingLogo } from '@whoop/web-components';
import { TITLE_3 } from 'components/text';

type LoadingProps = {
  handler: () => {};
};

function LoadingHandler({ handler }: LoadingProps) {
  useEffect(() => {
    handler();
  }, [handler]);

  return (
    <>
      <TITLE_3>Creating Group</TITLE_3>
      <PulsingLogo color="dark" size="medium" />
    </>
  );
}

export default LoadingHandler;
