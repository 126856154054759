import { PrivacyLevel, DashboardRole } from 'types/dashboardUser';
import { InviteStatus } from 'types/collaborator';
import { baseAccount, invitedAndBouncedCollaborators } from './data';

// Will get deprecated once PermissionsUser is fully off the site
export const currentDashboardUser = {
  first_name: 'John',
  last_name: 'Doe',
  name: 'John Doe',
  email: 'John Doe@whoop.com',
  display_role: 'Unite Account Administrator',
  role: DashboardRole.WHOOP_CSM,
  privacy_level: PrivacyLevel.details_value,
  avatar_url: '',
  user_id: 1000,
  salesforce_account_id: baseAccount.id,
};

export const currentDashboardUserCollaborator = {
  name: 'John Doe',
  email: 'JohnDoe@whoop.com',
  display_role: 'Unite Account Administrator',
  role: DashboardRole.WHOOP_CSM,
  privacy_level: PrivacyLevel.details_value,
  avatar_url: '',
  user_id: 1000,
  status: InviteStatus.accepted,
};

export const csmUser = {
  first_name: 'John',
  last_name: 'Doe',
  email: 'JohnDoe@whoop.com',
  avatar_url: '',
  user_id: 1000,
};

export const csm = {
  first_name: 'Jack',
  last_name: 'Clark',
  name: 'Jack Clark',
  email: 'jack.clark@whoop.com',
  display_role: 'WHOOP CSM',
  role: DashboardRole.WHOOP_CSM,
  privacy_level: PrivacyLevel.details_value,
  avatar_url: '',
  user_id: 10000,
};

export const csmCollaborator = {
  name: 'Jack Clark',
  email: 'jack.clark@whoop.com',
  display_role: 'WHOOP CSM',
  role: DashboardRole.WHOOP_CSM,
  privacy_level: PrivacyLevel.details_value,
  avatar_url: '',
  user_id: 10000,
  status: InviteStatus.accepted,
};

const dashboardUserTemplates = [
  {
    first_name: 'Cassie',
    last_name: 'Bell',
    display_role: 'HR Director',
    role: DashboardRole.ACCOUNT_ADMIN,
    privacy_level: PrivacyLevel.no_metrics,
  },
  {
    first_name: 'Brian',
    last_name: 'Carson',
    display_role: 'Finance Manager',
    role: DashboardRole.GROUP_MANAGER,
    managing_groups: 1,
    privacy_level: PrivacyLevel.no_metrics,
  }, {
    first_name: 'Brad',
    last_name: 'Fitzgerald',
    display_role: 'Sales Manager',
    role: DashboardRole.GROUP_MANAGER,
    managing_groups: 1,
    privacy_level: PrivacyLevel.aggregate_metrics,
  }, {
    first_name: 'Jeanne',
    last_name: 'Bryan',
    display_role: 'Strength and Conditioning Coach',
    role: DashboardRole.GROUP_MANAGER,
    managing_groups: 1,
    privacy_level: PrivacyLevel.details_value,
  }, {
    first_name: 'Julie',
    last_name: 'Joseph',
    display_role: 'Sports Nutritionist',
    role: DashboardRole.GROUP_MANAGER,
    managing_groups: 1,
    privacy_level: PrivacyLevel.aggregate_metrics,
  }, {
    first_name: 'Lindsay',
    last_name: 'Fox',
    display_role: 'Medical Director',
    role: DashboardRole.GROUP_MANAGER,
    managing_groups: 1,
    privacy_level: PrivacyLevel.aggregate_metrics,
  },
];

export const dashboardUsers = dashboardUserTemplates.map((template, i) => ({
  ...template,
  name: `${template.first_name} ${template.last_name}`,
  email: `${template.first_name.toLowerCase()}.${template.last_name.toLowerCase()}@fosterjefferson.com`,
  avatar_url: '',
  salesforce_account_id: baseAccount.id,
  user_id: 1000 + i + 1,
})).concat(csm, currentDashboardUser);

export const collaborators = dashboardUserTemplates.map((template, i) => ({
  ...template,
  name: `${template.first_name} ${template.last_name}`,
  email: `${template.first_name.toLowerCase()}.${template.last_name.toLowerCase()}@fosterjefferson.com`,
  avatar_url: '',
  user_id: 1000 + i + 1,
  status: InviteStatus.accepted,
})).concat(csmCollaborator, currentDashboardUserCollaborator, invitedAndBouncedCollaborators);

export default dashboardUsers;
