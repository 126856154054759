import { client } from 'api/apiClient';

const strapsUrl = 'enterprise-service/v1/straps';

const getAvailableStrapSerials = async () => {
  const { data } = await client.get<string[]>(`${strapsUrl}`);
  return data;
};

export default getAvailableStrapSerials;
