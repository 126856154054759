import { baseAccount } from './data';
import { baseSeats } from './seats';

export const PRIMARY_GROUP_NAME = 'Primary Group';
export const SALES_GROUP_NAME = 'Sales';
export const FINANCE_GROUP_NAME = 'Finance';
export const FIELD_HOCKEY_GROUP_NAME = "Women's Field Hockey";
export const SWIM_TEAM_NAME = "Men's Swim Team";
export const SURGEONS_GROUP_NAME = 'Surgeons';

const groupTemplates = [
  {
    name: PRIMARY_GROUP_NAME,
    seatIds: baseSeats.filter((seat) => seat.status === 'active').map((seat) => seat.id),
    collaborators: [],
    about: 'This contains all members.',
    is_primary: true,
  },
  {
    name: FINANCE_GROUP_NAME,
    seatIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    collaborators: [],
    about: 'Finance team managed by Ben Carson',
    created_at: '05/24/2022',
  },
  {
    name: SALES_GROUP_NAME,
    seatIds: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    collaborators: [],
    about: 'Sales team managed by Brad Fitzgerald',
    created_at: '07/01/2022',
  },
  {
    name: SWIM_TEAM_NAME,
    seatIds: [1, 2, 4, 6, 8, 12, 15, 18, 19, 20],
    collaborators: [],
    about: "Group for men's swimming athletes",
    created_at: '07/31/2022',
  },
  {
    name: FIELD_HOCKEY_GROUP_NAME,
    seatIds: [3, 5, 7, 9, 10, 11, 13, 14, 16, 17],
    collaborators: [],
    about: "Group for women's field hockey team",
    created_at: '10/08/2021',
  },
  {
    name: SURGEONS_GROUP_NAME,
    seatIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    collaborators: [],
    about: 'Surgeon rotation members managed by Lindsay Fox',
    created_at: '01/05/2022',
  },
];

export const baseGroups = groupTemplates.map((group, i) => ({
  ...group,
  id: i + 1,
  cohort_id: i + 1,
  community_id: i + 1,
  salesforce_account_id: baseAccount.id,
  legacy_team_id: null,
  member_count: group.seatIds.length,
  concealed: false,
}));

export default baseGroups;
