import { formatDate } from 'progress/profile/profileUtils';
import styles from './inputGroup.module.scss';

export enum InputGroupType {
  TEXT,
  TEXTAREA,
  DROPDOWN,
  DATE,
  TOGGLE,
}

type CommonProps = {
  label: string;
  id: string;
  type: InputGroupType;
};

type ConditionalProps =
 | { type: InputGroupType.TEXTAREA | InputGroupType.TEXT; placeholder: string; value: string;
   setValue: (text: string) => void; disabled?: boolean; error?: boolean;
   errorClass?: string; onBlur?: never; options?: never; passwordField?: boolean; }
 | { type: InputGroupType.TOGGLE; placeholder?: never; value?: never; setValue?: never;
   error?: never; errorClass?: never; onBlur?: never; options?: never; disabled?: never;
   passwordField?: never; }
 | { type: InputGroupType.DROPDOWN; placeholder: string; value: string;
   setValue: (text: string) => void; disabled?: boolean; error?: never; errorClass?: never;
   onBlur?: never; options?: string[]; passwordField?: string; }
 | { type: InputGroupType.DATE; placeholder: string; value: string;
   setValue: (text: string) => void; disabled?: never; error?: never;
   errorClass?: never; onBlur?: never; options?: never; passwordField?: never; };

type InputGroupProps = CommonProps & ConditionalProps;

function InputGroup({
  value,
  setValue,
  placeholder,
  label,
  id,
  error,
  errorClass,
  onBlur,
  type,
  options = [],
  disabled = false,
  passwordField = false,
}: InputGroupProps) {
  const handleChange = (event:
  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => (
    setValue(event.target.value)
  );
  const inputProps = {
    'input-id': `${id}-input`,
    'data-testid': `${id}-input`,
    'aria-labelledby': `${id}-input`,
    onBlur,
    placeholder,
    value,
    onChange: handleChange,
    disabled,
    id: 'input-group-input',
  };

  const maxTextAreaLength = 50;

  return (
    <div className={`${disabled ? styles.disabled : ''}`}>
      {label && (
        <label id={`${id}-input`} className={styles.inputLabel} htmlFor="input-group-input">
          {label}
        </label>
      )}
      {type === InputGroupType.TEXT && (
        <input
          {...inputProps}
          className={`${styles.input} ${error && errorClass}`}
          type={passwordField ? 'password' : 'text'}
        />
      )}
      {type === InputGroupType.TEXTAREA && (
        <>
          <textarea
            {...inputProps}
            className={`${styles.input} ${error && errorClass} ${
              styles.textArea
            }`}
            maxLength={maxTextAreaLength}
          />
          <div className={styles.charLimit}>
            {value.length}
            /
            {maxTextAreaLength}
          </div>
        </>
      )}
      {type === InputGroupType.DROPDOWN && (
        <select
          className={`${styles.input} ${error && errorClass} ${
            styles.dropdown
          }`}
          {...inputProps}
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {options.map((option) => (
            <option value={option} key={option}>{option}</option>
          ))}
        </select>
      )}
      {type === InputGroupType.DATE && (
        <input
          {...inputProps}
          className={`${styles.input}`}
          type="date"
          min="1910-01-01"
          max={formatDate()}
        />
      )}
    </div>
  );
}

export default InputGroup;
