import EmailsInputHeader from '../emailsInputHeader/emailsInputHeader';
import { typeEmails } from '../../constants';
import EmailsInput from '../../../../components/emailsInput/emailsInput';

type TypeEmailsProps = {
  emails: string[];
  setEmails: (emails: string[]) => void;
  invalidEmails: string[];
  setInvalidEmails: (emails: string[]) => void;
  setInputType: (inputType: string) => void;
  unclaimedLicenses: number;
};

function TypeEmails({
  emails,
  setEmails,
  invalidEmails,
  setInvalidEmails,
  setInputType,
  unclaimedLicenses,
}: TypeEmailsProps) {
  const emailsOverLicenseCap = emails.slice(unclaimedLicenses);
  let errorText = '';
  if (emailsOverLicenseCap.length) {
    errorText = 'The entered emails exceed the available memberships in your plan. '
      + `Only the first ${unclaimedLicenses} emails will be submitted.`;
  }

  return (
    <>
      <EmailsInputHeader
        inputType={typeEmails.value}
        setInputType={setInputType}
      />
      <EmailsInput
        label="Emails"
        emails={emails}
        setEmails={setEmails}
        invalidEmails={invalidEmails}
        setInvalidEmails={setInvalidEmails}
        contextualError={errorText}
      />
    </>
  );
}

export default TypeEmails;
