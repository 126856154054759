import { AxisTickProps } from '@nivo/axes';
import { PointSymbolProps, ResponsiveLine } from '@nivo/line';
import { StrainRecoveryTick } from 'dataVisualizations/axis/axis';
import { PillarPoint } from 'dataVisualizations/points/points';
import { DefaultStrainRecoveryTooltip } from 'dataVisualizations/tooltips/tooltips';
import { DayMonthXAxisTick } from 'progress/progressPageUtils';
import { StrainRecoveryPoints } from 'types/analytics';
import {
  getTransformedSeries, LineColor,
} from './strainRecoveryTrendGraphUtils';

type StrainRecoveryTrendGraphProps = {
  data: StrainRecoveryPoints[];
  daysInRange: number;
  daysToHighlight: string[];
};

function StrainRecoveryTrendGraph({
  data, daysInRange, daysToHighlight,
}: StrainRecoveryTrendGraphProps) {
  const margins = {
    top: 20, right: 100, bottom: 50, left: 100,
  };
  const strainGridValues = ['0.0', '3.5', '7.0', '10.5', '14.0', ' 17.5', ' 21.0'];
  return (
    <ResponsiveLine
      data={getTransformedSeries(data)}
      margin={margins}
      pointLabelYOffset={-12}
      gridYValues={strainGridValues}
      yScale={
        {
          type: 'linear',
          min: 0,
          max: 21.0,
        }
      }
      axisRight={{
        tickSize: 0,
        tickValues: strainGridValues,
        renderTick: (tick: AxisTickProps<string>) => StrainRecoveryTick(
          tick.x,
          tick.y,
          tick.value,
          false,
        ),
      }}
      axisLeft={{
        tickSize: 0,
        tickValues: strainGridValues,
        renderTick: (tick: AxisTickProps<string>) => StrainRecoveryTick(
          tick.x,
          tick.y,
          tick.value,
          true,
        ),
      }}
      axisBottom={{
        tickSize: 0,
        renderTick: (tick: AxisTickProps<string>) => {
          if (daysInRange < 15) {
            return DayMonthXAxisTick(tick, true);
          }
          if (tick.tickIndex % 7 === 0) {
            return DayMonthXAxisTick(tick, true);
          }
          return <g />;
        },
      }}
      enableGridX={false}
      isInteractive
      useMesh
      lineWidth={1}
      enableSlices="x"
      sliceTooltip={DefaultStrainRecoveryTooltip}
      pointSymbol={
        (pointSymbol: PointSymbolProps) => PillarPoint(
          pointSymbol,
          daysToHighlight,
          daysInRange < 30,
        )
      }
      colors={LineColor}
      theme={{
        grid: {
          line: {
            strokeWidth: 0.5,
          },
        },
        axis: {
          legend: {
            text: {
              fontSize: '13px',
              fontWeight: 700,
              fontFamily: 'DINPro',
              lineHeight: '14px',
            },
          },
        },
      }}
    />
  );
}

export default StrainRecoveryTrendGraph;
