import { Button, Modal, classes } from '@whoop/web-components';
import { BODY_SHORT_1 } from 'components/text';
import { useState } from 'react';
import styles from '../preRegistration.module.scss';

type SignInStepProps = {
  image: string;
  email: string;
  nextStep: () => void;
  backStep: () => void;
};

function SignInStep({
  image, email, nextStep, backStep,
}: SignInStepProps) {
  const isProdEnv = window._env.ENV === 'prod';
  const webAppLink = isProdEnv ? 'app.whoop.com/settings/profile' : 'app.qa.whoop.com/settings/profile';
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.preRegistrationContainer}>
      <div className={styles.instructions}>
        <p className={styles.stepText}>STEP 1/2</p>
        <h2>Sign in to your WHOOP account.</h2>
        <div className={styles.linkSection}>
          {'Open '}
          <a className={styles.link} href={`https://${webAppLink}`} target="_blank" rel="noreferrer">{webAppLink}</a>
          {' in a separate browser tab.'}
        </div>
        <ul className={styles.list}>
          <li>
            <span className={styles.bold}>Sign into the WHOOP account</span>
            {` associated with the email ${email}.`}
          </li>
          <li>
            After you log in, continue to the next step on this page.
          </li>
        </ul>
        <div className={styles.flowButtons}>
          <Button
            label="Back"
            onClick={backStep}
            theme="dark"
            variant="normal"
          />
          <Button
            label="Continue"
            onClick={() => setShowModal(true)}
            theme="enterprise"
            variant="primary"
          />
        </div>
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)} data-testid="modal">
        <div className={styles.signInModalWrapper}>
          <BODY_SHORT_1 className={styles.bold}>
            Did you click the highlighted link and open app.whoop.com?
          </BODY_SHORT_1>
          <BODY_SHORT_1>
            To complete this process, please make sure you followed the
            instruction on this page before you continue.
          </BODY_SHORT_1>
          <div className={classes(styles.flowButtons, styles.modalFlowButtons)}>
            <Button
              label="Let Me Try Again"
              onClick={() => setShowModal(false)}
              variant="normal"
            />
            <Button
              label="Yes"
              onClick={nextStep}
              theme="enterprise"
              variant="primary"
            />
          </div>
        </div>
      </Modal>
      <img className={styles.image} src={image} alt="sign in step" />
    </div>
  );
}

export default SignInStep;
