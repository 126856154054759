import { useState } from 'react';
import LandingPageWrapper from 'landingPageWrapper/landingPageWrapper';
import ForgotForm from './components/forgotForm';
import CheckEmail from './components/checkEmail';
import { PageState } from './forgotTypes';

function ForgotPassword() {
  const [pageState, setPageState] = useState<PageState>(PageState.Form);
  const [email, setEmail] = useState<string>('');

  return (
    <LandingPageWrapper>
      {pageState === PageState.Form && (
        <ForgotForm setPageState={setPageState} setEmail={setEmail} />
      )}
      {pageState === PageState.CheckEmail && <CheckEmail email={email} />}
    </LandingPageWrapper>
  );
}

export default ForgotPassword;
