import { useState, useEffect } from 'react';
import PulsingLogo from 'components/spinner';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '@whoop/web-components';
import LandingPageWrapper from 'landingPageWrapper/landingPageWrapper';
import { RequestAccess as RequestAccessType } from 'types/requestAccess';
import FullPageMessage from 'components/fullPageMessage';
import { requestAccess } from '../api/requestAccessApi';
import '@whoop/web-components/dist/index.css';
import styles from './requestAccess.module.scss';

function RequestAccess() {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
    setError,
  } = useForm({
    mode: 'onChange',
  });
  const watchEmail = watch('request_email', '');

  useEffect(() => {
    const emailPattern = /\S+@\S+\.\S+/;
    const timeOutId = setTimeout(() => {
      if (!watchEmail.match(emailPattern)) {
        setError('request_email', {
          type: 'pattern',
          message: 'Please enter a valid email',
        });
      }
    }, 400);
    return () => clearTimeout(timeOutId);
  }, [watchEmail, setError]);

  const onSubmit = async (values: RequestAccessType) => {
    setLoading(true);
    try {
      await requestAccess(values);
      setSubmitted(true);
    } catch (error) {
      alert('ERROR');
      setLoading(false);
    }
  };

  const emailValidationError = errors?.request_email?.type === 'pattern' && watchEmail;
  const emailInputClass = `${styles.inputField} ${
    emailValidationError && styles.inputFieldInvalid
  }`;

  if (submitted) {
    const returnToSignInButton = (
      <Button
        label="Return to sign in"
        aria-label="return"
        data-testid="return-to-sign-in-btn"
        theme="dark"
        variant="secondary"
        className={styles.signInButton}
        onClick={() => {
          navigate('/login');
        }}
      />
    );
    return (
      <LandingPageWrapper>
        <div className={`${styles.formContainer} ${styles.submitted}`}>
          <FullPageMessage
            header="Your request has been successfully submitted."
            subtext="Your administrator has been notified and will be in contact with you about your access request."
            state="success"
            button={returnToSignInButton}
          />
        </div>
      </LandingPageWrapper>
    );
  }

  return (
    <LandingPageWrapper>
      {loading ? <PulsingLogo overlay /> : <div />}

      <div className={styles.infoText}>
        <h1>Request access</h1>
        <p>
          Your administrator will receive an email letting them know that you
          are requesting access to WHOOP Unite.
          {' '}
        </p>
      </div>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <label id="organizationnamelabel" htmlFor="organization-input" className={styles.inputFieldLabel}>
          Company or team name
          <input
            data-testid="organization-input"
            aria-labelledby="organizationnamelabel"
            className={styles.inputField}
            {...register('organization', { required: true })}
          />
        </label>
        <label id="yournamelabel" htmlFor="name-input" className={styles.inputFieldLabel}>
          Your Name
          <input
            data-testid="name-input"
            aria-labelledby="yournamelabel"
            className={styles.inputField}
            {...register('name', { required: true })}
          />
        </label>
        <label id="emailLabel" htmlFor="email-input" className={styles.inputFieldLabel}>
          Email Address
          <input
            data-testid="email-input"
            aria-labelledby="emailLabel"
            className={emailInputClass}
            {...register('request_email', { required: true })}
          />
        </label>
        <div className={styles.invalidMessage} data-testid="invalid-email">
          {emailValidationError && errors.request_email.message}
        </div>
        <Button
          label="request access"
          aria-label="Request Access"
          variant="primary"
          theme="enterprise"
          type="submit"
          data-testid="request-access-btn"
          disabled={!isValid}
        />
      </form>
      <div className={styles.bottom}>
        <Button
          label="Return to sign in"
          variant="link"
          className={styles.returnToSignIn}
          onClick={() => {
            navigate('/login');
          }}
          data-testid="return-to-sign-in-link"
        />
      </div>
    </LandingPageWrapper>
  );
}

export default RequestAccess;
