import { BarTooltipProps, ComputedDatum, ResponsiveBar } from '@nivo/bar';
import { CustomPillarBreakdownBar } from 'dataVisualizations/bars/bars';
import { PillarBreakdownBarToolTip } from 'dataVisualizations/tooltips/tooltips';
import {
  PillarBreakdownBarData, PillarBreakdownDataWithMetric,
} from './pillarBreakdownBarConsts';

type BarChartProps = {
  data: PillarBreakdownBarData[];
  colors: string[];
  keys: string[];
  barClickHandler?: (datum: ComputedDatum<PillarBreakdownDataWithMetric>,
    event: React.MouseEvent<Element>) => void;
};

const margins = {
  top: 0, right: 0, bottom: 0, left: 0,
};

function PillarBreakdownBar({
  data, colors, keys, barClickHandler,
}: BarChartProps) {
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      innerPadding={1}
      borderRadius={1}
      margin={margins}
      layout="horizontal"
      enableLabel={false}
      enableGridX={false}
      enableGridY={false}
      axisBottom={null}
      axisLeft={null}
      colors={colors}
      tooltip={(node: BarTooltipProps<PillarBreakdownDataWithMetric>) => (
        PillarBreakdownBarToolTip(node)
      )}
      onClick={barClickHandler}
      barComponent={(bar) => CustomPillarBreakdownBar(bar, !!barClickHandler)}
    />
  );
}

export default PillarBreakdownBar;
