import { WhoopButtonProps } from 'types/collaborator';
import StickyFooter from 'components/stickyFooter';
import { Button } from '@whoop/web-components';
import styles from './progressActionFooter.module.scss';

/* eslint-disable react/jsx-props-no-spreading */

type ProgressActionFooterTypes<S> = {
  state?: S;
  getPrimaryButtonProps: (state: S) => WhoopButtonProps;
  getSecondaryButtonProps: (state: S) => WhoopButtonProps;
  dispatch?: (action: { type: 'next' } | { type: 'back' }) => void;
  hideSecondaryButton?: boolean;
  progressDots: {
    current: number;
    range: number;
  };
  trackNextEvent?: () => void;
};

function ProgressActionFooter<S extends unknown>({
  state,
  getPrimaryButtonProps,
  getSecondaryButtonProps,
  dispatch,
  hideSecondaryButton,
  progressDots,
  trackNextEvent,
}: ProgressActionFooterTypes<S>) {
  const { range, current } = progressDots;

  const handleNextButtonClick = () => {
    if (trackNextEvent) {
      trackNextEvent();
    }
    dispatch({ type: 'next' });
  };

  return (
    <StickyFooter>
      <div className={styles.actionFooter}>
        <div>
          {!hideSecondaryButton
            && (
              <Button
                className={styles.secondaryButton}
                onClick={() => dispatch({ type: 'back' })}
                {...getSecondaryButtonProps(state)}
              />
            )}
        </div>
        <div className={styles.progressDots}>
          {Array.from({ length: range }, (_, index) => index).map((ind, i) => (<div key={`dot-${ind}`} className={`${i === current ? styles.blackDot : styles.grayDot}`} />))}
        </div>
        <Button
          className={styles.primaryButton}
          onClick={handleNextButtonClick}
          {...getPrimaryButtonProps(state)}
        />
      </div>
    </StickyFooter>
  );
}

export default ProgressActionFooter;
