import dayjs from 'dayjs';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';
import {
  MetricType, RecoveryMetricType, SleepMetricType, StrainMetricType,
} from 'types/analytics';

dayjs.extend(duration);

export const convertStrainToString = (num: number) => {
  if (num === null) {
    return null;
  }
  return num.toFixed(1);
};

export const convertToNumberLocale = (num: number) => {
  if (num) {
    return Math.round(num).toLocaleString('en-US');
  }
  return null;
};

export const convertHRVtoString = (num: number) => Math.round(num * 1000).toString();

export const roundToOneDecimal = (val: number): number => Math.round(val * 10) / 10;

export const convertSecondToMillisecond = (val: number): number => Math.round(val * 1000);

export const formatNumberToPercent = (val: number): string => (val ? `${Math.round(val)}%` : '');

export const getSleepDuration = (milliseconds: number, unit: DurationUnitType = 'minute'): string => (milliseconds ? dayjs.duration(Math.round(dayjs.duration(milliseconds).as(unit)), unit).format('H:mm') : '');

export const METRIC_LABEL_FORMATTER: { [key in MetricType]: (val: number) => string } = {
  [StrainMetricType.STRAIN]: convertStrainToString,
  [StrainMetricType.AVG_HR]: null,
  [StrainMetricType.CALORIES]: convertToNumberLocale,
  [RecoveryMetricType.SCORE]: null,
  [RecoveryMetricType.HRV]: null,
  [RecoveryMetricType.RHR]: null,
  [RecoveryMetricType.RESPIRATORY_RATE]: null,
  [SleepMetricType.PERFORMANCE]: formatNumberToPercent,
  [SleepMetricType.LATENCY]: getSleepDuration,
  [SleepMetricType.DISTURBANCES]: null,
  [SleepMetricType.DURATION]: getSleepDuration,
  [SleepMetricType.EFFICIENCY]: null,
  [SleepMetricType.CONSISTENCY]: null,
};

export const METRIC_NUMBER_FORMATTER: { [key in MetricType]: (val: number) => number } = {
  [StrainMetricType.STRAIN]: roundToOneDecimal,
  [StrainMetricType.AVG_HR]: Math.round,
  [StrainMetricType.CALORIES]: Math.round,
  [RecoveryMetricType.SCORE]: Math.round,
  [RecoveryMetricType.HRV]: convertSecondToMillisecond,
  [RecoveryMetricType.RHR]: Math.round,
  [RecoveryMetricType.RESPIRATORY_RATE]: roundToOneDecimal,
  [SleepMetricType.PERFORMANCE]: Math.round,
  [SleepMetricType.LATENCY]: Math.round,
  [SleepMetricType.DISTURBANCES]: Math.round,
  [SleepMetricType.DURATION]: Math.round,
  [SleepMetricType.EFFICIENCY]: Math.round,
  [SleepMetricType.CONSISTENCY]: Math.round,
};
