import styles from './stepSubheader.module.scss';

type StepSubheaderProps = {
  children: string;
  stepNumber: number;
  totalSteps: number;
};

function StepSubheader({ children, stepNumber, totalSteps }: StepSubheaderProps) {
  return (
    <p className={styles.pageHeading}>
      <span className={styles.pageNumber}>{stepNumber}</span>
      <span className={styles.totalPages}>
        /
        {totalSteps}
      </span>
      {children}
    </p>
  );
}

export default StepSubheader;
