import React, { useReducer } from 'react';
import { TITLE_3, BODY_SHORT_1 } from 'components/text';
import { Alert, Button } from '@whoop/web-components';
import { PAGE_STATE } from 'constants/pageState';
import FullPageModal from 'components/fullPageModal';
import { UseStatePropType } from 'types/useStatePropType';
import { Cohort } from 'types/cohort';
import { Row } from 'react-table';
import { Member } from 'types/member';
import LoadingHandler from 'components/loadingHandler';
import * as Sentry from '@sentry/react';
import { createToastMessage, addMembersToGroup, ToastType } from 'groups/utils';
import {
  addMembersToGroupReducer,
  AddMembersToGroupReducerState,
  AddMembersToGroupSteps,
} from './utils';
import GroupList from './components/groupList/groupList';

type AddMembersToGroupProps = {
  selectedRows: Row<Member>[];
  setSelectedRows: UseStatePropType<Row<Member>[]>;
  setPageState: UseStatePropType<string>;
  triggerToast: (message: string) => void;
};

const initialState: AddMembersToGroupReducerState = {
  selectedGroup: null,
  step: AddMembersToGroupSteps.Selection,
  error: null,
};

function AddMembersToGroup({
  selectedRows,
  setSelectedRows,
  setPageState,
  triggerToast,
}: AddMembersToGroupProps) {
  const [state, dispatch] = useReducer(addMembersToGroupReducer, initialState);
  const { selectedGroup, step } = state;

  const handleAddMembersToGroup = async () => {
    dispatch({ type: 'next' });
  };

  const handleSelectGroup = async (group: Cohort) => {
    dispatch({ type: 'selectGroup', selectedGroup: group });
  };

  const addMembersToGroupHandler = async () => {
    try {
      const memberIds = selectedRows.map((row) => row.original.id);
      const response = await addMembersToGroup(selectedGroup.id, memberIds);
      triggerToast(
        createToastMessage(
          response.fulfilled,
          response.failed,
          selectedGroup.name,
          ToastType.ADD_MEMBER,
        ),
      );
      setSelectedRows([]);
      setPageState(PAGE_STATE.DASHBOARD);
    } catch (e) {
      Sentry.captureException(e);
      dispatch({ type: 'error', payload: e });
    }
  };

  const actionButton = (
    <Button
      label={`Add ${selectedRows.length} member${selectedRows.length > 1 ? 's' : ''
      } to group`}
      onClick={handleAddMembersToGroup}
      disabled={!selectedGroup}
      variant="primary"
    />
  );

  return (
    <FullPageModal
      setSelectedRows={setSelectedRows}
      setPageState={setPageState}
      ActionButton={actionButton}
    >
      {step === AddMembersToGroupSteps.Error && (
        <Alert theme="default" type="error">
          Error adding members to group
        </Alert>
      )}
      {step === AddMembersToGroupSteps.Loading ? (
        <LoadingHandler
          handler={addMembersToGroupHandler}
          title="Adding Members to group"
        />
      ) : (
        <>
          <TITLE_3>Add members to a group</TITLE_3>
          <BODY_SHORT_1>Select a group to add these members to</BODY_SHORT_1>
          <GroupList
            selectedGroup={selectedGroup}
            setSelectedGroup={handleSelectGroup}
          />
        </>
      )}
    </FullPageModal>
  );
}

export default AddMembersToGroup;
