import {
  hubs, hubsInfo, hubUserProfile, hubUsers, getCurrentDate, appsInfo,
} from 'mocks/store/data';
import { HubStatus } from 'types/hub';

export const getHubs = () => hubs;

export const getHubsInfo = () => hubsInfo;

export const getHubsUsers = () => hubUsers;

export const getHubMemberProfile = () => hubUserProfile;

export const getApps = () => appsInfo;

export const deployApp = () => {};

export const addHubMember = () => {};

const updateHubFields = (hub, update) => {
  const hubCopy = hub;
  if (hubCopy.mac_address === update.mac_address) {
    hubCopy.name = update.name;
    hubCopy.description = update.description;
  }
  return hubCopy;
};

export const updateHub = (update) => {
  hubs.map((hub) => updateHubFields(hub, update));
  hubsInfo.map((hub) => updateHubFields(hub, update));
};

export const archiveHubMember = (seatId) => {
  // This is used instead of the filter function because the action needs
  // to be in place instead of resetting the value
  hubUsers.splice(0, hubUsers.length, ...hubUsers.filter(
    (user) => Number(user.seat_id) !== Number(seatId),
  ));
};

export const createHub = (hub) => {
  const newHub = {
    last_seen_at: getCurrentDate(),
    created_at: getCurrentDate(),
    updated_at: getCurrentDate(),
    mac_address: hub.mac_address,
    salesforce_account_id: hub.salesforce_account_id,
    contract_number: null,
    name: hub.name,
    description: hub.description,
    status: HubStatus.PENDING,
  };

  const newHubInfo = {
    name: hub.name,
    description: hub.description,
    mac_address: hub.mac_address,
    last_seen_at: getCurrentDate(),
    member_count: 0,
    status: HubStatus.PENDING,
  };

  hubs.push(newHub);
  hubsInfo.push(newHubInfo);
};

export const setHubMemberProfile = (profileRequest) => {
  Object.entries(profileRequest).forEach(([key, value]) => {
    hubUserProfile[key] = value;
  });
};
