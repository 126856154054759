import { TABLE_COLUMN_HEADER } from 'constants/accountTable';
import RowSelection from 'components/rowSelection';
import StatusBadge from 'components/statusBadge';
import { DashboardRole } from 'types/dashboardUser';
import styles from './table.module.scss';
import { nameSubtitleCell, normalCell } from './tableCells';

const getHeaderSelection = ({
  toggleAllRowsSelected,
  getToggleAllPageRowsSelectedProps,
  getToggleAllRowsSelectedProps,
  pageCount,
  toggleRowSelected,
  rows,
}, disabled) => {
  const rowSelectionProps = pageCount > 1
    ? getToggleAllPageRowsSelectedProps()
    : getToggleAllRowsSelectedProps();

  const { onChange, ...rest } = rowSelectionProps;

  const overrideOnChange = () => {
    if (rowSelectionProps.indeterminate || rowSelectionProps.checked) {
      return toggleAllRowsSelected(false);
    }
    return rows.forEach((row) => {
      if ((disabled && row.original.status === 'active' && !row.original.strap_serial) || row.original.role === DashboardRole.WHOOP_CSM) {
        toggleRowSelected(row.id, false);
      } else {
        toggleRowSelected(row.id, true);
      }
    });
  };

  const newProps = { onChange: overrideOnChange };
  return (
    <RowSelection {...{ ...rest, ...newProps }} />
  );
};

const getCellSelection = (row, disabled, tooltipTitle) => {
  const { getToggleRowSelectedProps } = row;
  return (
    <RowSelection
      disabled={disabled}
      tooltipTitle={tooltipTitle}
      {...getToggleRowSelectedProps()}
    />
  );
};

export const SELECTION_COLUMN = {
  id: 'selection',
  width: '5%',
  align: 'left',
  Header: (cellProps) => getHeaderSelection(cellProps, false),
  Cell: ({ row }) => getCellSelection(row, false, undefined),
  disableSortBy: true,
};

export const STRAPS_TABLE_SELECTION_COLUMN = () => ({
  id: 'selection',
  width: '5%',
  align: 'left',
  Header: (cellProps) => getHeaderSelection(cellProps),
  Cell: ({ row }) => getCellSelection(row),
});

export const COLLABORATORS_TABLE_SELECTION_COLUMN = () => ({
  id: 'selection',
  width: '5%',
  align: 'left',
  Header: (cellProps) => getHeaderSelection(cellProps),
  Cell: ({ row }) => getCellSelection(row, row.original.role === DashboardRole.WHOOP_CSM),
});

export const collaboratorNameColumn = {
  id: 'collaborator_name',
  Header: 'Name',
  accessor: (collaborator) => `${collaborator.name} ${collaborator.email}`,
  width: '25%',
  align: 'left',
  Cell: (cellProps) => {
    const { row } = cellProps;
    return nameSubtitleCell(
      cellProps,
      row.original.name ?? row.original.email,
      !cellProps.cell.row.original.name,
    );
  },
};

export const MEMBERS_COLUMN = {
  Header: TABLE_COLUMN_HEADER.MEMBERS,
  accessor: (row) => (row.user_id
    ? `${row.first_name || '--'} ${row.last_name || '--'}`
    : null),
  width: '25%',
  align: 'left',
  Cell: (cellProps) => nameSubtitleCell(cellProps, null),
};

export const HUBS_COLUMN = {
  Header: TABLE_COLUMN_HEADER.HUBS,
  accessor: (row) => row.name,
  width: '25%',
  align: 'left',
  Cell: (cellProps) => nameSubtitleCell(cellProps, null, null, cellProps.row.original.mac_address),
};

export const STRAP_ID_COLUMN = {
  Header: TABLE_COLUMN_HEADER.STRAP_ID,
  accessor: 'strap_serial',
  width: '20%',
  colorCell: styles.grayCell,
  sortType: 'alphanumeric',
  Cell: normalCell,
};

export const USER_ID_COLUMN = {
  id: 'userId',
  Header: 'User ID',
  accessor: 'user_id',
  width: '15%',
  align: 'left',
  Cell: normalCell,
};

export const addUserIdColumn = (visible, columnList, start) => {
  if (visible) {
    return columnList.splice(start, 0, USER_ID_COLUMN);
  }
  return columnList;
};

export const STATUS_COLUMN = {
  Header: TABLE_COLUMN_HEADER.STATUS,
  accessor: 'status',
  width: '13%',
  disableGlobalFilter: true,
  Cell: (cellProps) => {
    const { row: { original: { status } } } = cellProps;
    return (
      <StatusBadge status={status} />
    );
  },
};
