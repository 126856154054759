import React, { ReactElement } from 'react';
import FullPageModal from 'components/fullPageModal';
import { BODY_SHORT_1, HEADING_4, TITLE_3 } from 'components/text';
import { Row } from 'react-table';
import SimpleTable from 'components/simpleTable/simpleTable';
import { SeatDetail } from 'types/seat';
import { HubListPayload } from 'types/hub';
import { Collaborator } from 'types/collaborator';

type ActionModalProps = {
  selectedRows: any[];
  setSelectedRows:(selectedRows: Row<any>[]) => void;
  setPageState: (pageState: string | boolean) => void;
  ActionButton: ReactElement;
  title: string;
  body: string;
  heading: string;
  columnList: any[];
  rowAccessor: (row: SeatDetail | HubListPayload | Collaborator) => number | string;
};

function ActionModal({
  selectedRows,
  setSelectedRows,
  setPageState,
  ActionButton,
  title,
  body,
  heading,
  columnList,
  rowAccessor,
}: ActionModalProps) {
  return (
    <FullPageModal
      setSelectedRows={setSelectedRows}
      setPageState={setPageState}
      ActionButton={ActionButton}
    >
      <TITLE_3>{title}</TITLE_3>
      <BODY_SHORT_1>
        {body}
      </BODY_SHORT_1>
      <HEADING_4>
        {heading}
      </HEADING_4>
      <SimpleTable
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        columnList={columnList}
        rowAccessor={rowAccessor}
      />
    </FullPageModal>
  );
}

export default ActionModal;
