import React, { useEffect } from 'react';
import Routes from 'components/routes';
import Loading from 'loading';
import { Route, useNavigate, useParams } from 'react-router-dom';
import { AccountParams, useAccounts } from 'context/account-context';
import MemberProfilePage from 'hub/memberProfilePage/memberProfilePage';
import SetupHubPage from 'hub/setup/setupHubPage';
import HubDetailsPage from 'hub/hubDetailsPage/hubDetailsPage';
import HubsListPage from 'hub/hubsListPage/hubsListPage';

function HubRoutes() {
  const { loading, accountConfig } = useAccounts();
  const { accountId } = useParams<AccountParams>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accountConfig.hub_enabled) {
      navigate(`/accounts/${accountId}/plan/summary`);
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route index element={<HubsListPage />} />
      <Route path="member/add" element={<MemberProfilePage activateSensor addMember />} />
      <Route path=":seatId/activate" element={<MemberProfilePage activateSensor />} />
      <Route path=":seatId/viewProfile" element={<MemberProfilePage activateSensor={false} />} />
      <Route path="setup" element={<SetupHubPage />} />
      <Route path=":macAddress" element={<HubDetailsPage />} />
    </Routes>
  );
}

export default HubRoutes;
