import styles from './stickyFooter.module.scss';

type StickyFooterProps = {
  children : React.ReactNode;
};

function StickyFooter(props: StickyFooterProps) {
  const { children } = props;

  return (
    <div className={styles.stickyFooter}>
      <div className={children ? styles.footerContent : ''}>{children}</div>
    </div>
  );
}

export default StickyFooter;
