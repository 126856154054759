import { useEffect, useState } from 'react';
import { PieCustomLayerProps, ResponsivePie } from '@nivo/pie';
import { Box } from '@nivo/core';
import { Pillars } from 'types/analytics';
import { Colors } from '@whoop/web-components';
import { CenteredMetric, getPieData, PieData } from './pillarPieChartUtils';

type PillarPieChartProps = {
  pieValue: number;
  pillarType: Pillars;
  color: string;
  margin?: Box;
};

function PillarPieChart({
  pieValue, pillarType, color, margin,
}: PillarPieChartProps) {
  const [pieData, setPieData] = useState<PieData[]>();

  useEffect(() => {
    setPieData(getPieData(pieValue, pillarType));
  }, [pieValue]);

  const getInnerLayer = ({ dataWithArc, centerX, centerY }: PieCustomLayerProps<PieData>) => (
    CenteredMetric(
      pillarType === Pillars.STRAIN,
      dataWithArc[0].value,
      centerX,
      centerY,
    )
  );

  return (
    <ResponsivePie
      data={pieData}
      margin={margin}
      innerRadius={0.88}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      isInteractive={false}
      colors={[color, Colors.gray300]}
      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', getInnerLayer]}
    />
  );
}

export default PillarPieChart;
