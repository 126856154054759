import { client } from 'api/apiClient';
import {
  Cohort,
  CohortCollaborator,
  CreateCohortParams,
  GroupMember,
  GroupMembersResult, GroupUser, GroupUserAccess,
} from 'types/cohort';

export const createGroup = async (
  group: CreateCohortParams,
) => {
  const response = await client.post<Cohort>(
    '/enterprise-service/v1/cohorts',
    group,
  );
  return response.data;
};

export const addMemberToGroup = async (
  cohortId: number,
  seatId: number,
) => {
  const response = await client.post<GroupUser>(
    `/enterprise-service/v1/cohorts/member?cohortId=${cohortId}&seatId=${seatId}`,
    { headers: { 'Content-type': 'application/json' } },
  );
  return response.data;
};

export const getCohortsBySalesforceAccountId = async (
  includeOwnerDetails: boolean,
) => {
  const params = { includeOwnerDetails };
  const response = await client.get<Cohort[]>(
    '/enterprise-service/v1/cohorts',
    {
      params,
    },
  );
  return response.data;
};

/**
 * @deprecated use {@link getGroupMembers} instead.
 * This endpoint uses a paginated model.
 */
export const getGroupMembersByGroupId = async (groupId: number) => {
  const response = await client.get<GroupMembersResult>(
    `/enterprise-service/v1/cohorts/members?cohortId=${groupId}`,
  );

  return response.data;
};

export const getGroupMembers = async (groupId: number) => {
  const params = { cohortId: groupId };
  const response = await client.get<GroupMember[]>('/enterprise-service/v2/cohorts/members', { params });
  return response.data;
};

export const removeMemberFromGroup = async (
  groupId: number,
  userId: number,
) => {
  await client.delete<void>(
    `/enterprise-service/v1/cohorts/member?cohortId=${groupId}&seatUserId=${userId}`,
  );
};

export const getGroupById = async (groupId: number) => {
  const response = await client.get<Cohort>(
    `/enterprise-service/v1/cohorts/details?cohortId=${groupId}`,
  );

  return response.data;
};

export const updateGroup = async (
  groupId: number,
  updatedGroup: Cohort,
) => {
  const response = await client.put<Cohort>(
    `/enterprise-service/v1/cohorts?cohortId=${groupId}`,
    updatedGroup,
  );
  return response.data;
};

export const getAvailableCohortMembers = async (
  groupId: number,
) => {
  const response = await client.get<GroupMember[]>(
    `/enterprise-service/v1/cohorts/available?cohortId=${groupId}`,
  );

  return response.data;
};

export const getAvailableCollaborators = async (
  groupId: number,
) => {
  // BE is sending this model type. Tech debt item to replace this
  // type with Collaborator. ENT-2074
  const response = await client.get<CohortCollaborator[]>(
    `/enterprise-service/v1/cohorts/collaborators/available?cohortId=${groupId}`,
  );

  return response.data;
};

export const addGroupAccess = async (groupUser: GroupUser) => {
  const response = await client.post<GroupUserAccess>(
    `/enterprise-service/v1/cohorts/access?cohortId=${groupUser.cohort_id}`,
    groupUser,
  );

  return response.data;
};

export const getCohortAccessForSpecificCollaborator = async (
  collaboratorId: number,
) => {
  const params = {
    collaboratorId,
  };
  const response = await client.get<GroupUserAccess[]>('/enterprise-service/v1/cohorts/access/user', { params });

  return response.data;
};

export const removeCohortAccess = async (
  userId: number,
  cohortId: number,
) => {
  const cohortEgg = {
    user_id: userId,
    cohort_id: cohortId,
  };
  await client.delete<void>(`/enterprise-service/v1/cohorts/access?cohortId=${cohortId}`, { data: cohortEgg });
};
export const getGroupsByUserAccess = async (
  includePrimaryGroup: boolean = false,
) => {
  const params = { includePrimaryCohort: includePrimaryGroup };
  const response = await client.get<Cohort[]>(
    '/enterprise-service/v1/cohorts/access',
    { params },
  );

  return response.data;
};

export const deleteGroup = async (
  groupId: string,
) => {
  await client.delete(
    `/enterprise-service/v1/cohorts?cohortId=${groupId}`,
  );
};
