import { formatDate } from 'progress/profile/profileUtils';
import { client } from './apiClient';

export type BreakdownMetricType = 'dayStrain'
| 'calories'
| 'workoutCount'
| 'sleepDuration'
| 'sleepPerformance'
| 'sleepConsistency'
| 'sleepEfficiency'
| 'recoveryScore'
| 'hrv'
| 'rhr';

export interface UserBreakdownMetric<T extends BreakdownMetricType> {
  user_id: number;
  metric: T;
  data_value?: number;
}

export interface BreakdownDTO<T extends BreakdownMetricType> {
  average?: number;
  user_data: UserBreakdownMetric<T>[];
}

function createParams(groupId: number, start: Date, end: Date) {
  return {
    cohortId: groupId,
    startDate: formatDate(start),
    endDate: formatDate(end),
  };
}

export async function getStrainBreakdown(groupId: number, start: Date, end: Date) {
  const response = await client.get<BreakdownDTO<'dayStrain'>>('/enterprise-service/v3/breakdown/strain', { params: createParams(groupId, start, end) });
  return response.data;
}

export async function getCaloriesBreakdown(groupId: number, start: Date, end: Date) {
  const response = await client.get<BreakdownDTO<'calories'>>('/enterprise-service/v3/breakdown/calories', { params: createParams(groupId, start, end) });
  return response.data;
}

export async function getWorkoutCountBreakdown(groupId: number, start: Date, end: Date) {
  const response = await client.get<BreakdownDTO<'workoutCount'>>('/enterprise-service/v3/breakdown/workout-count', { params: createParams(groupId, start, end) });
  return response.data;
}

export async function getRecoveryScoreBreakdown(groupId: number, start: Date, end: Date) {
  const response = await client.get<BreakdownDTO<'recoveryScore'>>('/enterprise-service/v3/breakdown/recovery-score', { params: createParams(groupId, start, end) });
  return response.data;
}

export async function getRhrBreakdown(groupId: number, start: Date, end: Date) {
  const response = await client.get<BreakdownDTO<'rhr'>>('/enterprise-service/v3/breakdown/rhr', { params: createParams(groupId, start, end) });
  return response.data;
}

export async function getHrvBreakdown(groupId: number, start: Date, end: Date) {
  const response = await client.get<BreakdownDTO<'hrv'>>('/enterprise-service/v3/breakdown/hrv', { params: createParams(groupId, start, end) });
  return response.data;
}

export async function getSleepConsistencyBreakdown(groupId: number, start: Date, end: Date) {
  const response = await client.get<BreakdownDTO<'sleepConsistency'>>('/enterprise-service/v3/breakdown/sleep-consistency', { params: createParams(groupId, start, end) });
  return response.data;
}

export async function getSleepEfficiencyBreakdown(groupId: number, start: Date, end: Date) {
  const response = await client.get<BreakdownDTO<'sleepEfficiency'>>('/enterprise-service/v3/breakdown/sleep-efficiency', { params: createParams(groupId, start, end) });
  return response.data;
}

export async function getSleepPerformanceBreakdown(groupId: number, start: Date, end: Date) {
  const response = await client.get<BreakdownDTO<'sleepPerformance'>>('/enterprise-service/v3/breakdown/sleep-performance', { params: createParams(groupId, start, end) });
  return response.data;
}

export async function getSleepDurationBreakdown(groupId: number, start: Date, end: Date) {
  const response = await client.get<BreakdownDTO<'sleepDuration'>>('/enterprise-service/v3/breakdown/sleep-duration', { params: createParams(groupId, start, end) });
  return response.data;
}
