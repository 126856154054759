import PulsingLogo from 'components/spinner';
import { TITLE_3, BODY_SHORT_1 } from 'components/text';
import React, { useEffect, useReducer } from 'react';
import { HubUserProfile, ParsedHubUserProfile } from 'types/hub';
import MoreIcon from 'assets/icons/more-icon.svg';
import ActionFooter from 'components/actionFooter/actionFooter';
import MemberProfileComp from 'hub/memberProfilePage/memberProfileComp/memberProfileComp';
import { UseStatePropType } from 'types/useStatePropType';
import { editHubUserAccountDetails } from 'api/hubApi';
import { ActivateSensorReducerAction } from 'wizards/hub/activateSensorWizard/utils';
import styles from '../memberProfile.module.scss';
import {
  editMemberProfileReducer,
  EditMemberProfileSteps,
  getProfileFromState,
  HubUserProfileReducerState,
} from './utils';

type EditMemberProfileProps = {
  profile: ParsedHubUserProfile;
  setEditProfile: UseStatePropType<boolean>;
  setProfileData: UseStatePropType<HubUserProfileReducerState>;
  seatId: string;
  inWizard?: boolean;
  addMember?: boolean;
  customDispatch?: (a: ActivateSensorReducerAction) => void;
};

function EditMemberProfile({
  profile, setEditProfile, setProfileData, seatId, inWizard = false,
  addMember = false, customDispatch,
} : EditMemberProfileProps) {
  const initialFormState: HubUserProfileReducerState = {
    ...profile,
    error: null,
    step: EditMemberProfileSteps.Input,
    hubs: [],
    sensorIds: [],
  };
  const [state, dispatch] = useReducer(editMemberProfileReducer, initialFormState);
  const { step } = state;

  const getPrimaryButtonProps = () => ({
    theme: 'enterprise',
    variant: 'primary',
    label: 'Save',
    disabled: false,
  });

  const getSecondaryButtonProps = () => ({
    variant: 'link',
    label: 'Cancel',
    disabled: false,
    onClick: () => setEditProfile(false),
  });

  const editMemberProfileHandler = async () => {
    try {
      dispatch({ type: 'loading', value: true });
      const profileData: HubUserProfile = getProfileFromState(state);
      await editHubUserAccountDetails(seatId, profileData);
      dispatch({ type: 'done' });
    } catch (e) {
      dispatch({ type: 'error', payload: e });
    }
  };

  useEffect(() => {
    if (step === EditMemberProfileSteps.Edit) {
      editMemberProfileHandler();
    }
    if (step === EditMemberProfileSteps.Done) {
      setProfileData(state);
      setEditProfile(false);
    }
  }, [step]);

  return (
    <>
      <div>
        {step === EditMemberProfileSteps.Loading && <PulsingLogo overlay />}
        <div className={styles.profileContent}>
          {!inWizard && (
            <div className={styles.detailHeader}>
              <div>
                <TITLE_3>Edit Member Profile</TITLE_3>
                <div className={styles.headerDescription}>
                  <BODY_SHORT_1>
                    {'Please fill out the fields below and then select \'Save\''}
                  </BODY_SHORT_1>
                </div>
              </div>
              <div className={styles.editProfileButtons}>
                <img src={MoreIcon} alt="more" className={styles.moreButton} />
              </div>
            </div>
          ) }
          <MemberProfileComp
            dispatch={dispatch}
            state={state}
            viewProfile={false}
            addMember={addMember}
            customDispatch={customDispatch}
          />
        </div>
      </div>
      {!inWizard && (
        <ActionFooter
          state={state}
          getPrimaryButtonProps={getPrimaryButtonProps}
          getSecondaryButtonProps={getSecondaryButtonProps}
          dispatch={dispatch}
        />
      )}
    </>
  );
}

export default EditMemberProfile;
