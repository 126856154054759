import { Colors } from '@whoop/web-components';
import { analyticsClient } from 'api';
import { usePrivacy } from 'context/privacy-context';
import { HROverview, SeriesType } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import OverviewHRGraph from 'dataVisualizations/overviewHRGraph/overviewHRGraph';
import PillarPieChart from 'dataVisualizations/pillarPieChart/pillarPieChart';
import { Col, Row } from 'layout/layout';
import Card from 'progress/components/Card/card';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import DayStrainPieCard from 'progress/components/dayPillarPieCards/dayStrainPieCard';
import {
  formatDate,
  getFormattedRangeDay, recoveryMoreInfo, sleepMoreInfo,
} from 'progress/profile/profileUtils';
import { getColorFromVal } from 'progress/progressPageUtils';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useParams } from 'react-router-dom';
import {
  Pillars, RecoveryMetricType, SleepMetricType,
} from 'types/analytics';
import { PrivacyLevel } from 'types/dashboardUser';
import { UrlParams } from 'types/profile';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import CardPlaceholder from 'progress/components/cardPlaceholder/cardPlaceholder';
import { GetMetricOverviewParams } from 'api/analyticsApi';
import styles from './views.module.scss';

function DailyView() {
  const { accountId, userId } = useParams<UrlParams>();
  const { isInPrivacyLevel } = usePrivacy();
  const { dateRange } = useDateRange();
  const [dayRecoveryScore, setDayRecoveryScore] = useState<number>(null);
  const [daySleepScore, setDaySleepScore] = useState<number>(null);
  const [overviewData, setOverviewData] = useState<HROverview>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const detailDate = getFormattedRangeDay(dateRange);

  const resetPillarState = () => {
    setDayRecoveryScore(null);
    setDaySleepScore(null);
    setOverviewData(null);
  };

  const getData = async (range: DateRange) => {
    // TODO: Bug with rendering the table's Axis https://github.com/plouc/nivo/issues/1006
    // If you remove the axes from the charts layers you don't need to set the state to null
    const {
      getDayOverview, getMetricOverview,
    } = analyticsClient;
    setLoading(true);
    resetPillarState();
    setOverviewData(null);

    const memberId = parseInt(userId, 10);
    const startDate = formatDate(range.from);
    try {
      const params: GetMetricOverviewParams = {
        memberId,
        startDate: formatDate(range.from),
      };
      const daySleep = await getMetricOverview(
        params,
        Pillars.SLEEP,
        SleepMetricType.PERFORMANCE,
      );
      const dayRecovery = await getMetricOverview(
        params,
        Pillars.RECOVERY,
        RecoveryMetricType.SCORE,
      );
      setDayRecoveryScore(dayRecovery.metrics[0].metric_value);
      setDaySleepScore(daySleep.metrics[0].metric_value);
    } catch {
      resetPillarState();
    }

    if (!isInPrivacyLevel(PrivacyLevel.performance_metrics)) {
      setLoading(false);
      return;
    }
    try {
      const dayOverview = await getDayOverview(memberId, startDate);
      setOverviewData(dayOverview);
    } catch {
      setOverviewData(null);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData(dateRange);
  }, [dateRange]);

  const getAnnotationLink = (activityId: string, type: SeriesType) => {
    if (type === SeriesType.ACTIVITY) {
      return `/accounts/${accountId}/progress/${userId}/profile/strain?activityId=${activityId}&from=${formatDate(dateRange.from)}`;
    }
    return '';
  };

  const renderGraph = () => {
    if (overviewData) {
      const numActivities = Object.keys(overviewData.activities).length;
      const numSleeps = Object.keys(overviewData.sleeps).length;
      const numDayHr = overviewData.day_hr.length;
      const isOverviewDataEmpty = numActivities === 0 && numSleeps === 0 && numDayHr === 0;
      if (!isOverviewDataEmpty) {
        return (
          <div className={styles.overviewHRGraph}>
            <OverviewHRGraph
              hrData={overviewData}
              getAnnotationLink={getAnnotationLink}
            />
          </div>
        );
      }
    }
    return <div> No Data for this day</div>;
  };

  return (
    <>
      <Row center="xs" className={styles.pillarCardsContainer}>
        <Col xs={4}>
          <DayStrainPieCard dateRange={dateRange} />
        </Col>
        <Col xs={4}>
          <Card
            loading={loading}
            id="Recovery"
          >
            <CardHeader
              title="Recovery"
              details={detailDate}
              moreInfoText={recoveryMoreInfo}
            />
            <div className={styles.centerElement}>
              <div className={styles.pieSize}>
                <PillarPieChart
                  pillarType={Pillars.RECOVERY}
                  pieValue={dayRecoveryScore}
                  color={getColorFromVal(dayRecoveryScore)}
                />
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={4}>
          <Card
            loading={loading}
            id="Sleep"
          >
            <CardHeader
              title="Sleep"
              details={detailDate}
              moreInfoText={sleepMoreInfo}
            />
            <div className={styles.centerElement}>
              <div className={styles.pieSize}>
                <PillarPieChart
                  pillarType={Pillars.SLEEP}
                  pieValue={daySleepScore}
                  color={Colors.sleepBlue}
                />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row center="xs" className={styles.overviewHRContainer}>
        <Col xs={12}>
          {isInPrivacyLevel(PrivacyLevel.performance_metrics)
            ? (
              <Card
                loading={loading}
                showBackground={false}
                id="HR Overview"
              >
                <CardHeader
                  title="HR Overview"
                  details={detailDate}
                />
                {renderGraph()}
              </Card>
            )
            : (
              <CardPlaceholder
                icon="hide_privacy"
                cardTitle="HR Overview"
                cardDetails={detailDate}
                text="Your access to HR Overview is restricted"
                subtext={['If you think this is an error, please contact your admin']}
              />
            )}
        </Col>
      </Row>
    </>
  );
}

export default DailyView;
