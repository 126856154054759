import {
  BarDatum, BarItemProps, BarTooltipProps, ComputedBarDatum,
} from '@nivo/bar';
import { classes, Colors } from '@whoop/web-components';
import { ReactElement } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTooltip } from '@nivo/tooltip';
import styles from './bars.module.scss';

export function LabeledBarComponent(
  barComp: ComputedBarDatum<BarDatum>,
  tooltip: React.FC<BarTooltipProps<BarDatum>>,
  dayHighlighted: string,
  setDayHighlighted: (dayHighlighted: string) => void,
  lastIndex: number,
  metricName: string,
  color?: string,
  customFormat: (val: number) => string = null,
): ReactElement<BarItemProps<BarDatum>> {
  const { data, ...bar } = barComp;
  const { showTooltipAt, hideTooltip } = useTooltip();
  const highlightVal = dayHighlighted === data.indexValue;
  // Move to the left based on how long the metric name is so that it doesn't cutoff the dashed line
  let raiseTooltipYOffset = 125;
  if (metricName.length > 12) {
    raiseTooltipYOffset += (12 * (metricName.length - 12));
  }
  const tooltipYVal = bar.absY - raiseTooltipYOffset;
  const tooltipXVal = lastIndex === bar.index
    ? bar.absX - raiseTooltipYOffset : Math.round(bar.absX + bar.width) + 5;

  const handleMouseEnter = () => {
    setDayHighlighted(data.indexValue as string);
    showTooltipAt(
      tooltip({ ...bar, ...data }),
      [tooltipXVal, tooltipYVal < 0 ? 0 : tooltipYVal],
    );
  };

  const handleMouseLeave = () => {
    setDayHighlighted(null);
    hideTooltip();
  };

  // Hides 0 values from the bar chart label
  const getValToDisplay = (val: number) => {
    if (val === 0) {
      return null;
    }
    return val;
  };

  // Get the height of the graph to calculate the hover line height
  const barComponent = document.getElementById(bar.label);
  // This is getting the parent SVG of the bar which is the graph as a whole
  const parentSVG = barComponent?.parentElement?.parentElement;
  const heightOfGraph = parentSVG?.clientHeight ?? 0;

  // Subtract 70 to account for the margins and padding around graph
  const hoverLineHeight = heightOfGraph - bar.height - 70;

  return (
    <g
      transform={`translate(${bar.x}, ${bar.y})`}
      onMouseEnter={(): void => handleMouseEnter()}
      onMouseLeave={(): void => handleMouseLeave()}
      id={bar.label}
    >
      {!dayHighlighted && (
        <text
          textAnchor="middle"
          className={styles.barLabel}
          x={bar.width / 2}
          y={-6}
          fill={color ?? Colors.strainBlue}
        >
          {customFormat ? customFormat(data.value) : getValToDisplay(data.value)}
        </text>
      )}
      {highlightVal && (
        <path
          d={`M${bar.width / 2} 0 L${bar.width / 2} -${hoverLineHeight}`}
          stroke={Colors.blackAlpha900}
          strokeDasharray="5,5"
          strokeWidth="1"
          fill="none"
          aria-label={`hover-line-${bar.index}`}
        />
      )}
      <rect
        role="img"
        aria-label={`bar-${bar.index}`}
        width={bar.width}
        height={bar.height}
        fill={dayHighlighted && !highlightVal ? Colors.strainBlueAlpha200 : bar.color}
        strokeWidth="1"
      />
    </g>
  );
}

export function CustomPillarBreakdownBar(
  { bar: { data, ...bar }, tooltip, onClick }: BarItemProps<BarDatum>,
  showHoverState: boolean,
) {
  const { showTooltipAt, hideTooltip } = useTooltip();
  const tooltipYVal = 100;

  let tooltipXVal = (bar.absX + (bar.width / 2));
  const handleMouseEnter = () => {
    const barComponent = document.getElementById(bar.label);
    // This gets the positioning of the bar on the screen
    const barPagePositioning = barComponent.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const tooltipOffset = 50;

    // These values are to prevent the tooltip from going off the screen
    // If the bar starts at greater than 85% of the screen,
    // we move the tooltip to the left by subtracting the tooltipOffset
    if (barPagePositioning.left / windowWidth > 0.85) {
      tooltipXVal = bar.absX + (bar.width / 2) - tooltipOffset;
    }
    // If the bar starts at less tha 15% of the screen,
    // we move the tooltip to the right by adding the tooltipOffset
    if (barPagePositioning.left / windowWidth < 0.15) {
      tooltipXVal = bar.absX + (bar.width / 2) + tooltipOffset;
    }
    showTooltipAt(
      tooltip({ ...bar, ...data }),
      [tooltipXVal, tooltipYVal],
    );
  };

  const handleMouseLeave = () => {
    hideTooltip();
  };

  return (
    <g
      transform={`translate(${bar.x}, ${bar.y})`}
      onMouseEnter={(): void => handleMouseEnter()}
      onMouseLeave={(): void => handleMouseLeave()}
      id={bar.label}
    >
      <rect
        aria-label={`bar-${bar.label}`}
        width={bar.width}
        height={bar.height}
        fill={bar.color}
        rx={2}
        className={classes(showHoverState && styles.pillarBreakdownBar)}
        onClick={(event) => onClick && onClick({ ...data, color: bar.color }, event)}
      />
    </g>
  );
}

export default LabeledBarComponent;
