import React, { useState, useMemo, useEffect } from 'react';
import { GroupMember } from 'types/cohort';
import Table from 'components/table';
import {
  Column, CellProps, Row, TableCommonProps,
} from 'react-table';
import { dateCell, nameSubtitleCell, normalCell } from 'tableUtils/tableCells';
import { Button } from '@whoop/web-components';
import RowsPerPageSelect from 'tableUtils/rowsPerPageSelect/rowsPerPageSelect';
import StatusBadge from 'components/statusBadge';
import { addUserIdColumn, SELECTION_COLUMN } from 'tableUtils/tableColumns';
import { ModalType } from 'groups/utils';
import { UseStatePropType } from 'types/useStatePropType';
import { DashboardRole } from 'types/dashboardUser';
import { useAccounts } from 'context/account-context';
import { useNavigate, useParams } from 'react-router-dom';
import { GroupPagesParamsType } from 'types/utils';
import styles from './groupMembersTable.module.scss';

type GroupMembersTableProps = {
  members: GroupMember[];
  loading: boolean;
  setShowModal: (type: ModalType) => void;
  selectedMemberRows: Row<GroupMember>[];
  setSelectedMemberRows: UseStatePropType<Row<GroupMember>[]>;
};

function GroupMembersTable({
  members,
  loading,
  setShowModal,
  selectedMemberRows,
  setSelectedMemberRows,
}: GroupMembersTableProps) {
  const [pageSize, setPageSize] = useState<number>(10);
  const { checkAccess, accountConfig, isReidTokenValid } = useAccounts();
  const { accountId, groupId } = useParams<GroupPagesParamsType>();
  const [isTokenValid, setIsTokenValid] = useState<boolean>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const tokenValidation = async () => {
      const tokenValid = await isReidTokenValid();
      setIsTokenValid(tokenValid);
    };

    tokenValidation();
  }, [accountId]);

  const statusBadgeCell = (cellProps: CellProps<GroupMember>) => (
    <StatusBadge status={cellProps.row.original.seat_status} />
  );

  const columns: Column<GroupMember>[] = useMemo(
    () => {
      const memberName = {
        Header: 'Name',
        id: 'name',
        accessor: (member: GroupMember) => `${member.first_name} ${member.last_name}`,
        width: '33%',
        align: 'left',
        Cell: (cellProps: CellProps<GroupMember>) => nameSubtitleCell(cellProps),
      } as Column<GroupMember>;
      const sensorId = {
        Header: 'Sensor ID',
        id: 'strap_serial',
        accessor: 'strap_serial',
        width: '10%',
        align: 'left',
        Cell: normalCell,
      } as Column<GroupMember>;
      const membershipStatus = {
        id: 'member_status',
        Header: 'Status',
        accessor: 'seat_status',
        width: '10%',
        align: 'left',
        Cell: statusBadgeCell,
      } as Column<GroupMember>;
      const activatedOn = {
        Header: 'Activated On',
        width: '20%',
        align: 'left',
        sort: 'datetime',
        accessor: (member: GroupMember) => new Date(member.activated_on),
        Cell: dateCell,
      } as Column<GroupMember>;
      const email = {
        id: 'email',
        accessor: 'email',
      } as Column<GroupMember>;

      const columnList: Column<GroupMember>[] = [
        SELECTION_COLUMN,
        memberName,
        sensorId,
        membershipStatus,
        activatedOn,
        email,
      ];

      addUserIdColumn(checkAccess(DashboardRole.WHOOP_CSM)
      && (!accountConfig.deidentified || isTokenValid), columnList, 2);

      return columnList;
    },
    [isTokenValid],
  );

  const handleAddMemberBtn = () => {
    navigate(`/accounts/${accountId}/people/groups/${groupId}/add-members`);
  };

  const handleAddCollaborators = () => {
    navigate(`/accounts/${accountId}/people/groups/${groupId}/add-collaborators`);
  };

  const handleRemoveMemberBtn = () => {
    setShowModal(ModalType.REMOVE_GROUP_MEMBERS);
  };

  const getRowStyles = (): TableCommonProps => ({
    style: {
      height: '80px',
    },
  });

  const controls = (
    <div className={styles.controls}>
      <div className={styles.left}>
        <RowsPerPageSelect onOptionClick={(size) => setPageSize(size)} selectedOption={pageSize} />
      </div>
      {checkAccess(DashboardRole.ACCOUNT_MANAGER) && (
        <div className={styles.buttons}>
          {selectedMemberRows.length > 0 ? (
            <Button
              className={styles.button}
              label="Remove Members"
              size="small"
              theme="enterprise"
              variant="primary"
              onClick={handleRemoveMemberBtn}
            />
          )
            : (
              <>
                <Button
                  className={styles.button}
                  label="Add Collaborators"
                  size="small"
                  theme="enterprise"
                  variant="normal"
                  onClick={handleAddCollaborators}
                />
                <Button
                  className={styles.button}
                  label="Add Members"
                  size="small"
                  theme="enterprise"
                  variant="primary"
                  onClick={handleAddMemberBtn}
                />
              </>
            )}
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.tableContainer}>
      <Table
        columns={columns}
        data={members}
        initialSortByField="first_name"
        loading={loading}
        tableName="groupMembers"
        heading="Group Members"
        unit="member"
        searchPlaceholder="Search by member name, email or strap serial"
        noDataFoundMessage="No members found"
        tableLength={pageSize}
        getAdditionalRowProps={getRowStyles}
        optionalControls={controls}
        hiddenColumns={['email']}
        setSelectedRows={setSelectedMemberRows}
      />
    </div>
  );
}

export default GroupMembersTable;
