import { ReactElement } from 'react';

type DemoDisabledProps = {
  children: ReactElement<any, any>;
};

const isDemoEnv = window.location.origin.includes('demo');

// Use this value for isDemoEnv when running localhost
// const isDemoEnv = window.location.pathname.includes('foster-and-jefferson');

export default function ({ children }: DemoDisabledProps) {
  return isDemoEnv ? null : children;
}
