import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button } from '@whoop/web-components';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDecimalToPercent } from 'tableUtils/utils';
import TableColumnHeader from 'components/tableColumnHeader';
import { HEADING_3, N7 } from 'components/text';
import InviteWorkspaceManager from 'components/inviteWorkspaceManager';
import { dateCell, normalCell } from 'tableUtils/tableCells';
import Table from 'components/table';
import { TableHeaderColumn } from 'types/utils';
import { AdminAccountDetail } from 'types/admin';
import { CellProps } from 'react-table';
import styles from './accountsTable.module.scss';

type AdminAccountsTableProps = {
  accounts: AdminAccountDetail[];
};

const currentDate = new Date();
const lastDate = new Date();
lastDate.setDate(currentDate.getDate() - 28);

function AdminAccountsTable(props: AdminAccountsTableProps) {
  const { accounts } = props;
  const navigate = useNavigate();
  const [rowIndex, setRowIndex] = useState(null);
  const [accountForInvite, setAccountForInvite] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: 'ACCOUNT NAME',
        id: 'account_name',
        accessor: 'account_name',
        width: '33%',
        align: 'left',
        Cell: normalCell,
      },
      {
        Header: 'CONTRACT ENDS ON',
        accessor: (row: AdminAccountDetail) => new Date(row.expiration_date),
        width: '15%',
        align: 'left',
        sortType: 'datetime',
        tooltip: 'The soonest expiration date out of the active contracts.',
        Cell: (cellProps: CellProps<AdminAccountDetail>) => dateCell(cellProps, styles.grayText),
      },
      {
        Header: 'ACTIVE CONTRACTS',
        accessor: 'total_active_contracts',
        width: '15%',
        align: 'left',
        Cell: normalCell,
      },
      {
        Header: 'ACTIVATED SENSORS',
        id: 'activated_sensors',
        accessor: (row: AdminAccountDetail) => row.active_seats,
        width: '15%',
        align: 'left',
        tooltip:
          'Total number of sensors activated out of the total number of sensors purchased.',
        // column is likely to get replaced in designs - not worth refactor
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (cellProps: CellProps<AdminAccountDetail>) => {
          const { row } = cellProps;
          const data = row.original;
          return (
            <N7>
              {data.active_seats}
              /
              <span>{data.total_licenses}</span>
            </N7>
          );
        },
      },
      {
        Header: '28 DAY WHOOP USAGE',
        id: 'compliance',
        accessor: (row: AdminAccountDetail) => (row.num_compliant / row.active_seats) * 100,
        width: '18%',
        align: 'left',
        tooltip: `The number of unique users who have worn their sensor from ${lastDate.toDateString()} through ${currentDate.toDateString()} (last 28 days).`,
        // column is likely to get replaced in designs - not worth refactor
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (cellProps: CellProps<AdminAccountDetail>) => {
          const { row: { original: data } } = cellProps;
          const compliant = data.num_compliant;
          const total = data.active_seats;
          return (
            <N7>
              {formatDecimalToPercent(compliant / total)}
              <span className={styles.grayText}>
                {' '}
                (
                {compliant}
                /
                {total}
                )
              </span>
            </N7>
          );
        },
      },
    ],
    [],
  );

  const redirectToAccount = (row: AdminAccountDetail) => {
    navigate(`/accounts/${row.id}`);
  };

  const handleClose = () => setAccountForInvite(null);

  const getTableColumnHeader = (column: TableHeaderColumn) => (
    <TableColumnHeader
      thProps={{
        className: column.tooltip ? styles.tooltip : '',
      }}
      column={column}
      key={column.id}
    />
  );

  const createExtraRowComponent = (row: any, i: number) => (
    <td
      className={`${rowIndex !== null && rowIndex === i && styles.show} ${
        styles.viewAccountBtn
      }`}
    >
      <Button
        label={(
          <>
            <HEADING_3>View Account</HEADING_3>
            <ArrowForwardIcon />
          </>
          )}
        variant="link"
        theme="enterprise"
      />
    </td>
  );

  return (
    <>
      <Table
        tableName={null}
        columns={columns}
        data={accounts}
        loading={false}
        initialSortByField="account_name"
        heading="All Customer Accounts"
        unit="account"
        searchPlaceholder="Search by account name"
        onRowClickHandler={redirectToAccount}
        onMouseEnterHandler={(i) => {
          setRowIndex(i);
        }}
        onMouseLeaveHandler={() => {
          setRowIndex(null);
        }}
        getTableColumnHeader={getTableColumnHeader}
        createExtraRowComponent={createExtraRowComponent}
        tableLength={10}
        hideOnSearch
        showEmptyMessage
      />
      <InviteWorkspaceManager
        account={accountForInvite}
        handleClose={handleClose}
      />
    </>
  );
}

export default AdminAccountsTable;
