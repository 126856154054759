import SuccessIcon from 'assets/icons/checkGreen.svg';
import ErrorIcon from 'assets/icons/xRed.svg';
import { Button } from '@whoop/web-components';
import styles from '../register.module.scss';

function FeedbackMessage({
  onConfirm, success, header, subtext,
}) {
  return (
    <div className={styles.feedbackMessage}>
      <div>
        {success ? (
          <img className={styles.checkMark} src={SuccessIcon} alt="Success" />
        ) : (
          <img className={styles.checkMark} src={ErrorIcon} alt="Error" />
        )}

        <h1 className={styles.feedbackMessage}>{header}</h1>
        <p className={styles.feedbackMessage}>{subtext}</p>

        <Button
          label="return to sign in"
          aria-label="return"
          variant="primary"
          type="submit"
          data-testid="return-btn"
          onClick={onConfirm}
        />
      </div>
    </div>
  );
}

export default FeedbackMessage;
