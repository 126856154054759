import React, { useState, useEffect } from 'react';
import styles from './loadingBar.module.scss';

type LoadingBarProps = {
  show: boolean;
};

function LoadingBar({ show }: LoadingBarProps) {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 999);

    return () => setLoading(false);
  }, [show]);

  return (
    <tr className={styles.requestProgress}>
      <td className={loading ? styles.bar : styles.hide} />
    </tr>
  );
}

export default LoadingBar;
