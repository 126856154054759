import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    maxWidth: '540px',
    marginBottom: '80px',
  },
  children: {
    padding: '20px 20px 28px 20px',
    textAlign: 'center',
    borderRadius: '5px',
    color: 'white',
    backgroundColor: (props) => props.color,
  },
  progress: {
    zIndex: '10000',
    position: 'inherit',
    height: '8px',
    width: '100%',
    bottom: '0',
    backgroundColor: 'var(--whoop-color-gray-700)',
    borderRadius: '0 0 5px 5px',
  },
  bar: {
    width: '0',
    height: '100%',
    borderRadius: '0 0 5px 5px',
    backgroundColor: 'var(--whoop-color-gray-500)',
    transition: 'width 8s linear',
  },
  open: {
    width: '100%',
  },
  hidden: {
    visibility: 'hidden',
  },
});

export default useStyles;
