import { rest } from 'msw';
import { getDelayVal, mswBaseUri } from 'mocks/utils/utils';
import {
  getAccount,
  getAvailableBulkLicenses,
  getDashboardUsers,
  getConfigurations,
  setConfigurations,
  getAllAccessPrivacy,
  removeCollaboratorFromAccount,
  updateInvitedCollaborator,
  getDashboardUser,
  setUserProfile,
  updateDashboardUsers,
  getReidentificationToken,
  validateReidentificationToken,
  getCollaborators,
  removeInvitedCollaboratorFromAccount,
  inviteCollaborator, getCollaboratorLink,
} from '../actions/accountActions';

export const accountHandlers = [
  rest.get(mswBaseUri('v1/accounts'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json([getAccount()]),
  )),
  rest.get(mswBaseUri('v1/admin/accounts'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json([getAccount()]),
  )),
  rest.get(mswBaseUri('v1/dashboard'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(getDashboardUser()),
  )),
  rest.get(mswBaseUri('v1/accounts/license-count'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(getAvailableBulkLicenses()),
  )),
  rest.get(mswBaseUri('v1/admin/accounts/configuration'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(getConfigurations()),
  )),
  rest.put(mswBaseUri('v1/admin/accounts/configuration'), async (req, res, ctx) => res(
    ctx.delay(getDelayVal(100)),
    ctx.status(200),
    ctx.json(setConfigurations(await req.json())),
  )),
  rest.post(mswBaseUri('v1/invite'), async (req, res, ctx) => {
    const invitedCollaboratorData = await req.json();
    inviteCollaborator(invitedCollaboratorData);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(204),
    );
  }),
  rest.get(mswBaseUri('v1/dashboard/collaborators'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(100)),
    ctx.status(200),
    ctx.json(getCollaborators()),
  )),
  rest.get(mswBaseUri('v1/dashboard/permissions'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(100)),
    ctx.status(200),
    ctx.json(getDashboardUsers()),
  )),
  rest.put(mswBaseUri('v1/dashboard/permissions'), async (req, res, ctx) => res(
    ctx.delay(getDelayVal(100)),
    ctx.status(200),
    ctx.json(updateDashboardUsers(await req.json())),
  )),
  rest.put(mswBaseUri('v1/invite'), async (req, res, ctx) => {
    const email = req.url.searchParams.get('email');
    const data = await req.json();
    return res(
      ctx.delay(getDelayVal(100)),
      ctx.status(200),
      ctx.json(updateInvitedCollaborator(email, data)),
    );
  }),
  rest.get(mswBaseUri('v1/user/privacy'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(0)),
    ctx.status(200),
    ctx.json(getAllAccessPrivacy()),
  )),
  rest.get(mswBaseUri('v1/user'), async (req, res, ctx) => {
    const originalResponse = await ctx.fetch(req);
    const originalResponseData = await originalResponse.json();
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(setUserProfile(originalResponseData)),
    );
  }),
  rest.get(mswBaseUri('v1/invite/account-admin'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(400)),
    ctx.status(200),
    ctx.json(false),
  )),
  rest.delete(mswBaseUri('v1/dashboard'), (req, res, ctx) => {
    const collaboratorId = req.url.searchParams.get('collaboratorUserId');
    removeCollaboratorFromAccount(collaboratorId);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
    );
  }),
  rest.delete(mswBaseUri('v1/invite/cancel'), (req, res, ctx) => {
    const collaboratorEmail = req.url.searchParams.get('email');
    removeInvitedCollaboratorFromAccount(collaboratorEmail);
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
    );
  }),
  rest.get(mswBaseUri('v1/invite/link'), (req, res, ctx) => {
    const link = getCollaboratorLink();
    return res(
      ctx.delay(getDelayVal(400)),
      ctx.status(200),
      ctx.json(link),
    );
  }),
  rest.post(mswBaseUri('v1/reidentification/token'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(0)),
    ctx.status(200),
    ctx.json(getReidentificationToken()),
  )),
  rest.get(mswBaseUri('v1/reidentification'), (req, res, ctx) => res(
    ctx.delay(getDelayVal(0)),
    ctx.status(200),
    ctx.json(validateReidentificationToken()),
  )),
];

export default accountHandlers;
