import ErrorIcon from 'assets/icons/xRed.svg';
import SuccessIcon from 'assets/icons/checkGreen.svg';
import { TITLE_4, P2 } from 'components/text';
import styles from './fullPageMessage.module.scss';

function FullPageMessage(props) {
  const {
    header, subtext, button, state,
  } = props;

  return (
    <div className={`${styles.container}`}>
      <div className={styles.messageContainer}>
        {state === 'success' ? (
          <img className={styles.logo} src={SuccessIcon} alt="Success" />
        ) : (
          <img className={styles.logo} src={ErrorIcon} alt="Error" />
        )}
        <TITLE_4>{header}</TITLE_4>
        <P2>{subtext}</P2>
        {button}
      </div>
    </div>
  );
}

export default FullPageMessage;
