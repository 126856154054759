import { P2 } from 'components/text/index';
import CustomSwitch from 'accountDetails/settings/customSwitch';
import { Button } from '@whoop/web-components';
import { AccountConfigurationSettingType } from 'accountDetails/types';
import styles from './settings.module.scss';

type AccountConfigurationProps = {
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEnabled?: boolean;
  title: string;
  description: string;
  name: string;
  type?: AccountConfigurationSettingType;
};

export default function ({
  onChangeHandler, isEnabled, title, description, name,
  type = AccountConfigurationSettingType.Toggle,
}: AccountConfigurationProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.sectionContainer}>
        <div className={styles.header}>
          <P2>{title}</P2>
        </div>
        <div className={styles.toggleContainer}>
          <p>{description}</p>
          <div className={styles.buttonWrap}>
            {type === AccountConfigurationSettingType.Toggle && (
            <CustomSwitch
              onChange={onChangeHandler}
              value={isEnabled}
              checked={isEnabled}
              name={name}
              customheight={30}
              customwidth={60}
            />
            )}
            {type === AccountConfigurationSettingType.Button
            && (
            <Button
              className={styles.settingsButton}
              label={title}
              name={name}
              size="small"
              theme="enterprise"
              variant="normal"
              onClick={onChangeHandler}
            />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
