import { pillarKeysToDisplay, RecoveryKeys, StrainKeys } from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarConsts';

const getDisabledColorsToUse = (
  disabledColorsToUse: string[],
  actualColors: string[],
  selectedVal: StrainKeys | RecoveryKeys,
) => {
  const newColors = [...disabledColorsToUse];
  const { colorIndex } = pillarKeysToDisplay[selectedVal];
  newColors[colorIndex] = actualColors[colorIndex];
  return newColors;
};

export default getDisabledColorsToUse;
