import {
  Button,
} from '@whoop/web-components';
import { BODY_SHORT_1 } from 'components/text';
import logo from 'assets/ui-logo.svg';
import styles from '../preRegistration.module.scss';

type WelcomeStepProps = {
  image: string;
  email: string;
  nextStep: () => void;
};

function WelcomeStep({ image, email, nextStep }: WelcomeStepProps) {
  return (
    <div className={styles.preRegistrationContainer}>
      <div className={styles.instructions}>
        <img className={styles.logo} src={logo} alt="Whoop Header Logo" />
        <BODY_SHORT_1 className={styles.bold}>Welcome to WHOOP Unite!</BODY_SHORT_1>
        <BODY_SHORT_1 className={styles.bold}>
          Your email
          {' '}
          {email}
          {' '}
          is already registered
          on app.whoop.com
        </BODY_SHORT_1>
        <BODY_SHORT_1 className={styles.bold}>
          You will need to update your email before you create a WHOOP Unite account.
          We’ll guide you through this process now to help you get set up quickly and securely.
        </BODY_SHORT_1>
        <div className={styles.flowButtons}>
          <Button
            label="Start"
            onClick={nextStep}
            theme="enterprise"
            variant="primary"
          />
        </div>
      </div>
      <img className={styles.image} src={image} alt="Athletics" />
    </div>
  );
}

export default WelcomeStep;
