import { Tooltip } from '@material-ui/core';
import React, { Ref, forwardRef, MutableRefObject } from 'react';
import styles from './rowSelection.module.scss';

type RowSelectionProps = {
  indeterminate?: boolean;
  onChange?: () => void;
  checked?: boolean;
  ref: Ref<HTMLInputElement>;
  disabled?: boolean;
  tooltipTitle?: string;
};

/* eslint-disable react/jsx-props-no-spreading */

const RowSelection = forwardRef<Ref<HTMLInputElement>, RowSelectionProps>(
  ({
    disabled, tooltipTitle, indeterminate, ...rest
  }, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      (
        resolvedRef as MutableRefObject<HTMLInputElement>
      ).current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const checkbox = (
      <div className={styles.checkmark} data-test-id="checkmark" aria-disabled={disabled}>
        <svg
          className={styles.checkIcon}
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={styles.path}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.41288 7.06596C4.39109 7.09302 4.36721 7.11904 4.34123 7.14382L4.03391 7.43696C3.71353 7.74255 3.1922 7.74376 2.87138 7.43775L0.240452 4.92826C-0.0807399 4.62189 -0.0798369 4.12569 0.241281 3.81939L0.548608 3.52625C0.868989 3.22066 1.39032 3.21945 1.71114 3.52546L3.44724 5.18142L8.28779 0.564296C8.61001 0.256952 9.13076 0.256256 9.45243 0.563075L9.75975 0.856216C10.0806 1.16226 10.08 1.66043 9.75847 1.9671L4.41288 7.06596Z"
          />
        </svg>
        <svg
          className={styles.indeterminate}
          width="10"
          height="2"
          viewBox="0 0 10 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="1" x2="10" y2="1" stroke="white" strokeWidth="2" />
        </svg>
      </div>
    );
    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={styles.checkmarkContainer}>
        <input
          className={styles.checkbox}
          autoComplete="off"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          disabled={disabled}
        />
        {disabled && tooltipTitle ? (
          <Tooltip
            title={tooltipTitle}
            classes={{
              tooltip: styles.tooltip,
            }}
          >
            {checkbox}
          </Tooltip>
        ) : checkbox}

      </label>
    );
  },
);

export default RowSelection;
