import { HEADING_4 } from 'components/text';
import { ProgressCohort, ProgressMemberProfile } from 'types/analytics';
import { useState } from 'react';
import { classes, Icon } from '@whoop/web-components';
import dayjs from 'dayjs';
import { removeMemberFromGroup } from 'api/cohortApi';
import { useToast } from 'context/toast-context';
import * as Sentry from '@sentry/react';
import styles from './profileInformation.module.scss';

type ProfileInformationProps = {
  user: ProgressMemberProfile;
  groupList: ProgressCohort[];
  getMemberOverview: () => void;
};

function ProfileInformation({
  user, groupList, getMemberOverview,
}: ProfileInformationProps) {
  const { openToast } = useToast();
  const [loading, setLoading] = useState(false);

  const handleRemoveMemberFromGroup = async (group: ProgressCohort) => {
    setLoading(true);
    try {
      await removeMemberFromGroup(group.cohort_id, user.user_id);
    } catch (err) {
      Sentry.captureException(err);
      openToast('Unable to remove this member from the group.');
    } finally {
      await getMemberOverview();
      setLoading(false);
    }
  };

  return (
    <div className={styles.profileWrapper}>
      <div className={styles.profileHeader}>
        <h1 className={styles.userInfoHeader}>{`${user?.first_name ?? ''} ${user?.last_name ?? ''}`}</h1>
      </div>
      <div className={styles.bodyWrapper}>
        <div className={styles.userInformation}>
          <div className={styles.userInfoSection}>
            <div className={styles.subtitle}>First Name</div>
            <div className={styles.mainContent}>{user.first_name ?? '--'}</div>
          </div>
          <div className={styles.userInfoSection}>
            <div className={styles.subtitle}>Last Name</div>
            <div className={styles.mainContent}>{user.last_name ?? '--'}</div>
          </div>
          <div className={styles.userInfoSection}>
            <div className={styles.subtitle}>Username</div>
            <div className={styles.mainContent}>{user.username ?? '--'}</div>
          </div>
          <div className={styles.userInfoSection}>
            <div className={styles.subtitle}>Email</div>
            <div className={styles.mainContent}>{user.email ?? '--'}</div>
          </div>
          <div className={styles.userInfoSection}>
            <div className={styles.subtitle}>Device ID</div>
            <div className={styles.mainContent}>{user.strap_serial ?? '--'}</div>
          </div>
          <div className={styles.userInfoSection}>
            <div className={styles.subtitle}>User ID</div>
            <div className={styles.mainContent}>{user.user_id ?? '--'}</div>
          </div>
        </div>
        <div className={styles.groups}>
          <HEADING_4 className={styles.groupsHeader}>Groups</HEADING_4>
          {groupList.map((group) => (
            <div className={styles.groupSection} key={group.name}>
              <div>
                <div className={styles.mainContent}>{group.name}</div>
                <div
                  className={styles.subtitle}
                >
                  {`Added on ${dayjs(group.joined_date).format('MMM D, YYYY').toString()}`}
                </div>
              </div>
              <button
                type="button"
                aria-label="delete from group"
                className={classes(styles.button, styles.trashIcon)}
                onClick={() => handleRemoveMemberFromGroup(group)}
                disabled={loading}
              >
                <Icon name="delete" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProfileInformation;
