import {
  baseSeats,
} from '../store/seats';

export const getSeats = () => baseSeats;

export const inviteSeats = (emails, shipping_type) => {
  emails.forEach((email) => {
    if (baseSeats.every((seat) => seat.email !== email)) {
      baseSeats.push({
        first_name: null,
        last_name: null,
        email,
        id: baseSeats.length + 1,
        user_id: baseSeats.length + 1,
        strap_serial: null,
        status: 'invited',
        seat_status: 'invited',
        shipping_type,
        accept_token: null,
      });
    }
  });
};

export const removeSeat = (seatId) => {
  const seatIndex = baseSeats.findIndex((seat) => seat.id === seatId);
  const seat = baseSeats[seatIndex];
  baseSeats.splice(seatIndex, 1, {
    ...seat,
    status: 'deactivated',
  });
};

export const cancelInvitedSeat = (seatId) => {
  const seatIndex = baseSeats.findIndex((seat) => seat.id === seatId);
  baseSeats.splice(seatIndex, 1);
};

export const getInviteLink = (seatId) => {
  const seat = baseSeats[seatId - 1];
  const { accept_token } = seat;
  return { seat_invite_link: 'join.qa.whoop.com/en-us/enterprise/'.concat(accept_token) };
};
