import { useEffect, useState } from 'react';
// Components
import PulsingLogo from 'components/spinner';
// Library
import { ErrorOption, useForm } from 'react-hook-form';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button } from '@whoop/web-components';
// Assets
import '@whoop/web-components/dist/index.css';
import LandingPageWrapper from 'landingPageWrapper/landingPageWrapper';
import { LockOutlined } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { P3 } from 'components/text';
import { SignupAccountType, SignupDetails } from 'types/admin';
import { useAuth } from '../context/auth-context';
import styles from './login.module.scss';

type LoginProps = {
  signupDetails?: SignupDetails;
};

type SubmitLoginType = { email: string; password: string; staySignedIn: boolean };

type LoginParams = { token: string };

type LocationState = { from: string };

function Login({ signupDetails }: LoginProps) {
  const { token } = useParams<keyof LoginParams>();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [acceptFlow, setAcceptFlow] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors, dirtyFields },
    setError,
    setValue,
  } = useForm({
    mode: 'onChange',
  });
  const watchEmail = watch('email', '');
  const watchPassword = watch('password', '');

  useEffect(() => {
    if (signupDetails?.account_type === SignupAccountType.USER) {
      setValue('email', signupDetails.email);
      setAcceptFlow(true);
    }
  }, [token, setValue, signupDetails]);

  useEffect(() => {
    const emailPattern = /\S+@\S+\.\S+/;
    const timeOutId = setTimeout(() => {
      if (!watchEmail.match(emailPattern)) {
        setError('email', {
          type: 'pattern',
          message: 'Please enter a valid email',
        });
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [watchEmail, setError]);

  const onSubmit = async (values: SubmitLoginType) => {
    setLoading(true);
    try {
      await login(values.email.trim(), values.password, values.staySignedIn, token);
      const from = (location.state as LocationState)?.from || '/';
      navigate(from, { replace: true });
    } catch (error) {
      if (error.response?.status === 401) {
        setError('password', 'incorrect password' as ErrorOption);
      }
    } finally {
      setLoading(false);
    }
  };

  const forgotPasswordClick = () => {
    navigate('/forgot');
  };

  const showPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const emailValidationError = errors?.email?.type === 'pattern' && watchEmail;
  const emailInputClass = `${styles.inputField} ${emailValidationError && styles.inputFieldInvalid
  }`;

  const passwordValidationError = errors?.password
    || (dirtyFields.password && watchPassword.length === 0);
  const passwordInputClass = `${styles.inputField} ${passwordValidationError && styles.inputFieldInvalid
  }`;

  const header = acceptFlow
    ? 'Accept Invite'
    : 'The power of WHOOP, amplified together.';

  const subtext = acceptFlow
    ? 'Please login with your WHOOP Enterprise account to confirm your invitation to the workspace '
    : 'Use your WHOOP account to sign into your enterprise workspace.';

  return (
    <LandingPageWrapper>
      {loading && <PulsingLogo overlay />}
      <div className={styles.infoText}>
        <h1>{header}</h1>
        <p>
          {subtext}
          {' '}
          <b style={{ color: 'white' }}>
            {signupDetails?.salesforce_account_name}
          </b>
        </p>
      </div>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.emailWrap}>
          {!signupDetails ? (
            <label htmlFor="email" id="emailLabel" className={styles.inputFieldLabel}>
              Email Address
              <input
                id="email"
                data-testid="email-input"
                aria-labelledby="emailLabel"
                className={emailInputClass}
                {...register('email', { required: true })}
              />
            </label>
          ) : (
            <>
              <label htmlFor="email" id="emailLabelExist" className={styles.inputFieldLabel}>
                Email Address
                <input
                  id="email"
                  data-testid="email-input-exist"
                  aria-labelledby="emailLabelExist"
                  className={emailInputClass}
                  value={watchEmail}
                  disabled
                  {...register('email')}
                />
              </label>
              <Tooltip
                title={(
                  <P3>
                    Your account will be created with the email address you were invited with.
                  </P3>
                )}
              >
                <LockOutlined className={styles.lockIcon} />
              </Tooltip>
            </>
          )}
        </div>

        <div className={styles.invalidMessage}>
          {emailValidationError && errors.email.message}
        </div>
        <div className={styles.passwordWrap}>
          <label htmlFor="password" id="passwordLabel" className={styles.inputFieldLabel}>
            Password
            <input
              id="password"
              data-testid="password-input"
              aria-labelledby="passwordLabel"
              className={passwordInputClass}
              type={showPassword ? 'text' : 'password'}
              {...register('password')}
            />
          </label>
          <button type="button" className={styles.showPasswordButton} onClick={showPasswordClick}>
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
        <div className={styles.invalidMessage}>
          {dirtyFields.password
            && watchPassword.length === 0
            && 'Password cannot be blank'}
          {errors?.password && 'Invalid username or password'}
        </div>
        <Button
          label="sign in"
          aria-label="Sign In"
          variant="primary"
          theme="enterprise"
          type="submit"
          data-testid="signin-btn"
          disabled={!isValid || watchPassword?.length === 0}
        />
        <div className={styles.loginOptions}>
          <div className={styles.staySignedIn}>
            <label htmlFor="staySignedIn" id="staySignedInLabel" className={styles.inputFieldLabel}>
              <input
                id="staySignedIn"
                data-testid="stay-signed-in"
                aria-labelledby="staySignedInLabel"
                className={styles.checkbox}
                type="checkbox"
                {...register('staySignedIn')}
              />
              Stay signed in
            </label>
          </div>
          <Button
            label="Forgot password?"
            variant="link"
            className={styles.forgotPassword}
            onClick={forgotPasswordClick}
          />
        </div>
      </form>
    </LandingPageWrapper>
  );
}

export default Login;
