import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface SortIconProps {
  descending: boolean;
}

export default function SortIcon({ descending }: SortIconProps) {
  return descending
    ? <ExpandMoreIcon titleAccess="Descending" fontSize="small" />
    : <ExpandLessIcon titleAccess="Ascending" fontSize="small" />;
}
