import { Icon } from '@whoop/web-components';
import Card from '../Card/card';
import CardHeader from '../Card/cardHeader/cardHeader';
import styles from './cardPlaceholder.module.scss';

type CardPlaceholderProps = {
  icon: string;
  cardTitle: string;
  cardDetails: string;
  text: string;
  subtext: String[];
  showBackground?: boolean;
};

function CardPlaceholder({
  icon,
  cardTitle,
  cardDetails,
  text,
  subtext,
  showBackground = false,
}: CardPlaceholderProps) {
  return (
    <Card
      id={cardTitle}
      loading={false}
      showBackground={showBackground}
      className={styles.cardContainer}
    >
      <CardHeader
        title={cardTitle}
        details={cardDetails}
        activities={[]}
        activitySelected={null}
        setActivitySelected={() => { }}
      />
      <div className={styles.placeholderContainer}>
        <Icon className={styles.icon} name={icon} />
        <h2 className={styles.text}>{text}</h2>
        {subtext.map((sentence) => (
          <p key={sentence.split(' ').join('-')} className={styles.subtext}>
            {`${sentence}.`}
          </p>
        ))}
      </div>
    </Card>
  );
}

export default CardPlaceholder;
