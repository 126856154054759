import { ResponsivePie } from '@nivo/pie';
import { Colors } from '@whoop/web-components';
import { formatValue } from './pieChartUtils';
import GetInnerLayer from './getInnerLayer';

type PieChartProps = {
  pieValue: number;
  color: string;
  showPercent: boolean;
};

function PieChart({ pieValue, color, showPercent }: PieChartProps) {
  const pieData = formatValue(pieValue, showPercent);

  return (
    <ResponsivePie
      data={pieData}
      innerRadius={0.88}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      isInteractive={false}
      colors={[color, Colors.gray300]}
      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', GetInnerLayer]}
    />
  );
}

export default PieChart;
