// eslint-disable-next-line import/prefer-default-export
export const mockStrainActivities = [
  {
    id: 'fa60e070-032f-4e43-925b-4ce29c0bd685',
    cycle_id: 13172511,
    user_id: 20333,
    created_at: '2022-10-11T01:23:03.303+0000',
    updated_at: '2022-10-11T01:23:09.531+0000',
    version: 0,
    during: "['2022-10-10T23:38:12.200Z','2022-10-11T00:48:50.613Z')",
    timezone: null,
    timezone_offset: '-04:00',
    source: 'auto',
    score_state: 'complete',
    score_type: 'CARDIO',
    type: 'running',
    translated_type: 'Running',
    source_id: null,
    activity_v1_id: 8403849,
    scaled_intensity_score: 12.25072420947686,
    raw_intensity_score: 0.005798431447497032,
    percent_recorded: 1,
    kilojoules: 2704.788333205821,
    max_heart_rate: 173,
    average_heart_rate: 134,
    zone_durations: {
      zone90_to100_duration: 0,
      zone80_to90_duration: 408.558,
      zone70_to80_duration: 1249.702,
      zone60_to70_duration: 2330.249,
      zone50_to60_duration: 249.943,
      zone0_to50_duration: 0,
    },
    gps_data: null,
    cycle_day_start: [
      2022,
      10,
      10,
    ],
  },
  {
    id: 'c3d0865e-2db8-49ee-89fd-482b55d9f307',
    cycle_id: 13312163,
    user_id: 20333,
    created_at: '2022-10-07T02:51:26.094+0000',
    updated_at: '2022-10-07T02:51:32.069+0000',
    version: 0,
    during: "['2022-10-07T01:44:06.510Z','2022-10-07T02:24:28.049Z')",
    timezone: null,
    timezone_offset: '-04:00',
    source: 'auto',
    score_state: 'complete',
    score_type: 'CARDIO',
    type: 'volleyball',
    translated_type: 'Volleyball',
    source_id: null,
    activity_v1_id: 8340965,
    scaled_intensity_score: 9.304529819477594,
    raw_intensity_score: 0.0031739070262275355,
    percent_recorded: 1,
    kilojoules: 1558.7613219774319,
    max_heart_rate: 152,
    average_heart_rate: 135,
    zone_durations: {
      zone90_to100_duration: 0,
      zone80_to90_duration: 0,
      zone70_to80_duration: 1409.279,
      zone60_to70_duration: 988.267,
      zone50_to60_duration: 24.032,
      zone0_to50_duration: 0,
    },
    gps_data: null,
    cycle_day_start: [
      2022,
      10,
      6,
    ],
  },
  {
    id: '9ea865cf-1c28-4a0b-b329-c7d1bf942950',
    cycle_id: 13341318,
    user_id: 20333,
    created_at: '2022-10-08T20:06:47.925+0000',
    updated_at: '2022-10-08T20:06:54.031+0000',
    version: 0,
    during: "['2022-10-08T02:38:25.300Z','2022-10-08T03:43:17.640Z')",
    timezone: null,
    timezone_offset: '-04:00',
    source: 'auto',
    score_state: 'complete',
    score_type: 'CARDIO',
    type: 'running',
    translated_type: 'Running',
    source_id: null,
    activity_v1_id: 8368923,
    scaled_intensity_score: 10.800812345916558,
    raw_intensity_score: 0.004340061660654779,
    percent_recorded: 1,
    kilojoules: 2343.9291505208453,
    max_heart_rate: 150,
    average_heart_rate: 131,
    zone_durations: {
      zone90_to100_duration: 0,
      zone80_to90_duration: 0,
      zone70_to80_duration: 951.694,
      zone60_to70_duration: 2908.962,
      zone50_to60_duration: 31.723,
      zone0_to50_duration: 0,
    },
    gps_data: null,
    cycle_day_start: [
      2022,
      10,
      7,
    ],
  },
  {
    id: '1c1779c9-7904-4456-9d0a-bf4627101722',
    cycle_id: 13375630,
    user_id: 20333,
    created_at: '2022-10-09T04:13:13.603+0000',
    updated_at: '2022-10-09T04:13:19.595+0000',
    version: 0,
    during: "['2022-10-09T02:47:24.413Z','2022-10-09T03:39:18.107Z')",
    timezone: null,
    timezone_offset: '-04:00',
    source: 'auto',
    score_state: 'complete',
    score_type: 'CARDIO',
    type: 'running',
    translated_type: 'Running',
    source_id: null,
    activity_v1_id: 8374144,
    scaled_intensity_score: 10.882973777334662,
    raw_intensity_score: 0.004410683700806644,
    percent_recorded: 1,
    kilojoules: 2100.1791721318755,
    max_heart_rate: 151,
    average_heart_rate: 135,
    zone_durations: {
      zone90_to100_duration: 0,
      zone80_to90_duration: 0,
      zone70_to80_duration: 1615.002,
      zone60_to70_duration: 1498.731,
      zone50_to60_duration: 0,
      zone0_to50_duration: 0,
    },
    gps_data: null,
    cycle_day_start: [
      2022,
      10,
      8,
    ],
  },
  {
    id: '9c9e2a80-09b5-4a8a-ab34-688078a29f42',
    cycle_id: 13566859,
    user_id: 20333,
    created_at: '2022-10-15T20:22:57.790+0000',
    updated_at: '2022-10-15T20:23:03.648+0000',
    version: 0,
    during: "['2022-10-15T19:18:41.023Z','2022-10-15T19:48:56.936Z')",
    timezone: null,
    timezone_offset: '-04:00',
    source: 'auto',
    score_state: 'complete',
    score_type: 'CARDIO',
    type: 'running',
    translated_type: 'Running',
    source_id: null,
    activity_v1_id: 8486758,
    scaled_intensity_score: 10.497843324588308,
    raw_intensity_score: 0.004086062647697403,
    percent_recorded: 1,
    kilojoules: 1461.846197642036,
    max_heart_rate: 177,
    average_heart_rate: 148,
    zone_durations: {
      zone90_to100_duration: 9.613,
      zone80_to90_duration: 849.795,
      zone70_to80_duration: 787.316,
      zone60_to70_duration: 109.588,
      zone50_to60_duration: 50.027,
      zone0_to50_duration: 9.613,
    },
    gps_data: null,
    cycle_day_start: [
      2022,
      10,
      15,
    ],
  },
  {
    id: '5900ba2f-dcc5-4322-a4ba-c57488331ed9',
    cycle_id: 13983355,
    user_id: 20333,
    created_at: '2022-10-29T04:14:50.140+0000',
    updated_at: '2022-10-29T04:14:56.440+0000',
    version: 0,
    during: "['2022-10-29T02:21:42.290Z','2022-10-29T03:42:26.327Z')",
    timezone: null,
    timezone_offset: '-04:00',
    source: 'auto',
    score_state: 'complete',
    score_type: 'CARDIO',
    type: 'weightlifting',
    translated_type: 'Weightlifting',
    source_id: null,
    activity_v1_id: 8734210,
    scaled_intensity_score: 10.284592465337322,
    raw_intensity_score: 0.003911549234377467,
    percent_recorded: 1,
    kilojoules: 2392.906770896477,
    max_heart_rate: 151,
    average_heart_rate: 122,
    zone_durations: {
      zone90_to100_duration: 0,
      zone80_to90_duration: 0,
      zone70_to80_duration: 389.329,
      zone60_to70_duration: 3394.423,
      zone50_to60_duration: 1060.324,
      zone0_to50_duration: 0,
    },
    gps_data: null,
    cycle_day_start: [
      2022,
      10,
      28,
    ],
  },
  {
    id: '2a01d3b4-2499-44fb-9db0-08e9047beb0e',
    cycle_id: 14013606,
    user_id: 20333,
    created_at: '2022-10-30T00:44:30.380+0000',
    updated_at: '2022-10-30T00:44:36.414+0000',
    version: 0,
    during: "['2022-10-29T23:36:25.354Z','2022-10-30T00:21:06.445Z')",
    timezone: null,
    timezone_offset: '-04:00',
    source: 'auto',
    score_state: 'complete',
    score_type: 'CARDIO',
    type: 'soccer',
    translated_type: 'Soccer',
    source_id: null,
    activity_v1_id: 8748002,
    scaled_intensity_score: 13.708092092102254,
    raw_intensity_score: 0.008185985519843127,
    percent_recorded: 1,
    kilojoules: 2356.9652990872178,
    max_heart_rate: 184,
    average_heart_rate: 151,
    zone_durations: {
      zone90_to100_duration: 212.448,
      zone80_to90_duration: 1398.711,
      zone70_to80_duration: 450.848,
      zone60_to70_duration: 509.495,
      zone50_to60_duration: 109.628,
      zone0_to50_duration: 0,
    },
    gps_data: null,
    cycle_day_start: [
      2022,
      10,
      29,
    ],
  },
  {
    id: 'cb456714-c6fb-4b80-ae58-f5a8c0f90bbc',
    cycle_id: 14064391,
    user_id: 20333,
    created_at: '2022-11-01T02:35:35.847+0000',
    updated_at: '2022-11-01T02:35:42.349+0000',
    version: 0,
    during: "['2022-11-01T00:12:04.693Z','2022-11-01T02:00:12.539Z')",
    timezone: null,
    timezone_offset: '-04:00',
    source: 'auto',
    score_state: 'complete',
    score_type: 'CARDIO',
    type: 'running',
    translated_type: 'Running',
    source_id: null,
    activity_v1_id: 8782609,
    scaled_intensity_score: 14.542277111678906,
    raw_intensity_score: 0.00986394608253406,
    percent_recorded: 1,
    kilojoules: 4251.101916980843,
    max_heart_rate: 180,
    average_heart_rate: 135,
    zone_durations: {
      zone90_to100_duration: 25.956,
      zone80_to90_duration: 932.471,
      zone70_to80_duration: 2158.126,
      zone60_to70_duration: 2766.625,
      zone50_to60_duration: 604.707,
      zone0_to50_duration: 0,
    },
    gps_data: null,
    cycle_day_start: [
      2022,
      10,
      31,
    ],
  },
];
