import CheckIcon from '@material-ui/icons/Check';
import styles from './rowMessage.module.scss';

type RowMessageProps = {
  message: string;
  visible: boolean;
  check: boolean;
};

function RowMessage({ message, visible, check } : RowMessageProps) {
  return (
    <div className={styles.rowMessageContainer} style={{ opacity: visible ? '1' : '0', transition: visible ? 'none' : 'opacity 0.6s ease-out' }}>
      {check && <CheckIcon /> }
      <div>
        {message}
      </div>
    </div>
  );
}

export default RowMessage;
