export const trackEvent = (eventName: string, eventProperties: any) => {
  window.analytics.track(eventName, eventProperties);
};

export const loadAnalytics = () => {
  if (window.analytics.load) {
    window.analytics.load(window._env.SEGMENT_KEY);
  }
};

type PathProperties = {
  accountId?: string;
  groupId?: string;
  hubId?: string;
  seatId?: string;
  memberId?: string;
  registerId?: string;
};

export const trackPath = (path: string) => {
  let returnPath = path;
  const accountIdMatch = path.match(/\/accounts\/([^/]+)\/([^/]+)/);
  const properties: PathProperties = {};
  if (accountIdMatch) {
    const accountId = accountIdMatch[1];
    returnPath = returnPath.replace(accountId, '{accountId}');
    properties.accountId = accountId;
  }
  const groupIdMatch = returnPath.match(/\/groups\/([^/]+)/);
  if (groupIdMatch) {
    const groupId = groupIdMatch[1];
    returnPath = returnPath.replace(groupId, '{groupId}');
    properties.groupId = groupId;
  }
  const hubIdMatch = returnPath.match(/\/hub\/([^/]+)/);
  if (hubIdMatch) {
    const hubMatch = hubIdMatch[1];
    if (hubMatch.indexOf(':') > 0) {
      returnPath = returnPath.replace(hubMatch, '{hubId}');
      properties.hubId = hubMatch;
    } else {
      returnPath = returnPath.replace(hubMatch, '{seatId}');
      properties.seatId = hubMatch;
    }
  }

  const progressMemberIdMatch = returnPath.match(/\/progress\/([\d/]+)/);
  if (progressMemberIdMatch) {
    const progressMemberId = progressMemberIdMatch[1];
    returnPath = returnPath.replace(progressMemberId, '{memberId}');
    properties.memberId = progressMemberId;
  }

  const permissionsMemberIdMatch = returnPath.match(/\/plan\/permissions\/([\d/]+)/);
  if (permissionsMemberIdMatch) {
    const permissionsMemberId = permissionsMemberIdMatch[1];
    returnPath = returnPath.replace(permissionsMemberId, '{memberId}');
    properties.memberId = permissionsMemberId;
  }

  const registerIdMatch = returnPath.match(/\/register\/([^/]+)/);
  if (registerIdMatch) {
    const registerId = registerIdMatch[1];
    returnPath = returnPath.replace(registerId, '{registerId}');
    properties.registerId = registerId;
  }

  trackEvent(`Screen - ${returnPath}`, properties);
  return {
    returnPath,
    properties,
  };
};

export default trackEvent;
