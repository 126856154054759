import React, { useEffect, useReducer, useState } from 'react';
import InputGroup, { InputGroupType } from 'components/inputGroup/inputGroup';
import { SUBTITLE_1, TITLE_3 } from 'components/text';
import ActionContent from 'components/actionContent/actionContent';
import ActionFooter from 'components/actionFooter/actionFooter';
import { hubClient } from 'api';
import { useParams, useNavigate } from 'react-router-dom';
import { HubEgg } from 'types/hub';
import { captureException } from '@sentry/react';
import Loading from 'loading';
import { useToast } from 'context/toast-context';
import {
  SetupHubReducerState, setupHubReducer, getActionButtonProps, getSecondaryButtonProps,
} from '../hubUtils';
import styles from './setupHubPage.module.scss';

const initialFormState: SetupHubReducerState = {
  hubName: '',
  hubDescription: '',
  hubMacAddress: '',
  error: null,
  setupHub: false,
};

function SetupHubPage() {
  const { accountId } = useParams<{ accountId: string }>();
  const { openToast } = useToast();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(setupHubReducer, initialFormState);
  const {
    hubName, hubDescription, hubMacAddress, setupHub,
  } = state;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const createHub = async () => {
      try {
        setLoading(true);
        const hubEgg: HubEgg = {
          salesforce_account_id: accountId,
          mac_address: hubMacAddress,
          name: hubName,
          description: hubDescription,
        };

        await hubClient.createHub(hubEgg);

        openToast(`${hubName} has been added to this Enterprise account.`);
      } catch (error) {
        captureException(error);
        openToast(`Something went wrong setting up ${hubName}`);
      } finally {
        setLoading(false);
        navigate(`/accounts/${accountId}/people/hub`);
      }
    };

    if (setupHub) {
      createHub();
    }
  }, [setupHub]);

  const handleInputChange = (field: string, value: string) => {
    let modifiedVal = value;

    if (field === 'hubMacAddress') {
      modifiedVal = value.toUpperCase();
    }

    dispatch({
      type: 'handleInputText',
      field,
      value: modifiedVal,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ActionContent>
        <TITLE_3>Set up a new hub</TITLE_3>
        <SUBTITLE_1>
          Please fill out the following fields and then select ‘Save’.
        </SUBTITLE_1>
        <div className={styles.form}>
          <div className={styles.dropdowns}>
            <InputGroup
              value={hubName}
              setValue={(val) => handleInputChange('hubName', val)}
              placeholder="Hub Name"
              label="Hub Name*"
              id="hub-name"
              type={InputGroupType.TEXT}
            />
            <InputGroup
              value={hubMacAddress}
              setValue={(val) => handleInputChange('hubMacAddress', val)}
              placeholder="AA:BB:CC:DD:EE:FF"
              label="MAC Address*"
              id="hub-mac-address"
              type={InputGroupType.TEXT}
            />
          </div>
          <InputGroup
            value={hubDescription}
            setValue={(val) => handleInputChange('hubDescription', val)}
            placeholder="Describe the hub in a few words..."
            label="Hub Description"
            id="hub-description"
            type={InputGroupType.TEXTAREA}
          />
        </div>

      </ActionContent>
      <ActionFooter
        state={state}
        getPrimaryButtonProps={() => getActionButtonProps(state)}
        getSecondaryButtonProps={getSecondaryButtonProps}
        dispatch={dispatch}
      />
    </>
  );
}

export default SetupHubPage;
