import { Button } from '@whoop/web-components';
import DefaultAvatar from 'assets/hub-default-user-profile.svg';
import MemberProfileComp from 'hub/memberProfilePage/memberProfileComp/memberProfileComp';
import { UseStatePropType } from 'types/useStatePropType';
import { ParsedHubUserProfile } from 'types/hub';
import styles from '../memberProfile.module.scss';
import { EditMemberProfileSteps } from '../editMemberProfile/utils';

type ViewMemberProfileProps = {
  setEditProfile: UseStatePropType<boolean>;
  profile: ParsedHubUserProfile;
  showEditProfileButton: boolean;
  addMember?: boolean;
};

function ViewMemberProfile({
  setEditProfile, profile, showEditProfileButton, addMember = false,
}: ViewMemberProfileProps) {
  return (
    <div>
      {profile && (
        <div className={styles.profileContent}>
          <div className={styles.detailHeader}>
            <img src={DefaultAvatar} alt="my_account" className={styles.avatar} />
            <div>
              <div className={styles.idText}>
                {profile.patientIdNumber}
              </div>
              <div className={styles.idDetailText}>
                Sensor ID:
                {' '}
                {profile.sensorId}
              </div>
            </div>
            {showEditProfileButton && (
              <div className={styles.editProfileButtons}>
                <Button
                  label="Edit Profile"
                  size="small"
                  variant="normal"
                  className={styles.boldButton}
                  onClick={() => setEditProfile(true)}
                />
              </div>
            )}
          </div>
          <MemberProfileComp
            addMember={addMember}
            state={
                {
                  ...profile,
                  step: EditMemberProfileSteps.Input,
                  error: null,
                  hubs: [],
                  sensorIds: [],
                }
}
            viewProfile
          />
        </div>
      ) }
    </div>
  );
}

export default ViewMemberProfile;
