import { HubStatus } from 'types/hub';

const HUB_STATUSES = [
  HubStatus.ALL,
  HubStatus.ONLINE,
  HubStatus.AWAITING_ACTIVATION,
  HubStatus.PENDING,
  HubStatus.OFFLINE,
];

export default HUB_STATUSES;
