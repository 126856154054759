import ActionContent from 'components/actionContent/actionContent';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import profileStyles from 'hub/memberProfilePage/memberProfile.module.scss';
import Profile, { ProfileData, UserInfo } from 'components/profile/profile';
import { getAllCollaborators } from 'api/dashboardApi';
import { dashboardRoleDisplay } from 'accountDetails/collaboratorsPage/roleDropdown/util';
import Loading from 'loading';
import { getCohortAccessForSpecificCollaborator } from 'api/cohortApi';
import { GroupUserAccess } from 'types/cohort';
import RemoveCollaborator from 'accountDetails/removeCollaborator/removeCollaborator';
import { useAuth } from 'context/auth-context';
import { useAccounts } from 'context/account-context';
import { Collaborator as CollaboratorType, RemoveCollaboratorType } from 'types/collaborator';
import { Profile as UserProfile } from 'types/auth';
import { DashboardRole } from 'types/dashboardUser';

type UserIdParams = {
  userId: string;
};

export const removalAccess = (
  checkAccess: (role: DashboardRole, greaterThanAccessOnly?: boolean) => boolean,
  rowUser: { user_id?: number; role: DashboardRole },
  currentUser: UserProfile,
): boolean => {
  if (rowUser.user_id === currentUser.id) {
    return false;
  }
  if (!checkAccess(DashboardRole.GROUP_MANAGER, true)) {
    return false;
  }
  return checkAccess(rowUser.role);
};

function Collaborator() {
  const { userId } = useParams<UserIdParams>();
  const [collaborator, setCollaborator] = useState<CollaboratorType>();
  const [groups, setGroups] = useState<GroupUserAccess[]>([]);
  const [loading, setLoading] = useState(true);
  const [removalModal, setRemovalModal] = useState(false);
  const [removalType, setRemovalType] = useState<RemoveCollaboratorType>(
    RemoveCollaboratorType.ACCOUNT,
  );
  const [removalGroup, setRemovalGroup] = useState<GroupUserAccess>(null);
  const { checkAccess } = useAccounts();
  const { user } = useAuth();

  const profileUser: UserInfo = useMemo(() => ({
    name: collaborator?.name,
    subheading: dashboardRoleDisplay[collaborator?.role],
    avatar_url: collaborator?.avatar_url,
    user_id: collaborator?.user_id,
    role: collaborator?.role,
  }), [collaborator]);

  const leftColumn: ProfileData[] = useMemo(() => [
    {
      heading: 'First Name',
      value: collaborator?.name.split(' ')[0],
    },
    {
      heading: 'Email',
      value: collaborator?.email,
    },
    {
      heading: 'Role',
      value: dashboardRoleDisplay[collaborator?.role],
    },
  ], [collaborator]);

  const rightColumn: ProfileData[] = useMemo(() => [
    {
      heading: 'Last Name',
      value: collaborator?.name.split(' ')[1],
    },
    {
      heading: 'Job Title',
      value: collaborator?.display_role,
    },
    {
      heading: 'Privacy Level',
      value: collaborator?.privacy_level,
    },
  ], [collaborator]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const allCollaborators = await getAllCollaborators();
      const collaboratorUser = allCollaborators.find((u) => u.user_id === parseInt(userId, 10));
      if (collaboratorUser) {
        const accountGroups = await getCohortAccessForSpecificCollaborator(
          collaboratorUser.user_id,
        );
        setGroups(accountGroups);
        setCollaborator(collaboratorUser);
      }
      setLoading(false);
    };
    fetchData();
  }, [removalModal]);

  const handleRemoveMemberFromGroup = async (group: GroupUserAccess) => {
    setRemovalGroup(group);
    setRemovalType(RemoveCollaboratorType.GROUP);
    setRemovalModal(true);
  };

  const handleRemoveMemberFromAccount = async () => {
    setRemovalType(RemoveCollaboratorType.ACCOUNT);
    setRemovalModal(true);
  };

  if (loading) {
    return <Loading />;
  }

  if (removalModal) {
    return (
      <RemoveCollaborator
        group={removalGroup}
        setModal={setRemovalModal}
        type={removalType}
        collaborators={[collaborator]}
      />
    );
  }

  return (
    <ActionContent>
      <div className={profileStyles.profileContent}>
        <Profile
          bottomHeader="GROUPS MANAGED"
          leftColumn={leftColumn}
          rightColumn={rightColumn}
          topHeader="ACCOUNT INFORMATION"
          user={profileUser}
          groups={groups}
          handleRemoveMemberFromGroup={handleRemoveMemberFromGroup}
          profileOptionDropdown={removalAccess(checkAccess, profileUser, user)}
          handleRemoveMemberFromAccount={handleRemoveMemberFromAccount}
        />
      </div>
    </ActionContent>
  );
}

export default Collaborator;
