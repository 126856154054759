export enum ShippingMethodType {
  BULK = 'bulk',
  DIRECT = 'direct',
}

export type InviteFlowReducerState = {
  shippingMethod: ShippingMethodType | null;
};

export type InviteFlowReducerAction = {
  type: 'setShippingMethod';
  shippingMethod: ShippingMethodType;
};
