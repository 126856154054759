import { HubSeatStatus } from 'types/hub';

export type SetupHubReducerState = {
  hubName: string;
  hubDescription: string;
  hubMacAddress: string;
  error: Error;
  setupHub: boolean;
};

export type SetupHubReducerAction =
  | { type: 'next' }
  | { type: 'handleInputText'; field: string; value: string }
  | { type: 'error'; payload: Error };

export const setupHubReducer = (
  state: SetupHubReducerState,
  action: SetupHubReducerAction,
): SetupHubReducerState => {
  switch (action.type) {
    case 'handleInputText':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'error':
      return {
        ...state,
        error: action.payload,
      };
    case 'next':
      return {
        ...state,
        setupHub: true,
      };
    default:
      return state;
  }
};

export const getActionButtonProps = (state: SetupHubReducerState) => {
  const disabled: boolean = !state.hubName || !state.hubMacAddress;

  return {
    theme: 'enterprise',
    variant: 'primary',
    label: 'Save',
    disabled,
  };
};

export const getSecondaryButtonProps = () => ({
  variant: 'link',
  label: 'Cancel',
});

export type HubMemberPageOption = { name: string; display_name?: string; active: boolean };

export const initialPageSizes: HubMemberPageOption[] = [
  { name: '12', active: true },
  { name: '24', active: false },
  { name: '36', active: false },
];

const HUB_SEAT_STATUSES = [
  'active',
  'pending',
  'archived',
];

export const initialStatusOptions: HubMemberPageOption[] = HUB_SEAT_STATUSES.map((status) => ({
  name: status,
  active: status === HubSeatStatus.Active,
}));
