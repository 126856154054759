/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Button } from '@whoop/web-components';
import Pagination from 'components/pagination';
import SearchBar from 'components/searchBar/searchBar';
import TableColumnHeader from 'components/tableColumnHeader';
import TableHeader from 'components/tableHeader';
import LoadingBar from 'components/tableLoadingBar';
import {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import {
  Cell,
  Column,
  HeaderGroup,
  Row,
  TableCommonProps,
  useGlobalFilter, useMountedLayoutEffect, usePagination, useRowSelect, useSortBy,
  useTable,
} from 'react-table';
import { cellProps } from 'tableUtils/tableProps';
import { UseStatePropType } from 'types/useStatePropType';
import { TableHeaderColumn } from 'types/utils';
import styles from './table.module.scss';

interface BasicTableInterface { }

type TableProps<D extends {}> = {
  columns: Column<D>[];
  data: D[];
  initialSortByField?: string;
  disableSort?: boolean;
  loading: boolean;
  heading?: string;
  unit?: string;
  search?: boolean;
  searchPlaceholder?: string;
  onRowClickHandler?: (originalRow: D) => void;
  noDataFoundMessage?: string;
  onMouseEnterHandler?: (i: number) => void;
  onMouseLeaveHandler?: () => void;
  getTableColumnHeader?: (column: Column<D>) => ReactElement;
  extraRowComponent?: ReactElement;
  createExtraRowComponent?: (row: Row<D>, i: number) => ReactElement;
  createExtraLeadingRowComponent?: (row: Row<D>, i: number) => ReactElement;
  getAdditionalRowProps?: (row: Row<D>) => TableCommonProps;
  customHeader?: ReactElement;
  pagination?: boolean;
  keywordFilter?: string;
  runReset?: boolean;
  tableName: string;
  // These two variables track whether we should hide the
  // table/header (respectively) when there are no results from a search
  hideOnSearch?: boolean;
  hideHeaderOnSearch?: boolean;
  // Variable to track whether or not we should display the message
  // in the "noDataFoundMessage" variable when the table has no data in it.
  showEmptyMessage?: boolean;
  setFilteredLength?: UseStatePropType<number>;
  tableLength: number;
  // Variable to track whether or not we should display the "Loading <tablename>" message
  displayLoadingMessage?: boolean;
  // Variable to track whether or not the header should be displayed.
  // If false, the header will never appear.
  displayHeader?: boolean;
  optionalControls?: ReactElement;
  hiddenColumns?: string[];
  totalCount?: number;
  manualPagination?: boolean;
  handlePageChange?: (pageIndex: number) => void;
  pageNum?: number;
  setSelectedRows?: UseStatePropType<Row<D>[]>;
  clearSelectedRows?: boolean;
  drilldownArrow?: boolean;
  onDrilldownClickHandler?: (originalRow: D) => void;
  tableClassName?: string;
  tableHeaderClassName?: string;
  showOptionalControls?: boolean;
  updateRow?: (row: D) => void;
  descendingDefaultSort?: boolean;
  displayColumnNames?: boolean;
  selectAllInitially?: boolean;
  useCustomSearchVal?: boolean;
  customSubHeading?: string;
  setVisibleRows?: UseStatePropType<Row<D>[]>;
  tableHeaderOnClickHandler?: (column: TableHeaderColumn) => void;
  pinnedRowId?: string;
};

function Table<D extends BasicTableInterface>({
  columns,
  data,
  initialSortByField,
  disableSort = false,
  loading,
  heading = 'tableheader',
  unit = 'table',
  search = true,
  searchPlaceholder,
  onRowClickHandler,
  noDataFoundMessage = 'No search results or data.',
  onMouseEnterHandler,
  onMouseLeaveHandler,
  getTableColumnHeader,
  createExtraRowComponent,
  createExtraLeadingRowComponent,
  getAdditionalRowProps = () => ({ className: '' }),
  customHeader,
  pagination = true,
  keywordFilter,
  runReset,
  tableName,
  hideOnSearch,
  hideHeaderOnSearch = false,
  showEmptyMessage = true,
  setFilteredLength,
  tableLength,
  displayHeader = true,
  optionalControls,
  hiddenColumns = [],
  totalCount,
  manualPagination = false,
  handlePageChange,
  pageNum = 0,
  setSelectedRows,
  clearSelectedRows = false,
  drilldownArrow = false,
  onDrilldownClickHandler,
  tableClassName = '',
  tableHeaderClassName = '',
  showOptionalControls = true,
  displayColumnNames = true,
  selectAllInitially = false,
  updateRow,
  descendingDefaultSort = false,
  useCustomSearchVal = false,
  customSubHeading = '',
  setVisibleRows,
  tableHeaderOnClickHandler,
  pinnedRowId = null,
}: TableProps<D>) {
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize, selectedRowIds },
    setGlobalFilter,
    toggleAllRowsSelected,
    isAllRowsSelected,
    selectedFlatRows,
  } = useTable<D>(
    {
      columns,
      data,
      loading,
      disableSortBy: disableSort,
      initialState: {
        pageIndex: pageNum,
        pageSize: tableLength,
        sortBy: disableSort ? [] : [{ id: initialSortByField, desc: descendingDefaultSort }],
        hiddenColumns,
      },
      manualPagination,
      pageCount: Math.floor(totalCount / tableLength) + 1,
      getRowId: useCallback(
        (originalRow: D, relativeIndex: number) => `${tableName}-${relativeIndex.toString()}`,
        [tableName],
      ),
      updateRow,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [rowToPin, setRowToPin] = useState<Row<D>>(null);
  const [pageToUse, setPageToUse] = useState(page);
  const [allRows, setAllRows] = useState<Row<D>[]>(rows);

  // Removing that row from the page if it exists so there aren't duplicated
  const removeRowFromPage = (newPage: Row<D>[]) => {
    newPage.slice();
    const pageIndexToRemove = newPage.findIndex(
      (rowInPage: Row<D>) => rowInPage.id === pinnedRowId,
    );
    if (pageIndexToRemove > -1) {
      newPage.splice(pageIndexToRemove, 1);
    }
    setPageToUse(newPage);
  };

  // Finding the row to pin by the id ;
  const setupRowToPin = () => {
    setRowToPin(null);
    const foundRowToPin = rows.find((row) => row.id === pinnedRowId);
    if (foundRowToPin) {
      setRowToPin(foundRowToPin);
      removeRowFromPage(page);
    }
  };

  useEffect(() => {
    if (setGlobalFilter) {
      setGlobalFilter(keywordFilter);
    }
  }, [setGlobalFilter, keywordFilter]);

  useEffect(() => {
    if (clearSelectedRows) {
      toggleAllRowsSelected(false);
    }
  }, [clearSelectedRows]);

  useEffect(() => {
    if (runReset) {
      gotoPage(1);
      setGlobalFilter(null);
    }
  }, [runReset, gotoPage, setGlobalFilter]);

  useEffect(() => {
    if (tableLength !== pageSize) {
      setPageSize(tableLength);
    }
  }, [tableLength]);

  useEffect(() => {
    if (setFilteredLength) setFilteredLength(pageToUse.length);
    setPageToUse(page);
    if (pinnedRowId) {
      removeRowFromPage(page);
    }
  }, [page, setFilteredLength]);

  useEffect(() => {
    if (handlePageChange) {
      handlePageChange(pageIndex);
    }
  }, [pageIndex]);

  useEffect(() => {
    if (selectAllInitially && !isAllRowsSelected) {
      toggleAllRowsSelected(true);
    }
    if (setVisibleRows) {
      setVisibleRows(rows);
    }
    if (pinnedRowId) {
      setupRowToPin();
    } else {
      setRowToPin(null);
    }
  }, [rows]);

  useEffect(() => {
    if (rowToPin) {
      prepareRow(rowToPin);
    }
  }, [rowToPin]);

  useEffect(() => {
    setAllRows(rows);
  }, [data]);

  useMountedLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setSelectedRows
    && setSelectedRows(allRows.filter((row) => Object.keys(selectedRowIds).includes(row.id)));
  }, [selectedFlatRows]);

  const paginationRangeStart: number = 1 + pageSize * pageIndex;
  const paginationRangeEnd: number = canNextPage
    ? (pageIndex + 1) * pageSize
    : (totalCount || rows.length);

  const showTable: boolean = page.length > 0
    || keywordFilter === ''
    || keywordFilter === null
    || !hideOnSearch;

  return (
    <div
      className={`${styles.tableContainer} ${showTable && styles.bottomMargin
      }`}
    >
      {/* If the table is visible OR if we aren't hiding the header
          on an empty search, render the header for the table. */}
      {(showTable || !hideHeaderOnSearch) && (
        <>
          {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
          {!displayHeader && <></>}
          {displayHeader
            && (customHeader || (
              <TableHeader
                heading={heading}
                total={data.length}
                unit={unit}
                customSubheading={customSubHeading}
                toggleAllRowsSelected={toggleAllRowsSelected}
              />
            ))}
        </>
      )}
      {search && !useCustomSearchVal && (
        <div className={styles.searchBar}>
          <SearchBar
            placeholder={searchPlaceholder}
            setSearchTerm={setGlobalFilter}
            value={searchValue}
            setValue={setSearchValue}
          />
        </div>
      )}
      {showOptionalControls && (
        <div className={styles.controlButtons}>
          {optionalControls}
        </div>
      )}
      {/* If the table is not visible and we want to show
          an empty message, render the empty message. */}
      {!showTable && showEmptyMessage && (
        <p className={`${styles.emptyTable} ${styles.noData}`}>
          {noDataFoundMessage}
        </p>
      )}
      {showTable && (
        <table
          className={`${styles.table} ${tableClassName}`}
          {...getTableBodyProps()}
          role="table"
        >
          {displayColumnNames && (
            <thead className={tableHeaderClassName}>
              {headerGroups.map((headerGroup) => (
                <tr
                  key={`${heading}TableHeader`}
                  className={styles.headerGroup}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column: Column<D>) => {
                    if (getTableColumnHeader) {
                      return getTableColumnHeader(column);
                    }
                    return (
                      <TableColumnHeader
                        column={column as HeaderGroup}
                        key={column.id}
                        tableHeaderOnClickHandler={tableHeaderOnClickHandler}
                      />
                    );
                  })}
                </tr>
              ))}
              <LoadingBar show={loading} />
            </thead>
          )}
          <tbody {...getTableBodyProps()} className={styles.tableBody}>
            {rowToPin?.getRowProps
              && (
              <tr
                className={styles.tableRow}
                {...rowToPin.getRowProps(getAdditionalRowProps(rowToPin))}
              >
                {rowToPin.cells.map((cell: Cell<D>) => (
                  <td
                    key={cell.row.id}
                    // Ignoring error that says TextAlign property
                    // can't be a string (but we are passing in the proper value)
                    // @ts-ignore
                    {...cell.getCellProps([cellProps(cell.column)])}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
              )}
            {pageToUse.length > 0
              && pageToUse.map((row: Row<D>, i) => {
                prepareRow(row);
                let { cells } = row;
                if (createExtraLeadingRowComponent) {
                  cells = row.cells.slice(1);
                }
                return (
                  <tr
                    className={styles.tableRow}
                    key={row.id}
                    onMouseEnter={() => onMouseEnterHandler && onMouseEnterHandler(i)}
                    onMouseLeave={() => onMouseLeaveHandler && onMouseLeaveHandler()}
                    onClick={() => onRowClickHandler
                      && onRowClickHandler(row.original)}
                    {...row.getRowProps(getAdditionalRowProps(row))}
                  >
                    {createExtraLeadingRowComponent
                      && createExtraLeadingRowComponent(row, i)}
                    {cells.map((cell: Cell<D>) => (
                      <td
                        key={cell.row.id}
                        // @ts-ignore
                        {...cell.getCellProps([cellProps(cell.column)])}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                    {createExtraRowComponent
                      && createExtraRowComponent(row, i)}
                    {drilldownArrow
                      && (
                        <td
                          className={styles.drilldownBtn}
                        >
                          <Button label={<ArrowForward />} variant="link" theme="enterprise" onClick={() => onDrilldownClickHandler && onDrilldownClickHandler(row.original)} />
                        </td>
                      )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {pagination && page.length > 0 && (
        <Pagination
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          rangeStart={paginationRangeStart}
          rangeEnd={paginationRangeEnd}
          total={totalCount || rows.length}
        />
      )}
      {!loading && (pageToUse.length === 0 || !pageToUse.length) && showTable && (
        <div className={styles.noData}>{noDataFoundMessage}</div>
      )}
    </div>
  );
}

export default Table;
