import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { sendForgotPasswordEmail } from 'api/authApi';
import { Button } from '@whoop/web-components';
import { ForgotFormProps, FormData, PageState } from '../forgotTypes';
import styles from '../forgot.module.scss';

function ForgotForm({ setPageState, setEmail }: ForgotFormProps) {
  const navigate = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async ({ email }) => {
    setEmail(email);
    try {
      await sendForgotPasswordEmail(email);
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Unhandled error: ${error.message}`);
      }
    }
    setPageState(PageState.CheckEmail);
  };

  return (
    <div className={styles.forgotPassword}>
      <div className={styles.infoText}>
        <h1>Forgot password?</h1>
        <p>
          Enter the email address associated with your account,
          and we’ll email you a link to reset your password.
        </p>
      </div>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.emailWrap}>
          <label htmlFor="emailLabel" id="emailLabel" className={styles.inputFieldLabel}>
            Email Address
            <input
              aria-labelledby="emailLabel"
              data-testid="email-input"
              className={styles.inputField}
              type="email"
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
              onChange={(e) => {
                setValue('email', e.target.value, { shouldValidate: true });
              }}
            />
          </label>

        </div>

        <div className={styles.buttonWrap}>
          <Button
            label="Send Reset Link"
            variant="primary"
            theme="enterprise"
            type="submit"
            data-testid="recoverBtn"
            disabled={!isValid}
            className={styles.resetLink}
          />
          <Button
            label="Return to Sign In"
            data-testid="returnToSignIn"
            onClick={() => navigate('/login')}
            className={styles.returnToSignIn}
          />
        </div>
      </form>
    </div>
  );
}

export default ForgotForm;
